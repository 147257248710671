import { AxiosError, AxiosResponse } from 'axios'
import { combineReducers } from 'redux'
import { ActionType, createReducer } from 'typesafe-actions'
import { responseConverter } from '../../utils'
import { lotterBetState, putLotterBetState } from './constants'
import * as lotterBetAction from './actionCreator'

export type lotterBetActionType = ActionType<typeof lotterBetAction>

const getLotterBet = createReducer<ReducerState<IPagination<IResponseBetUser>>, lotterBetActionType>(lotterBetState)
    .handleAction(lotterBetAction.getLotterBetAction.request, (state: ReducerState<IPagination<IResponseBetUser>>) => {
        return {
            ...state,
            isFetching: true,
        }
    })
    .handleAction(lotterBetAction.getLotterBetAction.success, (state: ReducerState<IPagination<IResponseBetUser>>, action: lotterBetActionType) => {
        const payload: AxiosResponse<IAPIResponse<IPagination<IResponseBetUser>>> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data: payload.data.data
        }
    })
    .handleAction(lotterBetAction.getLotterBetAction.failure, (state: ReducerState<IPagination<IResponseBetUser>>, action: lotterBetActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message,
        }
    })
    .handleAction(lotterBetAction.getLotterBetAction.cancel, () => lotterBetState)

const getBetRead = createReducer<ReducerState<IPagination<IResponseBetUser>>, lotterBetActionType>(lotterBetState)
    .handleAction(lotterBetAction.getBetReadAction.request, (state: ReducerState<IPagination<IResponseBetUser>>) => {
        return {
            ...state,
            isFetching: true,
        }
    })
    .handleAction(lotterBetAction.getBetReadAction.success, (state: ReducerState<IPagination<IResponseBetUser>>, action: ActionType<typeof lotterBetAction.getBetReadAction.success>) => {
        const payload: AxiosResponse<IAPIResponse<IPagination<IResponseBetUser>>> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        const data: IPagination<IResponseBetUser> = (action.meta)
            ? payload.data.data
            : ({
                ...payload.data.data,
                dataList: [
                    ...(state.data.dataList || []),
                    ...(payload.data.data.dataList || [])
                ]
            })
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data,
        }
    })
    .handleAction(lotterBetAction.getBetReadAction.failure, (state: ReducerState<IPagination<IResponseBetUser>>, action: lotterBetActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message,
        }
    })
    .handleAction(lotterBetAction.getBetReadAction.cancel, () => lotterBetState)

const putLotterBet = createReducer<ReducerState<IUpdateBetUser>, lotterBetActionType>(putLotterBetState)
    .handleAction(lotterBetAction.putLotterBetAction.request, (state: ReducerState<IUpdateBetUser>) => {
        return {
            ...state,
            isFetching: true,
        }
    })
    .handleAction(lotterBetAction.putLotterBetAction.success, (state: ReducerState<IUpdateBetUser>, action: lotterBetActionType) => {
        const payload: AxiosResponse<IAPIResponse<IUpdateBetUser>> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)

        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data: payload.data.data,
        }
    })
    .handleAction(lotterBetAction.putLotterBetAction.failure, (state: ReducerState<IUpdateBetUser>, action: lotterBetActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message,
        }
    })

export default combineReducers({
    getLotterBet,
    putLotterBet,
    getBetRead,
})