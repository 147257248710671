import colors from 'constants/colors'
import React, { FC } from 'react'
import './placeholderLoading.style.scss'

declare interface IPlaceholderLoadingProps {
    customCol?: { col: number; show: boolean; color?: string; size?: 'S' | 'M' | 'L' }[][]
}

const defaultProps: IPlaceholderLoadingProps = {
    customCol: [],
}

const PlaceholderLoading: FC<IPlaceholderLoadingProps> = ({
    customCol,
}) => {

    const ListComponent = () => {
        if (customCol!.length > 0) {
            const PlaceholderList = customCol!.map((rowProps, rowIndex) => {
                const ColList = rowProps.map((columnProps, columnIndex) => {
                    const columnClass = `col-${columnProps.col}`
                    if (!columnProps.show) {
                        return (<div className={columnClass} key={`column-${columnIndex}`} />)
                    }
                    const height = columnProps.size === 'S'
                        ? 8
                        : columnProps.size === 'M'
                            ? 12
                            : 18
                    return (
                        <div className={columnClass} key={`column-${columnIndex}`}>
                            <div
                                className="placeholder-loading"
                                style={{
                                    backgroundColor: columnProps.color || colors.SECONDARY_TEXT,
                                    height,
                                }}
                            />
                        </div>
                    )
                })
                return (<div className="row m0-b" key={`row-${rowIndex}`}>{ColList}</div>)
            })
            return (<>{PlaceholderList}</>)
        }
        return (
            <div className="row">
                <div className="col">
                    <div className="placeholder-loading" style={{ backgroundColor: colors.SECONDARY_TEXT }} />
                </div>
            </div>
        )
    }

    return (
        <div className="row">
            <div className="col">
                <ListComponent />
            </div>
        </div>
    )
}

PlaceholderLoading.defaultProps = defaultProps

export default PlaceholderLoading