import axios from 'axios'
import environment from 'constants/environment'
import { transformer } from 'utils'

const getCreditMeEndpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.getCreditMe}`
const getCreditMeDetailEndpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.getCreditMeDetail}`
const getUserMoneyEndpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.getUserMoney}`

const fetGetCreditMe = (query: ICreditQuery) => axios.get(`${getCreditMeEndpoint}${transformer.urlSearchParams(query)}`)
const fetGetUserMoney = (query: IGetUserMoneyQuery) => axios.get(`${getUserMoneyEndpoint}${transformer.urlSearchParams(query)}`)
const fetPutUserMoney = (body: IPutUserMoneyBody) => axios.put(`${getUserMoneyEndpoint}`, body)

const fetGetCreditMeDetail = (data: ICreditMeDetailReq) => axios.post(`${getCreditMeDetailEndpoint}?userid=${data.userid}`
    , {
        createdAt: data.createdAt,
        slug: data.slug,
        status: data.status
    })

export {
    fetGetCreditMe,
    fetGetCreditMeDetail,
    fetGetUserMoney,
    fetPutUserMoney,
}