import React, { FC } from 'react'
import './userDetailHeader.style.scss'
import { faCalendar, faDatabase, faIdCard, faUser,faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { get, isEmpty } from 'lodash'
import colors from 'constants/colors'
import { format } from 'date-fns'
import { AlertNotification, ButtonIcon } from 'components'
import iconAff from 'assets/images/global/affiliate/affiliate.png'
import { date } from 'utils'

interface IUserDetailHeaderProps { 
id: number | string
user: string
createdAt: string
updatedAt: string
affilate_me_uuid:string
}

const constants = {
	copied: 'คัดลอกแล้ว!',
}

const defaultProps: IUserDetailHeaderProps = {
    id: 0,
    user: '',
    createdAt: '',
    updatedAt: '',
    affilate_me_uuid:''
}

type DefaultProps = Readonly<typeof defaultProps>

const UserDetailHeader: FC<IUserDetailHeaderProps & DefaultProps> = (props) => {

    return (
        <div className="row mb-3 topic-content shadow-lg">
                <div className="col-12 pt-2">
                  <div className="row">
                    <div className="col-12 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-2">
                      <span className="body-1 responsive-font">
                        <FontAwesomeIcon
                          className="mr-2"
                          color={colors.PRIMARY_RED}
                          icon={faIdCard}
                        />
                        ID :
                        <span className="pl-1">
                          {get(props, 'id', '')}
                        </span>
                      </span>
                    </div>
                    <div className="col-12 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                      <span className="body-1 responsive-font">
                        <FontAwesomeIcon
                          className="mr-2"
                          color={colors.PRIMARY_RED}
                          icon={faUser}
                        />
                        USER :
                        <span className="pl-1 responsive-font">
                          {get(props, 'user', '')}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row mt-2">
                    <div className="col-12 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-2">
                      <span className="body-1 responsive-font">
                        <FontAwesomeIcon
                          className="mr-2"
                          color={colors.PRIMARY_RED}
                          icon={faDatabase}
                        />
                        Created Date :
                        <span className="pl-1 responsive-font">
                          {props.id !== '' || 0
                            ? format(date.calibratingTime(props.createdAt, true, true), 'dd/MM/yyyy')
                            : ''}
                        </span>
                      </span>
                    </div>
                    <div className="col-12 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                      <span className="body-1 responsive-font">
                        <FontAwesomeIcon
                          className="mr-2"
                          color={colors.PRIMARY_RED}
                          icon={faCalendar}
                        />
                        Updated Date :
                        <span className="pl-1 responsive-font">
                          {props.id !== '' || 0
                            ? format(date.calibratingTime(props.updatedAt, true, true), 'dd/MM/yyyy')
                            : ''}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row mt-2">
                    <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                      <span className="body-1 responsive-font d-flex justify-content-start align-items-center">
                        <img src={iconAff} className="mr-2 align-self-center iconAff" alt='affilate icon' />
                        <span className="text-nowrap">Affilate :</span>
                        <span className="pl-1">
                          {get(props, 'affilate_me_uuid', '')}
                        </span>
                        <span className="pl-1">
                          <ButtonIcon
                            id={`affilate_me_uuid`}
                            type='custom'
                            CustomIcon={
                              <FontAwesomeIcon
                                color={colors.PRIMARY_RED}
                                icon={faCopy}
                              />
                            }
                            onClick={()=>{
                              let uuid = get(props, 'affilate_me_uuid', '')
                              if (!isEmpty(uuid)) {
                                AlertNotification.show({ text: constants.copied, countdown: true })
                                navigator.clipboard.writeText(get(props, 'affilate_me_uuid', ''))
                              }
                            }}
                          />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
    )
}
UserDetailHeader.defaultProps = defaultProps

export default UserDetailHeader
