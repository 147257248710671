// eslint-disable-next-line import/no-anonymous-default-export
export default {
    PRIMARY_BG: '#252525',
    SECONDARY_BG: '#353a40',
    TERTIARY_BG: '#2C2E32',
    PRIMARY_TEXT: '#f7f7f7',
    SECONDARY_TEXT: '#c5c5c5',
    TERTIARY_TEXT: '#A7ABB7',
    GRAY_TEXT: '#919191',
    PRIMARY_PURPLE: '#714BDA',
    SECONDARY_PURPLE: '#845BDD',
    TERTIARY_PURPLE: '#E2DBF7',
    PRIMARY_GREEN: '#6CCFB8',
    SECONDARY_GREEN: '#71CE99',
    TERTIARY_GREEN: '#DFF5EB',
    QUATERNARY_GREEN: '#27A02C',
    PRIMARY_RED: '#f4263d',
    SECONDARY_RED: '#f65163',
    TERTIARY_RED: '#DD3E45',
    QUATERNARY_RED: '#D32F2F',
    PRIMARY_YELLOW: '#ffb751',
    SECONDARY_YELLOW: '#e5a448',
    PRIMARY_WHITE: '#ffffff',
    SECONDARY_WHITE: '#A8ABB6',
    PRIMARY_ORANGE: '#FF9800',
    TRANSPARENT: 'TRANSPARENT',
    PRIMARY_BLACK: '#000000',
}