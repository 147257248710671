import { AxiosError, AxiosResponse } from 'axios'
import { combineReducers } from 'redux'
import { ActionType, createReducer } from 'typesafe-actions'
import { responseConverter } from '../../utils'
import * as authenticationAction from './actionCreator'
import { loginState, tokenState } from './constants'

export type AuthenticationActionType = ActionType<typeof authenticationAction>

const login = createReducer<ReducerState<ILogin>, AuthenticationActionType>(loginState)
    .handleAction(authenticationAction.loginAction.request, (state: ReducerState<ILogin>) => {
        return {
            ...state,
            isFetching: true,
        }
    })
    .handleAction(authenticationAction.loginAction.success, (state: ReducerState<ILogin>, action: AuthenticationActionType) => {
        const payload: AxiosResponse<IAPIResponse<ILogin>> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data: payload.data.data,
        }
    })
    .handleAction(authenticationAction.loginAction.failure, (state: ReducerState<ILogin>, action: AuthenticationActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message,
        }
    })

const token = createReducer<IToken, AuthenticationActionType>(tokenState)
    .handleAction(authenticationAction.setAuthenticationAction.success, (state: IToken, action: AuthenticationActionType) => {
        const payload: ILogin = action.payload
        return {
            ...state,
            accessToken: payload.token,
            refreshToken: payload.token,
        }
    })
    .handleAction(authenticationAction.setAuthenticationAction.failure, (state: IToken, action: AuthenticationActionType) => state)


export default combineReducers({
    login,
    token,
})