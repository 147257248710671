import React, { Component, createRef, RefObject } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  Button,
  ButtonIcon,
  ErrorModal,
  InputNumber,
  InputSelect,
  LoadMore,
  PlaceholderLoading,
  // PlaceholderLoading,
  // RollerLoading,
  SelectorItem
} from 'components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faUser } from '@fortawesome/free-solid-svg-icons'
import { format } from 'date-fns'
import { th } from 'date-fns/locale'
import {
  capitalize,
  Dictionary,
  find,
  findKey,
  get,
  groupBy,
  isEmpty,
  isEqual,
  keys,
  map,
  noop,
  reverse,
  sortBy,
  xor
} from 'lodash'
import { date, interactive, number, transformer } from 'utils'
import { responseCode, responseMessage } from 'constants/response'
import colors from 'constants/colors'
// import InfiniteScroll from 'react-infinite-scroll-component'
import './transactionManagement.style.scss'
import {
  betStatusOptions,
  GAME_TYPE_NAME,
  LOTTO_GAME_TYPE_NAME,
  LOTTO_SLUG_NAME,
  LOTTO_TYPE,
  SLUG_GAME_COMPANY,
  TRANSACTION_STATUS,
  TRANSACTION_STATUS_COLOR
} from 'constants/variables'

import { NumberFormatValues } from 'react-number-format'
import { Formik, FormikProps } from 'formik'
import scheme from './models/scheme'
import { ConfirmModal } from 'components/Modal/components/Confirm'
import { Panginate } from 'components/Panginate'
import SimpleBar from 'simplebar-react'
import { faPen, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { BackButton } from 'components/BackButton'
import { TransactionFilterForm } from './components'
import initialValues from './models/initialValues'
import { INPUT } from 'constants/styles'
import timeCalibrated from 'utils/date'

import { utils, writeFile } from 'xlsx'
import axios, { AxiosResponse } from 'axios'
import environment from 'constants/environment'

const constants = {
  routeTextSecodary: 'Home',
  routeTextActive: 'Transaction Management',
  routeTextTertiary: 'OMEGA',
  back: 'Back',
  ok: 'OK',
  search: 'Search',
  labelSection: 'Transaction Management',
  labelSectionTh: 'จัดการรายการ',
  userSearch: 'ค้นหาผู้ใช้',
  round: (round: string) => `รอบที่ ${round}`,
  toggleEdit: 'แก้ไข',
  toggleSave: 'บันทึก',
  toggleCancel: 'ยกเลิก',
  reportDownload: 'ดาวน์โหลดรายงาน',
  gainLoss: 'กำไร ขาดทุน',
  depositWithdraw: 'ฝาก/ถอน',
  allBet: 'เดิมพันครั้งทั้งหมด',
  submitCaution: `โปรดระมัดระวังการแก้ไขข้อมูล
    หลังจากยืนยันการแก้ไขข้อมูลแล้วการแก้ไขข้อมูลดังกล่าวจะแจ้งเตือนไปยังผู้ดูแลระบบ
    เพื่อความปลอดภัยของผู้ใช้และระบบ`,
  errorModalTitle: 'เกิดข้อผิดพลาด',
  errorModalNotFound: 'ไม่พบข้อมูล',
  LOTTER: 'หวย',
  CASINO: 'คาสิโน',
  all: 'ทั้งหมด',
  start: 'เริ่มต้น',
  last: 'ล่าสุด',
  desktopQueryLimit: 50,
  mobileQueryLimit: 15,
}

type TTableTitleLabel =
  | 'รหัส'
  | 'ประเภทเกม'
  | 'ประเภทเดิมพัน'
  | 'เลข'
  | 'เดิมพัน'
  | 'ผลลัพธ์'
  | 'สถานะ'
  | 'เวลาแทง'
  | 'เวลาออกผล'
  | 'จัดการ'

const tableTitleConstants: { title: TTableTitleLabel }[] = [
  { title: 'รหัส' },
  { title: 'ประเภทเกม' },
  { title: 'ประเภทเดิมพัน' },
  { title: 'เลข' },
  { title: 'เดิมพัน' },
  { title: 'ผลลัพธ์' },
  { title: 'สถานะ' },
  { title: 'เวลาแทง' },
  { title: 'เวลาออกผล' },
  { title: 'จัดการ' }
]

const TRANSACTION_STATUS_ACTION: { [key in TTransactionStatus]?: string } = {
  CANCEL: 'ยกเลิก',
  WINNER: 'ได้',
  LOSER: 'เสีย'
}

const defaultUser: IUser = {
  id: 0,
  isFake: false,
  createdAt: '',
  affilateMeUuid: '',
  permission: '',
  phoneNumber: '',
  updatedAt: '',
  username: '',
  userBank: {
    id: 0,
    name: '',
    number: '',
    type: '',
    createdAt: '',
    updatedAt: ''
  },
  wallet: {
    id: 0,
    money: 0,
    createdAt: '',
    updatedAt: ''
  }
}

const defaultCSVFilterData: ICSVFilterData = {
  type: constants.all,
  subType: constants.all,
  status: constants.all,
  playTime: constants.all,
  resultTime: constants.all
}

const defaultProps: ITransactionManagementContainerActionProps &
  ITransactionManagementContainerProps = {
  getAllCode: 0,
  getAllError: '',
  getAllIsFetching: false,
  getAllUser() {
    noop()
  },
  clearAllUser() {
    noop()
  },
  userList: {
    dataList: [],
    limit: 0,
    page: 0,
    total: 0
  },
  getLotterBet() {
    noop()
  },
  clearLotterBet() {
    noop()
  },
  getLotterBetCode: 0,
  getLotterBetError: '',
  getLotterBetIsFetching: false,
  lotterBet: {
    dataList: [],
    limit: 0,
    page: 0,
    total: 0
  },
  getBetReadCode: 0,
  getBetReadError: '',
  getBetReadIsFetching: false,
  betRead: {
    dataList: [],
    limit: 0,
    page: 0,
    total: 0
  },
  getBetRead() {
    noop()
  },
  clearBetRead() {
    noop()
  },
  putLotterBetUser() {
    noop()
  },
  putLotterBetUserCode: 0,
  putLotterBetUserError: '',
  putLotterBetUserIsFetching: false,
  getStatUserReadCode: 0,
  getStatUserReadError: '',
  getStatUserReadIsFetching: false,
  statUser: {
    dataList: [],
    limit: 0,
    page: 0,
    total: 0
  },
  getStatUserRead() {
    noop()
  },
  clearStatUserRead() {
    noop()
  }
}

type DefaultProps = Readonly<typeof defaultProps>

class TransactionManagementContainer extends Component<
  ITransactionManagementContainerActionProps &
    ITransactionManagementContainerProps &
    RouteComponentProps &
    DefaultProps,
  ITransactionManagementContainerState
> {
  static defaultProps = defaultProps
  pageContainerRef: RefObject<HTMLDivElement> = createRef()
  scrollRef: RefObject<HTMLDivElement> = createRef()
  state: ITransactionManagementContainerState = {
    lotterBetQuery: {
      u_user_id: 0,
      limit: 50,
      page: 1
    },
    queryUserData: {
      permission: 'USER',
      search: '',
      page: 1,
      limit: 50
    },
    hasMore: true,
    hasMoreBetRead: true,
    spinIcon: false,
    isSearched: false,
    isSelected: false,
    queryTimer: setTimeout(() => {
      noop()
    }, 0),
    selectedUser: defaultUser,
    userList: [],
    currentPage: {
      selected: 0
    },
    tempEdit: [],
    tempOnEdit: false,
    tempOnEditId: 0,
    tempOnEditData: {
      betTransactionId: 0,
      result: '',
      status: 'CANCEL',
      value: ''
    },
    isCSVDownload: false,
    CSVFilterData: defaultCSVFilterData,
    loadMoreHeight: '0px'
  }

  componentDidMount() {
    this.handleCheckMobile()
    this.onGetUserLists()
    this.handleSetWindowHeight()
    window.addEventListener('resize', this.handleSetWindowHeight)
    window.addEventListener('scroll', this.handleSetWindowHeight)
  }

  componentDidUpdate(prevProps: ITransactionManagementContainerProps) {
    if (
      prevProps.getAllIsFetching !== this.props.getAllIsFetching &&
      !this.props.getAllIsFetching
    ) {
      if (this.props.getAllCode === responseCode.OK) {
        this.setState(
          {
            isSearched: false,
            userList: this.props.userList.dataList
          },
          () =>
            !this.state.tempOnEdit &&
            map(this.props.betRead.dataList, (data) =>
              this.setState({ tempEdit: { [data.betId]: false } })
            )
        )
        if (this.props.userList.page >= this.props.userList.total) {
          this.setState({ hasMore: false })
        } else {
          this.setState({ hasMore: true })
        }
      } else if (this.props.getAllCode === responseCode.NOT_FOUND) {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: `${this.props.getAllError}\n`,
          actionText: constants.ok
        })
        this.setState({ isSearched: false, userList: [] })
      }
    }
    if (
      prevProps.getLotterBetIsFetching !== this.props.getLotterBetIsFetching &&
      !this.props.getLotterBetIsFetching
    ) {
    }
    if (
      prevProps.getStatUserReadIsFetching !==
        this.props.getStatUserReadIsFetching &&
      !this.props.getStatUserReadIsFetching
    ) {
      if (this.props.getStatUserReadCode === responseCode.OK) {
      }
    }
    if (
      prevProps.getBetReadIsFetching !== this.props.getBetReadIsFetching &&
      !this.props.getBetReadIsFetching
    ) {
      if (this.props.getBetReadCode === responseCode.OK) {
        this.setState(
          (prevState) => ({
            lotterBetQuery: {
              ...prevState.lotterBetQuery,
              page: prevState.lotterBetQuery.page
            }
          }),
          () =>
            !this.state.tempOnEdit &&
            map(this.props.betRead.dataList, (data) =>
              this.setState({ tempEdit: { [data.betId]: false } })
            )
        )
        if (this.props.betRead.page >= this.props.betRead.total) {
          this.setState({ hasMoreBetRead: false })
        } else {
          this.setState({ hasMoreBetRead: true })
        }
        this.getTableRowHeight()
      } else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: `${this.props.getBetReadError}\n`,
          actionText: constants.ok
        })
      }
    }
    if (
      prevProps.putLotterBetUserIsFetching !==
        this.props.putLotterBetUserIsFetching &&
      !this.props.putLotterBetUserIsFetching
    ) {
      if (this.props.putLotterBetUserCode === responseCode.OK) {
        this.setState((prevState) => ({
          tempEdit: {
            ...prevState.tempEdit,
            [prevState.tempOnEditId]: false
          }
        }))
        this.onReloadLotterBet()
      } else {
        ErrorModal.show({
          action: () => {
            ErrorModal.hide()
            this.onGetLotterBet()
          },
          description: `${this.props.putLotterBetUserError}\n`,
          actionText: constants.ok
        })
      }
    }
  }
  componentWillUnmount() {
    this.props.clearAllUser()
    this.onClearLotterBet()
    this.props.clearStatUserRead()
    window.removeEventListener('resize', this.handleSetWindowHeight)
    window.removeEventListener('scroll', this.handleSetWindowHeight)
  }
  // Method
  handleSetWindowHeight = () => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)

    this.getTableRowHeight()
  }

  handleCheckMobile = () => {
    if (window.innerHeight < 500 || window.innerWidth < 500) {
      this.setState((prevState) => ({
        lotterBetQuery: {
          ...prevState.lotterBetQuery,
          limit: constants.mobileQueryLimit
        },
        queryUserData: {
          ...prevState.queryUserData,
          limit: constants.mobileQueryLimit
        }
      }))
    }
  }

  onGetUserLists = (isFirst: boolean = true) => {
    this.setState(
      (prevState, props) => ({
        queryUserData: {
          ...prevState.queryUserData,
          page: isFirst ? 1 : props.userList.page + 1
        }
      }),
      () => {
        this.props.getAllUser(this.state.queryUserData, isFirst)
      }
    )
    this.setState((_, props) => ({ userList: props.userList.dataList }))
  }

  onNextGetUserLists = (isFirst: boolean = false) => {
    if (isFirst) {
      this.onGetUserLists(isFirst)
    }
  }

  onGetLotterBet = (isFirst: boolean = true) => {
    this.setState(
      (prevState, props) => ({
        lotterBetQuery: {
          ...prevState.lotterBetQuery,
          page: isFirst ? 1 : prevState.lotterBetQuery.page + 1
        }
      }),
      () => {
        this.props.getBetRead(this.state.lotterBetQuery, isFirst)
        this.onGetStatUser()
      }
    )
  }
  onNextGetLotterBet = (isFirst: boolean = false) => {
    if (isFirst) {
      this.onGetLotterBet(isFirst)
    }
  }

  onGetStatUser = () => {
    this.props.getStatUserRead({
      u_user_id: this.state.lotterBetQuery.u_user_id,
      limit: 50,
      page: 1,
      bet_game_type: this.state.lotterBetQuery.bet_game_type,
      bet_game_company: this.state.lotterBetQuery.bet_game_company,
      created_start_time: this.state.lotterBetQuery.bet_created_start_time,
      created_end_time: this.state.lotterBetQuery.bet_created_end_time,
      updated_start_time: this.state.lotterBetQuery.bet_updated_start_time,
      updated_end_time: this.state.lotterBetQuery.bet_updated_end_time,
    })
  }

  onClearLotterBet = () => {
    this.setState(
      (prevState) => ({
        lotterBetQuery: { ...prevState.lotterBetQuery, page: 1, u_user_id: 0 },
        tempEdit: []
      })
    )
    this.props.clearBetRead()
  }

  onReloadLotterBet = () => {
    const lotterBetQuery = this.state.lotterBetQuery
    this.props.getBetRead({
      ...lotterBetQuery,
      page: 1,
      limit: lotterBetQuery.limit * lotterBetQuery.page
    }, true)
    this.onGetStatUser()
  }

  handleCancelEdit = () => {
    this.setState(
      (prevState) => ({
        tempEdit: {},
        tempOnEdit: false
      }),
      () => this.onReloadLotterBet()
    )
  }

  getFormatBetValue = (
    data: IUpdateBetUser | IV1RespReadBetTx,
    key: string
  ) => {
    if (data === null) return 0
    const val = Number(get(data, key, '0'))
    if (val % 1 === 0) {
      return val.toLocaleString('en-US')
    }
    return val.toLocaleString('en-US', {
      minimumFractionDigits: 2
    })
  }

  handleSubmit = (value: IUpdateBetUser) => {
    const edittingTransaction = find(this.props.betRead.dataList, {
      betId: value.betTransactionId
    })
    const originalValue: IUpdateBetUser = {
      betTransactionId: edittingTransaction!.betId,
      value: edittingTransaction!.betValue,
      result: edittingTransaction!.betResult,
      status: edittingTransaction!.betStatus,
    }

    if (isEqual(originalValue, value)) {
      this.setState(
        (prevState) => ({
          tempEdit: {
            ...prevState.tempEdit,
            [value.betTransactionId]:
              !prevState.tempEdit[value.betTransactionId]
          },
          tempOnEdit: false
        }),
        () => this.onReloadLotterBet()
      )
    } else {
      ConfirmModal.show({
        width: '98vw',
        maxWidth: '1200px',
        title: (
          <h6 className="primary-white-text scale p2-y">
            ยืนยันการทำรายการแก้ไข
          </h6>
        ),
        confirmButtonColor: colors.QUATERNARY_GREEN,
        cancelButtonColor: colors.QUATERNARY_RED,
        description: this.modalConfirmDescription(value, edittingTransaction!),
        padding: '14px 16px 0px 16px',
        cancel: () => {
          ConfirmModal.hide()
          this.setState(
            (prevState) => ({
              tempEdit: {
                ...prevState.tempEdit,
              }
            }),
            () => this.onReloadLotterBet()
          )
        },
        action: () => {
          ConfirmModal.hide()
          this.props.putLotterBetUser({
            ...value,
            result: value.status === 'WAIT' ? null : value.result
          })
          this.setState({
            tempOnEditId: value.betTransactionId,
            tempOnEdit: false
          })
        }
      })
    }
  }

  handleOnBack = () => {
    this.props.history.replace('/main')
  }

  handleOnClickBreadcrumb = (path: string) => {
    this.props.history.replace(path)
  }

  handleOnSearch = (isFirst: boolean) => {
    clearTimeout(this.state.queryTimer)
    this.setState((prevState, props) => ({
      queryUserData: {
        ...prevState.queryUserData,
        page: isFirst ? 1 : props.userList.page + 1
      },
      queryTimer: setTimeout(() => {
        this.props.getAllUser(this.state.queryUserData, isFirst)
        this.setState((_, props) => ({
          userList: props.userList.dataList,
          spinIcon: false,
          isSearched: false
        }))
      }, 1000)
    }))
  }

  handleInputQuerySearch = (values: any) => {
    const querySearch = values.target.value
    this.setState(
      (prevState) => ({
        isSearched: true,
        spinIcon: true,
        queryUserData: {
          ...prevState.queryUserData,
          search: querySearch
        }
      }),
      () => {
        this.handleClearUser()
        this.onClearLotterBet()
        this.handleOnSearch(this.state.isSearched)
      }
    )
  }

  handleSelectUser = (user: IUser) => {
    interactive.scrollTo(this.pageContainerRef)
    this.setState(
      (prevState) => ({
        lotterBetQuery: {
          ...prevState.lotterBetQuery,
          u_user_id: user.id,
          page: 1
        },
        queryUserData: {
          ...prevState.queryUserData,
          search: user.username
        },
        selectedUser: user,
        isSelected: true,
        tempOnEdit: false,
        isCSVDownload: true
      }),
      () => {
        // this.onGetLotterBet()
        this.handlePageClick({ selected: 1 }, true)
        // this.onGetStatUser()
      }
    )
  }

  handleClearUser = () => {
    this.props.clearAllUser()
    this.setState((_, props) => ({
      isSearched: false,
      userList: props.userList.dataList
    }))
  }

  handlePageClick = (
    { selected: selectedPage }: ISelectPage,
    reset: boolean
  ) => {
    if (!reset) {
      this.setState(
        (prevState) => ({
          currentPage: { selected: selectedPage },
          lotterBetQuery: {
            ...prevState.lotterBetQuery,
            page: reset ? 1 : selectedPage + 1
          },
          tempOnEdit: false
        }),
        () => {
          // this.props.getLotterBet(this.state.lotterBetQuery)
          this.props.getBetRead(this.state.lotterBetQuery)
        }
      )
    }
  }

  onPressEditHandler = (data: IV1RespReadBetTx) => {
    this.setState((prevState) => ({
      tempEdit: {
        [data.betId]: !prevState.tempEdit[data.betId]
      },
      tempOnEditData: {
        betTransactionId: data.betId,
        result: data.betResult || '0.00',
        status: data.betStatus as TTransactionStatus,
        value: data.betValue
      },
      tempOnEdit: true
    }))
  }

  onGenerateStatusItem = () => {
    const status: { value: string; name: string }[] = []
    return map(TRANSACTION_STATUS, (value, key) =>
      status.push({ name: value, value: key })
    )
  }

  limitMoney = (numbers: NumberFormatValues) => {
    const { floatValue } = numbers
    if (floatValue! <= 9999999999) {
      return floatValue! <= 9999999999
    } else if (floatValue === undefined) {
      return true
    } else {
      return false
    }
  }

  csvFilterDataFormat = (values: ITransactionFilterForm) => {
    const {
      type,
      subType,
      status,
      playTimeStart,
      playTimeEnd,
      resultTimeStart,
      resultTimeEnd
    } = values
    let csvBetType = constants.all
    if (!(type.includes('LOTTER') && type.includes('CASINO'))) {
      if (type.includes('LOTTER')) {
        csvBetType = constants.LOTTER
      } else if (type.includes('CASINO')) {
        csvBetType = constants.CASINO
      }
    }
    
    const csvStatus = status.length < 3 
      ? status.map((status) => get(TRANSACTION_STATUS, status, '')).join(', ')
      : constants.all
    const csvTimeFormat = (timeStart: string, timeEnd: string) => {
      let start = constants.start
      let end = constants.last
      if (timeStart) {
        start = date.formatDateTime(timeStart, true)
      }
      if (timeEnd) {
        end = date.formatDateTime(timeEnd, true)
      }
      if (timeStart || timeEnd) {
        return `${start} - ${end}`
      }
      return constants.all
    }
    return {
      type: csvBetType,
      status: csvStatus ? `${csvStatus}` : constants.all,
      subType: subType ? subType : constants.all,
      playTime: csvTimeFormat(playTimeStart, playTimeEnd),
      resultTime: csvTimeFormat(resultTimeStart, resultTimeEnd)
    }
  }

  onSubmitTransactionFilter = (values: ITransactionFilterForm) => {
    const {
      type,
      subType,
      status,
      playTimeStart,
      playTimeEnd,
      resultTimeStart,
      resultTimeEnd
    } = values

    let betType: IBetType = ''
    if (!(type.includes('LOTTER') && type.includes('CASINO'))) {
      if (type.includes('LOTTER')) {
        betType = 'LOTTER'
      } else if (type.includes('CASINO')) {
        betType = 'CASINO'
      }
    }
    let subTypeValue =
      findKey({ ...LOTTO_TYPE, ...SLUG_GAME_COMPANY }, (e) => e === subType) ||
      ''
    if (subTypeValue === 'SlotXO') {
      subTypeValue = 'JOKER'
    }

    let statusValue = ''
    const allBetStatusOptions = betStatusOptions.map(status => status.value)
    if (!isEmpty(xor(allBetStatusOptions, status)) && !isEmpty(status)) {
      //ถ้าเลือกทุกตัวเลือก กับค่าอาเรย์ว่าง ไม่ต้องมีค่า
      statusValue = `[${status}]`
    }

    const { formatDateTimeForApi, floorTime } = timeCalibrated
    const floorPlayTimeStart = floorTime(playTimeStart, 'start')
    const floorPlayTimeEnd = floorTime(playTimeEnd, 'end')
    const floorResultTimeStart = floorTime(resultTimeStart, 'start')
    const floorResultTimeEnd = floorTime(resultTimeEnd, 'end')
    const newCSVFilterData: ICSVFilterData = this.csvFilterDataFormat({
      ...values,
      playTimeStart: floorPlayTimeStart,
      playTimeEnd: floorPlayTimeEnd,
      resultTimeStart: floorResultTimeStart,
      resultTimeEnd: floorResultTimeEnd,
    })
    const transactionList = document.getElementsByClassName("transaction-table")[0]
    transactionList.scrollTo(0, 0)

    this.setState(
      (prevState) => ({
        isSearched: true,
        lotterBetQuery: {
          ...prevState.lotterBetQuery,
          bet_game_type: betType,
          bet_game_company: subTypeValue,
          bet_status: statusValue,
          bet_created_start_time: formatDateTimeForApi(floorPlayTimeStart),
          bet_created_end_time: formatDateTimeForApi(floorPlayTimeEnd),
          bet_updated_start_time: formatDateTimeForApi(floorResultTimeStart),
          bet_updated_end_time: formatDateTimeForApi(floorResultTimeEnd)
        },
        CSVFilterData: {
          ...newCSVFilterData
        },
        tempEdit: {},
        tempOnEdit: false
      }),
      () => {
        this.onGetLotterBet()
      }
    )
  }
  
  handleBackButton = () => {
    this.props.history.push('/main')
  }

  handleBackOnClickUserSearch = () => {
    this.onClearLotterBet()
    this.props.clearStatUserRead()
    this.props.clearAllUser()
    this.setState((prevState) => ({
      selectedUser: defaultUser,
      isSelected: false,
      lotterBetQuery: {
        ...prevState.lotterBetQuery,
        u_user_id: 0,
        page: 1
      }
    }))
    this.onGetUserLists()
  }
  getStatusColor = (status: TTransactionStatus) => {
    return (
      TRANSACTION_STATUS_COLOR[status as TTransactionStatus] ||
      colors.PRIMARY_ORANGE
    )
  }
  generateStatus = (status: TTransactionStatus) => {
    return (
      <span style={{ color: this.getStatusColor(status) }}>
        {TRANSACTION_STATUS[status as TTransactionStatus]}
      </span>
    )
  }

  onGetCreditinfo = (isFirst = false) => {
    if (isFirst) {
      this.getCreditInfo(isFirst)
    }
  }

  getCreditInfo = (isFirst = false) => {
    const queryData: APIPaginationQuery = {
      limit: 25,
      page: isFirst ? 1 : this.props.userList.page + 1
    }
    this.props.getAllUser(queryData, isFirst)
  }

  // Element
  renderUserListComponent = () => {
    const dateGroupFormat = (dateToBeFormatted: string) =>
      format(date.calibratingTime(dateToBeFormatted, true, true), 'yyyyMMdd', {
        locale: th
      })
    const userGroupList: Dictionary<IUser[]> = groupBy<IUser>(
      map(this.state.userList, (userLists) => ({
        ...userLists,
        groupTime: dateGroupFormat(userLists.createdAt)
      })),
      'groupTime'
    )
    if (isEmpty(userGroupList)) {
      return <></>
    }

    return (
      <div className='pt-2 h-100'>
        <LoadMore
          className="user-list-wrap"
          isLoad={this.props.getAllIsFetching}
          handleClickLoadMore={() => {
            this.onGetUserLists(false)
          }}
          style={{ overflowX: 'hidden' }}
          height={'100%'}
          dataLength={this.props.userList.dataList.length}
          next={this.onNextGetUserLists}
          scrollThreshold="15px"
          hasMore={this.state.hasMore}
          clickMeHeight={'clamp(42px, 8vh, 72px)'}
          margin={'0 -15px'}
          width={'auto'}
          loader={
            <div className="row p2-y">
              <div className="col">
                <PlaceholderLoading
                  customCol={[
                    [
                      {
                        col: 4,
                        color: colors.PRIMARY_TEXT,
                        show: true,
                        size: 'M'
                      },
                      { col: 6, show: false },
                      {
                        col: 2,
                        color: colors.PRIMARY_TEXT,
                        show: true,
                        size: 'M'
                      }
                    ],
                    [
                      {
                        col: 5,
                        color: colors.SECONDARY_TEXT,
                        show: true,
                        size: 'S'
                      }
                    ]
                  ]}
                />
              </div>
            </div>
          }
        >
          {reverse(keys(userGroupList).sort()).map((key, index) => {
            const UserList = map(
              reverse(sortBy(userGroupList[key], ['createdAt'])),
              (users, userIndex) => (
                <div
                  key={`user-${userIndex}`}
                  tabIndex={0}
                  onClick={() => this.handleSelectUser(users)}
                  className={`user-wrapper ${
                    users.id === this.state.selectedUser.id ? 'active' : ''
                  }`}
                >
                  <FontAwesomeIcon icon={faUser} color={colors.PRIMARY_RED} />
                  <span className="subtitle-2 scale">{users.username}</span>
                </div>
              )
            )
            return (
              <div className="row" key={`${key}-${index}`}>
                <div className="col-12 w-100 body-1 rounded">{UserList}</div>
              </div>
            )
          })}
        </LoadMore>
      </div>
    )
  }
  handleGenerateTableTitle: React.FC<{
    tableTitle: { title: string }[]
    isModal?: boolean
  }> = ({ tableTitle, isModal = false }) => (
    <>
      {map(tableTitle, (data, index) => {
        return (
          <th
            key={`label-item-${index}`}
            className={`sticky-header
              ${
                [4, 5].includes(index)
                  ? 'justify-content-end'
                  : [0, 1, 2, 3].includes(index)
                    ? 'justify-content-start'
                    : 'justify-content-center'
              }
              ${[0].includes(index) && 'flex-grow-2 mr-n3'}
              ${[1, 2].includes(index) && 'flex-grow-3'}
              ${[3].includes(index) && 'flex-grow-1 mr-n2'}
              
              ${[7, 8].includes(index) && 'pr-1'}
              ${[4, 5].includes(index) && 'pl-1'}
              
              ${[1].includes(index) ? (isModal ? 'pr-2' : 'pr-2 pl-1') : ''}
              ${[2].includes(index) ? (isModal ? 'pr-3' : '') : ''}
              ${[3].includes(index) && isModal && 'ml-n2 mr-n3'}
              ${[4].includes(index) && isModal && 'pl-2'}
              ${[5].includes(index) && isModal && 'pl-2'}
              ${[6].includes(index) && isModal && 'pl-2'}
              ${[9].includes(index) && isModal && 'd-none'}
            `}
          >
            {data.title}
          </th>
        )
      })}
    </>
  )

  handleStatusItem = ({
    item,
    ...selectProps
  }: IInputDefaultSelectProps<any>) => (
    <SelectorItem
      title={TRANSACTION_STATUS[item as TTransactionStatus]}
      textAlign="center"
      fontSize="clamp(7px, .8vw, 14px)"
      {...selectProps}
      backgroundColor={colors.TRANSPARENT}
      backgroundHoverColor={colors.TRANSPARENT}
      color={this.getStatusColor(item)}
    />
  )

  handleStatusSelectorItem = ({
    item,
    ...selectProps
  }: IInputDefaultSelectProps<any>) => (
    <SelectorItem
      title={TRANSACTION_STATUS[item as TTransactionStatus]}
      textAlign="start"
      fontSize="clamp(7px, .8vw, 14px)"
      {...selectProps}
      backgroundColor={colors.PRIMARY_WHITE}
      backgroundHoverColor={colors.SECONDARY_TEXT}
      color={colors.PRIMARY_BLACK}
    />
  )
  slugNameFormat = (data: IV1RespReadBetTx) => {
    let slugTranslate = ''
    let typeTranslate = ''
    if (data.betSlug.startsWith('LOTTER')) {
      const getLotterType = (type: string) => {
        const lendingType = get(type.split(/_([^_]*)$/, 1), '0', '')

        return LOTTO_SLUG_NAME[lendingType as TLottoSlug]
      }
      const getLotterRound = (round: string) => {
        const lendingRound = get(round.match(/(\d+)/), '0', '')
        return lendingRound.substr(lendingRound.length - 3)
      }
      const displayRound = getLotterRound(data.betSlug)
      const displayName = getLotterType(data.betSlug)

      slugTranslate = `${displayName} ${
        data.betSlug.startsWith('LOTTER_YEGEE')
          ? ` - ${constants.round(displayRound)}`
          : ''
      }`
      typeTranslate = LOTTO_GAME_TYPE_NAME[data.betType as TLottoGameType]
    } else {
      const gameNameUnderscore = (data.betSlug || '').replace(
        /GAME_CASINO_JOKER_|GAME_CASINO_THBGAME_|GAME_CASINO_AESEXY_|GAME_CASINO_DT_|GAME_CASINO_PGGAME_|GAME_CASINO_WMGAME_|GAME_CASINO_ALLBET_|CASINO_JOKER_|CASINO_PGGAME_|GAME_CASINO_JILIGAME_|GAME_CASINO_EVOLUTIONGAME_|GAME_CASINO_PPGAME_|CASINO_THBGAME_/,
        ''
      )
      const removeRound = gameNameUnderscore.substring(
        0,
        gameNameUnderscore.lastIndexOf('_')
      )
      const gameName = capitalize(removeRound)
      const ifW88Formater =
        gameName.search('w' || 'w88') !== -1
          ? gameName
              .split('_')
              .map((dl) => (['w', 'w88'].includes(dl) ? dl.toUpperCase() : dl))
              .join(' ')
          : gameName
      const transactionName = `${
        Object.entries(GAME_TYPE_NAME).filter((f) =>
          f[0].includes(data.betType)
        ).length !== 0
          ? GAME_TYPE_NAME[data.betType as TGameType]
          : data.betType
      } ${ifW88Formater}`

      slugTranslate = transactionName
      typeTranslate = GAME_TYPE_NAME[data.betType as TGameType] || '-'
    }
    return {
      slugTranslate,
      typeTranslate
    }
  }
  renderTableDataComponent: React.FC<{
    data: IV1RespReadBetTx
    index: number
  }> = ({ data, index }) => {
    const { slugTranslate, typeTranslate } = this.slugNameFormat(data)
    const iconFontSize = 'clamp(10px, 0.9vw, 14px)'
    const handleSubmit = () => {
      this.handleSubmit(this.state.tempOnEditData)
    }
    const handleReset = () => {
      this.handleCancelEdit()
    }
    return (
      <tr
        key={`column-${index}`}
        className={`active p-0 mx-2 transaction-row ${
          this.state.tempEdit[data.betId] && 'on-select'
        }`}
      >
        <td className="justify-content-start align-middle flex-grow-2 mr-n3">
          {data.betId}
        </td>
        <td className="justify-content-start align-middle flex-grow-3 pl-1 pr-2">
          {slugTranslate}
        </td>
        <td className="justify-content-start align-middle flex-grow-3">
          {typeTranslate}
        </td>
        <td className="justify-content-start align-middle flex-grow-1 mr-n2">
          {data.betNumber}
        </td>
        {this.state.tempEdit[data.betId] ? (
          <>
            <td className="justify-content-end pb-0 pl-1 h-100">
              <div className="w-75">
                <InputNumber
                  id={'value'}
                  className="transact-input scale"
                  name={'value'}
                  value={this.state.tempOnEditData.value || '0.00'}
                  textAlign={'right'}
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  allowNegative={false}
                  isAllowed={this.limitMoney}
                  onValueChange={({ value }) => 
                    this.setState((prevState) => ({
                      tempOnEditData: {
                        ...prevState.tempOnEditData,
                        value: value
                      }
                    }))}
                  hiddenErrorBlock
                  fontSize="inherit"
                  underlineSpace="calc(-1% - 1px)"
                  verticalAlign='bottom'
                />
              </div>
            </td>
            <td className="justify-content-end pb-0 pl-1 h-100">
              <div className="w-75">
                <InputNumber
                  name={'result'}
                  className={'transact-input scale'}
                  id={'result'}
                  value={this.state.tempOnEditData.result! || '0.00'}
                  textAlign={'right'}
                  thousandSeparator
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale
                  isAllowed={this.limitMoney}
                  onValueChange={({ value }) => 
                    this.setState((prevState) => ({
                      tempOnEditData: {
                        ...prevState.tempOnEditData,
                        result: value
                      }
                    }))}
                  hiddenErrorBlock
                  fontSize="inherit"
                  underlineSpace="calc(-1% - 1px)"
                  verticalAlign='bottom'
                />
              </div>
            </td>
            <td className="justify-content-end py-0 pt-1 h-100 status-select">
              <div className="align-self-center">
                <InputSelect<TGameStatus>
                  key={index}
                  name={`status`}
                  items={map(
                    TRANSACTION_STATUS_ACTION,
                    (_, key) => key as TGameStatus
                  )}
                  value={this.state.tempOnEditData.status}
                  onChange={(selected) => {
                    this.setState((prevState) => ({
                      tempOnEditData: {
                        ...prevState.tempOnEditData,
                        status: selected
                      }
                    }))
                  }}
                  RenderSelected={this.handleStatusItem}
                  RenderSelector={this.handleStatusSelectorItem}
                  height="clamp(11px, 1.2vw, 25px)"
                  width="clamp(30px, 4vw, 100px)"
                  border={INPUT.BORDER1PX}
                  borderRadius={'2px'}
                  iconColor={colors.PRIMARY_WHITE}
                />
              </div>
            </td>
          </>
        ) : (
          <>
            <td className="justify-content-end text-right pl-1 align-middle">
              {this.getFormatBetValue(data, 'betValue')}
            </td>
            <td className="justify-content-end text-right pl-1 align-middle">
              {this.getFormatBetValue(data, 'betResult')}
            </td>
            <td className="justify-content-center align-middle">
              {this.generateStatus(data.betStatus as TTransactionStatus)}
            </td>
          </>
        )}
        <td className="justify-content-center align-middled date-text pr-1">
          {date.formatDateTime(data.betCreatedAt, true, true)}
        </td>
        <td className="justify-content-center align-middle date-text pr-1">
          {date.formatDateTime(data.betUpdatedAt, true, true)}
        </td>
        <td className="justify-content-center align-middle icon-gap">
          {this.state.tempEdit[data.betId] ? (
            <>
              <ButtonIcon
                id={`confirm-edit-transation-${data.betId}`}
                type="custom"
                CustomIcon={
                  <FontAwesomeIcon
                    color={colors.PRIMARY_GREEN}
                    icon={faCheck}
                  />
                }
                onClick={handleSubmit}
                fontSize={iconFontSize}
                disableHover
                disabled={
                  isEmpty(this.state.tempOnEditData.value) ||
                  isEmpty(this.state.tempOnEditData.result)
                }
              />
              <ButtonIcon
                id={`cancel-edit-transation-${data.betId}`}
                type="custom"
                onClick={handleReset}
                fontSize={iconFontSize}
                disableHover
                CustomIcon={
                  <FontAwesomeIcon color={colors.PRIMARY_RED} icon={faTimes} />
                }
              />
            </>
          ) : (
            <ButtonIcon
              id={`edit-transation-${data.betId}`}
              type="custom"
              disableHover
              CustomIcon={
                <FontAwesomeIcon color={colors.PRIMARY_PURPLE} icon={faPen} />
              }
              onClick={() => this.onPressEditHandler(data)}
              fontSize={iconFontSize}
            />
          )}
        </td>
      </tr>
    )
  }

  handleGenerateTableData = () => {
    const RenderTableData = this.renderTableDataComponent
    const tableData = isEmpty(this.props.betRead.dataList) ? (
      <></>
    ) : (
      map(this.props.betRead.dataList, (data, index) => {
        return ( <RenderTableData key={`table-data-id-${data.betId}`} data={data} index={index} /> )
      })
    )
    return <>{tableData}</>
  }

  getTableRowHeight = () => {
    const allRow: HTMLCollection = document.getElementsByClassName('transaction-row')
    if (allRow.length < 3)
      return
    const lastHeight = allRow[allRow.length - 1].getBoundingClientRect().height
    const secondLastHeight = allRow[allRow.length - 2].getBoundingClientRect().height

    const twoLastHeight = lastHeight + secondLastHeight
    this.setState({
      loadMoreHeight: `${twoLastHeight + 2}px`
    })
  }

  renderTableData = () => {
    const RenderTableTitle = this.handleGenerateTableTitle
    const RenderTableData = this.handleGenerateTableData
    return (
      <table className="ea_table table-dark table-grid table-text scale">
        <thead>
          <tr>
            <RenderTableTitle tableTitle={tableTitleConstants} />
          </tr>
        </thead>
        <tbody style={{ overflowY: 'hidden' }}>
          <LoadMore
            isLoad={this.props.getBetReadIsFetching}
            handleClickLoadMore={() => {
              this.onGetLotterBet(false)
            }}
            style={{ overflowX: 'hidden', overflowY: 'scroll' }}
            height={'100%'}
            className="transaction-table"
            dataLength={this.props.betRead.dataList.length}
            next={this.onNextGetLotterBet}
            scrollThreshold="15px"
            hasMore={this.state.hasMoreBetRead}
            clickMeHeight={this.state.loadMoreHeight}
            loader={
              <div className="row p2-y">
                <div className="col">
                  <PlaceholderLoading
                    customCol={[
                      [
                        {
                          col: 4,
                          color: colors.PRIMARY_TEXT,
                          show: true,
                          size: 'M'
                        },
                        { col: 6, show: false },
                        {
                          col: 2,
                          color: colors.PRIMARY_TEXT,
                          show: true,
                          size: 'M'
                        }
                      ],
                      [
                        {
                          col: 5,
                          color: colors.SECONDARY_TEXT,
                          show: true,
                          size: 'S'
                        }
                      ]
                    ]}
                  />
                </div>
              </div>
            }
          >
            <RenderTableData />
          </LoadMore>
          {/* <RenderTableData /> */}
        </tbody>
      </table>
    )
  }
  handlePagination = () => (
    <div className="row m1-t">
      <div className="col">
        {isEmpty(this.props.betRead.dataList) ? (
          <></>
        ) : (
          <Panginate
            pageTotal={this.props.betRead.total || 0}
            forcePage={this.state.lotterBetQuery.page - 1}
            initialPage={this.state.lotterBetQuery.page - 1}
            pageRangeDisplayed={this.state.currentPage.selected}
            onPageChange={(data) => this.handlePageClick(data, false)}
          />
        )}
      </div>
    </div>
  )
  generateNumberValueChange = (oldValue: string | 0, newValue?: string | 0) => {
    // const changeTo = <>&nbsp;&gt;&nbsp;</>
    let valueChangeTo = <></>
    if (newValue !== oldValue) {
      valueChangeTo = (
        <>
          <span>&nbsp;&gt;&nbsp;</span>
          <span style={{ color: colors.SECONDARY_PURPLE, whiteSpace: 'pre' }}>{newValue}</span>
        </>
      )
    }
    const render = (
      <span>
        {oldValue}
        {valueChangeTo}
      </span>
    )
    return render
  }
  modalConfirmDescription = (value: IUpdateBetUser, data: IV1RespReadBetTx) => {
    const RenderHeader = this.handleGenerateTableTitle
    const { slugTranslate, typeTranslate } = this.slugNameFormat(data)
    const changeTo = <>&nbsp;&gt;&nbsp;</>
    const RenderValue = () => {
      const oldValue = this.getFormatBetValue(data, 'betValue')
      const newvalue = this.getFormatBetValue(value, 'value')

      return this.generateNumberValueChange(oldValue, newvalue)
    }
    const RenderResult = () => {
      const oldResult = this.getFormatBetValue(data, 'betResult')
      const newResult = this.getFormatBetValue(value, 'result')

      return this.generateNumberValueChange(oldResult, newResult)
    }
    const RenderStatus = () => {
      const status = TRANSACTION_STATUS[data.betStatus as TTransactionStatus]
      const changeStatus =
        TRANSACTION_STATUS[value.status as TTransactionStatus]
      let renderStatus = this.generateStatus(
        data.betStatus as TTransactionStatus
      )
      if (status !== changeStatus) {
        renderStatus = (
          <span>
            {this.generateStatus(data.betStatus as TTransactionStatus)}
            {changeTo}
            {this.generateStatus(value.status as TTransactionStatus)}
          </span>
        )
      }
      return renderStatus
    }

    const RenderBody = () => (
      <tr
        className={`active primary-transparent-bg d-flex p-0 ${
          this.state.tempEdit[data.betId] && 'on-select'
        }`}
      >
        <td className="justify-content-start align-middle flex-grow-2 mr-n3">
          {data.betId}
        </td>
        <td className="justify-content-start align-middle text-left flex-grow-3 pr-2">
          {slugTranslate}
        </td>
        <td className="justify-content-start align-middle text-left flex-grow-3 pr-3">
          {typeTranslate}
        </td>
        <td className="justify-content-start align-middle text-left flex-grow-1 z-10 ml-n2 mr-n3">
          {data.betNumber}
        </td>
        <td className="justify-content-end align-middle text-right pl-2">
          <RenderValue />
        </td>
        <td className="justify-content-end align-middle text-right pl-2">
          <RenderResult />
        </td>
        <td className="justify-content-center pl-2 align-middle">
          <RenderStatus />
        </td>
        <td className="justify-content-center align-middle text-left pr-1">
          {date.formatDateTime(data.betCreatedAt, true, true)}
        </td>
        <td className="justify-content-center align-middle text-left pr-1">
          {date.formatDateTime(data.betUpdatedAt, true, true)}
        </td>
      </tr>
    )

    return (
      <>
        <div className="modal-bet-transaction-table">
          <div className="detail-content">
            <table className="ea_table table-dark table-grid table-text scale-modal w-100 primary-transparent-bg">
              <thead className="p-0">
                <tr>
                  <RenderHeader
                    tableTitle={tableTitleConstants}
                    isModal
                  />
                </tr>
              </thead>
              <tbody className="p-0 primary-transparent-bg">
                <RenderBody />
              </tbody>
            </table>
          </div>
        </div>
        <div className="tertiary-red-text input-text-scale mt-2">
          {constants.submitCaution}
        </div>
      </>
    )
  }
  renderTransactionFilterForm = (): JSX.Element => {
    const TransactionFilterFormComponent = (
      formProps: FormikProps<ITransactionFilterForm>
    ) => {
      const { isSelected, queryUserData: { search }} = this.state
      return <TransactionFilterForm {...formProps} 
        onClickUserSearch={this.handleBackOnClickUserSearch}
        isUserSelected={isSelected}
        usernameFieldValue={search!}
        handleUserSearchChange={this.handleInputQuerySearch} />
    }
    const initValue = {
      ...initialValues.betQuery,
      usernameSearch: this.state.selectedUser.username
    }
    return (
      <Formik
        onSubmit={this.onSubmitTransactionFilter}
        validationSchema={scheme.betQuery}
        initialValues={initValue}
        enableReinitialize
      >
        {TransactionFilterFormComponent}
      </Formik>
    )
  }
  renderUserQueryAndStatPanel = (): JSX.Element => {
    const RenderTransactionFilterForm = this.renderTransactionFilterForm

    const RenderUserStat = () => {
      if (!this.state.isSelected){
        return <></>
      }
      return (
      <div className="user-stat">
        <div className="row mx-0 justify-content-between align-items-center primary-white-text subtitle-4 scale">
          <span>{constants.gainLoss}</span>
          <span>
            {number.addComma(
              get(this.props.statUser, 'dataList[0].benefit', 0)
            )}
          </span>
        </div>
        <div className="row mx-0 justify-content-between align-items-center primary-white-text subtitle-4 scale">
          <span>{constants.depositWithdraw}</span>
          <span>
            {number.addComma(
              get(this.props.statUser, 'dataList[0].deposit', 0)
            )}
            /
            {number.addComma(
              get(this.props.statUser, 'dataList[0].withdraw', 0)
            )}
          </span>
        </div>
        <div className="row mx-0 justify-content-between align-items-center primary-white-text subtitle-4 scale">
          <span>{constants.allBet}</span>
          <span>
            {number.addComma(
              get(this.props.statUser, 'dataList[0].betCountTotal', 0)
            )}
          </span>
        </div>
      </div>
      )}
    return (
      <>
        <RenderTransactionFilterForm />
        <RenderUserStat />
      </>
    )
  }
  renderUserSearch = () => {
    const RenderUserListComponent = this.renderUserListComponent
    if (this.state.isSelected)
      return <></>
    return (
      <>
        <RenderUserListComponent />
        <FontAwesomeIcon
          icon={faSpinner}
          className={`fa-spin`}
          color={'white'}
          style={
            this.state.spinIcon
              ? { visibility: 'unset' }
              : { visibility: 'hidden', display: 'none' }
          }
        />
      </>
    )
  }
  handleCSVDownload = () => {
    //get all transaction then save in file
    const betReadEndpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.getBetRead}`
    const betReadQuery = {
      ...this.state.lotterBetQuery,
      page: 1,
      limit: 9999
    }
    this.setState({ isCSVDownload: false })
    axios
      .get<AxiosResponse<APIPagination<IV1RespReadBetTx, any, any>>>(
        `${betReadEndpoint}${transformer.urlSearchParams(betReadQuery)}`
      )
      .then((res) => {
        this.setState({ isCSVDownload: true })
        const { data } = res
        let dataArray: Array<object> = []
        const username = get(data.data, 'dataList[0].uUsername', '')
        const { type, subType, status, playTime, resultTime } =
          this.state.CSVFilterData
        const { withdraw, deposit, benefit, betCountTotal } =
          this.props.statUser!.dataList[0]
        data.data.dataList.forEach((item) => {
          const { slugTranslate, typeTranslate } = this.slugNameFormat(item)
          let objectData = {
            col1: item.betId,
            col2: slugTranslate,
            col3: typeTranslate,
            col4: item.betNumber,
            col5: this.getFormatBetValue(item, 'betValue'),
            col6: this.getFormatBetValue(item, 'betResult'),
            col7: TRANSACTION_STATUS[item.betStatus as TTransactionStatus],
            col8: date.formatDateTime(item.betCreatedAt, true),
            col9: date.formatDateTime(item.betUpdatedAt, true)
          }
          dataArray.push(objectData)
        })

        const csvData = [
          { col1: 'รายงานการเดิมพัน' },
          { col1: 'ผู้ใช้', col2: username },

          { col1: 'ประเภท', col2: type },
          { col1: 'ย่อย', col2: subType },
          { col1: 'สถานะ', col2: status },
          { col1: 'เวลาแทง', col2: playTime },
          { col1: 'เวลาออกผล', col2: resultTime },
          {},
          { col1: 'กำไร ขาดทุน', col2: number.addComma(benefit) },
          {
            col1: 'ฝาก/ถอน',
            col2: `${number.addComma(deposit)}/${number.addComma(withdraw)}`
          },
          { col1: 'เดิมพันครั้งทั้งหมด', col2: number.addComma(betCountTotal) },
          {},
          {
            col1: 'รหัส',
            col2: 'ประเภทเกม',
            col3: 'ประเภทเดิมพัน',
            col4: 'เลข',
            col5: 'เดิมพัน',
            col6: 'ผลลัพธ์',
            col7: 'สถานะ',
            col8: 'เวลาแทง',
            col9: 'เวลาออกผล'
          }
        ]

        const worksheet = utils.json_to_sheet(csvData.concat(dataArray), {
          skipHeader: true
        })
        worksheet['!cols'] = [
          { width: 17 },
          { width: 30 },
          { width: 15 },
          { width: 12 },
          { width: 12 },
          { width: 12 },
          { width: 14 },
          { width: 16 }
        ]

        const workbook = utils.book_new()
        utils.book_append_sheet(workbook, worksheet, 'Sheet1')
        writeFile(
          workbook,
          `ข้อมูลเดิมพัน${username}_${new Date().getDate()}/${new Date().getMonth()}/${
            new Date().getFullYear() + 543
          }.csv`
        )
      })
      .catch((err) => {
        this.setState({ isCSVDownload: true })
        ErrorModal.show({
          title: constants.errorModalTitle,
          action: ErrorModal.hide,
          description: get(
            responseMessage,
            this.props.getBetReadCode,
            constants.errorModalNotFound
          )
        })
      })
  }
  render() {
    const RenderTableData = this.renderTableData
    const RenderUserQueryAndStatPanel = this.renderUserQueryAndStatPanel
    const RenderUserSearch = this.renderUserSearch

    return (
      <div className='transaction-management'>
      <div
        className="container-fluid px-0 page-container"
        ref={this.pageContainerRef}
      >
        <div className="row w-100 mx-auto page-header align-items-center justify-content-between">
          <BackButton
            titleText={constants.labelSectionTh}
            onClick={this.handleBackButton}
          />
          <Button
            id={'report-download'}
            text={constants.reportDownload}
            size="medium"
            borderRadius="5px"
            backgroundColor={colors.PRIMARY_PURPLE}
            backgroundHoverColor={colors.SECONDARY_PURPLE}
            width="20%"
            height="clamp(24px, 2.5vw, 40px)"
            fontSize="clamp(10px, 1.2vw, 16px)"
            textColor={colors.PRIMARY_WHITE}
            onClick={this.handleCSVDownload}
            disabled={isEmpty(this.props.betRead.dataList)}
          />
        </div>
        <div className="row mx-auto w-100 h-100 justify-content-end content-wrapper rounded pt-0">
          <div className="input-wrapper-transaction h-100 d-flex flex-column pl-0">
            <div className="h-100 w-100 d-flex flex-column">
                <RenderUserQueryAndStatPanel />
                <RenderUserSearch />
            </div>
          </div>
          <div className="mb-1 detail-wrapper-transaction h-100 px-0">
            <SimpleBar
              className="scroll h-100 rounded-lg"
              style={{ overflowY: 'hidden', width: '100%' }}
            >
              <div className="col-12 detail-content h-100">
                <RenderTableData />
              </div>
            </SimpleBar>
            {/* <RenderPagination /> */}
          </div>
        </div>
      </div>
      </div>
    )
  }
}

export default TransactionManagementContainer
