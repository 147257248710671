import { responseCode } from 'constants/response'
import { AxiosError, AxiosResponse } from 'axios'
import { combineReducers } from 'redux'
import { ActionType, createReducer } from 'typesafe-actions'
import { responseConverter, transformer } from 'utils'
import * as configLottoAction from './actionCreator'
import {
  initialLottoCurrentResultState,
  initialLottoResultState,
  initialLottoReverseState
} from './constants'

export type configLottoActionType = ActionType<typeof configLottoAction>

const result = createReducer<
  ReducerState<ILotterySchedule[]>,
  configLottoActionType
>(initialLottoResultState)
  .handleAction(
    configLottoAction.postLottoResultAction.request,
    (state: ReducerState<ILotterySchedule[]>) => {
      return {
        ...state,
        isFetching: true
      }
    }
  )
  .handleAction(
    configLottoAction.postLottoResultAction.success,
    (
      state: ReducerState<ILotterySchedule[]>,
      action: configLottoActionType
    ) => {
      const payload: AxiosResponse<IAPIResponse<ILotterySchedule[]>> =
        action.payload
      const convertedResponse = responseConverter.getMessage(payload)
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
        error: ''
      }
    }
  )
  .handleAction(
    configLottoAction.postLottoResultAction.failure,
    (
      state: ReducerState<ILotterySchedule[]>,
      action: configLottoActionType
    ) => {
      const payload: AxiosError<IAPIResponse> = action.payload
      const convertedResponse = responseConverter.getMessage(payload)
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message
      }
    }
  )

const reverse = createReducer<ReducerState<null>, configLottoActionType>(
  initialLottoReverseState
)
  .handleAction(
    configLottoAction.postLottoReverseAction.request,
    (state: ReducerState<null>) => {
      return {
        ...state,
        isFetching: true
      }
    }
  )
  .handleAction(
    configLottoAction.postLottoReverseAction.success,
    (state: ReducerState<null>, action: configLottoActionType) => {
      const payload: AxiosResponse<IAPIResponse<null>> = action.payload
      const convertedResponse = responseConverter.getMessage(payload)
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: ''
      }
    }
  )
  .handleAction(
    configLottoAction.postLottoReverseAction.failure,
    (state: ReducerState<null>, action: configLottoActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload
      const convertedResponse = responseConverter.getMessage(payload)
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message
      }
    }
  )

const currentResult = createReducer<
  ReducerState<IResponseLotterCurrentResult>,
  configLottoActionType
>(initialLottoCurrentResultState)
  .handleAction(
    configLottoAction.postLottoCurrentResultAction.request,
    (state: ReducerState<IResponseLotterCurrentResult>) => {
      return {
        ...state,
        isFetching: true
      }
    }
  )
  .handleAction(
    configLottoAction.postLottoCurrentResultAction.success,
    (
      state: ReducerState<IResponseLotterCurrentResult>,
      action: configLottoActionType
    ) => {
      const payload: AxiosResponse<IAPIResponse<IResponseLotterCurrentResult>> =
        action.payload
      const convertedResponse = responseConverter.getMessage(payload)
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
        error: ''
      }
    }
  )
  .handleAction(
    configLottoAction.postLottoCurrentResultAction.failure,
    (
      state: ReducerState<IResponseLotterCurrentResult>,
      action: configLottoActionType
    ) => {
      const payload: AxiosError<IAPIResponse> = action.payload
      const requestPayload: IQueryLotterCurrentResult =
        transformer.camelcaseTransform(
          JSON.parse(payload.config.data)
        ) as IQueryLotterCurrentResult
      const convertedResponse = responseConverter.getMessage(payload)
      if (convertedResponse.code === responseCode.NOT_FOUND) {
        return {
          ...state,
          isFetching: false,
          code: convertedResponse.code,
          error: convertedResponse.message,
          data: {
            createdAt: 'ไม่เปิดใช้งาน',
            lotterCurrentResult:
              requestPayload.lotterType === 'LAO_SUITE'
                ? [
                    {
                      value: 'xxxx',
                      valueType: 'FOUR_SUITE'
                    }
                  ]
                : [
                    {
                      value: 'xxx',
                      valueType: 'THREE_UP'
                    },
                    {
                      value: 'xx',
                      valueType: 'TWO_DOWN'
                    }
                  ],
            status: 'ไม่เปิดใช้งาน'
          }
        }
      }
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
        data: initialLottoCurrentResultState.data
      }
    }
  )

export default combineReducers({
  result,
  reverse,
  currentResult
})
