import React, { FC } from "react";
import ReactPaginate from "react-paginate";
import "./Panginate.style.scss";

const defaultProps: IPanginate = {
  pageTotal: 0,
  initialPage: 0,
  pageRangeDisplayed: 2,
  marginPagesDisplayed: 0,
  previousLabel: "<",
  nextLabel: ">",
  breakLabel: "",
  onPageChange() {},
  forcePage: 1,
};

const PanginateComponent: FC<IPanginate> = (props) => {
  const {
    pageTotal,
    initialPage,
    pageRangeDisplayed,
    marginPagesDisplayed,
    previousLabel,
    nextLabel,
    breakLabel,
    onPageChange,
    forcePage
  } = props;
  return (
    <div className="panginate">
      <ReactPaginate
        pageCount={pageTotal}
        forcePage={forcePage}
        initialPage={initialPage}
        pageRangeDisplayed={pageRangeDisplayed + 1 === pageTotal ? 3 : 2}
        marginPagesDisplayed={Number(marginPagesDisplayed)}
        previousLabel={previousLabel}
        nextLabel={nextLabel}
        breakLabel={breakLabel}
        onPageChange={onPageChange}
        containerClassName={"pagination"}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"pagination__link--disabled"}
        activeClassName={"pagination__link--active"}
      />
    </div>
  );
};

PanginateComponent.defaultProps = defaultProps;

export default PanginateComponent;
