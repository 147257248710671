import { AxiosError, AxiosResponse } from 'axios'
import { createAsyncAction } from 'typesafe-actions'
import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,
    SET_AUTHENTINATION_KEY_REQUEST,
    SET_AUTHENTINATION_KEY_SUCCESS,
    SET_AUTHENTINATION_KEY_FAILURE,
    // VERYFY_TOKEN
} from './constants'

const loginAction = createAsyncAction(
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
)<any, AxiosResponse<IAPIResponse<ILogin>>, AxiosError<IAPIResponse>>()

const logoutAction = createAsyncAction(
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,
)<any, any, any>()

const setAuthenticationAction = createAsyncAction(
    SET_AUTHENTINATION_KEY_REQUEST,
    SET_AUTHENTINATION_KEY_SUCCESS,
    SET_AUTHENTINATION_KEY_FAILURE,
)<any, ILogin, number>()

const verifyAuthenticationAction = createAsyncAction(
    SET_AUTHENTINATION_KEY_SUCCESS,
    "",
    "",
)<any>()


export {
    loginAction,
    logoutAction,
    setAuthenticationAction,
    verifyAuthenticationAction,
}