export const GET_ALL_USER_REQUEST = 'GET_ALL_USER_REQUEST'
export const GET_ALL_USER_SUCCESS = 'GET_ALL_USER_SUCCESS'
export const GET_ALL_USER_FAILURE = 'GET_ALL_USER_FAILURE'
export const CLEAR_ALL_USER = 'CLEAR_ALL_USER'

export const GET_ME_USER_REQUEST = 'GET_ME_USER_REQUEST'
export const GET_ME_USER_SUCCESS = 'GET_ME_USER_SUCCESS'
export const GET_ME_USER_FAILURE = 'GET_ME_USER_FAILURE'
export const CLEAR_ME_USER = 'CLEAR_ME_USER'

export const GET_AFFILATE_USER_REQUEST = 'GET_AFFILATE_USER_REQUEST'
export const GET_AFFILATE_USER_SUCCESS = 'GET_AFFILATE_USER_SUCCESS'
export const GET_AFFILATE_USER_FAILURE = 'GET_AFFILATE_USER_FAILURE'
export const CLEAR_AFFILATE_USER = 'CLEAR_AFFILATE_USER'

export const GET_STAT_USER_READ_REQUEST = 'GET_STAT_USER_READ_REQUEST'
export const GET_STAT_USER_READ_SUCCESS = 'GET_STAT_USER_READ_SUCCESS'
export const GET_STAT_USER_READ_FAILURE = 'GET_STAT_USER_READ_FAILURE'
export const CLEAR_STAT_USER_READ = 'CLEAR_STAT_USER_READ'

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'

export const IGetMeUserState: ReducerState<IUserMe> = {
    isFetching: false,
    code: 0,
    data: {
        id: 0,
        affilateMeUuid: '',
        username: '',
        phoneNumber: '',
        permission: '',
        bank: {
            createdAt: '',
            id: 0,
            name: '',
            number: '',
            type: '',
            updatedAt: '',
        },
        user_bank_id: 0,
        wallet: {
            createdAt: '',
            id: 0,
            money: 0,
            updatedAt: '',
        },
        wallet_id: 0,
        createdAt: '',
        updatedAt: '',
    },
    error: ''
}

export const statUserReadState: ReducerState<IPagination<IV1RespReadStatsUser>> = {
    isFetching: false,
    code: 0,
    data: {
        dataList: [],
        total: 0,
        limit: 0,
        page: 0,
    },
    error: ''
}

export const allUserState: ReducerState<IPagination<IUser>> = {
    isFetching: false,
    code: 0,
    data: {
        dataList: [],
        total: 0,
        limit: 0,
        page: 0,
    },
    error: ''
}

export const allUserAffilateState: ReducerState<IPagination<IUserAffilate, IAffilateTansaction, IUserAffilate>> = {
    isFetching: false,
    code: 0,
    data: {
        dataList: [],
        host: [],
        total: 0,
        limit: 0,
        page: 0,
    },
    error: ''
}

export const updateUserState: ReducerState<IUpdateUser> = {
    isFetching: false,
    code: 0,
    data: {
        id: 0,
        username: '',
        phoneNumber: '',
        userBankName: '',
        affilateMeUuid: '',
        userBankNumber: '',
        userBankType: '',
    },
    error: ''
}