import React from 'react';
import {
    Form,
    FormikProps
} from 'formik'
import { Button, InputTextIcon } from 'components';
import { faKey, faUser } from '@fortawesome/free-solid-svg-icons';

const constants = {
    placeholderUsername: 'Username',
    placeholderPassword: 'Password',
    buttonLogin: 'Login',
    username: 'username',
    password: 'password',
}

const LoginForm = (props: FormikProps<ILogin1>) => {

    const {
        values,
        errors,
        touched,
        handleBlur,
        setFieldValue,
        handleSubmit,
    } = props

    return (
        <Form>
            <InputTextIcon
                toLowercase
                icon={faUser}
                id={constants.username}
                name={constants.username}
                onBlur={handleBlur}
                value={values.username}
                setFieldValue={setFieldValue}
                errorMessage={errors.username}
                placeholder={constants.placeholderUsername}
                error={!!errors.username && touched.username}
            />
            <InputTextIcon
                toLowercase
                icon={faKey}
                type={`password`}
                id={constants.password}
                name={constants.password}
                onBlur={handleBlur}
                setFieldValue={setFieldValue}
                errorMessage={errors.password}
                value={values.password}
                placeholder={constants.placeholderPassword}
                error={!!errors.password && touched.password}
            />
            <div className="p2-t">
                <Button
                    id={`login-submit-button`}
                    buttonType={`submit`}
                    onClick={handleSubmit}
                    text={constants.buttonLogin}
                />
            </div>

        </Form>
    )
}

export default LoginForm