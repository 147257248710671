import { AxiosError, AxiosResponse } from 'axios'
import { createAsyncAction } from 'typesafe-actions'
import {
    GET_ALL_USER_REQUEST,
    GET_ALL_USER_SUCCESS,
    GET_ALL_USER_FAILURE,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
    CLEAR_ALL_USER,
    GET_ME_USER_REQUEST,
    GET_ME_USER_SUCCESS,
    GET_ME_USER_FAILURE,
    CLEAR_ME_USER,
    GET_AFFILATE_USER_REQUEST,
    GET_AFFILATE_USER_SUCCESS,
    GET_AFFILATE_USER_FAILURE,
    CLEAR_AFFILATE_USER,
    GET_STAT_USER_READ_REQUEST,
    GET_STAT_USER_READ_SUCCESS,
    GET_STAT_USER_READ_FAILURE,
    CLEAR_STAT_USER_READ,
} from './constants'

const getAllUserAction = createAsyncAction(
    GET_ALL_USER_REQUEST,
    GET_ALL_USER_SUCCESS,
    GET_ALL_USER_FAILURE,
    CLEAR_ALL_USER,
)<any, [AxiosResponse<IAPIResponse<IPagination<IUser>>>, Boolean], AxiosError<IAPIResponse>, any>()

const getAllUserAffilateAction = createAsyncAction(
    GET_AFFILATE_USER_REQUEST,
    GET_AFFILATE_USER_SUCCESS,
    GET_AFFILATE_USER_FAILURE,
    CLEAR_AFFILATE_USER,
)<any, [AxiosResponse<IAPIResponse<IPagination<IUserAffilate, IAffilateTansaction, IUserAffilate>>>, Boolean], AxiosError<IAPIResponse>, any>()


const getMeUserAction = createAsyncAction(
    GET_ME_USER_REQUEST,
    GET_ME_USER_SUCCESS,
    GET_ME_USER_FAILURE,
    CLEAR_ME_USER,
)<any, AxiosResponse<IAPIResponse<IUserMe>>, AxiosError<IAPIResponse>, any>()

const updateUserAction = createAsyncAction(
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
)<any, AxiosResponse<IAPIResponse<IUpdateUser>>, AxiosError<IAPIResponse>>()

const getStatUserReadAction = createAsyncAction(
    GET_STAT_USER_READ_REQUEST,
    GET_STAT_USER_READ_SUCCESS,
    GET_STAT_USER_READ_FAILURE,
    CLEAR_STAT_USER_READ,
)<any, [AxiosResponse<IAPIResponse<IPagination<IV1RespReadStatsUser>>>, Boolean], AxiosError<IAPIResponse>, any>()

export {
    getAllUserAction,
    updateUserAction,
    getMeUserAction,
    getAllUserAffilateAction,
    getStatUserReadAction,
}