import { AxiosError, AxiosResponse } from "axios"
import { Dispatch } from "redux"
import { loaderAction } from "reduxs/loader/actionCreator"
import { getWebbankObserverAction, postWebbankSwitchingAction, postWebbankResetAction } from './actionCreator'
import { fetWebbankObserver, fetWebbankSwitching, fetWebbankReset } from './services'


const getWebBankObserver = (query: IQueryPagination, isFirst: boolean) => async (dispatch: Dispatch) => {
    dispatch(loaderAction.request({}))
    dispatch(getWebbankObserverAction.request({}))
    await fetWebbankObserver(query)
        .then((response: AxiosResponse<IAPIResponse<IPagination<IResponseWebbankObserver>>>) => {
            dispatch(loaderAction.success({}))
            return dispatch(getWebbankObserverAction.success(response, isFirst))
        })
        .catch((error: AxiosError<IAPIResponse>) => {
            dispatch(loaderAction.success({}))
            return dispatch(getWebbankObserverAction.failure(error))
        })
}
const clearWebBankObserver = () => (dispatch: Dispatch) =>
    dispatch(getWebbankObserverAction.cancel({}))

const postWebbankSwitching = (body: { id: number, type: string }) => async (dispatch: Dispatch) => {
    dispatch(loaderAction.request({}))
    dispatch(postWebbankSwitchingAction.request({}))
    await fetWebbankSwitching(body)
        .then((response: AxiosResponse<IAPIResponse<any>>) => {
            dispatch(loaderAction.success({}))
            return dispatch(postWebbankSwitchingAction.success(response))
        })
        .catch((error: AxiosError<IAPIResponse>) => {
            dispatch(loaderAction.success({}))
            return dispatch(postWebbankSwitchingAction.failure(error))
        })
}

const postWebbankReset = (body: { id: number }) => async (dispatch: Dispatch) => {
    dispatch(loaderAction.request({}))
    dispatch(postWebbankResetAction.request({}))
    await fetWebbankReset(body)
        .then((response: AxiosResponse<IAPIResponse<any>>) => {
            dispatch(loaderAction.success({}))
            return dispatch(postWebbankResetAction.success(response))
        })
        .catch((error: AxiosError<IAPIResponse>) => {
            dispatch(loaderAction.success({}))
            return dispatch(postWebbankResetAction.failure(error))
        })
}

const credits = {
    getWebBankObserver,
    clearWebBankObserver,
    postWebbankSwitching,
    postWebbankReset
}

export default credits