import { Dispatch } from 'redux';
import { getAllPartnersAction, updatePartnerAction, getPartnerSchedulesAction, updatePartnerScheduleAction } from './actionCreator'
import { fetchGetAllPartners, fetchPutPartner, fetchGetPartnerSchedule, fetchPutPartnerSchedule } from './services'
import { loaderAction } from '../loader/actionCreator'
import { AxiosError, AxiosResponse } from 'axios';

const getAllPartner = () => async (dispatch: Dispatch) => {
    dispatch(loaderAction.request({}))
    dispatch(getAllPartnersAction.request({}))
    await fetchGetAllPartners()
        .then((response: AxiosResponse<IAPIResponse<IPartnerConfig>>) => {
            dispatch(loaderAction.success({}))
            return dispatch(getAllPartnersAction.success(response))
        })
        .catch((error: AxiosError) => {
            dispatch(loaderAction.success({}))
            return dispatch(getAllPartnersAction.failure(error))
        })
}

const getPartnerSchedule = (query?: IPartnerScheduleQuery) => async (dispatch: Dispatch) => {
    dispatch(loaderAction.request({}))
    dispatch(getPartnerSchedulesAction.request({}))
    await fetchGetPartnerSchedule(query)
    .then((resp: AxiosResponse<IAPIResponse<IPartnerScheduleConfig[]>>) => {
        dispatch(loaderAction.success({}))
        dispatch(getPartnerSchedulesAction.success(resp))
        })
        .catch((error: AxiosError) => {
            dispatch(loaderAction.success({}))
            dispatch(getPartnerSchedulesAction.failure(error))
        })
}

const putPartner = (putData: IGameConfig) => async (dispatch: Dispatch) => {
    dispatch(loaderAction.request({}))
    dispatch(updatePartnerAction.request({}))
    await fetchPutPartner(putData)
        .then((response: AxiosResponse<IAPIResponse<IGameConfig>>) => {
            dispatch(loaderAction.success({}))
            return dispatch(updatePartnerAction.success(response))
        })
        .catch((error: AxiosError<IAPIResponse>) => {
            dispatch(loaderAction.success({}))
            return dispatch(updatePartnerAction.failure(error))
        })
}

const putPartnerSchedule = (putData: IPartnerScheduleConfig[]) => async (dispatch: Dispatch) => {
    dispatch(loaderAction.request({}))
    dispatch(updatePartnerScheduleAction.request({}))
    await fetchPutPartnerSchedule(putData)
        .then((response: AxiosResponse<IAPIResponse<IPartnerScheduleConfig[]>>) => {
            dispatch(loaderAction.success({}))
            return dispatch(updatePartnerScheduleAction.success(response))
        })
        .catch((error: AxiosError<IAPIResponse>) => {
            dispatch(loaderAction.success({}))
            return dispatch(updatePartnerScheduleAction.failure(error))
        })
}

const clearAllPartner = () => (dispatch: Dispatch) => dispatch(getAllPartnersAction.cancel({}))
const clearPartnerSchedule = () => (dispatch: Dispatch) => dispatch(getPartnerSchedulesAction.cancel({}))
const partners = {
    getAllPartner,
    getPartnerSchedule,
    clearAllPartner,
    clearPartnerSchedule,
    putPartner,
    putPartnerSchedule,
}
export default partners