import React, { FC } from 'react'
import { noop } from 'lodash'
import { ResponsiveIcon, Button } from 'components'
import CheckIcon from 'assets/images/modal/check.svg'
import { Modal } from '../../'
import './success.style.scss'

const constants = {
  title: 'Success',
  description: 'Your request has been successful',
  actionText: 'กลับสู่หน้าหลัก',
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: ISuccessModal = {
  title: constants.title,
  description: constants.description,
  actionText: constants.actionText,
  action() { noop() },
}

const SuccessModal: FC<ISuccessModal & DefaultProps> = (props) => {

  return (
    <div className="success-modal-container border-rounded">
      <ResponsiveIcon
        alt="success-icon"
        className="success-checked-icon"
        icon={CheckIcon}
      />
      <h2 className="secondary-green-text p1-t">{props.title}</h2>
      <p className="body-1">{props.description}</p>
      <div className="m3-y">
        <Button id="success-ok-button" type="rectangle" onClick={props.action} text={props.actionText!} />
      </div>
    </div>
  )
}

SuccessModal.defaultProps = defaultProps

const successModal = {
  show: (props: ISuccessModal) => {
    return Modal.event('MODAL_OVER', {
      state: 'show',
      extraProps: { size: 'sm' },
      component: (<SuccessModal {...props} />),
    })
  },
  hide: () => Modal.event('MODAL_OVER', { state: 'hide' }),
}

export default successModal