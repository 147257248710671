import { values as _values } from 'lodash'
import ImageBankSet from 'assets/images/global/bank'
import colors from './colors'

export const THEME_MODE = {
  DARK: 'darkMode',
  LIGHT: 'lightMode'
}

export const EBANK: { [key in TBankType]: string } = {
  BBL: 'BBL', // กรุงเทพ
  KBANK: 'KBANK', // กสิกร
  KTB: 'KTB', // กรุงไทย
  TMB: 'TMB', // ทหารไทน
  TTB: 'TTB', // ทีเอ็มบีธนชาต
  SCB: 'SCB', // ไทยพาณิชย์
  BAY: 'BAY', // กรุงศรีอยุธยา
  KKP: 'KKP', // เกียรตินาคิน
  CIMBT: 'CIMBT', // ซีไอเอ็มบีไทย
  TBANK: 'TBANK', // ธนชาต
  TISCO: 'TISCO', // ทิสโก้
  UOBT: 'UOBT', // ยูโอบี
  TCD: 'TCD', // ไทยเครดิตเพื่อรายย่อย
  ICBCT: 'ICBCT', // ไอซีบีซี (ไทย)
  GSB: 'GSB', // ออมสิน
  ISBT: 'ISBT', // อิสลามแห่งประเทศไทย
  BAAC: 'BAAC',
  GHB: 'GHB'
}
export const BANK_NAME: { [key in TBankType]: string } = {
  BBL: 'ธนาคารกรุงเทพ',
  KBANK: 'ธนาคารกสิกร',
  KTB: 'ธนาคารกรุงไทย',
  TMB: 'ธนาคารทหารไทย',
  TTB: 'ธนาคารทีเอ็มบีธนชาต',
  TBANK: 'ธนาคารธนชาต',
  SCB: 'ธนาคารไทยพาณิชย์',
  BAY: 'ธนาคารกรุงศรีอยุธยา',
  KKP: 'ธนาคารเกียรตินาคิน',
  CIMBT: 'ธนาคารซีไอเอ็มบีไทย',
  TISCO: 'ธนาคารทิสโก้',
  UOBT: 'ธนาคารยูโอบี',
  TCD: 'ธนาคารไทยเครดิตเพื่อรายย่อย',
  ICBCT: 'ธนาคารไอซีบีซี (ไทย)',
  GSB: 'ธนาคารออมสิน',
  ISBT: 'ธนาคารอิสลามแห่งประเทศไทย',
  BAAC: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร',
  GHB: 'ธนาคารอาคารสงเคราะห์'
}

export const BANKS: ICBank[] = _values(ImageBankSet).map((item) => ({
  value: item.key,
  name: BANK_NAME[item.key],
  Icon: item.Icon
}))

// export const STATUS: ICStatus = _values(TRANSACTION_STATUS).map(item => ({

// }))

export const TRANSACTION_TYPE: { [type in TTransactionType]: string } = {
  DEPOSIT: 'ฝากเครดิต',
  WITHDRAW: 'ถอนเครดิต',
  DEPOSIT_GAME_FISHING: 'ฝากเครดิตจากเกมยิงปลา',
  WITHDRAW_GAME_FISHING: 'ถอนเครดิตเข้าเกมยิงปลา',
  BET_GAME_CASINO: 'เกมคาสิโน',
  AFFILATE_WITHDRAW: 'ย้ายเงินจากกระเป๋าระบบแนะนำสมาชิก',
  SYSTEM_INCOME: 'รายได้ระบบ',
  SYSTEM_MONEY_LOSS: 'จ่ายผู้เล่น',
  DEPOSIT_SYSTEM_INCOME: 'ลูกค้าฝากเข้าระบบ',
  WITHDRAW_SYSTEM_INCOME: 'ลูกค้าแจ้งถอนเงินออกจากระบบ',
  WITHDRAW_SYSTEM_MONEY_LOSS: 'ลูกค้าถอนเงินออกจากระบบ'
}

export const SELECT_ALL_TYPE: { [type in TSelectAllType]: string } = {
  ALL: 'ทั้งหมด'
}

export const LOTTO_TYPE: { [type in TLottoType]: string } = {
  // EMPTY: 'ไม่ระบุ',
  GOVN: 'หวยรัฐบาล',
  BAAC: 'หวยธกส.',
  GSB: 'หวยออมสิน',
  TH_SHARE_MORNING: 'หุ้นไทยเช้า',
  TH_SHARE_MIDDAY: 'หุ้นไทยเที่ยง',
  TH_SHARE_AFTERNOON: 'หุ้นไทยบ่าย',
  TH_SHARE_EVENING: 'หุ้นไทยเย็น',
  NAT_SHARE_DOWNJON: 'หุ้นดาวโจนส์',
  NAT_SHARE_EGYPT: 'หุ้นอียิปต์',
  NAT_SHARE_GERMANY: 'หุ้นเยอรมัน',
  NAT_SHARE_NIKAII_MORNING: 'หุ้นนิเคอิรอบเช้า',
  NAT_SHARE_NIKAII_AFTERNOON: 'หุ้นนิเคอิรอบบ่าย',
  NAT_SHARE_CHINA_MORNING: 'หุ้นจีนรอบเช้า',
  NAT_SHARE_CHINA_AFTERNOON: 'หุ้นจีนรอบบ่าย',
  NAT_SHARE_TAIWAN: 'หุ้นไต้หวัน',
  NAT_SHARE_KOREA: 'หุ้นเกาหลี',
  NAT_SHARE_SINGAPORE: 'หุ้นสิงคโปร์',
  NAT_SHARE_INDIA: 'หุ้นอินเดีย',
  NAT_SHARE_HANOI_SPECIAL: 'หุ้นฮานอยพิเศษ',
  NAT_SHARE_MALAY: 'หุ้นมาเลย์',
  NAT_SHARE_VIETNAM_HANOI: 'หุ้นเวียดนาม / ฮานอย',
  NAT_SHARE_VIETNAM_HANOI_VIP: 'หุ้นเวียดนาม / ฮานอย VIP',
  NAT_SHARE_HANOI_4D: 'หุ้นฮานอย (4D)',
  NAT_SHARE_RUSSIA: 'หุ้นรัสเซีย',
  NAT_SHARE_ENGLISH: 'หุ้นอังกฤษ',
  NAT_SHARE_HUNGSENG_MORNING: 'หุ้นฮั่งเส็งรอบเช้า',
  NAT_SHARE_HUNGSENG_AFTERNOON: 'หุ้นฮั่งเส็งรอบบ่าย',
  NAT_SHARE_LAO: 'หุ้นลาว',
  LAO_SUITE: 'หวยลาว (เลขชุด)',
  YEGEE: 'ยี่กี',
  LAO_HD: 'หวยลาว HD',
  HANOI_TV: 'หวยฮานอย TV',
  LAO_STAR: 'หวยลาวสตาร์',
  HANOI_RED_CROSS: 'หวยฮานอย กาชาด',
  HANOI_SAMAKKEE: 'หวยฮานอยสามัคคี',
  HANOI_PHATTANA: 'หวยฮานอยพัฒนา',
  LAO_SAMAKKEE: 'หวยลาวสามัคคี',
  LAO_ASEAN: 'หวยลาวอาเซียน',
  LAO_STAR_VIP: 'หวยลาวสตาร์ VIP',
  ENGLISH_VIP: 'หวยอังกฤษ VIP',
  GERMANY_VIP: 'หวยเยอรมัน VIP',
  LAO_RED_CROSS: 'หวยลาว กาชาด',
  RUSSIA_VIP: 'หวยรัสเซีย VIP',
  DOWNJON_VIP: 'หวยดาวโจนส์ VIP',
  HANOI_ASEAN: 'หวยฮานอยอาเซียน',
  LAO_TV: 'หวยลาว TV',
  LAO_VIP: 'หวยลาว VIP',
  HANOI_HD: 'หวยฮานอย HD',
  HANOI_STAR: 'หวยฮานอยสตาร์',
  MALAY: 'หวยมาเลเซีย',
  NAT_SHARE_HUNGSENG_MORNING_VIP: 'หวยหุ้นฮั่งเส็งรอบเช้า VIP',
  NAT_SHARE_HUNGSENG_AFTERNOON_VIP: 'หวยหุ้นฮั่งเส็งรอบบ่าย VIP',
  NAT_SHARE_CHINA_MORNING_VIP: 'หวยหุ้นจีนรอบเช้า VIP',
  NAT_SHARE_CHINA_AFTERNOON_VIP: 'หวยหุ้นจีนรอบบ่าย VIP',
  NAT_SHARE_SINGAPORE_VIP: 'หวยหุ้นสิงคโปร์ VIP',
  NAT_SHARE_KOREA_VIP: 'หวยหุ้นเกาหลี VIP',
  NAT_SHARE_TAIWAN_VIP: 'หวยหุ้นไต้หวัน VIP',
  NAT_SHARE_NIKAII_MORNING_VIP: 'หวยหุ้นนิเคอิรอบเช้า VIP',
  NAT_SHARE_NIKAII_AFTERNOON_VIP: 'หวยหุ้นนิเคอิรอบบ่าย VIP',
  HANOI_EXTRA: 'ฮานอยเอ็กซ์ตร้า',
  HANOI_CHINESE_NEW_YEAR: 'หวยฮานอยตรุษจีน',
  NAT_SHARE_DOWNJON_STAR: 'หวยหุ้นดาวน์โจน สตาร์',
}

export const LOTTO_SLUG_NAME: {
  [type in TLottoSlug | TPmpgAwardStatus]: string
} = {
  LOTTER_YEGEE: 'หวยยี่กี',
  LOTTER_GOVN: 'หวยรัฐบาล',
  LOTTER_BAAC: 'หวยธกส',
  LOTTER_GSB: 'หวยออมสิน',
  LOTTER_NAT_SHARE_LAO: 'หวยลาว',
  LOTTER_LAO_SUITE: 'หวยลาว (เลขชุด)',
  LOTTER_TH_SHARE_MORNING: 'หวยหุ้นไทยเช้า',
  LOTTER_TH_SHARE_MIDDAY: 'หวยหุ้นไทยเที่ยง',
  LOTTER_TH_SHARE_AFTERNOON: 'หวยหุ้นไทยบ่าย',
  LOTTER_TH_SHARE_EVENING: 'หวยหุ้นไทยเย็น',
  LOTTER_NAT_SHARE_DOWNJON: 'หวยหุ้นดาวโจนส์',
  LOTTER_NAT_SHARE_EGYPT: 'หวยหุ้นต่างประเทศอียิปต์',
  LOTTER_NAT_SHARE_GERMANY: 'หวยหุ้นต่างประเทศเยอรมัน',
  LOTTER_NAT_SHARE_NIKAII_MORNING: 'หวยหุ้นนิเคอิรอบเช้า',
  LOTTER_NAT_SHARE_NIKAII_AFTERNOON: 'หวยหุ้นนิเคอิรอบบ่าย',
  LOTTER_NAT_SHARE_CHINA_MORNING: 'หวยหุ้นจีนรอบเช้า',
  LOTTER_NAT_SHARE_CHINA_AFTERNOON: 'หวยหุ้นจีนรอบบ่าย',
  LOTTER_NAT_SHARE_TAIWAN: 'หวยหุ้นไต้หวัน',
  LOTTER_NAT_SHARE_KOREA: 'หวยหุ้นเกาหลี',
  LOTTER_NAT_SHARE_SINGAPORE: 'หวยหุ้นสิงคโปร์',
  LOTTER_NAT_SHARE_INDIA: 'หวยหุ้นอินเดีย',
  LOTTER_NAT_SHARE_HANOI_SPECIAL: 'หวยหุ้นฮานอยพิเศษ',
  LOTTER_NAT_SHARE_MALAY: 'หวยหุ้นมาเลย์',
  LOTTER_NAT_SHARE_VIETNAM_HANOI: 'หวยหุ้นเวียดนาม / ฮานอย',
  LOTTER_NAT_SHARE_VIETNAM_HANOI_VIP: 'หวยหุ้นเวียดนาม / ฮานอย VIP',
  LOTTER_NAT_SHARE_HANOI_4D: 'หวยหุ้นฮานอย (4D)',
  LOTTER_NAT_SHARE_RUSSIA: 'หวยหุ้นรัสเซีย',
  LOTTER_NAT_SHARE_ENGLISH: 'หวยหุ้นอังกฤษ',
  LOTTER_NAT_SHARE_HUNGSENG_MORNING: 'หวยหุ้นฮั่งเส็งรอบเช้า',
  LOTTER_NAT_SHARE_HUNGSENG_AFTERNOON: 'หวยหุ้นฮั่งเส็งรอบบ่าย',
  LOTTER_LAO_HD: 'หวยลาว HD',
  LOTTER_HANOI_TV: 'หวยฮานอย TV',
  LOTTER_LAO_STAR: 'หวยลาวสตาร์',
  LOTTER_HANOI_RED_CROSS: 'หวยฮานอย กาชาด',
  LOTTER_HANOI_SAMAKKEE: 'หวยฮานอยสามัคคี',
  LOTTER_HANOI_PHATTANA: 'หวยฮานอยพัฒนา',
  LOTTER_LAO_SAMAKKEE: 'หวยลาวสามัคคี',
  LOTTER_LAO_ASEAN: 'หวยลาวอาเซียน',
  LOTTER_LAO_STAR_VIP: 'หวยลาวสตาร์ VIP',
  LOTTER_ENGLISH_VIP: 'หวยอังกฤษ VIP',
  LOTTER_GERMANY_VIP: 'หวยเยอรมัน VIP',
  LOTTER_LAO_RED_CROSS: 'หวยลาว กาชาด',
  LOTTER_RUSSIA_VIP: 'หวยรัสเซีย VIP',
  LOTTER_DOWNJON_VIP: 'หวยดาวโจนส์ VIP',
  LOTTER_HANOI_ASEAN: 'หวยฮานอยอาเซียน',
  LOTTER_LAO_TV: 'หวยลาว TV',
  LOTTER_HANOI_HD: 'หวยฮานอย HD',
  LOTTER_HANOI_STAR: 'หวยฮานอยสตาร์',
  LOTTER_MALAY: 'หวยมาเลเซีย',
  LOTTER_NAT_SHARE_HUNGSENG_MORNING_VIP: 'หวยหุ้นฮั่งเส็งรอบเช้า VIP',
  LOTTER_NAT_SHARE_HUNGSENG_AFTERNOON_VIP: 'หวยหุ้นฮั่งเส็งรอบบ่าย VIP',
  LOTTER_NAT_SHARE_CHINA_MORNING_VIP: 'หวยหุ้นจีนรอบเช้า VIP',
  LOTTER_NAT_SHARE_CHINA_AFTERNOON_VIP: 'หวยหุ้นจีนรอบบ่าย VIP',
  LOTTER_NAT_SHARE_SINGAPORE_VIP: 'หวยหุ้นสิงคโปร์ VIP',
  LOTTER_NAT_SHARE_KOREA_VIP: 'หวยหุ้นเกาหลี VIP',
  LOTTER_NAT_SHARE_TAIWAN_VIP: 'หวยหุ้นไต้หวัน VIP',
  LOTTER_NAT_SHARE_NIKAII_MORNING_VIP: 'หวยหุ้นนิเคอิรอบเช้า VIP',
  LOTTER_NAT_SHARE_NIKAII_AFTERNOON_VIP: 'หวยหุ้นนิเคอิรอบบ่าย VIP',
  LOTTER_HANOI_EXTRA: 'หวยฮานอยเอ็กซ์ตร้า',
  PMPG_BRONZE_AWARD: 'โบนัส กองเงินกองทอง',
  PMPG_GOLD_AWARD: 'โบนัส กองเงินกองทอง',
  PMPG_SILVER_AWARD: 'โบนัส กองเงินกองทอง',
  PMPG_TIN_AWARD: 'โบนัส กองเงินกองทอง',
  LOTTER_LAO_VIP: 'หวยลาว VIP',
  LOTTER_HANOI_CHINESE_NEW_YEAR: 'หวยฮานอยตรุษจีน',
  LOTTER_NAT_SHARE_DOWNJON_STAR: 'หวยหุ้นดาวน์โจน สตาร์'
}

export const LOTTO_SLUG_TO_TYPE: { [type in TLottoSlug]: TLottoType } = {
  LOTTER_YEGEE: 'YEGEE',
  LOTTER_GOVN: 'GOVN',
  LOTTER_BAAC: 'BAAC',
  LOTTER_GSB: 'GSB',
  LOTTER_NAT_SHARE_LAO: 'NAT_SHARE_LAO',
  LOTTER_LAO_SUITE: 'LAO_SUITE',
  LOTTER_TH_SHARE_MORNING: 'TH_SHARE_MORNING',
  LOTTER_TH_SHARE_MIDDAY: 'TH_SHARE_MIDDAY',
  LOTTER_TH_SHARE_AFTERNOON: 'TH_SHARE_AFTERNOON',
  LOTTER_TH_SHARE_EVENING: 'TH_SHARE_EVENING',
  LOTTER_NAT_SHARE_DOWNJON: 'NAT_SHARE_DOWNJON',
  LOTTER_NAT_SHARE_EGYPT: 'NAT_SHARE_EGYPT',
  LOTTER_NAT_SHARE_GERMANY: 'NAT_SHARE_GERMANY',
  LOTTER_NAT_SHARE_NIKAII_MORNING: 'NAT_SHARE_NIKAII_MORNING',
  LOTTER_NAT_SHARE_NIKAII_AFTERNOON: 'NAT_SHARE_NIKAII_AFTERNOON',
  LOTTER_NAT_SHARE_CHINA_MORNING: 'NAT_SHARE_CHINA_MORNING',
  LOTTER_NAT_SHARE_CHINA_AFTERNOON: 'NAT_SHARE_CHINA_AFTERNOON',
  LOTTER_NAT_SHARE_TAIWAN: 'NAT_SHARE_TAIWAN',
  LOTTER_NAT_SHARE_KOREA: 'NAT_SHARE_KOREA',
  LOTTER_NAT_SHARE_SINGAPORE: 'NAT_SHARE_SINGAPORE',
  LOTTER_NAT_SHARE_INDIA: 'NAT_SHARE_INDIA',
  LOTTER_NAT_SHARE_HANOI_SPECIAL: 'NAT_SHARE_HANOI_SPECIAL',
  LOTTER_NAT_SHARE_MALAY: 'NAT_SHARE_MALAY',
  LOTTER_NAT_SHARE_VIETNAM_HANOI: 'NAT_SHARE_VIETNAM_HANOI',
  LOTTER_NAT_SHARE_VIETNAM_HANOI_VIP: 'NAT_SHARE_VIETNAM_HANOI_VIP',
  LOTTER_NAT_SHARE_HANOI_4D: 'NAT_SHARE_HANOI_4D',
  LOTTER_NAT_SHARE_RUSSIA: 'NAT_SHARE_RUSSIA',
  LOTTER_NAT_SHARE_ENGLISH: 'NAT_SHARE_ENGLISH',
  LOTTER_NAT_SHARE_HUNGSENG_MORNING: 'NAT_SHARE_HUNGSENG_MORNING',
  LOTTER_NAT_SHARE_HUNGSENG_AFTERNOON: 'NAT_SHARE_HUNGSENG_AFTERNOON',
  LOTTER_LAO_HD: 'LAO_HD',
  LOTTER_HANOI_TV: 'HANOI_TV',
  LOTTER_LAO_STAR: 'LAO_STAR',
  LOTTER_HANOI_RED_CROSS: 'HANOI_RED_CROSS',
  LOTTER_HANOI_SAMAKKEE: 'HANOI_SAMAKKEE',
  LOTTER_HANOI_PHATTANA: 'HANOI_PHATTANA',
  LOTTER_LAO_SAMAKKEE: 'LAO_SAMAKKEE',
  LOTTER_LAO_ASEAN: 'LAO_ASEAN',
  LOTTER_LAO_STAR_VIP: 'LAO_STAR_VIP',
  LOTTER_ENGLISH_VIP: 'ENGLISH_VIP',
  LOTTER_GERMANY_VIP: 'GERMANY_VIP',
  LOTTER_LAO_RED_CROSS: 'LAO_RED_CROSS',
  LOTTER_RUSSIA_VIP: 'RUSSIA_VIP',
  LOTTER_DOWNJON_VIP: 'DOWNJON_VIP',
  LOTTER_HANOI_ASEAN: 'HANOI_ASEAN',
  LOTTER_LAO_TV: 'LAO_TV',
  LOTTER_HANOI_HD: 'HANOI_HD',
  LOTTER_HANOI_STAR: 'HANOI_STAR',
  LOTTER_MALAY: 'MALAY',
  LOTTER_HANOI_EXTRA: 'HANOI_EXTRA',
  LOTTER_NAT_SHARE_HUNGSENG_MORNING_VIP: 'NAT_SHARE_HUNGSENG_MORNING_VIP',
  LOTTER_NAT_SHARE_HUNGSENG_AFTERNOON_VIP: 'NAT_SHARE_HUNGSENG_AFTERNOON_VIP',
  LOTTER_NAT_SHARE_CHINA_MORNING_VIP: 'NAT_SHARE_CHINA_MORNING_VIP',
  LOTTER_NAT_SHARE_CHINA_AFTERNOON_VIP: 'NAT_SHARE_CHINA_AFTERNOON_VIP',
  LOTTER_NAT_SHARE_SINGAPORE_VIP: 'NAT_SHARE_SINGAPORE_VIP',
  LOTTER_NAT_SHARE_KOREA_VIP: 'NAT_SHARE_KOREA_VIP',
  LOTTER_NAT_SHARE_TAIWAN_VIP: 'NAT_SHARE_TAIWAN_VIP',
  LOTTER_NAT_SHARE_NIKAII_MORNING_VIP: 'NAT_SHARE_NIKAII_MORNING_VIP',
  LOTTER_NAT_SHARE_NIKAII_AFTERNOON_VIP: 'NAT_SHARE_NIKAII_AFTERNOON_VIP',
  LOTTER_LAO_VIP: 'LAO_VIP',
  LOTTER_HANOI_CHINESE_NEW_YEAR: 'HANOI_CHINESE_NEW_YEAR',
  LOTTER_NAT_SHARE_DOWNJON_STAR: 'NAT_SHARE_DOWNJON_STAR'
}

export const LOTTO_GAME_TYPE_NAME: { [type in TLottoGameType]: string } = {
  TWO_UP: '2 บน',
  TWO_DOWN: '2 ล่าง',
  THREE_UP: '3 บน',
  THREE_TOAST: '3 โต๊ด',
  FOUR_SUITE: 'เลขชุดสี่ตัว',
  RUN_UP: 'วิ่งบน',
  RUN_DOWN: 'วิ่งล่าง'
  // TODO:
  // ONE_AWARD: 'รางวัลที่ 1',
  // YEGEE_PLAY_AWARD: 'รางวัลยิงเลขลำดับที่',
}

export const LOTTO_BET_TYPES: { [type in TLottoGameType]: TLottoGameType } = {
  TWO_UP: 'TWO_UP',
  TWO_DOWN: 'TWO_DOWN',
  THREE_UP: 'THREE_UP',
  THREE_TOAST: 'THREE_TOAST',
  FOUR_SUITE: 'FOUR_SUITE',
  RUN_UP: 'RUN_UP',
  RUN_DOWN: 'RUN_DOWN'
  // TODO:
  // ONE_AWARD: 'ONE_AWARD',
  // YEGEE_PLAY_AWARD: 'YEGEE_PLAY_AWARD',
}
export const LOTTO_GAME_TYPES: { [type in TLottoSlug]: TLottoGameType[] } = {
  LOTTER_YEGEE: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_GOVN: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_BAAC: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_GSB: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_LAO_SUITE: ['FOUR_SUITE'],
  LOTTER_NAT_SHARE_LAO: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_TH_SHARE_MORNING: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_TH_SHARE_MIDDAY: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_TH_SHARE_AFTERNOON: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_TH_SHARE_EVENING: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_NAT_SHARE_DOWNJON: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_NAT_SHARE_EGYPT: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_NAT_SHARE_GERMANY: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_NAT_SHARE_NIKAII_MORNING: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_NAT_SHARE_NIKAII_AFTERNOON: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_NAT_SHARE_CHINA_MORNING: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_NAT_SHARE_CHINA_AFTERNOON: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_NAT_SHARE_TAIWAN: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_NAT_SHARE_KOREA: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_NAT_SHARE_SINGAPORE: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_NAT_SHARE_INDIA: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_NAT_SHARE_HANOI_SPECIAL: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_NAT_SHARE_MALAY: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_NAT_SHARE_VIETNAM_HANOI: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_NAT_SHARE_VIETNAM_HANOI_VIP: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_NAT_SHARE_HANOI_4D: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_NAT_SHARE_RUSSIA: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_NAT_SHARE_ENGLISH: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_NAT_SHARE_HUNGSENG_MORNING: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_NAT_SHARE_HUNGSENG_AFTERNOON: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_LAO_HD: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_HANOI_TV: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_LAO_STAR: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_HANOI_RED_CROSS: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_HANOI_SAMAKKEE: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_HANOI_PHATTANA: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_LAO_SAMAKKEE: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_LAO_ASEAN: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_LAO_STAR_VIP: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_ENGLISH_VIP: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_GERMANY_VIP: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_LAO_RED_CROSS: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_RUSSIA_VIP: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_DOWNJON_VIP: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_HANOI_ASEAN: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_LAO_TV: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_HANOI_HD: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_HANOI_STAR: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_MALAY: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_HANOI_EXTRA: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_NAT_SHARE_HUNGSENG_MORNING_VIP: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_NAT_SHARE_HUNGSENG_AFTERNOON_VIP: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_NAT_SHARE_CHINA_MORNING_VIP: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_NAT_SHARE_CHINA_AFTERNOON_VIP: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_NAT_SHARE_SINGAPORE_VIP: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_NAT_SHARE_KOREA_VIP: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_NAT_SHARE_TAIWAN_VIP: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_NAT_SHARE_NIKAII_MORNING_VIP: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_NAT_SHARE_NIKAII_AFTERNOON_VIP: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_LAO_VIP: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_HANOI_CHINESE_NEW_YEAR: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ],
  LOTTER_NAT_SHARE_DOWNJON_STAR: [
    'THREE_UP',
    'THREE_TOAST',
    'TWO_UP',
    'TWO_DOWN',
    'RUN_UP',
    'RUN_DOWN'
  ]
}

export const LOTTO_GAME_TYPE_LENGTH: { [type in TLottoGameType]: number } = {
  TWO_UP: 2,
  TWO_DOWN: 2,
  THREE_UP: 3,
  THREE_TOAST: 3,
  FOUR_SUITE: 4,
  RUN_UP: 1,
  RUN_DOWN: 1
  // TODO:
  // ONE_AWARD: 6,
  // YEGEE_PLAY_AWARD: 5,
}

export const LOTTO_FLAG_ALPHA: { [type in TLottoType]: TFlag } = {
  GOVN: 'THA',
  BAAC: 'BAAC',
  GSB: 'GSB',
  TH_SHARE_MORNING: 'THA',
  TH_SHARE_MIDDAY: 'THA',
  TH_SHARE_AFTERNOON: 'THA',
  TH_SHARE_EVENING: 'THA',
  NAT_SHARE_DOWNJON: 'USA',
  NAT_SHARE_EGYPT: 'EGY',
  NAT_SHARE_GERMANY: 'DEU',
  NAT_SHARE_NIKAII_MORNING: 'JPN',
  NAT_SHARE_NIKAII_AFTERNOON: 'JPN',
  NAT_SHARE_CHINA_MORNING: 'CHN',
  NAT_SHARE_CHINA_AFTERNOON: 'CHN',
  NAT_SHARE_TAIWAN: 'TWN',
  NAT_SHARE_KOREA: 'KOR',
  NAT_SHARE_SINGAPORE: 'SGP',
  NAT_SHARE_INDIA: 'IND',
  NAT_SHARE_HANOI_SPECIAL: 'VNM',
  NAT_SHARE_MALAY: 'MYS',
  NAT_SHARE_VIETNAM_HANOI: 'VNM',
  NAT_SHARE_VIETNAM_HANOI_VIP: 'VNM',
  NAT_SHARE_HANOI_4D: 'VNM',
  NAT_SHARE_RUSSIA: 'RUS',
  NAT_SHARE_ENGLISH: 'GBR',
  NAT_SHARE_HUNGSENG_MORNING: 'HKG',
  NAT_SHARE_HUNGSENG_AFTERNOON: 'HKG',
  NAT_SHARE_LAO: 'LAO',
  LAO_SUITE: 'LAO',
  YEGEE: 'THA',
  LAO_HD: 'LAO',
  HANOI_TV: 'VNM',
  LAO_STAR: 'LAO',
  HANOI_RED_CROSS: 'VNM',
  HANOI_SAMAKKEE: 'VNM',
  HANOI_PHATTANA: 'VNM',
  LAO_SAMAKKEE: 'LAO',
  LAO_ASEAN: 'LAO',
  LAO_STAR_VIP: 'LAO',
  ENGLISH_VIP: 'GBR',
  GERMANY_VIP: 'DEU',
  LAO_RED_CROSS: 'LAO',
  RUSSIA_VIP: 'RUS',
  DOWNJON_VIP: 'USA',
  HANOI_ASEAN: 'VNM',
  LAO_TV: 'LAO',
  LAO_VIP: 'LAO',
  HANOI_HD: 'VNM',
  HANOI_STAR: 'VNM',
  MALAY: 'MYS',
  HANOI_EXTRA: 'VNM',
  NAT_SHARE_HUNGSENG_MORNING_VIP: 'HKG',
  NAT_SHARE_HUNGSENG_AFTERNOON_VIP: 'HKG',
  NAT_SHARE_CHINA_MORNING_VIP: 'CHN',
  NAT_SHARE_CHINA_AFTERNOON_VIP: 'CHN',
  NAT_SHARE_SINGAPORE_VIP: 'SGP',
  NAT_SHARE_KOREA_VIP: 'KOR',
  NAT_SHARE_TAIWAN_VIP: 'TWN',
  NAT_SHARE_NIKAII_MORNING_VIP: 'JPN',
  NAT_SHARE_NIKAII_AFTERNOON_VIP: 'JPN',
  HANOI_CHINESE_NEW_YEAR: 'VNM',
  NAT_SHARE_DOWNJON_STAR: 'USA',
}

export const LOTTO_SLUG_TO_QA_TYPE: { [type in TLottoSlug]: TQAType } = {
  LOTTER_YEGEE: 'yeege',
  LOTTER_GOVN: 'yeege',
  LOTTER_BAAC: 'yeege',
  LOTTER_GSB: 'yeege',
  LOTTER_NAT_SHARE_LAO: 'yeege',
  LOTTER_LAO_SUITE: 'yeege',
  LOTTER_TH_SHARE_MORNING: 'yeege',
  LOTTER_TH_SHARE_MIDDAY: 'yeege',
  LOTTER_TH_SHARE_AFTERNOON: 'yeege',
  LOTTER_TH_SHARE_EVENING: 'yeege',
  LOTTER_NAT_SHARE_DOWNJON: 'yeege',
  LOTTER_NAT_SHARE_EGYPT: 'yeege',
  LOTTER_NAT_SHARE_GERMANY: 'yeege',
  LOTTER_NAT_SHARE_NIKAII_MORNING: 'yeege',
  LOTTER_NAT_SHARE_NIKAII_AFTERNOON: 'yeege',
  LOTTER_NAT_SHARE_CHINA_MORNING: 'yeege',
  LOTTER_NAT_SHARE_CHINA_AFTERNOON: 'yeege',
  LOTTER_NAT_SHARE_TAIWAN: 'yeege',
  LOTTER_NAT_SHARE_KOREA: 'yeege',
  LOTTER_NAT_SHARE_SINGAPORE: 'yeege',
  LOTTER_NAT_SHARE_INDIA: 'yeege',
  LOTTER_NAT_SHARE_HANOI_SPECIAL: 'yeege',
  LOTTER_NAT_SHARE_MALAY: 'yeege',
  LOTTER_NAT_SHARE_VIETNAM_HANOI: 'yeege',
  LOTTER_NAT_SHARE_VIETNAM_HANOI_VIP: 'yeege',
  LOTTER_NAT_SHARE_HANOI_4D: 'yeege',
  LOTTER_NAT_SHARE_RUSSIA: 'yeege',
  LOTTER_NAT_SHARE_ENGLISH: 'yeege',
  LOTTER_NAT_SHARE_HUNGSENG_MORNING: 'yeege',
  LOTTER_NAT_SHARE_HUNGSENG_AFTERNOON: 'yeege',
  LOTTER_LAO_HD: 'yeege',
  LOTTER_HANOI_TV: 'yeege',
  LOTTER_LAO_STAR: 'yeege',
  LOTTER_HANOI_RED_CROSS: 'yeege',
  LOTTER_HANOI_SAMAKKEE: 'yeege',
  LOTTER_HANOI_PHATTANA: 'yeege',
  LOTTER_LAO_SAMAKKEE: 'yeege',
  LOTTER_LAO_ASEAN: 'yeege',
  LOTTER_LAO_STAR_VIP: 'yeege',
  LOTTER_ENGLISH_VIP: 'yeege',
  LOTTER_GERMANY_VIP: 'yeege',
  LOTTER_LAO_RED_CROSS: 'yeege',
  LOTTER_RUSSIA_VIP: 'yeege',
  LOTTER_DOWNJON_VIP: 'yeege',
  LOTTER_HANOI_ASEAN: 'yeege',
  LOTTER_LAO_TV: 'yeege',
  LOTTER_LAO_VIP: 'yeege',
  LOTTER_HANOI_HD: 'yeege',
  LOTTER_HANOI_STAR: 'yeege',
  LOTTER_MALAY: 'yeege',
  LOTTER_HANOI_EXTRA: 'yeege',
  LOTTER_NAT_SHARE_HUNGSENG_MORNING_VIP: 'yeege',
  LOTTER_NAT_SHARE_HUNGSENG_AFTERNOON_VIP: 'yeege',
  LOTTER_NAT_SHARE_CHINA_MORNING_VIP: 'yeege',
  LOTTER_NAT_SHARE_CHINA_AFTERNOON_VIP: 'yeege',
  LOTTER_NAT_SHARE_SINGAPORE_VIP: 'yeege',
  LOTTER_NAT_SHARE_KOREA_VIP: 'yeege',
  LOTTER_NAT_SHARE_TAIWAN_VIP: 'yeege',
  LOTTER_NAT_SHARE_NIKAII_MORNING_VIP: 'yeege',
  LOTTER_NAT_SHARE_NIKAII_AFTERNOON_VIP: 'yeege',
  LOTTER_HANOI_CHINESE_NEW_YEAR: 'yeege',
  LOTTER_NAT_SHARE_DOWNJON_STAR: 'yeege'
}

export const GAME_TYPE_NAME: { [type in TGameType]: string } = {
  BINGO: 'เล่นบิงโก',
  ECASINO: 'พนันคาสิโน',
  FISHING: 'ยิงปลา',
  SLOT: 'หมุนสล๊อต',
  CASINO: 'คาสิโนออนไลน์',
  P2P: 'เกมเล่นผู้ใช้ต่อผู้ใช้'
}

export const TRANSACTION_STATUS: { [type in TTransactionStatus]: string } = {
  SUCCESS: 'สำเร็จ',
  FAIL: 'ไม่สำเร็จ',
  WAIT: 'รอ',
  RESERVE: 'จอง',
  WINNER: 'ได้',
  LOSER: 'เสีย',
  CANCEL: 'ยกเลิก',
  DRAW: 'เสมอ',
  PMPG_GOLD_AWARD: 'ทอง',
  PMPG_SILVER_AWARD: 'เงิน',
  PMPG_BRONZE_AWARD: 'ทองแดง',
  PMPG_TIN_AWARD: 'ดีบุก'
}

export const GAME_TYPE: { [key in string]: string } = {
  FISHING: 'เกมยิงปลา',
  SLOT: 'เกมสล๊อต',
  ECASINO: 'คาสิโนอิเล็กทรอนิกส์',
  BINGO: 'บิงโก',
  LOTTERY: 'ลอตเตอรี่',
  CASINO: 'คาสิโน',
  P2P: 'เกมเล่นผู้ใช้ต่อผู้ใช้',
  CARD: 'PG',
  Bonus: 'Joker Roma',
  Main: 'Joker Roma',
  PJPMINI: 'Joker tiger lair',
  Powerbar: 'Joker jackpot'
}

export const SLUG_GAME_COMPANY: { [key in TSlugGameCompany]: string } = {
  JOKER: 'Joker',
  SlotXO: 'SlotXO',
  AESEXY: 'AE Sexy',
  SAGAME: 'SA Gaming',
  PGGAME: 'PG',
  DT: 'Dream Tech',
  ALLBET: 'Allbet',
  WMGAME: 'WMGAME',
  DG: 'Dream Gaming',
  W88: 'W88',
  PPGAME:'PPGAME',
  EVOLUTION:"EVOLUTION",
  JILIGAME:"JILIGAME"
}

export const TRANSACTION_STATUS_COLOR: { [key in TTransactionStatus]?: string } = {
  WINNER: colors.SECONDARY_GREEN,
  LOSER: colors.TERTIARY_RED,
  CANCEL: colors.SECONDARY_WHITE,
  WAIT: colors.PRIMARY_ORANGE,
  RESERVE: colors.PRIMARY_ORANGE,
  PMPG_GOLD_AWARD: colors.PRIMARY_ORANGE,
  PMPG_SILVER_AWARD: colors.PRIMARY_ORANGE,
  PMPG_BRONZE_AWARD: colors.PRIMARY_ORANGE,
  PMPG_TIN_AWARD: colors.PRIMARY_ORANGE,
  DRAW: colors.PRIMARY_ORANGE,
  SUCCESS: colors.SECONDARY_GREEN,
  FAIL: colors.TERTIARY_RED
}

export const betTypeOptions: IInputCheckboxGroupOption[] = [
  { value: 'LOTTER', label: 'หวย' },
  { value: 'CASINO', label: 'คาสิโน' },
]

export const betStatusOptions: IInputCheckboxGroupOption[] = [
  { value: 'WAIT', label: 'รอ' },
  { value: 'WINNER', label: 'ได้' },
  { value: 'LOSER', label: 'เสีย' },
]