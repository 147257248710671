export const GET_ME_CREDIT_REQUEST = 'GET_ME_CREDIT_REQUEST'
export const GET_ME_CREDIT_SUCCESS = 'GET_ME_CREDIT_SUCCESS'
export const GET_ME_CREDIT_FAILURE = 'GET_ME_CREDIT_FAILURE'
export const CLEAR_ME_CREDIT = 'CLEAR_ME_CREDIT'

export const GET_ME_CREDIT_DETAIL_REQUEST = 'GET_ME_CREDIT_DETAIL_REQUEST'
export const GET_ME_CREDIT_DETAIL_SUCCESS = 'GET_ME_CREDIT_DETAIL_SUCCESS'
export const GET_ME_CREDIT_DETAIL_FAILURE = 'GET_ME_CREDIT_DETAIL_FAILURE'
export const CLEAR_ME_CREDIT_DETAIL = 'CLEAR_ME_CREDIT_DETAIL'

export const GET_USER_MONEY_REQUEST = 'GET_USER_MONEY_REQUEST'
export const GET_USER_MONEY_SUCCESS = 'GET_USER_MONEY_SUCCESS'
export const GET_USER_MONEY_FAILURE = 'GET_USER_MONEY_FAILURE'
export const CLEAR_USER_MONEY = 'CLEAR_USER_MONEY'

export const PUT_USER_MONEY_REQUEST = 'PUT_USER_MONEY_REQUEST'
export const PUT_USER_MONEY_SUCCESS = 'PUT_USER_MONEY_SUCCESS'
export const PUT_USER_MONEY_FAILURE = 'PUT_USER_MONEY_FAILURE'

export const CreditMeState: ReducerState<IPagination<ICredit>> = {
  isFetching: false,
  code: 0,
  data: {
    dataList: [],
    limit: 0,
    page: 0,
    total: 0,
  },
  error: '',
}

export const userMoneyState: ReducerState<IUserMoney> = {
  isFetching: false,
  code: 0,
  data: {
    username: '',
    money: 0,
  },
  error: '',
}

export const putUserMoneyState: ReducerState<IPutUserMoneyBody> = {
  isFetching: false,
  code: 0,
  data: {
    id: 0,
    increment: 0,
    decrement: 0,
  },
  error: '',
}

export const CreditMeDetailState: ReducerState<ICreditDetail> = {
  isFetching: false,
  code: 0,
  storeData: {},
  data: {
    createDate: '',
    money: '',
    numbers: '',
    numbersBetResult: '',
    rate: '',
    slug: '',
    status: 'WAIT',
    type: 'WITHDRAW',
    updatedAt: ''
  },
  error: '',
}