import { AxiosError, AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import { getAllUserAction, getAllUserAffilateAction, getMeUserAction, getStatUserReadAction } from './actionCreator'
import { fetchGetAllUser, fetchGetMeUser, fetchStatUserRead, fetchUpdateUser, fetGetAllUserAffilate } from './services'
import { updateUserAction } from './actionCreator'
import { loaderAction } from 'reduxs/loader/actionCreator'

const getAllUser = (userQuery: IUserQuery, isFirst?: boolean) => async (dispatch: Dispatch) => {
    dispatch(loaderAction.request({}))
    dispatch(getAllUserAction.request({}))
    await fetchGetAllUser(userQuery)
        .then((response: AxiosResponse<IAPIResponse<IPagination<IUser>>>) => {
            dispatch(loaderAction.success({}))
            return dispatch(getAllUserAction.success(response, isFirst!))
        })
        .catch((error: AxiosError<IAPIResponse>) => {
            dispatch(loaderAction.success({}))
            return dispatch(getAllUserAction.failure(error))
        })
}
const getAllUserAffilate = (userQuery: IGetAffilateUserQuery, isFirst?: boolean) => async (dispatch: Dispatch) => {
    dispatch(loaderAction.request({}))
    dispatch(getAllUserAffilateAction.request({}))
    await fetGetAllUserAffilate(userQuery)
        .then((response: AxiosResponse<IAPIResponse<IPagination<IUserAffilate, IAffilateTansaction, IUserAffilate>>>) => {
            dispatch(loaderAction.success({}))
            return dispatch(getAllUserAffilateAction.success(response, isFirst!))
        })
        .catch((error: AxiosError<IAPIResponse>) => {
            dispatch(loaderAction.success({}))
            return dispatch(getAllUserAffilateAction.failure(error))
        })
}


const getMeUser = () => async (dispatch: Dispatch) => {
    dispatch(loaderAction.request({}))
    dispatch(getMeUserAction.request({}))
    await fetchGetMeUser()
        .then((response: AxiosResponse<APIResponse<IUserMe>>) => {
            dispatch(loaderAction.success({}))
            return dispatch(getMeUserAction.success(response))
        })
        .catch((error: AxiosError<IAPIResponse>) => {
            dispatch(loaderAction.success({}))
            return dispatch(getMeUserAction.failure(error))
        })
}

const getStatUserRead = (userQuery: IV1ReadStatsUser, isFirst?: boolean) => async (dispatch: Dispatch) => {
    dispatch(loaderAction.request({}))
    dispatch(getStatUserReadAction.request({}))
    await fetchStatUserRead(userQuery)
        .then((response: AxiosResponse<IAPIResponse<IPagination<IV1RespReadStatsUser>>>) => {
            dispatch(loaderAction.success({}))
            return dispatch(getStatUserReadAction.success(response, isFirst!))
        })
        .catch((error: AxiosError<IAPIResponse>) => {
            dispatch(loaderAction.success({}))
            return dispatch(getStatUserReadAction.failure(error))
        })
}

const clearMeUser = () => (dispatch: Dispatch) =>
    dispatch(getMeUserAction.cancel({}))

const clearUserAffilate = () => (dispatch: Dispatch) =>
    dispatch(getAllUserAffilateAction.cancel({}))

const clearAllUser = () => (dispatch: Dispatch) =>
    dispatch(getAllUserAction.cancel({}))

const clearStatUserRead = () => (dispatch: Dispatch) =>
    dispatch(getStatUserReadAction.cancel({}))

const updateUser = (userQuery: IUserQuery, userData: IUpdateUser) => async (dispatch: Dispatch) => {
    dispatch(loaderAction.request({}))
    dispatch(updateUserAction.request({}))
    await fetchUpdateUser(userQuery, userData)
        .then((response: AxiosResponse<IAPIResponse<IUpdateUser>>) => {
            dispatch(loaderAction.success({}))
            return dispatch(updateUserAction.success(response))
        })
        .catch((error: AxiosError<IAPIResponse>) => {
            dispatch(loaderAction.success({}))
            return dispatch(updateUserAction.failure(error))
        })
}

const users = {
    getAllUser,
    updateUser,
    clearAllUser,
    getMeUser,
    clearMeUser,
    getAllUserAffilate,
    clearUserAffilate,
    getStatUserRead,
    clearStatUserRead,
}

export default users

