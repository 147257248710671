import { get } from 'lodash'
import {lotterBetReadState, lotterBetState, putLotterBetState } from './constants'

const creditMe = (state: RootReducers): ReducerState<APIPagination<IResponseBetUser>> =>
    get(state, 'betTransaction.getLotterBet', lotterBetState)
const betRead = (state: RootReducers): ReducerState<APIPagination<IV1RespReadBetTx>> =>
    get(state, 'betTransaction.getBetRead', lotterBetReadState)
const putUserMoney = (state: RootReducers): ReducerState<IPutUserMoneyBody> =>
    get(state, 'betTransaction.putLotterBet', putLotterBetState)


const userSelector = {
    creditMe,
    putUserMoney,
    betRead,
}

export default userSelector