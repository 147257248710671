import React, { FC, useState, Fragment } from 'react'
import { date, number } from 'utils'
import {
  split,
  groupBy,
  Dictionary,
  isEmpty,
  map,
  keys,
  get,
  capitalize,
  noop
} from 'lodash'
import { Badge, PlaceholderLoading } from 'components'
import {
  LOTTO_GAME_TYPE_NAME,
  TRANSACTION_TYPE,
  LOTTO_SLUG_NAME,
  GAME_TYPE_NAME
} from 'constants/variables'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import './transactionItemCollapsible.style.scss'
import colors from 'constants/colors'
import BronzeIcon from 'assets/images/pmpg/bronze.svg'
import SilverIcon from 'assets/images/pmpg/diamond.svg'
import GoldIcon from 'assets/images/pmpg/chest.svg'
import TinIcon from 'assets/images/pmpg/tin.svg'
import { format } from 'date-fns'
import { th } from 'date-fns/locale'

const constants = {
  round: 'รอบที่',
  makeLotto: 'แทง',
  waitResult: 'รอผลออก',
  pmpgResult: 'โบนัส กองเงินกองทอง',
  actureResult: 'เลขที่ออก',
  game: 'เกม',
  moneyRate: 'อัตราจ่าย'
}

const statusName: {
  [status in TBetStatus | TFinanceStatus | TAmountStatus | TPmpgAwardStatus]: {
    name: string
    color: string
    amountColor: string
  }
} = {
  WAIT: {
    name: 'รอตรวจสอบ',
    color: colors.PRIMARY_YELLOW,
    amountColor: colors.PRIMARY_YELLOW
  },
  SUCCESS: {
    name: 'สำเร็จ',
    color: colors.SECONDARY_GREEN,
    amountColor: colors.SECONDARY_GREEN
  },
  FAIL: {
    name: 'ไม่สำเร็จ',
    color: colors.PRIMARY_RED,
    amountColor: colors.SECONDARY_TEXT
  },
  WINNER: {
    name: 'ได้',
    color: colors.SECONDARY_GREEN,
    amountColor: colors.SECONDARY_GREEN
  },
  LOSER: {
    name: 'เสีย',
    color: colors.PRIMARY_RED,
    amountColor: colors.PRIMARY_RED
  },
  CANCEL: {
    name: 'ยกเลิก',
    color: colors.SECONDARY_TEXT,
    amountColor: colors.SECONDARY_TEXT
  },
  DRAW: {
    name: 'เสมอ',
    color: colors.SECONDARY_TEXT,
    amountColor: colors.SECONDARY_TEXT
  },
  W_SUCCESS: {
    name: 'สำเร็จ',
    color: colors.SECONDARY_GREEN,
    amountColor: colors.PRIMARY_RED
  },
  WF_SUCCESS: {
    name: 'สำเร็จ',
    color: colors.SECONDARY_GREEN,
    amountColor: colors.PRIMARY_RED
  },
  PMPG_BRONZE_AWARD: {
    name: 'โบนัส',
    color: colors.SECONDARY_YELLOW,
    amountColor: colors.PRIMARY_GREEN
  },
  PMPG_GOLD_AWARD: {
    name: 'โบนัส',
    color: colors.SECONDARY_YELLOW,
    amountColor: colors.PRIMARY_GREEN
  },
  PMPG_SILVER_AWARD: {
    name: 'โบนัส',
    color: colors.SECONDARY_YELLOW,
    amountColor: colors.PRIMARY_GREEN
  },
  PMPG_TIN_AWARD: {
    name: 'โบนัส',
    color: colors.SECONDARY_YELLOW,
    amountColor: colors.PRIMARY_GREEN
  }
}

declare interface ITransactionItemCollapsible {
  credit: ICredit
  creditDetail?: ICreditDetail[]
  action?(createdAt: string, slug: string | null, status: string): void
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: ITransactionItemCollapsible = {
  credit: {
    money: 0,
    status: '',
    createdAt: '',
    groupType: '',
    slug: '',
    list: []
  },
  action(createdAt: 'SUCCESS' | 'FAIL', slug: string | null) {
    noop()
  },
  creditDetail: []
}

const getDisplayTransactionName = (groupType: string) => {
  const lendingType = get(groupType.split('_', 1), '0', '')
  const trailingType = groupType.replace(`${lendingType}_`, '')
  const subLendingType = get(trailingType.split('_', 1), '0', '')
  if (lendingType === 'FINANCE') {
    const filterFNGameName = trailingType
      .replace(/^[^_]*_/, '')
      .split('_')
      .join(' ')

    const filterType = Object.entries(TRANSACTION_TYPE)
      .map((dl) => dl[0])
      .includes(trailingType)

    return filterType
      ? TRANSACTION_TYPE[trailingType as TTransactionType]
      : `${
          TRANSACTION_TYPE[subLendingType as TTransactionType]
        } ${filterFNGameName}`
  } else if (lendingType === 'BET') {
    if (subLendingType === 'GAME') {
      const trailingBetType = trailingType.replace(`${subLendingType}_`, '')
      const gameNameUnderscore = trailingBetType.replace(
        /CASINO_JOKER_|CASINO_THBGAME_/,
        ''
      )
      const gameName = capitalize(gameNameUnderscore)
        .split('_')
        .flatMap((dl) => [['w88'].includes(dl) ? dl.toUpperCase() : dl])
        .join(' ')
      return `เดิมพันเกม ${gameName}`
    }
    // LOTTO type
    return LOTTO_SLUG_NAME[trailingType as TLottoSlug | TPmpgAwardStatus]
  }
}

const TransactionItemCollapsible: FC<
  ITransactionItemCollapsible & DefaultProps
> = (props) => {
  const [isExpand, setExpand] = useState<boolean>(false)
  const { credit, creditDetail } = props

  const getGroupType = () => {
    const [nameSplited, typeSplited, subTypeSplited, endPoinTypeSplited] =
      split(credit.groupType, '_')
    return {
      name: nameSplited,
      type: typeSplited,
      subType: subTypeSplited || '',
      endPointType: endPoinTypeSplited || ''
    }
  }

  const getSlug = () => {
    const [slugName, slugType, slugRound] = split(credit.slug || '', '_')
    return {
      name: slugName,
      type: slugType,
      round: slugRound
    }
  }

  const { name, type, subType, endPointType } = getGroupType()
  const displayName = getDisplayTransactionName(credit.groupType)
  const displayTime = format(
    date.calibratingTime(credit.createdAt, true, true),
    'dd MMM yyyy HH:mm',
    { locale: th }
  )
  const numRound = getSlug().round
  const round =
    subType === 'YEGEE'
      ? ` (${constants.round} ${numRound.substring(
          numRound.length - 2,
          numRound.length
        )})`
      : ''

  const handleOnExpandClick = () => {
    if (name !== 'FINANCE') {
      setExpand(!isExpand)
      if (!isExpand) {
        props.action!(credit.createdAt, credit.slug!, credit.status)
      }
    }
  }

  const getStatus = () => {
    if (name === 'FINANCE') {
      if (
        type === 'WITHDRAW' &&
        endPointType === 'FISHING' &&
        credit.status === 'SUCCESS'
      ) {
        return statusName['WF_SUCCESS' as TBetStatus]
      } else if (
        type === 'WITHDRAW' &&
        endPointType !== 'FISHING' &&
        credit.status === 'SUCCESS'
      ) {
        return statusName['W_SUCCESS' as TBetStatus]
      }

      return statusName[credit.status as TFinanceStatus]
    } else if (name === 'BET') {
      if (credit.status === 'WAIT') {
        return {
          name: constants.makeLotto,
          color: statusName[credit.status as TBetStatus].color
        }
      }
      return statusName[credit.status as TBetStatus]
    }
    return { name: '', color: 'transparent' }
  }

  const CreditInfoListComponent = () => {
    if (name === 'FINANCE') {
      if (isEmpty(credit.list)) {
        return (
          <>
            <PlaceholderLoading
              customCol={[
                [
                  { col: 3, color: colors.PRIMARY_TEXT, show: true, size: 'S' },
                  { col: 2, color: colors.PRIMARY_TEXT, show: true, size: 'S' },
                  { col: 5, show: false },
                  {
                    col: 2,
                    color: colors.SECONDARY_TEXT,
                    show: true,
                    size: 'S'
                  }
                ]
              ]}
            />
          </>
        )
      }
      return map(credit.list, (cred, creditIndex) => {
        const stColor = get(statusName, `${cred.status}.color`, '')
        const stName = get(statusName, `${cred.status}.name`, '')
        return (
          <div
            className="d-flex flex-row align-items-center transaction-description-row"
            key={`transaction-description-amount-${creditIndex}`}
          >
            <div className="transaction-description-name-text">
              {TRANSACTION_TYPE[cred.type as TTransactionType]}{' '}
              <h6 className="transaction-description-lotto-number">
                {cred.numbers}
              </h6>{' '}
              (<span style={{ color: stColor }}>{stName}</span>)
            </div>
            <div className="transaction-description-amount">
              {number.castToMoney(Number(cred.money))}
            </div>
          </div>
        )
      })
    } else {
      // BET
      const creditGroupList: Dictionary<ICreditDetail[]> =
        groupBy<ICreditDetail>(creditDetail, 'type')
      if (isEmpty(creditGroupList)) {
        return (
          <div className="pt-3">
            <PlaceholderLoading
              customCol={[
                [
                  { col: 3, color: colors.PRIMARY_TEXT, show: true, size: 'M' },
                  { col: 9, show: false }
                ],
                [
                  { col: 2, color: colors.PRIMARY_TEXT, show: true, size: 'M' },
                  { col: 10, show: false }
                ],
                [
                  { col: 4, color: colors.PRIMARY_TEXT, show: true, size: 'S' },
                  { col: 6, show: false },
                  {
                    col: 2,
                    color: colors.SECONDARY_TEXT,
                    show: true,
                    size: 'S'
                  }
                ]
              ]}
            />
          </div>
        )
      } else {
        return map(keys(creditGroupList), (key, keyIndex) => {
          const creditDetails = creditGroupList[key]

          const DetailComponents = map(creditDetails, (detail, detailIndex) => {
            const stName = get(statusName, `${detail.status}.name`, '')
            const stColor = get(statusName, `${detail.status}.color`, '')
            const subCreditStatus =
              detail.status === 'WAIT' ? constants.waitResult : stName

            const gameNameUnderscore = (detail.slug || '').replace(
              /GAME_CASINO_JOKER_|GAME_CASINO_THBGAME_|GAME_CASINO_AESEXY_|GAME_CASINO_DT_|GAME_CASINO_WMGAME_|GAME_CASINO_ALLBET_/,
              ''
            )
            // const gameName = capitalize(upperCase(gameNameUnderscore))
            // const transactionName = (detail.numbers === '-') ?
            //   `${GAME_TYPE_NAME[detail.type as TGameType]} ${constants.game} ${gameName}`
            //   : detail.numbers
            const removeRound = gameNameUnderscore.substring(
              0,
              gameNameUnderscore.lastIndexOf('_')
            )
            const gameName = capitalize(removeRound)
              .split('_')
              .flatMap((dl) => [['w88'].includes(dl) ? dl.toUpperCase() : dl])
              .join(' ')
            const transactionName =
              detail.numbers === '-'
                ? `${GAME_TYPE_NAME[detail.type as TGameType]} ${
                    constants.game
                  } ${gameName}`
                : detail.numbers
            return (
              // DETAIl about BET
              <div
                className="d-flex flex-row align-items-center transaction-description-row"
                key={`transaction-description-detail-${detailIndex}`}
              >
                <div className="transaction-description-name-text">
                  <h6 className="transaction-description-lotto-number p1-r">
                    {transactionName}
                  </h6>
                  <span style={{ color: stColor }}>{subCreditStatus}</span>{' '}
                  <br />
                  <span className="secondary-text">
                    {type === 'LOTTER' && detail.status === 'LOSER' ? (
                      `${constants.actureResult} ${detail.numbersBetResult}`
                    ) : type === 'GAME' ? (
                      <></>
                    ) : (
                      <span
                        style={{ color: stColor }}
                        className="transaction-description-amount subtitle-1"
                      >
                        {`${constants.moneyRate} ${number.castToMoney(
                          Number(detail.rate)
                        )}`}
                      </span>
                    )}
                  </span>
                </div>
                <div className="transaction-description-amount subtitle-1">
                  {`${number.castToMoney(Number(detail.money))}`}
                </div>
              </div>
            )
          })

          return (
            <Fragment key={`transaction-description-amount-${keyIndex}`}>
              <div className="row mt-3">
                <div className="col transaction-description-name-text">
                  {LOTTO_GAME_TYPE_NAME[key as TLottoGameType]}
                </div>
              </div>
              {DetailComponents}
            </Fragment>
          )
        })
      }
    }
  }

  const trxName = get(getStatus(), 'name', '')
  const trxColor = get(getStatus(), 'color', '')
  const amountColor = get(getStatus(), 'amountColor', '')
  return (
    <div
      className="row py-3 credit-info-item-container"
      onClick={handleOnExpandClick}
    >
      <div className="col d-flex credit-info-item-wrapper">
        <div className="d-flex flex-column transaction-leading-wrapper">
          <div className="transaction-name-text d-flex">
            {displayName}
            {credit.status.includes('GOLD') && (
              <img
                src={GoldIcon}
                className="pl-2 pr-1 bonus-badge"
                alt="BONUS"
              />
            )}
            {credit.status.includes('SILVER') && (
              <img
                src={SilverIcon}
                className="pl-2 pr-1 bonus-badge"
                alt="BONUS"
              />
            )}
            {credit.status.includes('BRONZE') && (
              <img
                src={BronzeIcon}
                className="pl-2 pr-1 bonus-badge"
                alt="BONUS"
              />
            )}
            {credit.status.includes('TIN') && (
              <img
                src={TinIcon}
                className="pl-2 pr-1 bonus-badge"
                alt="BONUS"
              />
            )}
            <span className="transaction-badge-wrapper">
              <Badge text={trxName} color="white" backgroundColor={trxColor} />
            </span>
          </div>
          <div className="transaction-time-text py-2">
            {displayTime} {round}
          </div>
        </div>
        <div className="transaction-amount-text" style={{ color: amountColor }}>
          {number.castToMoney(Number(credit.money))}
        </div>
        <div className="d-flex transaction-chevron-right-icon">
          {name !== 'FINANCE' && (
            <FontAwesomeIcon
              icon={faChevronRight}
              className={`chevron-right-icon ${isExpand ? 'expanded' : ''}`}
            />
          )}
        </div>
      </div>
      <div
        className={`transaction-description-container ${
          isExpand ? 'expanded' : ''
        } custom-scrollbar`}
      >
        <div className="d-flex flex-column transaction-description-wrapper m2-l">
          {CreditInfoListComponent()}
        </div>
      </div>
    </div>
  )
}

TransactionItemCollapsible.defaultProps = defaultProps

export default TransactionItemCollapsible
