import React from 'react';
import {
  Form,
  FormikProps
} from 'formik'
import { Button, DatePickerNew, InputCheckboxGroup, InputSelect, SelectorItem } from 'components';
import { InputTextBorder } from 'components/InputTextBorder';
import colors from 'constants/colors';
import { map } from 'lodash';
import { betStatusOptions, betTypeOptions, LOTTO_TYPE, SELECT_ALL_TYPE, SLUG_GAME_COMPANY } from 'constants/variables';
import { INPUT } from 'constants/styles';
import './TransactionFilterForm.style.scss'

const constants = {
  usernameSearch: 'usernameSearch',
  search: 'search',
  userSearch: 'ค้นหาผู้ใช้',
  type: 'type',
  subType: 'subType',
  status: 'status',
  playTimeStart: 'playTimeStart',
  playTimeEnd: 'playTimeEnd',
  resultTimeStart: 'resultTimeStart',
  resultTimeEnd: 'resultTimeEnd',

  typeLabel: 'ประเภท',
  subTypeLabel: 'ย่อย',
  statusLabel: 'สถานะ',
  playTimeLabel: 'เวลาแทง',
  resultTimeLabel: 'เวลาออกผล',
  startSearchLabel: 'เริ่มค้นหา',
  start: 'เริ่มต้น',
  end: 'ล่าสุด',
  selectAll: 'ทั้งหมด',
  startSearch: 'เริ่มค้นหา',

  LOTTER: 'หวย',
  CASINO: 'คาสิโน',

  WAIT: 'รอ',
  WINNER: 'ได้',
  LOSER: 'เสีย',
}

const TransactionFilterForm = (props: FormikProps<ITransactionFilterForm> & ITransactionFilterProps) => {

  const {
    values,
    // errors,
    // touched,
    setFieldValue,
    handleSubmit,
    handleChange,
    onClickUserSearch,
    isUserSelected,
    handleUserSearchChange,
    usernameFieldValue
  } = props

  const getSelectorItems = (selectedType: Array<string>) => {
    let lottoSelector: TLottoType[] = []
    let casinoSelector: TSlugGameCompany[] = []
    const allSelector: TSelectAllType[] = map(SELECT_ALL_TYPE, (all) => all as TSelectAllType) 
    if (selectedType.includes('LOTTER') || selectedType.length === 0) {
      lottoSelector = map(LOTTO_TYPE, (lotto) => lotto as TLottoType) 
    }
    if (selectedType.includes('CASINO') || selectedType.length === 0) {
      casinoSelector = map(SLUG_GAME_COMPANY, (company) => company as TSlugGameCompany)
    }
    const allValueSelector = [...allSelector, ...lottoSelector, ...casinoSelector]

    if (allValueSelector.indexOf(values.subType as TLottoType | TSlugGameCompany | TSelectAllType) === -1){
      //setTimeout เพราะ bad setState warning
      //ให้มันไป setstate tick ถัดไปจากตอน mount
      setTimeout(() => setFieldValue('subType', 'ทั้งหมด'), 0);
    }
    
    return allValueSelector
  }

  const renderSelector = ({
    item,
    ...selectProps
  }: IInputDefaultSelectProps<any>) => (
    <SelectorItem
      title={item}
      textAlign='start'
      {...selectProps}
      backgroundColor={colors.PRIMARY_WHITE}
      backgroundHoverColor={colors.SECONDARY_TEXT}
      color={colors.PRIMARY_BLACK}
      fontSize={INPUT.FONTSIZE}
    />
  )

  const handlePickDate = (field: string, value: string) => {
    setFieldValue(field, value)
  }

  const handleChangeUserSearch = (value: React.ChangeEvent<HTMLInputElement>) => {
    if (handleUserSearchChange)
      handleUserSearchChange(value)
  }

  const handleOnClickUserSearch = () => {
    if (isUserSelected && onClickUserSearch)
      onClickUserSearch()
  }

  const usernameValue = isUserSelected ? values.usernameSearch : usernameFieldValue

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement> | undefined) => {
    //disable submit ตอนยังไม่ได้เลือก user
    if (!isUserSelected) {
      e!.preventDefault();
      return 
    }
    handleSubmit(e)
  }

  return (
    <Form className='transaction-filter-form-container' onSubmit={handleOnSubmit}>
      <div className="row mx-0 justify-content-between align-items-center">
        <div className="col-3 p-0">
          <span className='subtitle-3 scale text-nowrap'>
            {constants.userSearch}
          </span>
        </div>
        <div className="col-8 h-100 p-0">
          <InputTextBorder
            toLowercase
            name={constants.usernameSearch}
            placeholder={constants.userSearch}
            onChange={handleChangeUserSearch}
            value={usernameValue}
            hiddenErrorBlock
            fontSize={INPUT.FONTSIZE}
            height={INPUT.HEIGHT}
            onClick={handleOnClickUserSearch}
          />
        </div>
      </div>
      {isUserSelected
      ?
        <>
          <div className="row mx-0 justify-content-between align-items-center">
            <div className="col-3 p-0">
              <span className='subtitle-3 scale text-nowrap'>
                {constants.typeLabel}
              </span>
            </div>
            <div className="col-8 h-100 p-0">
              <InputCheckboxGroup
                name={constants.type}
                options={betTypeOptions}
                values={values.type}
                onChange={handleChange}
                isTextInBox
                boxHoverColor={colors.SECONDARY_PURPLE}
              />
            </div>
          </div>
          <div className="row mx-0 justify-content-between align-items-center">
            <div className="col-3 p-0">
              <span className='subtitle-3 scale text-nowrap'>
                {constants.subTypeLabel}
              </span>
            </div>
            <div className="col-8 h-100 p-0">
              <InputSelect<TLottoType | TSlugGameCompany | TSelectAllType>
                key={`transaction-filter-subtype`}
                name={`subType`}
                items={getSelectorItems(values.type)}
                // valueKey={values.subType}
                value={values.subType}
                onChange={(selected) => {
                  setFieldValue('subType', selected)
                }}
                placeholder={constants.selectAll}
                // RenderSelected={this.handleStatusItem}
                RenderSelector={renderSelector}
                height={INPUT.HEIGHT}
                border={INPUT.BORDER}
                textAlign='start'
                fontSize={INPUT.FONTSIZE}
                iconColor={colors.PRIMARY_WHITE}
              />
            </div>
          </div>
          <div className="row mx-0 justify-content-between align-items-center">
            <div className="col-3 p-0">
              <span className='subtitle-3 scale text-nowrap'>
                {constants.statusLabel}
              </span>
            </div>
            <div className="col-8 h-100 p-0">
              <InputCheckboxGroup
                name={constants.status}
                options={betStatusOptions}
                values={values.status}
                onChange={handleChange}
                isTextInBox
                boxHoverColor={colors.SECONDARY_PURPLE}
              />
            </div>
          </div>
          <div className="row mx-0 justify-content-between align-items-center">
            <div className="col-3 p-0">
              <span className='subtitle-3 scale text-nowrap'>
                {constants.playTimeLabel}
              </span>
            </div>
            <div className="col-8 h-100 p-0">
              <div className='form-flex-gap'>
                <DatePickerNew
                  isShowIcon
                  name={constants.playTimeStart}
                  width={'50%'}
                  value={values.playTimeStart}
                  placeholder={constants.start}
                  format="DD MMM YYYY"
                  editable={false}
                  onChange={(value: any) => {handlePickDate(constants.playTimeStart, value)}}
                  hasErrorMessage={false}
                  border={INPUT.BORDER}
                  height={INPUT.HEIGHT}
                />
                <DatePickerNew
                  isShowIcon
                  name={constants.playTimeEnd}
                  width={'50%'}
                  value={values.playTimeEnd}
                  placeholder={constants.end}
                  format="DD MMM YYYY"
                  editable={false}
                  onChange={(value: any) => {handlePickDate(constants.playTimeEnd, value)}}
                  hasErrorMessage={false}
                  border={INPUT.BORDER}
                  height={INPUT.HEIGHT}
                />
              </div>
            </div>
          </div>

          <div className="row mx-0 justify-content-between align-items-center">
            <div className="col-3 p-0">
              <span className='subtitle-3 scale text-nowrap'>
                {constants.resultTimeLabel}
              </span>
            </div>
            <div className="col-8 h-100 p-0">
              <div className='form-flex-gap'>
                <DatePickerNew
                  isShowIcon
                  name={constants.resultTimeStart}
                  width={'50%'}
                  value={values.resultTimeStart}
                  placeholder={constants.start}
                  format="DD MMM YYYY"
                  editable={false}
                  onChange={(value: any) => {handlePickDate(constants.resultTimeStart, value)}}
                  hasErrorMessage={false}
                  border={INPUT.BORDER}
                  height={INPUT.HEIGHT}
                />
                <DatePickerNew
                  isShowIcon
                  name={constants.resultTimeEnd}
                  width={'50%'}
                  value={values.resultTimeEnd}
                  placeholder={constants.end}
                  format="DD MMM YYYY"
                  editable={false}
                  onChange={(value: any) => {handlePickDate(constants.resultTimeEnd, value)}}
                  hasErrorMessage={false}
                  border={INPUT.BORDER}
                  height={INPUT.HEIGHT}
                />
              </div>
            </div>
          </div>

          <div className="">
            <Button
              id={`login-submit-button`}
              text={constants.startSearch}
              size='medium'
              borderRadius='5px'
              backgroundColor={colors.PRIMARY_PURPLE}
              backgroundHoverColor={colors.SECONDARY_PURPLE}
              height={INPUT.HEIGHT}
              fontSize='clamp(10px, 1.2vw, 16px)'
              buttonType={`submit`}
              textColor={colors.PRIMARY_WHITE}
            />
          </div>
        </>
      : <></>}

    </Form>
  )
}

export default TransactionFilterForm