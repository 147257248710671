import { connect } from 'react-redux'
import LoginContainer from './Login.container'
import authSelector from '../../reduxs/authentication/selector'
import { login } from '../../reduxs/authentication/login/action'


const mapStateToProps = (state: RootReducers): ILoginContainerProps => {
    const loginState = authSelector.login(state)
    return {
        loginCode: loginState.code,
        loginError: loginState.error,
        loginIsFetching: loginState.isFetching,
    }
}
const mapDispatchToProps: ILoginContainerActionProps = {
    login: login

}
export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)