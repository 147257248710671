import './dropdown.style.scss'
import React, { FC } from 'react'
import Select from 'react-select'
import colors from 'constants/colors'

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IDropdown = {
  placeholder: '',
  controlBackgroundColor: colors.PRIMARY_BG,
  singleValueTextColor: colors.PRIMARY_TEXT,
  options: [],
  handleOnChange: () => {}
}

const Dropdown: FC<IDropdown & DefaultProps> = (props) => {
  const { placeholder, controlBackgroundColor, singleValueTextColor, options, handleOnChange } =
    props
  return (
        <Select
      placeholder={placeholder}
      styles={{
        control: (provided:any) => ({
          ...provided,
          backgroundColor: controlBackgroundColor
        }),
        singleValue: (provided:any) => ({
          ...provided,
          color: singleValueTextColor
        }),
        input: (provided:any) => ({
          ...provided,
          color: colors.PRIMARY_TEXT
        })
      }}
      options={options}
      onChange={handleOnChange}
      isOptionDisabled={(option:any) => option.disabled}
    />
  )
}

Dropdown.defaultProps = defaultProps

export default Dropdown
