import { AxiosError, AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import { get } from 'lodash'
import { loginAction } from '../actionCreator'
import { fetchLogin } from '../services'
import { setToken } from '../token/action'
import users from '../../users/actions'
import { loaderAction } from '../../loader/actionCreator'

const login = (loginData: ILoginRequest) => async (dispatch: Dispatch) => {
    dispatch(loaderAction.request({}))
    dispatch(loginAction.request({}))

    await fetchLogin(loginData)
        .then((response: AxiosResponse<IAPIResponse<ILogin>>) => {
            dispatch(loginAction.success(response))
            return get(response, 'data.data', { token: '' })
        })
        .then((loginData: ILogin) => {
            dispatch(loaderAction.success({}))
            setToken(loginData)(dispatch)
            users.getMeUser()(dispatch)
        })
            .catch((error: AxiosError<IAPIResponse>) => {
            dispatch(loaderAction.success({}))
            dispatch(loginAction.failure(error))})
}

export {
    login,
}