import React, { FC, useState } from 'react'
import './input.style.scss'
import colors from 'constants/colors'


type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IInput = {
    id: '',
    placeholder: '',
    label: '',
    disabled: false,
    backgroundColor: colors.SECONDARY_BG,
    backgroundActiveColor: colors.PRIMARY_BG,
    borderColor: colors.SECONDARY_BG,
    value: '',
    onChange: () => {},
    maxLength: undefined,
}

const Input: FC<IInput & DefaultProps> = (props) => {

    const {
        id,
        placeholder,
        label,
        disabled,
        backgroundColor,
        backgroundActiveColor,
        borderColor,
        value,
        onChange,
        maxLength,
    } = props

    const [isFocus, setIsFocus] = useState(false)
    const [dgColor, setBgColor] = useState(backgroundColor)

    return (
        <div className="d-flex justify-content-center flex-grow-1">
            {/* {isFocus && */}
            <div className={`text-left ${isFocus ? 'moveIn-label label-show' : 'moveOut-label label-hide'} `}>
                <label className="gray-text">{label}</label>
            </div>
            {/* } */}
            <input
                id={id}
                type="text"
                value={value}
                style={{ backgroundColor: dgColor, border: disabled ? 0 : undefined, borderColor: borderColor, }}
                disabled={disabled}
                onChange={onChange}
                maxLength={maxLength}
                className={`base-input ${backgroundColor} p2`}
                placeholder={`${isFocus ? '' : placeholder}`}
                onFocus={() => { setIsFocus(true); setBgColor(backgroundActiveColor) }}
                onBlur={() => { value === '' && setIsFocus(false); setBgColor(backgroundColor) }}
            />
        </div >
    )
}

Input.defaultProps = defaultProps

export default Input