
import emiter from 'configs/emiter'
import Core from './Modal.component'

const event = (eventName: TEvent, modalProps: IModalStateProps) => {
  return emiter.emit<IModalStateProps>(eventName, modalProps)
}

const coreModal = {
  Core,
  event,
}

export default coreModal