import { LOTTO_SLUG_NAME } from 'constants/variables'
import { format } from 'date-fns'
import { get } from 'lodash'
import React, { FC } from 'react'
import { date, number } from 'utils'
import '../../userInfo.style.scss'

declare interface IAffilateTansactionList {
  affilateTransaction: IAffilateTansaction
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IAffilateTansactionList = {
  affilateTransaction: {
    affRate: '',
    affilateMeUuid: '',
    betTransactionId: '',
    betValue: 0,
    createdAt: '',
    id: 0,
    money: '',
    slug: ''
  }
}

const constants = {
  betValue: 'Money : ',
  affRate: 'AffRate : ',
  round: (round: string) => `รอบที่ ${round}`
}

const AffilateTransaction: FC<IAffilateTansactionList & DefaultProps> = (
  props
) => {
  const { affilateTransaction } = props
  const subLendingType = get(affilateTransaction.slug.split('_', 1), '0', '')
  const getAffliateType = (type: string) => {

    if (subLendingType === 'GAME') {
      return type.split('_', 3).join('_')
    }
    const lendingType = get(type.split(/_([^_]*)$|(\d+)/, 1), '0', '')
    return LOTTO_SLUG_NAME[lendingType as TLottoSlug]
  }
  const getAffilateRound = (round: string) => {
    const lendingRound = get(round.match(/(\d+)/), '0', '')
    return lendingRound.substr(lendingRound.length - 3)
  }

  const displayRound = getAffilateRound(affilateTransaction.slug)
  const displayName = getAffliateType(affilateTransaction.slug)

  return (
    <>
      <div className="">
        <div className="topic-content py-1 px-2 my-1">
          <div className="row">
            <div className="col-12 col-lg-6 col-md-6 col-sm-6 my-2">
              <span className="body-1">
                {
                  subLendingType !== 'GAME' ? `${displayName} ${constants.round(
                    String(Number(displayRound))
                  )}` : displayName
                }
                <span className="subtitle-2">
                  {`\t\t\t(${format(
                    date.calibratingTime(affilateTransaction.createdAt, true, true),
                    'HH:mm:ss'
                  )})`}
                </span>
              </span>
            </div>
            <div className="col-12 col-lg-6 col-md-6 col-sm-6 text-right my-2">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-5 col-lg-7 col-md-6 col-sm-6"><span className="body-2">{constants.affRate}</span></div>
                    <div className="col-7 col-lg-5 col-md-6 col-sm-6"> <span className="body-1 text-success">{`${affilateTransaction.affRate}%`}</span></div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-5 col-lg-7 col-md-6 col-sm-6"><span className="body-2">{constants.betValue}</span></div>
                    <div className="col-7 col-lg-5 col-md-6 col-sm-6"> <span className="body-1 text-success">{`${number.castToMoney(Number(affilateTransaction.money))}`}</span></div>
                  </div>
                </div>
                <div className="col-12">
                  <span className="subtitle-2">
                    {`\t\t\t(${number.castToMoney(
                      affilateTransaction.betValue
                    )})`}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row justify-content-end text-right m1-b pb-2">
            <div className="col" />
            <div className="col-4">
              <span className="body-1 text-success">
                {`${number.castToMoney(Number(affilateTransaction.money))}`}
                <span className="subtitle-2">
                  {`\t\t\t(${number.castToMoney(
                    affilateTransaction.betValue
                  )})`}
                </span>
              </span>
            </div>
            <div className="col-2">
              {''}
            </div>
          </div> */}
        </div>
      </div>
    </>
  )
}
AffilateTransaction.defaultProps = defaultProps
export default AffilateTransaction
