import axios from 'axios'
import environment from '../../constants/environment'
import CryptoJS from 'crypto-js'
import ProjectENV from 'constants/environment'

const fetchLogin = (data: ILoginRequest) => {
    const encryptPassword = CryptoJS.AES.encrypt(
        data.password,
        ProjectENV.environments[environment.environmentName].endpoint.aesSecretKey
    ).toString()
    const loginData: ILogin1 = {
        username: data.username,
        password: encryptPassword
    }
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.login}`
    return axios.post(endpoint, loginData)
}
export {
    fetchLogin,
}