import { AxiosError, AxiosResponse } from 'axios'
import { combineReducers } from 'redux'
import { ActionType, createReducer } from 'typesafe-actions'
import { responseConverter } from '../../utils'
import { CreditMeState, userMoneyState, putUserMoneyState, CreditMeDetailState } from './constants'
import * as creditAction from './actionCreator'
import { get } from 'lodash'

export type CreditActionType = ActionType<typeof creditAction>

const getCreditMe = createReducer<ReducerState<IPagination<ICredit>>, CreditActionType>(CreditMeState)
    .handleAction(creditAction.getCreditMeAction.request, (state: ReducerState<IPagination<ICredit>>) => {
        return {
            ...state,
            isFetching: true,
        }
    })
    .handleAction(creditAction.getCreditMeAction.success, (state: ReducerState<IPagination<ICredit>>, action: ActionType<typeof creditAction.getCreditMeAction.success>) => {
        const payload: AxiosResponse<IAPIResponse<IPagination<ICredit>>> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        const data: IPagination<ICredit> = (action.meta)
            ? payload.data.data
            : ({
                ...payload.data.data,
                dataList: [
                    ...(state.data.dataList || []),
                    ...(payload.data.data.dataList || [])
                ]
            })
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data,
        }
    })
    .handleAction(creditAction.getCreditMeAction.failure, (state: ReducerState<IPagination<ICredit>>, action: CreditActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message,
        }
    })
    .handleAction(creditAction.getCreditMeAction.cancel, () => CreditMeState)

const getCreditMeDetail = createReducer<ReducerState<ICreditDetail>, CreditActionType>(CreditMeDetailState)
    .handleAction(creditAction.postCreditMeDeTail.request, (state: ReducerState<ICreditDetail>) => {
        return {
            ...state,
            isFetching: true,
        }
    })
    .handleAction(creditAction.postCreditMeDeTail.success, (state: ReducerState<ICreditDetail>, action: CreditActionType): ReducerState<ICreditDetail> => {
        const payload: AxiosResponse<IAPIResponse<ICreditDetail>> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        let status = JSON.parse(action.payload.config.data).status
        let res: { [key in string]: ICreditDetail[] } = {}
        let dataKey = get(action.payload.data, 'data[0]', {})
        res[`${dataKey.slug}_${dataKey.createDate}_${dataKey.type === 'CASINO' ? '' : dataKey.status}_${status}`] = action.payload.data.data
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            storeData: { ...state.storeData, ...res },
            data: action.payload.data.data,
        }
    })
    .handleAction(creditAction.postCreditMeDeTail.failure, (state: ReducerState<ICreditDetail>, action: CreditActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message,
        }
    })
    .handleAction(creditAction.postCreditMeDeTail.cancel, () => CreditMeDetailState)




const getUserMoney = createReducer<ReducerState<IUserMoney>, CreditActionType>(userMoneyState)
    .handleAction(creditAction.getUserMoneyAction.request, (state: ReducerState<IUserMoney>) => {
        return {
            ...state,
            isFetching: true,
        }
    })
    .handleAction(creditAction.getUserMoneyAction.success, (state: ReducerState<IUserMoney>, action: CreditActionType) => {
        const payload: AxiosResponse<IAPIResponse<IUserMoney>> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data: payload.data.data,
        }
    })
    .handleAction(creditAction.getUserMoneyAction.failure, (state: ReducerState<IUserMoney>, action: CreditActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message
        }
    })
    .handleAction(creditAction.getUserMoneyAction.cancel, () => userMoneyState)

const putUserMoney = createReducer<ReducerState<IPutUserMoneyBody>, CreditActionType>(putUserMoneyState)
    .handleAction(creditAction.putUserMoneyAction.request, (state: ReducerState<IPutUserMoneyBody>) => {
        return {
            ...state,
            isFetching: true,
        }
    })
    .handleAction(creditAction.putUserMoneyAction.success, (state: ReducerState<IPutUserMoneyBody>, action: CreditActionType) => {
        const payload: AxiosResponse<IAPIResponse<IPutUserMoneyBody>> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)

        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data: payload.data.data,
        }
    })
    .handleAction(creditAction.putUserMoneyAction.failure, (state: ReducerState<IPutUserMoneyBody>, action: CreditActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message,
        }
    })

export default combineReducers({
    list: getCreditMe,
    userMoney: getUserMoney,
    putUserMoney: putUserMoney,
    getCreditMeDetail: getCreditMeDetail
})