export const GET_ALL_PARTNER_REQUEST = 'GET_ALL_PARTNER_REQUEST'
export const GET_ALL_PARTNER_SUCCESS = 'GET_ALL_PARTNER_SUCCESS'
export const GET_ALL_PARTNER_FAILURE = 'GET_ALL_PARTNER_FAILURE'
export const CLEAR_ALL_PARTNER = 'CLEAR_ALL_PARTNER'

export const GET_PARTNER_SCHEDULE_REQUEST = 'GET_PARTNER_SCHEDULE_REQUEST'
export const GET_PARTNER_SCHEDULE_SUCCESS = 'GET_PARTNER_SCHEDULE_SUCCESS'
export const GET_PARTNER_SCHEDULE_FAILURE = 'GET_PARTNER_SCHEDULE_FAILURE'
export const CLEAR_SCHEDULE_PARTNER = 'CLEAR_SCHEDULE_PARTNER'

export const UPDATE_PARTNER_REQUEST = 'UPDATE_PARTNER_REQUEST'
export const UPDATE_PARTNER_SUCCESS = 'UPDATE_PARTNER_SUCCESS'
export const UPDATE_PARTNER_FAILURE = 'UPDATE_PARTNER_FAILURE'

export const UPDATE_PARTNER_SCHEDULE_REQUEST = 'UPDATE_PARTNER_SCHEDULE_REQUEST'
export const UPDATE_PARTNER_SCHEDULE_SUCCESS = 'UPDATE_PARTNER_SCHEDULE_SUCCESS'
export const UPDATE_PARTNER_SCHEDULE_FAILURE = 'UPDATE_PARTNER_SCHEDULE_FAILURE'

export const putPartnerState: ReducerState<IGameConfig> = {
  code: 0,
  data: {
    JOKER: false,
    AESEXY: false,
    THBGAME: false,
    SAGAME: false,
    DREAMTECH: false,
    ALLBET: false,
    PG: false,
    WMGAME: false,
    DREAMGAMING: false,
    W88_CASINO: false,
    W88_LOTTERY: false,
    W88_P2P: false,
    W88_SLOT: false,
  },
  error: '',
  isFetching: false,
}

export const putPartnerScheduleState: ReducerState<IPartnerScheduleConfig[]> = {
  code: 0,
  data: [],
  error: '',
  isFetching: false,
}
export const partnerScheduleState: ReducerState<IPartnerScheduleConfig[]> = {
  code: 0,
  data: [],
  error: '',
  isFetching: false
}

export const partnerState: ReducerState<IPartnerConfig> = {
  code: 0,
  data: {
    production: {
      JOKER: false,
      AESEXY: false,
      THBGAME: false,
      SAGAME: false,
      DREAMTECH: false,
      ALLBET: false,
      PG: false,
      WMGAME: false,
      DREAMGAMING: false,
      W88_CASINO: false,
      W88_LOTTERY: false,
      W88_P2P: false,
      W88_SLOT: false,
    },
    staging: {
      JOKER: false,
      AESEXY: false,
      THBGAME: false,
      SAGAME: false,
      DREAMTECH: false,
      ALLBET: false,
      PG: false,
      WMGAME: false,
      DREAMGAMING: false,
      W88_CASINO: false,
      W88_LOTTERY: false,
      W88_P2P: false,
      W88_SLOT: false,
    },
    develop: {
      JOKER: false,
      AESEXY: false,
      THBGAME: false,
      SAGAME: false,
      DREAMTECH: false,
      ALLBET: false,
      PG: false,
      WMGAME: false,
      DREAMGAMING: false,
      W88_CASINO: false,
      W88_LOTTERY: false,
      W88_P2P: false,
      W88_SLOT: false,
    },
  },
  error: '',
  isFetching: false
}