import { AxiosError, AxiosResponse } from 'axios'
import { find, get, isEmpty } from 'lodash'
import { responseMessage } from 'constants/response'
import { transformer } from 'utils'

const constants = {
    defaultError: 'กรุณาลองใหม่อีกครั้ง',
}

const getMessage = (response: AxiosError<IAPIResponse> | AxiosResponse<IAPIResponse>): IResponseConverter => {
    if (isEmpty(get(response, 'response', {}))) {
        // Succes case
        const axiosResponse = response as AxiosResponse<IAPIResponse>
        const messageCode: number = get(axiosResponse, 'data.code', get(response, 'status', 0))
        const code: number = get(response, 'status', 0)
        return {
            code,
            message: get(responseMessage(), messageCode, constants.defaultError),
        }
    } else {
        // Error case
        const axiosResponse = response as AxiosError<IAPIResponse>
        const requestPayload = transformer.camelcaseTransform(JSON.parse(axiosResponse.config.data))
        const requestPayloadWithSlugProperty: any = find(requestPayload, 'slug')
        const messageCode: number = get(axiosResponse, 'response.data.code', get(response, 'response.status', Number(get(response, 'code', 0))))
        const code: number = get(response, 'response.status', Number(get(response, 'code', 0)))
        return {
            code,
            message: requestPayloadWithSlugProperty ?
                get(responseMessage(requestPayloadWithSlugProperty.slug), messageCode, constants.defaultError) :
                get(responseMessage(), messageCode, constants.defaultError)
        }

    }
}

const responseConverter = { getMessage }
export default responseConverter

