import React, { FC } from 'react'
import { noop } from 'lodash'
import './buttonIcon.style.scss'

import { ReactComponent as CloseIcon } from 'assets/images/global/close/close.svg'
import { ReactComponent as SaveIcon } from 'assets/images/global/save/save.svg'
import { ReactComponent as SettingIcon } from 'assets/images/global/setting/setting.svg'
import { ReactComponent as SignOutIcon } from 'assets/images/global/signout/signout.svg'
import { ReactComponent as addIcon } from 'assets/images/global/add/add.svg'
import colors from 'constants/colors'



const iconSet: { [typed in IButtonIconType]: React.FunctionComponent<React.SVGProps<SVGSVGElement>> } = {
  close: CloseIcon,
  save: SaveIcon,
  setting: SettingIcon,
  signout: SignOutIcon,
  add: addIcon,
  custom: () => <></>,
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IButtonIcon = {
  id: '',
  type: 'close',
  onClick() { noop() },
}

const ButtonIcon: FC<IButtonIcon & DefaultProps> = ({
  color,
  id,
  type,
  fontSize,
  onClick,
  CustomIcon,
  disabled,
  disableHover
}) => {
  const createColor: React.CSSProperties = { fill: color ? color : colors.PRIMARY_TEXT, color: color ? color : colors.PRIMARY_TEXT }

  const Icon = () => {
    switch (type) {
      case 'custom':
        if (typeof CustomIcon === 'undefined') { return <></> }
        return CustomIcon
      default:
        const IconComponent = iconSet[type]
        return <IconComponent className="icon-button" style={createColor} />
    }
  }

  return (
    <div
      className={`icon-button-container ${disableHover && 'disable-hover'}`}
      style={
        fontSize ? { fontSize: fontSize, height: 'unset', width: 'unset' } : {}
      }
      onClick={disabled ? noop : onClick}
      id={id}
    >
      <Icon />
    </div>
  )
}

ButtonIcon.defaultProps = defaultProps

export default ButtonIcon