import React, { FC } from 'react'
import { noop } from 'lodash'
import { ResponsiveIcon, Button } from 'components'
import AlertIcon from 'assets/images/modal/error.png'
import { Modal } from '../..'
import './confirm.style.scss'
import colors from 'constants/colors'

const constants = {
  title: '',
  description: 'ยืนยันการทำรายการแก้ไข',
  actionText: 'ยืนยัน',
  cancelText: 'ยกเลิก'
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IConfirmModal = {
  title: constants.title,
  description: constants.description,
  actionText: constants.actionText,
  action() {
    noop()
  },
  cancel() {
    noop()
  },
  cancelText: constants.cancelText,
  width: '',
  padding: '',
  maxWidth: '',
  confirmButtonColor: '',
  cancelButtonColor: ''
}

const ConfirmModal: FC<IInfoModal & DefaultProps> = (props) => {
  const { width, padding, maxWidth, confirmButtonColor, cancelButtonColor } = props
  return (
    <div className="info-modal-container border-rounded" style={{ width, padding, maxWidth }}>
      <ResponsiveIcon
        alt="info-icon"
        className="info-checked-icon"
        icon={AlertIcon}
      />
      {typeof props.title === 'string' 
        ? <h2 className="secondary-green-text p1-t p2-b">{props.title}</h2>
        : props.title
      }
      {typeof props.description === 'string' 
        ? props.description!.split('\n').map((str, index) => (
          <p className="body-1 mb-0" key={`${index}`}>
            {str}
          </p>
          ))
        : props.description
      }
      <div className="m3-y p3-x">
        <div className="row">
          <div className="col m1-r">
            <Button
              id="info-ok-button"
              type="rectangle"
              onClick={props.cancel}
              text={props.cancelText!}
              backgroundColor={cancelButtonColor ? cancelButtonColor : colors.PRIMARY_RED}
              backgroundHoverColor={colors.TERTIARY_RED}
            />
          </div>
          <div className="col m1-l">
            <Button
              id="info-ok-button"
              type="rectangle"
              onClick={props.action}
              backgroundColor={confirmButtonColor ? confirmButtonColor : colors.SECONDARY_GREEN}
              backgroundHoverColor={colors.PRIMARY_GREEN}
              text={props.actionText!}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

ConfirmModal.defaultProps = defaultProps

const confirmModal = {
  show: (props: IConfirmModal) => {
    return Modal.event('MODAL_OVER', {
      state: 'show',
      extraProps: { size: 'md' },
      component: <ConfirmModal {...props} key="confirm-modal" />
    })
  },
  hide: () => Modal.event('MODAL_OVER', { state: 'hide' })
}

export default confirmModal
