import { connect } from "react-redux"
import users from "reduxs/users/actions"
import userSelector from "reduxs/users/selector"
import UserInfoContainer from './UserInfo.container'
import creditSelector from "reduxs/credits/selector"
import credits from 'reduxs/credits/actions'

const mapStateToProps = (state: RootReducers): IUserInfoContainerProps => {
    const allUser = userSelector.allUser(state)
    const meUser = userSelector.getMeUser(state)
    const userAffilate = userSelector.getUserAffilate(state)
    const updatedUser = userSelector.updatedUser(state)
    const userMoney = creditSelector.userMoney(state)
    const PutUserMoney = creditSelector.putUserMoney(state)
    return {
        getAllCode: allUser.code,
        getAllError: allUser.error,
        getAllIsFetching: allUser.isFetching,
        updateUserCode: updatedUser.code,   
        updateUserError: updatedUser.error,
        updateUserIsFetching: updatedUser.isFetching,
        userList: allUser.data,
        userMe: meUser.data,
        getUserMoneyCode: userMoney.code,
        getUserMoneyData: userMoney.data,
        getUserMoneyError: userMoney.error,
        getUserMoneyIsFetching: userMoney.isFetching,
        putUserMoneyCode: PutUserMoney.code,
        putUserMoneyError: PutUserMoney.error,
        putUserMoneyIsFetching: PutUserMoney.isFetching,
        getAllUserAffilateCode: userAffilate.code,
        getAllUserAffilateError: userAffilate.error,
        getAllUserAffilateIsFetching: userAffilate.isFetching,
        userAffilateData: userAffilate.data,
    }
}

const mapDispatchToProos: IUserInfoContainerActionProps = {
    getAllUser: (query, isFirst) => users.getAllUser(query, isFirst),
    getUserMoney: (query) => credits.getUserMoney(query),
    putUserMoney: (body) => credits.putUserMoney(body),
    getUserAffilate: (query, isFirst) => users.getAllUserAffilate(query, isFirst),
    clearUserMoney: credits.clearUserMoney,
    updateUser: users.updateUser,
    clearAllUser: users.clearAllUser,
    clearUserAffilate: users.clearUserAffilate
}

export default connect(mapStateToProps, mapDispatchToProos)(UserInfoContainer)