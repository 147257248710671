import React, { FC, useEffect, useState } from 'react'
import { noop } from 'lodash'
import './button.style.scss'
import colors from 'constants/colors'

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IButton = {
  id: '',
  text: '',
  onClick() {
    noop()
  },
  type: 'normal',
  size: 'medium',
  disabled: false,
  buttonType: 'button',
  backgroundColor: colors.PRIMARY_RED,
  backgroundHoverColor: colors.SECONDARY_RED,
  paddingLeft: '0.5rem',
  paddingRight: '0.5rem',
}

const Button: FC<IButton & DefaultProps> = (props) => {
  const {
    id,
    text,
    type,
    size,
    onClick,
    disabled,
    buttonType,
    backgroundColor,
    backgroundHoverColor,
    borderRadius,
    paddingLeft,
    paddingRight,
    fontSize,
    width,
    height,
    textColor,
  } = props

  const containerClass = `col button-container ${type} ${size} ${disabled ? 'disabled' : ''}`

  const [hoverColor, setHoverColor] = useState(backgroundColor)

  useEffect(() => {
    setHoverColor(backgroundColor)
  }, [backgroundColor])

  const handleOnMouseOver = () => {
    setHoverColor(backgroundHoverColor)
  }

  const handleOnMouseLeave = () => {
    setHoverColor(backgroundColor)
  }

  return (
    <button
      onMouseLeave={handleOnMouseLeave}
      onMouseOver={handleOnMouseOver}
      id={id}
      type={buttonType}
      style={{
        backgroundColor: disabled ? backgroundColor : hoverColor,
        borderRadius: borderRadius,
        paddingLeft: paddingLeft,
        paddingRight: paddingRight,
        maxWidth: width,
        height: height,
      }}
      className={`${containerClass} shadow-lg`}
      onClick={disabled ? noop : onClick}
    >
      <div className="button-text p1-x">
        <h4 style={{ fontSize: fontSize, color: textColor }}>
          {text}
        </h4>
      </div>
    </button>
  )
}

Button.defaultProps = defaultProps

export default Button
