import { get } from 'lodash'
import { webbankObserverState, webbankPostState } from './constants'

const webBankObserverSelector = (state: RootReducers): ReducerState<APIPagination<IResponseWebbankObserver>> =>
    get(state, 'webbank.getWebBankObserver', webbankObserverState)

const webBankSwitchingSelector = (state: RootReducers): ReducerState<APIPagination<IResponseWebbankObserver>> =>
    get(state, 'webbank.postWebbankSwitching', webbankPostState)

const webBankResetSelector = (state: RootReducers): ReducerState<APIPagination<IResponseWebbankObserver>> =>
    get(state, 'webbank.postWebbankReset', webbankPostState)


const userSelector = {
    webBankObserverSelector,
    webBankSwitchingSelector,
    webBankResetSelector,
}

export default userSelector