export const GET_LOTTER_BET_REQUEST = 'GET_LOTTER_BET_REQUEST'
export const GET_LOTTER_BET_SUCCESS = 'GET_LOTTER_BET_SUCCESS'
export const GET_LOTTER_BET_FAILURE = 'GET_LOTTER_BET_FAILURE'
export const CLEAR_LOTTER_BET = 'CLEAR_LOTTER_BET'

export const PUT_LOTTER_BET_REQUEST = 'PUT_LOTTER_BET_REQUEST'
export const PUT_LOTTER_BET_SUCCESS = 'PUT_LOTTER_BET_SUCCESS'
export const PUT_LOTTER_BET_FAILURE = 'PUT_LOTTER_BET_FAILURE'

export const GET_BET_READ_REQUEST = 'GET_BET_READ_REQUEST'
export const GET_BET_READ_SUCCESS = 'GET_BET_READ_SUCCESS'
export const GET_BET_READ_FAILURE = 'GET_BET_READ_FAILURE'
export const CLEAR_BET_READ = 'CLEAR_BET_READ'

export const lotterBetState: ReducerState<IPagination<IResponseBetUser>> = {
  isFetching: false,
  code: 0,
  data: {
    dataList: [],
    limit: 0,
    page: 0,
    total: 0,
  },
  error: '',
}

export const lotterBetReadState: ReducerState<IPagination<IV1RespReadBetTx>> = {
  isFetching: false,
  code: 0,
  data: {
    dataList: [],
    limit: 0,
    page: 0,
    total: 0,
  },
  error: '',
}

export const putLotterBetState: ReducerState<any> = {
  isFetching: false,
  code: 0,
  data: [],
  error: '',
}
