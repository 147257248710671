import axios from 'axios'
import { MiddlewareAPI, Dispatch } from 'redux'
import ProejectENV from 'constants/environment'

const axiosMiddleware =
  ({ getState }: MiddlewareAPI<Dispatch, RootReducers>) =>
  (next: Dispatch) =>
  (action: any) => {
    const token = getState().authentication.token.accessToken
    const node = ProejectENV.name
    axios.defaults.headers.common.token = token
    axios.defaults.headers.common.node = node
    return next(action)
  }

export default axiosMiddleware
