import axios from 'axios'
import environment from 'constants/environment'
import { transformer } from 'utils'

const fetchGetLottoList = (query?: ILottoQuery) => {
  const endpoint = `${
    environment.environments[environment.environmentName].endpoint.url
  }${environment.endpoint.getAllLotter}${transformer.urlSearchParams(query)}`
  return axios.get(endpoint, { headers: { token: '' } })
}

export { fetchGetLottoList }
