import { AxiosError, AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import { loaderAction } from 'reduxs/loader/actionCreator'
import { postLottoReverseAction } from '../actionCreator'
import { fetchPostLottoReverse } from '../services'

const postLottoReverse = (data: IReverseLotterPayment) => async (dispatch: Dispatch) => {
    dispatch(loaderAction.request({}))
    dispatch(postLottoReverseAction.request({}))
    await fetchPostLottoReverse(data)
    .then((res: AxiosResponse<IAPIResponse<null>>) => {
        dispatch(loaderAction.success({}))
        dispatch(postLottoReverseAction.success(res))
    })
    .catch((error: AxiosError<IAPIResponse>) => {
        dispatch(loaderAction.success({}))
        dispatch(postLottoReverseAction.failure(error))
    })
}

export { postLottoReverse }