import React, { Component } from 'react'
import {
  isEqual,
  isEmpty,
  noop
} from 'lodash'
import routes from 'configs/routes'
import {
  BrowserRouter as Router,
  RouteComponentProps,
  Redirect,
  Switch,
  Route,
} from 'react-router-dom'
import {
  AlertNotification,
  Drawer,
  ErrorModal,
  Loader,
  Modal,
  Navbar,
  TextRunning
} from 'components'

const constants = {
  permErrorDesc: `This application is available for ' TECHNICAL SUPPORT ' only.`,
  ok: 'OK'
}

type DefaultProps = Readonly<typeof defaultProps>
const defaultProps: IRootProps & IRootActionProps = {
  verifyAccess() { noop() },
  login() { noop() },
  logout() { noop() },
  getMeUser() { noop() },
  clearMeUser() { noop() },
  accessToken: '',
  textRunning: '',
  userMe: {
    id: 0,
        affilateMeUuid: '',
        username: '',
        phoneNumber: '',
        permission: '',
        bank: {
            createdAt: '',
            id: 0,
            name: '',
            number: '',
            type: '',
            updatedAt: '',
        },
        user_bank_id: 0,
        wallet: {
            createdAt: '',
            id: 0,
            money: 0,
            updatedAt: '',
        },
        wallet_id: 0,
        createdAt: '',
        updatedAt: '',
  },
}

class RootContainer extends Component<DefaultProps, IRootStates> {
  static defaultProps = defaultProps

  state: IRootStates = {
    isOpenDrawer: false,
    themeMode: 'dark-mode',
  }

  componentDidMount() {
    if (!isEmpty(this.props.accessToken)) {
      this.props.getMeUser()
    } else {
      this.handleOnLogout()
    }
  }

  componentDidUpdate(prevProps: IRootProps) {
    if (this.props.userMe.permission !== prevProps.userMe.permission && this.props.userMe.permission !== 'TECHNICAL_SUPPORT') {
      if (!isEmpty(this.props.accessToken)) {
        this.handleOnLogout()
        ErrorModal.show({
          action: ErrorModal.hide,
          description: constants.permErrorDesc,
          actionText: constants.ok
        })
      }
    }
  }

  handleOnClickBurgerMenu = () => {
    this.setState({ isOpenDrawer: !this.state.isOpenDrawer })
  }

  handleOnCloseDrawer = () => {
    this.setState({ isOpenDrawer: false })
  }

  handleOnLogout = () => {
    this.handleOnCloseDrawer()
    this.props.logout()
    this.props.clearMeUser()
  }

  renderGuardRoute = ({ component: RouteComponent, name, path, exact }: IRoutes) => {
    const renderRoute = (routeProps: RouteComponentProps) => {
      if (isEmpty(this.props.accessToken)) {
        return (<Redirect to={{ pathname: '/', state: { from: routeProps.location } }} />)
      }
      return (<RouteComponent {...routeProps} />)
    }

    return (
      <Route
        key={`${name}-page`}
        exact={exact}
        path={path}
        render={renderRoute}
      />
    )
  }

  renderRedirectRoute = ({ component: RouteComponent, name, path, exact }: IRoutes) => {
    const renderRoute = (routeProps: RouteComponentProps) => {
      if (!isEmpty(this.props.accessToken)) {
        return (<Redirect to={{ pathname: '/main', state: { from: routeProps.location } }} />)
      }
      return (<RouteComponent {...routeProps} />)
    }

    return (
      <Route
        key={`${name}-page`}
        exact={exact}
        path={path}
        render={renderRoute}
      />
    )
  }


  renderPageElement = () => (
    <Switch>
      {routes.map(route =>
        isEqual(route.name, '404')
          ? (<Route key={`${route.name}-page`} component={route.component} />)
          : (route.private)
            ? this.renderGuardRoute(route)
            : this.renderRedirectRoute(route))}
    </Switch>
  )

  renderNavbar = () => (
    <Navbar
      username={this.props.userMe.username}
      mode={'dark-mode'}
      isAuthorized={!isEmpty(this.props.accessToken)}
      onPressesMenu={this.handleOnClickBurgerMenu}
      onPressLogout={this.handleOnLogout}
    />
  )

  renderDrawer = () => {
    if (!isEmpty(this.props.accessToken)) {
      return (
        <Drawer
          mode={`dark-mode`}
          isOpen={this.state.isOpenDrawer}
          onPressLogout={this.handleOnLogout}
          onCloseDrawer={this.handleOnCloseDrawer}
          onPressBackground={this.handleOnClickBurgerMenu}
        />
      )
    }
    return <></>
  }

  render() {
    const PageElement = this.renderPageElement
    const PageNavbar = this.renderNavbar
    const PageDrawer = this.renderDrawer

    return (
      <>
        <Router>
          <TextRunning
            text={this.props.textRunning}
          />
          <PageNavbar />
          <PageDrawer />
          <PageElement />
        </Router>
        <AlertNotification.Core />
        <Modal.Core event="MODAL" />
        <Modal.Core event="MODAL_OVER" />
        <Loader />
      </>
    )
  }

}

export default RootContainer