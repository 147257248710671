import React, { Component } from 'react'
import { isEmpty, map, noop, find, get, isEqual } from 'lodash'
import colors from 'constants/colors'
import { SelectorItem } from 'components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import './inputSelect.style.scss'
import 'simplebar/dist/simplebar.min.css';
// import SimpleBar from 'simplebar-react';

const defaultProps: IInputSelectProps = {
	disabled: false,
	placeholder: 'เลือก',
	value: '',
	name: '',
	backgroundColor: colors.PRIMARY_BG,
	backgroundHoverColor: colors.PRIMARY_RED,
	onChange() { noop() },
	height: '',
	width: '',
	border: '',
	textAlign: 'center',
	fontSize: '',
	iconColor: '',
	borderRadius: '',
}

class InputSelectComponent<T = string, K = string> extends Component<IInputSelectProps<T, K>, IInputSelectState<T>> {

	static defaultProps = defaultProps

	state: IInputSelectState<T> = {
		selectedValue: '',
		isSelected: false,
		isExpand: false,
	}

	componentDidMount() {
		if (typeof this.props.value !== 'undefined'
			&& ((typeof this.props.value === 'number' && this.props.value > 0) || !isEmpty(this.props.value))) {
			if (typeof this.props.valueKey !== 'undefined') {
				const defalutValue: T | undefined = find<T>(this.props.items, [this.props.valueKey || '', this.props.value])
				this.setState({ isSelected: true, selectedValue: defalutValue || '' })
			} else {
				this.setState({ isSelected: true, selectedValue: this.props.value })
			}
		}
	}

	componentDidUpdate(prevProps: IInputSelectProps<T, K>) {
		if (typeof this.props.value !== 'undefined' && prevProps.value !== this.props.value
			&& ((typeof this.props.value === 'number' && this.props.value > 0)
				|| !isEmpty(this.props.value))) {
			if (typeof this.props.valueKey !== 'undefined') {
				const defalutValue: T | undefined = find<T>(this.props.items, [this.props.valueKey || '', this.props.value])
				this.setState({ isSelected: true, selectedValue: defalutValue })
			} else {
				this.setState({ isSelected: true, selectedValue: this.props.value })
			}
		}

		if (this.props.value !== prevProps.value && isEmpty(this.props.value)) {
			if (typeof this.props.value !== 'number' && isEmpty(this.props.value)) {
				this.setState({ isSelected: false, selectedValue: '' })
			} else if (typeof this.props.value === 'number' && this.props.value <= 0) {
				this.setState({ isSelected: false, selectedValue: '' })
			}
		}
	}

	handleOpenSelector = () => {
		if (!this.props.disabled) {
			this.setState({ isExpand: !this.state.isExpand })
		}
	}

	handleOnBlurSelector = () => {
		this.setState({ isExpand: false })
	}

	handleOnClickItem = (item: T) => {
		this.setState({ selectedValue: item, isSelected: true, isExpand: false }, () => {
			this.props.onChange!(item, this.props.name)
		})
	}

	renderDisplaySelectedValues = () => {
		const { placeholder, RenderSelected, backgroundColor, backgroundHoverColor, fontSize } = this.props
		const { isSelected, selectedValue } = this.state

		if (isSelected) {
			if (typeof RenderSelected !== 'undefined') {
				return (
					<RenderSelected
						item={selectedValue}
						isDisplaying
						backgroundColor={backgroundColor}
						backgroundHoverColor={backgroundHoverColor}
					/>
				)
			}
			return (<h5 style={{ fontSize, lineHeight: fontSize }}>{selectedValue}</h5>)
		}

		return (<h5 style={{ fontSize, lineHeight: fontSize }}>{placeholder}</h5>)
	}

	renderItems = () => {
		const { items, RenderSelected, RenderSelector, name, backgroundColor, backgroundHoverColor, textAlign, fontSize } = this.props

		const Items = map(items, (item, index) => {
			const key = `${name}-${index}`
			const selectedValueKey = (typeof this.props.valueKey !== 'undefined')
				? get(item, this.props.valueKey || '', '')
				: item
			if (typeof RenderSelector !== 'undefined') {
				return (
					<li onClick={() => this.handleOnClickItem(item)} key={key} id={key}>
						<RenderSelector
							item={item}
							isSelected={isEqual(selectedValueKey, this.props.value)}
							backgroundColor={backgroundColor}
							backgroundHoverColor={backgroundHoverColor}
						/>
					</li>
				)
			}
			if (typeof RenderSelected !== 'undefined') {
				return (
					<li onClick={() => this.handleOnClickItem(item)} key={key} id={key}>
						<RenderSelected
							item={item}
							isSelected={isEqual(selectedValueKey, this.props.value)}
							backgroundColor={backgroundColor}
							backgroundHoverColor={backgroundHoverColor}
						/>
					</li>
				)
			}

			return (
				<li onClick={() => this.handleOnClickItem(item)} key={key} id={key}>
					<SelectorItem
						title={String(item) || ''}
						backgroundColor={backgroundColor}
						isSelected={isEqual(item, this.props.value)}
						backgroundHoverColor={backgroundHoverColor}
						textAlign={textAlign}
						fontSize={fontSize}
					/>
				</li>
			)
		})

		return <>{Items}</>
	}

	render() {
		const { name, backgroundColor, height, width, border, iconColor, borderRadius } = this.props
		const { isExpand } = this.state
		const DisplaySelectedValuesComponent = this.renderDisplaySelectedValues
		const ItemsComponent = this.renderItems
		return (
			<div
				className={`input-select-container ${this.props.disabled ? 'disabled' : ''}`}
				onBlur={this.handleOnBlurSelector}
				tabIndex={0}
				style={{ height, width, border, borderRadius }}
			>
				<ul
					id={name}
					style={{ backgroundColor }}
					className={`selected-wrapper ${height && 'h-100'}`}
					onClick={this.handleOpenSelector}
					onBlur={this.handleOnBlurSelector}
				>
					<li>
						<div className="input-selected-wrapper">
							<DisplaySelectedValuesComponent />
						</div>
						<FontAwesomeIcon
							icon={faChevronDown}
							style={{ color: iconColor }}
							className={`chevron-right-icon ${this.state.isExpand ? '' : ''}`}
						/>
					</li>
				</ul>
				<ul className={`selector-wrapper ${isExpand ? 'opened custom-scrollbar' : ''}`} style={{ backgroundColor }}>
				{/* <SimpleBar style={{minHeight:'100%' ,maxHeight:'20vh'}} clickOnTrack> */}
					<ItemsComponent />
				{/* </SimpleBar> */}
				</ul>
			</div>
		)
	}
}

export default InputSelectComponent