import { connect } from 'react-redux'
import { PartnerControllersContainer } from './PartnerControllers.container'
import partners from 'reduxs/partners/actions'
import partnerSelector from 'reduxs/partners/selector'

const mapStateToProps = (
  state: RootReducers
): IPartnerControllersContainerProps => {
  const allPartner = partnerSelector.allPartner(state)
  const putPartner = partnerSelector.putPartner(state)
  const putPartnerSchedule = partnerSelector.putPartnerSchedule(state)
  const schedulePartner = partnerSelector.schdulePartner(state)
  return {
    getAllPartnerCode: allPartner.code,
    getAllPartnerError: allPartner.error,
    getAllPartnerIsFetching: allPartner.isFetching,
    partnerList: allPartner.data,
    putPartnerCode: putPartner.code,
    putPartnerError: putPartner.error,
    putPartnerIsFetching: putPartner.isFetching,
    getPartnerScheduleCode: schedulePartner.code,
    getPartnerScheduleError: schedulePartner.error,
    getPartnerScheduleIsFetching: schedulePartner.isFetching,
    partnerSchedule: schedulePartner.data,
    putPartnerScheduleCode: putPartnerSchedule.code,
    putPartnerScheduleError: putPartnerSchedule.error,
    putPartnerScheduleIsFetching: putPartnerSchedule.isFetching
  }
}
const mapDispatchToProos: IPartnerControllersContainerActionProps = {
  getAllPartner: partners.getAllPartner,
  clearAllPartner: partners.clearAllPartner,
  clearPartnerSchdule: partners.clearPartnerSchedule,
  getPartnerSchedule: partners.getPartnerSchedule,
  putPartner: partners.putPartner,
  putPartnerSchedule: partners.putPartnerSchedule
}

export default connect(
  mapStateToProps,
  mapDispatchToProos
)(PartnerControllersContainer)
