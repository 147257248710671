import { connect } from "react-redux";
import TransactionManagementContainer from "./TransactionManagement.container";
import users from "reduxs/users/actions"
import betTransaction from "reduxs/betTransaction/actions"
import userSelector from "reduxs/users/selector"
import betTransactionSelector from "reduxs/betTransaction/selector"

const mapStateToProps = (state: RootReducers): ITransactionManagementContainerProps => {
    const allUser = userSelector.allUser(state)
    const betTransaction = betTransactionSelector.creditMe(state)
    const putBetTransaction = betTransactionSelector.putUserMoney(state)
    const getBetReadTransaction = betTransactionSelector.betRead(state)
    const getStatUserRead = userSelector.getStatUserRead(state)
    return {
        getAllCode: allUser.code,
        getAllError: allUser.error,
        getAllIsFetching: allUser.isFetching,
        userList: allUser.data,
        getLotterBetCode: betTransaction.code,
        getLotterBetError: betTransaction.error,
        getLotterBetIsFetching: betTransaction.isFetching,
        lotterBet: betTransaction.data,
        getBetReadCode: getBetReadTransaction.code,
        getBetReadError: getBetReadTransaction.error,
        getBetReadIsFetching: getBetReadTransaction.isFetching,
        betRead: getBetReadTransaction.data,
        putLotterBetUserCode:putBetTransaction.code,
        putLotterBetUserError:putBetTransaction.error,
        putLotterBetUserIsFetching:putBetTransaction.isFetching,
        getStatUserReadCode: getStatUserRead.code,
        getStatUserReadError: getStatUserRead.error,
        getStatUserReadIsFetching: getStatUserRead.isFetching,
        statUser: getStatUserRead.data,
    }
}

const mapDispatchToProps: ITransactionManagementContainerActionProps = {
    getAllUser: (query, isFirst) => users.getAllUser(query, isFirst),
    getLotterBet: betTransaction.getLotterBet,
    clearAllUser: users.clearAllUser,
    clearLotterBet: betTransaction.clearLotterBet,
    putLotterBetUser: betTransaction.putUserMoney,
    getBetRead: betTransaction.getBetRead,
    clearBetRead: betTransaction.clearBetRead,
    getStatUserRead: users.getStatUserRead,
    clearStatUserRead: users.clearStatUserRead,
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionManagementContainer)