import { AxiosError, AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import { loaderAction } from 'reduxs/loader/actionCreator'
import { getLottoListAction } from '../actionCreator'
import { fetchGetLottoList } from '../services'

const getLottoList = (query?: ILottoQuery) => async (dispatch: Dispatch) => {
  dispatch(loaderAction.request({}))
  dispatch(getLottoListAction.request({}))
  await fetchGetLottoList(query)
    .then((res: AxiosResponse<IAPIResponse<ILotto[]>>) => {
      dispatch(loaderAction.success({}))
      dispatch(getLottoListAction.success(res))
    })
    .catch((error: AxiosError<IAPIResponse>) => {
      dispatch(loaderAction.success({}))
      dispatch(getLottoListAction.failure(error))
    })
}

export { getLottoList }
