import React, { FC, useState, useEffect } from 'react'
import { ButtonIcon, ALink, ResponsiveIcon } from 'components'
import { noop } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory, useLocation } from 'react-router-dom'
import {
  faSignOutAlt,
  faHome,
  faBook,
  faGamepad,
  faMoneyBill,
  faDice,
  faCog,
} from '@fortawesome/free-solid-svg-icons'
import Logo from 'assets/images/logo/logothailandbet.png'
import './drawer.style.scss'
import routes from 'constants/routes'

declare interface IDrawer {
  mode: TThemeMode
  isOpen: boolean
  onCloseDrawer?(): void
  onPressBackground?(): void
  onPressLogout?(): void
}

const constants = {
  signout: 'Sign-Out',
  home: 'Main',
  user: 'User Edit',
  credit: 'Credit Me',
  vendorController: 'Partner Controllers',
  lotteryManageMent: 'Lottery Management',
  transactionManagement: 'Transaction Manage'
}

const defaultProps: IDrawer = {
  mode: 'dark-mode',
  isOpen: false,
  onCloseDrawer() { noop() },
  onPressBackground() { noop() },
  onPressLogout() { noop() },
}

const DrawerComponent: FC<IDrawer> = ({
  mode,
  isOpen,
  onPressLogout,
  onCloseDrawer,
  onPressBackground,
}) => {

  const [opened, setOpen] = useState(false)

  const history = useHistory()
  const location = useLocation()
  const currentMenu = location.pathname

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  const handleOnChangePage = (path: string) => {
    history.push(path)
    onCloseDrawer!()
  }

  return (
    <div className={`drawer-container d-flex flex-row ${mode} ${opened ? 'opened' : ''}`}>
      <div className="flex" onClick={onPressBackground} />
      <div className="drawer-wrapper p4-y d-flex">
        <div className="container flex d-flex flex-column">
          <div className="flex m2-b">
            <div className="d-flex flex-row-reverse">
              <ButtonIcon onClick={onCloseDrawer} type="close" id="close-drawer" />
            </div>
            <div className="text-center m3-b">
              <ResponsiveIcon icon={Logo} className="thailandbet-logo" />
            </div>
            <div>
              <div
                className={`drawer-item-container ${currentMenu === routes.main.path ? 'active' : ''}`}
                onClick={() => handleOnChangePage(routes.main.path)}
              >
                <h5 className="icon-container"><FontAwesomeIcon icon={faHome} className="m1-r" /></h5>
                <h5>{constants.home}</h5>
              </div>
              <div
                className={`drawer-item-container ${currentMenu === routes.userInfo.path ? 'active' : ''}`}
                onClick={() => handleOnChangePage(routes.userInfo.path)}
              >
                <h5 className="icon-container"><FontAwesomeIcon icon={faBook} className="m1-r" /></h5>
                <h5>{constants.user}</h5>
              </div>
              <div
                className={`drawer-item-container ${currentMenu === routes.creditInfo.path ? 'active' : ''}`}
                onClick={() => handleOnChangePage(routes.creditInfo.path)}
              >
                <h5 className="icon-container"><FontAwesomeIcon icon={faMoneyBill} className="m1-r" /></h5>
                <h5>{constants.credit}</h5>
              </div>
              <div
                className={`drawer-item-container ${currentMenu === routes.partnerControllers.path ? 'active' : ''}`}
                onClick={() => handleOnChangePage(routes.partnerControllers.path)}
              >
                <h5 className="icon-container"><FontAwesomeIcon icon={faGamepad} className="m1-r" /></h5>
                <h5>{constants.vendorController}</h5>
              </div>
              <div className={`drawer-item-container ${currentMenu === routes.lotteryManagement.path ? 'active' : ''}`}
                onClick={() => handleOnChangePage(routes.lotteryManagement.path)}
              >
                <h5 className="icon-container"><FontAwesomeIcon icon={faDice} className="m1-r" /></h5>
                <h5>{constants.lotteryManageMent}</h5>
              </div>
              <div
                className={`drawer-item-container ${currentMenu === routes.transactionManagement.path ? 'active' : ''}`}
                onClick={() => handleOnChangePage(routes.transactionManagement.path)}
              >
                <h5 className="icon-container"><FontAwesomeIcon icon={faCog} className="m1-r" /></h5>
                <h5>{constants.transactionManagement}</h5>
              </div>
            </div>
          </div>
          <div className="m2-b">
            <div className="text-center">
              <ALink id="signout-button" onClick={onPressLogout}>
                {constants.signout}
                <FontAwesomeIcon icon={faSignOutAlt} className="m1-l" />
              </ALink>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

DrawerComponent.defaultProps = defaultProps

export default DrawerComponent