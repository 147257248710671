import axios from 'axios'
import environment from 'constants/environment'
import { transformer } from 'utils'

const webbankEndpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.webbank}`

const fetWebbankObserver = (query: IQueryPagination) => axios.get(`${webbankEndpoint}/observer${transformer.urlSearchParams(query)}`)
const fetWebbankSwitching = (body: {id: number, type: string}) => axios.post(`${webbankEndpoint}/switching`, body)
const fetWebbankReset = (body: {id: number}) => axios.post(`${webbankEndpoint}/reset`, body)

export {
    fetWebbankObserver,
    fetWebbankSwitching,
    fetWebbankReset,
}