import { AxiosError, AxiosResponse } from 'axios'
import { createAsyncAction } from 'typesafe-actions'
import {
    GET_WEBBANK_OBSERVER_REQUEST,
    GET_WEBBANK_OBSERVER_SUCCESS,
    GET_WEBBANK_OBSERVER_FAILURE,
    CLEAR_WEBBANK_OBSERVER,
    POST_WEBBANK_SWITCHING_REQUEST,
    POST_WEBBANK_SWITCHING_SUCCESS,
    POST_WEBBANK_SWITCHING_FAILURE,
    POST_WEBBANK_RESET_REQUEST,
    POST_WEBBANK_RESET_SUCCESS,
    POST_WEBBANK_RESET_FAILURE,
} from './constants'

const getWebbankObserverAction = createAsyncAction(
    GET_WEBBANK_OBSERVER_REQUEST,
    GET_WEBBANK_OBSERVER_SUCCESS,
    GET_WEBBANK_OBSERVER_FAILURE,
    CLEAR_WEBBANK_OBSERVER,
)<any, [AxiosResponse<IAPIResponse<IPagination<IResponseWebbankObserver>>>, Boolean], AxiosError<IAPIResponse>, any>()

const postWebbankSwitchingAction = createAsyncAction(
    POST_WEBBANK_SWITCHING_REQUEST,
    POST_WEBBANK_SWITCHING_SUCCESS,
    POST_WEBBANK_SWITCHING_FAILURE,
)<any, {}, AxiosError<IAPIResponse>>()

const postWebbankResetAction = createAsyncAction(
    POST_WEBBANK_RESET_REQUEST,
    POST_WEBBANK_RESET_SUCCESS,
    POST_WEBBANK_RESET_FAILURE,
)<any, {}, AxiosError<IAPIResponse>>()


export {
    getWebbankObserverAction,
    postWebbankSwitchingAction,
    postWebbankResetAction
}