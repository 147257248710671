import { get } from 'lodash'
import { partnerState, putPartnerState } from './constants'

const allPartner = (state: RootReducers): ReducerState<IPartnerConfig> =>
    get(state, 'partner.list', partnerState)

const schdulePartner = (state: RootReducers): ReducerState<IPartnerScheduleConfig[]> =>
    get(state, 'partner.schedule', partnerState)

const putPartner = (state: RootReducers): ReducerState<IPartnerConfig> =>
    get(state, 'partner.put', putPartnerState)

const putPartnerSchedule = (state: RootReducers): ReducerState<IPartnerScheduleConfig[]> =>
    get(state, 'partner.putSchedule', putPartnerState)

const partnerSelector = {
    allPartner,
    schdulePartner,
    putPartner,
    putPartnerSchedule
}

export default partnerSelector