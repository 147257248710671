import { get } from 'lodash'
import { initialLottoListState } from './constants'

const listLotto = (state: RootReducers): ReducerState<ILotto[]> =>
  get(state, 'lotto.list', initialLottoListState)

const lottoSelector = {
  listLotto
}

export default lottoSelector
