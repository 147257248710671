import {
  ALink,
  Breadcrumb,
  Button,
  ErrorModal,
  InputNumberIcon,
  InputTextIcon,
  RollerLoading,
  SuccessModal
} from 'components'
import { responseCode } from 'constants/response'
import React, { Component, createRef, RefObject } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  faCashRegister,
  faChessKing,
  faChevronLeft,
  faCog,
  faMoneyBill,
  faSearch,
  faSpinner,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './userInfo.style.scss'
import colors from 'constants/colors'
import {
  Dictionary,
  get,
  groupBy,
  isEmpty,
  keys,
  map,
  noop,
  reverse,
  sortBy
} from 'lodash'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Formik, FormikProps } from 'formik'
import { AffilateTransaction, UpdateUserForm } from './components'
import scheme from './model/scheme'
import moment from 'moment'
import { format } from 'date-fns'
import { date, interactive, number } from 'utils'
import { UserDetailHeader } from './components/UserDetailHeader'

const constants = {
  routeTextSecodary: 'General',
  routeTextActive: 'User-Info',
  routeTextTertiary: 'OMEGA',
  back: 'Back',
  ok: 'OK',
  search: 'Search',
  sucess: '',
  detailSection: 'General Details',
  affilateSection: 'Affilate Details',
  validate: 'โปรดกรอกข้อมูลที่ไม่ซ้ำกับข้อมูลเดิม !!',
  editMoneyField: 'Edit Money',
  placeHolderEditMoney: (money: number) =>
    `(Total Money: ${number.castToMoney(money)})`,
  incrementButtonTitle: '+',
  decrementButtonTitle: '-',
  today: 'วันนี้',
  yesterday: 'เมื่อวาน',
  host: 'Host',
  child: 'Child',
  affRate: 'AffRate',
  money: 'Money'
}
type DefaultProps = Readonly<typeof defaultProps>
const defaultProps: IUserInfoContainerProps & IUserInfoContainerActionProps = {
  clearUserAffilate() {
    noop()
  },
  getUserAffilate() {
    noop()
  },
  getAllUser() {
    noop()
  },
  updateUser() {
    noop()
  },
  getUserMoney() {
    noop()
  },
  putUserMoney() {
    noop()
  },
  clearUserMoney() {
    noop()
  },
  clearAllUser() {
    noop()
  },
  getAllUserAffilateCode: 0,
  getAllUserAffilateError: '',
  getAllUserAffilateIsFetching: false,
  userAffilateData: [],
  getUserMoneyCode: 0,
  getUserMoneyData: {
    money: 0,
    username: ''
  },
  getUserMoneyError: '',
  getUserMoneyIsFetching: false,
  putUserMoneyCode: 0,
  putUserMoneyError: '',
  putUserMoneyIsFetching: false,
  getAllCode: 0,
  getAllError: '',
  getAllIsFetching: false,
  updateUserCode: 0,
  updateUserError: '',
  updateUserIsFetching: false,
  userList: {
    dataList: [],
    limit: 0,
    page: 0,
    total: 0
  },
  userMe: {
    id: 0,
    affilateMeUuid: '',
    username: '',
    phoneNumber: '',
    permission: '',
    bank: {
      createdAt: '',
      id: 0,
      name: '',
      number: '',
      type: '',
      updatedAt: ''
    },
    user_bank_id: 0,
    wallet: {
      createdAt: '',
      id: 0,
      money: 0,
      updatedAt: ''
    },
    wallet_id: 0,
    createdAt: '',
    updatedAt: ''
  }
}
export default class UserInfoContainer extends Component<
  DefaultProps & RouteComponentProps,
  IUserInfoContainerState
> {
  static defaultProps = defaultProps

  userSectionRef: RefObject<HTMLDivElement> = createRef()

  state: IUserInfoContainerState = {
    affilateChild: [],
    affilateHost: [],
    affilateTransaction: [],
    hasMoreAffilate: true,
    queryData: {
      permission: 'USER',
      search: '',
      page: 1,
      limit: 100
    },
    initialForm: {
      username: '',
      id: '',
      affilateMeUuid: '',
      phoneNumber: '',
      userBankName: '',
      userBankNumber: ''
    },
    queryAffilate: {
      affilateme: '',
      page: 1,
      limit: 20
    },
    hasMore: true,
    queryTimer: setTimeout(() => {
      noop()
    }, 0),
    spinIcon: false,
    isSearched: false,
    isSelected: false,
    userList: [],
    selectedUser: {
      id: 0,
      isFake: false,
      createdAt: '',
      affilateMeUuid: '',
      permission: '',
      phoneNumber: '',
      updatedAt: '',
      username: '',
      userBank: {
        id: 0,
        name: '',
        number: '',
        type: '',
        createdAt: '',
        updatedAt: ''
      },
      wallet: {
        id: 0,
        money: 0,
        createdAt: '',
        updatedAt: ''
      }
    },
    isMoneyValue: '',
    putUserMoneyBody: {
      id: 0,
      increment: 0,
      decrement: 0
    },
    userMoneyData: {
      money: 0,
      username: ''
    }
  }

  componentDidMount() {
    this.onGetUserLists()
  }

  componentDidUpdate(prevProps: IUserInfoContainerProps) {
    if (
      prevProps.getAllIsFetching !== this.props.getAllIsFetching &&
      !this.props.getAllIsFetching
    ) {
      if (this.props.getAllCode === responseCode.OK) {
        this.setState({
          isSearched: false,
          userList: this.props.userList.dataList
        })
        if (this.props.userList.page >= this.props.userList.total) {
          this.setState({ hasMore: false })
        } else {
          this.setState({ hasMore: true })
        }
      } else if (this.props.getAllCode === responseCode.NOT_FOUND) {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: `${this.props.getAllError}\n`,
          actionText: constants.ok
        })
        this.setState({ isSearched: false, userList: [] })
      }
    }
    if (
      prevProps.getAllUserAffilateIsFetching !==
        this.props.getAllUserAffilateIsFetching &&
      !this.props.getAllUserAffilateIsFetching
    ) {
      if (this.props.getAllUserAffilateCode === responseCode.OK) {
        this.setState({
          isSearched: false,
          affilateHost: this.props.userAffilateData.host,
          affilateChild: this.props.userAffilateData.child,
          affilateTransaction: this.props.userAffilateData.dataList
        })
        if (
          this.props.userAffilateData.page >= this.props.userAffilateData.total
        ) {
          this.setState({ hasMoreAffilate: false })
        } else {
          this.setState({ hasMoreAffilate: true })
        }
      } else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: `${this.props.getAllUserAffilateError}\n`,
          actionText: constants.ok
        })
        this.setState({
          isSearched: false,
          userList: [],
          affilateChild: [],
          affilateHost: [],
          affilateTransaction: []
        })
      }
    }
    if (
      prevProps.getUserMoneyIsFetching !== this.props.getUserMoneyIsFetching &&
      !this.props.getUserMoneyIsFetching
    ) {
      if (this.props.getUserMoneyCode === responseCode.OK) {
        this.setState((_, props) => ({ userMoneyData: props.getUserMoneyData }))
      } else {
        ErrorModal.show({
          description: `${this.props.getUserMoneyError}\n`,
          actionText: constants.ok,
          action: ErrorModal.hide
        })
      }
    }
    if (
      prevProps.putUserMoneyIsFetching !== this.props.putUserMoneyIsFetching &&
      !this.props.putUserMoneyIsFetching
    ) {
      if (this.props.putUserMoneyCode === responseCode.OK) {
        this.setState(
          {
            isMoneyValue: ''
          },
          () => this.props.getUserMoney({ id: this.state.selectedUser.id })
        )
      } else {
        ErrorModal.show({
          description: `${this.props.putUserMoneyError}\n`,
          actionText: constants.ok,
          action: ErrorModal.hide
        })
        this.setState(
          {
            isMoneyValue: ''
          },
          () => this.props.getUserMoney({ id: this.state.selectedUser.id })
        )
      }
    }

    if (
      prevProps.updateUserIsFetching !== this.props.updateUserIsFetching &&
      !this.props.updateUserIsFetching
    ) {
      if (this.props.updateUserCode === responseCode.OK) {
        SuccessModal.show({
          action: () => {
            this.onGetUserLists()
            return SuccessModal.hide()
          },
          description: constants.sucess,
          actionText: constants.ok
        })
        this.setState((prevState) => ({
          validForm: { ...prevState.initialForm }
        }))
      } else {
        ErrorModal.show({
          action: () => {
            this.onGetUserLists()
            return ErrorModal.hide()
          },
          description: `${this.props.updateUserError}\n`,
          actionText: constants.ok
        })
        this.setState((prevState) => ({
          initialForm: { ...prevState.validForm }
        }))
      }
    }
  }

  onGetUserLists = (isFirst: boolean = true) => {
    this.props.getAllUser(this.state.queryData, isFirst)
    this.setState((_, props) => ({ userList: props.userList.dataList }))
  }

  onSubmitUpdateUser = (value: IUpdateUser) => {
    const values = {
      ...value,
      affilateMeUuid:
        value.affilateMeUuid !== 'N/A'
          ? value.affilateMeUuid
          : this.state.initialForm.affilateMeUuid
    }
    const queryUpdate: IUserQuery = {
      permission: this.props.userMe!.permission,
      search: values.username
    }
    
    const putData = {
      id: Number(values.id),
      affilateMeUuid:
        values.affilateMeUuid === this.state.initialForm.affilateMeUuid
          ? ''
          : values.affilateMeUuid,
      phoneNumber: value.phoneNumber,
      userBankName: value.userBankName,
      userBankNumber: value.userBankNumber,
      username: value.username
    }
    this.setState(
      (prevState) => ({
        validForm: { ...prevState.initialForm },
        initialForm: {
          ...putData,
          userBankType: values.userBankType,
          affilateMeUuid: values.affilateMeUuid
        },
        isSelected: false,
        queryData: { search: '', permission: 'USER', page: 1, limit: 100 }
      }),
      () => {
        this.props.updateUser(queryUpdate, {...putData,bankType: values.userBankType})
      }
    )
  }

  handleOnBack = () => {
    this.props.history.push('/main')
  }

  handleOnClickBreadcrumb = (path: string) => {
    this.props.history.push(path)
  }

  handleScroll = () => {
    this.setState(
      (prevState, props) => ({
        queryData: {
          ...prevState.queryData,
          page: prevState.isSearched ? 1 : props.userList.page + 1
        }
      }),
      () => this.props.getAllUser(this.state.queryData, this.state.isSearched)
    )
  }

  handleAffliateTransactionScroll = () => {
    this.setState(
      (prevState, props) => ({
        queryAffilate: {
          ...prevState.queryAffilate,
          page: prevState.isSearched ? 1 : props.userAffilateData.page + 1
        }
      }),
      () => this.props.getUserAffilate(this.state.queryAffilate, false)
    )
  }

  handleOnSearch = (isFirst: boolean) => {
    clearTimeout(this.state.queryTimer)
    this.setState((prevState, props) => ({
      queryData: {
        ...prevState.queryData,
        page: isFirst ? 1 : props.userList.page + 1
      },
      queryTimer: setTimeout(() => {
        this.props.getAllUser(this.state.queryData, isFirst)
        this.setState((_, props) => ({
          userList: props.userList.dataList,
          spinIcon: false,
          isSearched: false
        }))
      }, 1000)
    }))
  }

  handleInputQuerySearch = (values: any) => {
    const querySearch = values.target.value
    this.setState(
      (prevState) => ({
        isSearched: true,
        spinIcon: true,
        queryData: {
          ...prevState.queryData,
          search: querySearch
        }
      }),
      () => {
        this.handleClearUser()
        this.handleOnSearch(this.state.isSearched)
      }
    )
  }

  handleSelectUser = (user: IUser) => {
    this.props.getUserMoney({ id: user.id })
    interactive.scrollTo(this.userSectionRef)
    this.setState(
      (ps) => ({
        selectedUser: user,
        isSelected: true,
        initialForm: {
          username: user.username,
          id: user.id,
          affilateMeUuid: user.affilateMeUuid,
          phoneNumber: user.phoneNumber,
          userBankName: user.userBank.name,
          userBankNumber: user.userBank.number,
          userBankType: user.userBank.type
        },
        queryAffilate: {
          ...ps.queryAffilate,
          affilateme: user.affilateMeUuid
        }
      }),
      () => this.props.getUserAffilate(this.state.queryAffilate, true)
    )
  }

  renderUpdateUserForm = () => {
    const createData = {
      ...this.state.initialForm,
      affilateMeUuid: 'N/A'
    }
    const UpdateUserFormComponent = (formProps: FormikProps<IUpdateUser>) => {
      return <UpdateUserForm {...formProps} />
    }
    return (
      <Formik
        onSubmit={this.onSubmitUpdateUser}
        validationSchema={scheme}
        initialValues={createData}
        enableReinitialize
      >
        {UpdateUserFormComponent}
      </Formik>
    )
  }

  renderAffilateTransaction = () => {
    const affilateTransactionGroupList: Dictionary<IAffilateTansaction[]> =
      groupBy<IAffilateTansaction>(
        map(this.state.affilateTransaction, (transactionList) => ({
          ...transactionList,
          groupTime: format(
            date.calibratingTime(transactionList.createdAt, true, true),
            'yyyyMMdd'
          )
        })),
        'groupTime'
      )
    if (isEmpty(affilateTransactionGroupList)) {
      return <></>
    }
    return (
      <InfiniteScroll
        style={{
          overflowX: 'hidden'
        }}
        height={'50vh'}
        dataLength={this.state.affilateTransaction.length}
        next={this.handleAffliateTransactionScroll}
        hasMore={this.state.hasMoreAffilate}
        loader={<RollerLoading />}
      >
        {reverse(keys(affilateTransactionGroupList).sort()).map(
          (key, index) => {
            const affilatePerDay = map(
              reverse(sortBy(affilateTransactionGroupList[key], ['createdAt'])),
              (transaction, transactionIndex) => (
                <AffilateTransaction
                  affilateTransaction={transaction}
                  key={`affilate-transaction-item-${transactionIndex}`}
                />
              )
            )
            const dateMoment = moment(key, 'YYYYMMDD').local()
            const isToDay = dateMoment.clone().diff(moment().local(), 'day')
            const displayDayString =
              isToDay === 0
                ? constants.today
                : isToDay === -1
                ? constants.yesterday
                : dateMoment.format('DD MMM YYYY')
            return (
              <div className="row mt-4" key={`${key}-${index}`}>
                <div className="col">
                  <div className="row justify-content-end">
                    <div className="col">
                      <div className="display-date-text mb-2">
                        {displayDayString}
                      </div>
                    </div>
                  </div>
                  <div>{affilatePerDay}</div>
                </div>
              </div>
            )
          }
        )}
      </InfiniteScroll>
    )
  }

  renderAffilateHostList = () => {
    const affilateHostList: Dictionary<IUserAffilate[]> =
      groupBy<IUserAffilate>(
        map(this.state.affilateHost, (userLists) => ({
          ...userLists,
          groupTime: format(
            date.calibratingTime(userLists.createdAt, true, true),
            'yyyyMMdd'
          )
        })),
        'groupTime'
      )
    if (isEmpty(affilateHostList)) {
      return <></>
    }
    return (
      <div className="m4-b">
        <div className="body-1 m1-b">{`\t\t\t${constants.host}`}</div>
        <div>
          {reverse(keys(affilateHostList).sort()).map((key, index) => {
            const UserList = map(
              reverse(sortBy(affilateHostList[key], ['createdAt'])),
              (users, userIndex) => (
                <div
                  key={`user-${userIndex}`}
                  tabIndex={0}
                  onClick={() => this.handleSelectUser(users as IUser)}
                  className={`user-wrapper py-1 ${
                    users.id === this.state.selectedUser.id ? 'active' : ''
                  }`}
                >
                  <FontAwesomeIcon
                    icon={faChessKing}
                    color={colors.PRIMARY_RED}
                  />
                  &nbsp;
                  {users.username}
                </div>
              )
            )
            return (
              <div className="row" key={`${key}-${index}`}>
                <div className="col-12 body-1 rounded">{UserList}</div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  renderAffilateChildList = () => {
    const affilateChildList: Dictionary<IUserAffilate[]> =
      groupBy<IUserAffilate>(
        map(this.state.affilateChild, (userLists) => ({
          ...userLists,
          groupTime: format(
            date.calibratingTime(userLists.createdAt),
            'yyyyMMdd'
          )
        })),
        'groupTime'
      )
    if (isEmpty(affilateChildList)) {
      return <></>
    }

    return (
      <>
        <div className="body-1 m1-b">{`\t\t\t${constants.child}`}</div>
        <div>
          {reverse(keys(affilateChildList).sort()).map((key, index) => {
            const UserList = map(
              reverse(sortBy(affilateChildList[key], ['createdAt'])),
              (users, userIndex) => (
                <div
                  key={`user-${userIndex}`}
                  tabIndex={0}
                  onClick={() => this.handleSelectUser(users as IUser)}
                  className={`user-wrapper py-1 ${
                    users.id === this.state.selectedUser.id ? 'active' : ''
                  }`}
                >
                  <FontAwesomeIcon icon={faUser} color={colors.PRIMARY_RED} />
                  &nbsp;
                  {users.username}
                </div>
              )
            )
            return (
              <div className="row" key={`${key}-${index}`}>
                <div className="col-12 body-1 rounded">{UserList}</div>
              </div>
            )
          })}
        </div>
      </>
    )
  }

  renderUserList = () => {
    const userGroupList: Dictionary<IUser[]> = groupBy<IUser>(
      map(this.state.userList, (userLists) => ({
        ...userLists,
        groupTime: format(date.calibratingTime(userLists.createdAt), 'yyyyMMdd')
      })),
      'groupTime'
    )
    if (isEmpty(userGroupList)) {
      return <></>
    }

    return (
      <InfiniteScroll
        style={{
          overflowX: 'hidden'
        }}
        height={'65vh'}
        dataLength={this.state.userList.length}
        next={this.handleScroll}
        hasMore={this.state.hasMore}
        loader={<RollerLoading />}
      >
        {reverse(keys(userGroupList).sort()).map((key, index) => {
          const UserList = map(
            reverse(sortBy(userGroupList[key], ['createdAt'])),
            (users, userIndex) => (
              <div
                key={`user-${userIndex}`}
                tabIndex={0}
                onClick={() => this.handleSelectUser(users)}
                className={`user-wrapper py-1 ${
                  users.id === this.state.selectedUser.id ? 'active' : ''
                }`}
              >
                <FontAwesomeIcon icon={faUser} color={colors.PRIMARY_RED} />
                &nbsp;
                <span>{users.username}</span>
              </div>
            )
          )
          return (
            <div className="row" key={`${key}-${index}`}>
              <div className="col-12 body-1 rounded">{UserList}</div>
            </div>
          )
        })}
      </InfiniteScroll>
    )
  }

  onPutUserMoney = (type: 'increment' | 'decrement') => {
    this.setState(
      (ps) => ({
        putUserMoneyBody: {
          id: ps.selectedUser.id,
          increment: type !== 'decrement' ? Number(ps.isMoneyValue) : 0,
          decrement: type !== 'increment' ? Number(ps.isMoneyValue) : 0
        }
      }),
      () => this.props.putUserMoney(this.state.putUserMoneyBody)
    )
  }

  renderEditMoneySection = () => {
    return (
      <div className="row">
        <div className="col-12 col-xl-8 col-lg-8 col-md-6 col-sm-7 col-xs-4">
          <InputNumberIcon
            disabled={!this.state.isSelected}
            decimalScale={2}
            thousandSeparator
            allowNegative={false}
            id={'editMoney'}
            name={'editMoney'}
            type="text"
            placeholder={constants.placeHolderEditMoney(
              get(this.state.userMoneyData, 'money', 0)
            )}
            value={get(this.state, 'isMoneyValue', '')}
            onValueChange={({ value }) =>
              this.setState({ isMoneyValue: value })
            }
            label={constants.editMoneyField}
            icon={faMoneyBill}
          />
        </div>
        <div className="col-6 col-xl-2 col-lg-2 col-md-3 col-sm-2 col-xs-3 px-1 pt-1">
          <Button
            disabled={
              !this.state.isSelected ||
              isEmpty(this.state.isMoneyValue) ||
              this.state.isMoneyValue === '0'
            }
            id={`increment`}
            buttonType={'submit'}
            onClick={() => this.onPutUserMoney('increment')}
            text={constants.incrementButtonTitle}
            backgroundColor={colors.SECONDARY_GREEN}
            size={'large'}
            backgroundHoverColor={colors.PRIMARY_GREEN}
          />
        </div>
        <div className="col-6 col-xl-2 col-lg-2 col-md-3 col-sm-2 col-xs-3 px-1 pt-1">
          <Button
            disabled={
              !this.state.isSelected ||
              isEmpty(this.state.isMoneyValue) ||
              this.state.isMoneyValue === '0'
            }
            id={`decrement`}
            buttonType={'submit'}
            onClick={() => this.onPutUserMoney('decrement')}
            text={constants.decrementButtonTitle}
            size={'large'}
            backgroundHoverColor={colors.SECONDARY_RED}
          />
        </div>
      </div>
    )
  }

  handleClearUser = () => {
    this.props.clearAllUser()
    this.props.clearUserMoney()
    this.setState((_, props) => ({
      isSearched: false,
      userList: props.userList.dataList
    }))
  }

  componentWillUnmount() {
    this.handleClearUser()
  }

  render() {
    const {
      queryData: { search }
    } = this.state
    const navigates: IBreadcrumbItem[] = [
      { label: constants.routeTextTertiary, active: false, path: '/main' },
      { label: constants.routeTextSecodary, active: false, path: '/main' },
      { label: constants.routeTextActive, active: true, path: '/userinfo' }
    ]
    const RenderEditMoney = this.renderEditMoneySection()
    const UserLists = this.renderUserList()
    const RenderAffilateChild = this.renderAffilateChildList()
    const RenderAffilateTransaction = this.renderAffilateTransaction()
    const RenderAffilateHost = this.renderAffilateHostList()
    const RenderUpdateUserFormComponent = this.renderUpdateUserForm
    return (
      <div className="container-fluid pt-5 px-5 page-container">
        <div className="row">
          <div className="col-12 pt-5 mt-2">
            <Breadcrumb
              items={navigates}
              handleOnClickItem={this.handleOnClickBreadcrumb}
            />
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-12">
            <ALink
              id="backto-previus-page"
              color={colors.SECONDARY_RED}
              bold
              onClick={this.handleOnBack}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              &nbsp;{constants.back}
            </ALink>
          </div>
        </div>
        <div className="row mt-3 justify-content-end content-wrapper shadow-lg bg-dark rounded">
          <div className="col-12 col-lg-3 col-md-4 col-sm-12 col-xs-12 mb-1">
            <div className="row px-4">
              <div className="col-12">
                <div className="row">
                  <div className="col-10">
                    <div className="pt-3 search-wrapper">
                      <InputTextIcon
                        className={`centered`}
                        icon={faSearch}
                        id={`querySearch`}
                        name={`querySearch`}
                        placeholder={constants.search}
                        value={search}
                        onChange={this.handleInputQuerySearch}
                      />
                    </div>
                  </div>
                  <div className="col-2 d-flex align-items-center body-1">
                    <FontAwesomeIcon
                      icon={faSpinner}
                      className={`fa-spin`}
                      style={
                        this.state.spinIcon
                          ? { visibility: 'unset' }
                          : { visibility: 'hidden' }
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <span className="col-12 px-0">{UserLists}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-9 col-md-8 col-sm-12 col-xs-12 mb-1 detail-wrapper">
            <div className="row" ref={this.userSectionRef}>
              <div className="col-12 mt-4 pt-1">
                <span className="body-1" style={{ fontSize: '1.5rem' }}>
                  <FontAwesomeIcon icon={faCog} color={colors.PRIMARY_RED} />
                  &nbsp;
                  {constants.detailSection}
                </span>
              </div>
            </div>
            <div className="col-12 py-2 mt-3 detail-content">
              <UserDetailHeader
                id={get(this.state.initialForm, 'id', '')}
                user={get(this.state.initialForm, 'username', '')}
                createdAt={this.state.selectedUser.createdAt}
                updatedAt={this.state.selectedUser.updatedAt}
                affilate_me_uuid={get(
                  this.state.initialForm,
                  'affilateMeUuid',
                  ''
                )}
              />
              <div className="row">
                <div className="col-12">
                  <RenderUpdateUserFormComponent />
                </div>
              </div>
              <div className="row m3-t">
                <div className="col-12">{RenderEditMoney}</div>
              </div>
              <div className="row">
                <div className="col-12 mt-4 pt-1">
                  <span className="body-1" style={{ fontSize: '1.5rem' }}>
                    <FontAwesomeIcon
                      icon={faCashRegister}
                      color={colors.PRIMARY_RED}
                    />
                    &nbsp;
                    {constants.affilateSection}
                  </span>
                </div>
              </div>
              <div className="row m3-t affilate-section">
                <div className="col-12 col-xl-3 col-lg-12 col-md-12 sol-sm-12 affilate-host">
                  {RenderAffilateHost}
                  {RenderAffilateChild}
                </div>
                <div className="col-12 col-xl-9 col-lg-12 col-md-12 sol-sm-12">
                  {RenderAffilateTransaction}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
