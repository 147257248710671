import { camelCase, snakeCase, keys, split, head, last, get, map } from 'lodash'

const isArray = (array: any) => Array.isArray(array)

const isObject = (object: any) => object === Object(object) && !isArray(object) && typeof object !== 'function';

const keyW88: {[key in string] : string } ={
  W88SLOT: 'w88_slot',
  W88P2P: 'w88_p2p',
  W88LOTTERY: 'w88_lottery',
  W88CASINO: 'w88_casino'
}

const camelcaseTransform = (data: any): { [name: string]: any } | [] => {
  if (isObject(data)) {
    const objectData = data as { [name: string]: any }
    const newObject: { [name: string]: any } = {}
    keys(objectData).forEach((key) => {
      newObject[camelCase(key)] = camelcaseTransform(objectData[key]);
    })
    return newObject
  } else if (isArray(data)) {
    const arrayData = data as []
    const newArray = arrayData.map((i) => camelcaseTransform(i))
    return newArray
  }
  return data
}

const snakecaseTransform = (data: any): { [name: string]: any } | [] => {
  if (isObject(data)) {
    const objectData = data as { [name: string]: any }
    const newObject: { [name: string]: any } = {}
    keys(objectData).forEach((key) => {
      newObject[key.startsWith('W88') ? keyW88[key] : snakeCase(key)] = snakecaseTransform(objectData[key]);
    })
    return newObject
  } else if (isArray(data)) {
    const arrayData = data as []
    const newArray = arrayData.map((i) => snakecaseTransform(i))
    return newArray
  }
  return data
}

const hiddenString = (text: string): string => {
  const splitedString = split(text, '')
  const firstString = head(splitedString)
  const lastString = last(splitedString)
  return `${firstString}****${lastString}`
}

const urlSearchParams = <T = any>(data: T): string => {
  const dataKeys = keys(data) || []
  const params = new URLSearchParams()
  map(dataKeys, (key) => {
    const queryValue = get(data, key, '')
    if (queryValue) {
      params.append(key, queryValue)
    }
  })

  if (params.toString()) {
    return `?${params.toString()}`
  }
  return ''
}

const queryToObject = (query: string): any => {
  const urlParams = new URLSearchParams(query)
  const entity = Object.fromEntries(urlParams)
  return entity
}


const transformer = { camelcaseTransform,
  snakecaseTransform,
  urlSearchParams,
  hiddenString,
  queryToObject,} 
export default  transformer

