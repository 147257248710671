import { get } from 'lodash'
import { loginState, tokenState } from './constants'

const login = (state: RootReducers): ReducerState<ILogin> =>
    get(state, 'authentication.login', loginState)

const logout = (state: RootReducers): ReducerState<ILogout> =>
    get(state, 'authentication.login', {})

const token = (state: RootReducers): IToken =>
    get(state, 'authentication.token', tokenState)

const authSelector = {
    login,
    token,
    logout,
}

export default authSelector

