import { object, date, ObjectSchema } from 'yup'

const constants = {
    startTime: 'กรอกวันที่เริ่ม',
    endTime: 'กรอกวันที่สิ้นสุด'
}

const schema: ObjectSchema<any> =
    object().shape({
        startTime: date()
            .required(constants.startTime),
        endTime: date()
            .required(constants.endTime)
        ,
    })

export default schema