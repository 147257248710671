import React, { FC } from 'react'
import { noop } from 'lodash'
import { ResponsiveIcon, Button } from 'components'
import ErrorIcon from 'assets/images/modal/alert.svg'
import { Modal } from '../../'
import './error.style.scss'

const constants = {
  title: 'ERROR',
  description: 'Please try again.',
  actionText: 'กลับสู่หน้าหลัก',
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IErrorModal = {
  title: constants.title,
  description: constants.description,
  actionText: constants.actionText,
  action() { noop() },
}

const ErrorModal: FC<IErrorModal & DefaultProps> = (props) => {

  return (
    <div className="error-modal-container border-rounded">
      <ResponsiveIcon
        alt="error-icon"
        className="error-checked-icon"
        icon={ErrorIcon}
      />
      <h2 className="primary-yellow-text p1-t">{props.title}</h2>
      <p className="body-1">{props.description}</p>
      <div className="m3-y">
        <Button id="error-ok-button" type="rectangle" onClick={props.action} text={props.actionText!} />
      </div>
    </div>
  )
}

ErrorModal.defaultProps = defaultProps

const errorModal = {
  show: (props: IErrorModal) => {
    return Modal.event('MODAL_OVER', {
      state: 'show',
      extraProps: { size: 'md' },
      component: (<ErrorModal {...props} />),
    })
  },
  hide: () => Modal.event('MODAL_OVER', { state: 'hide' }),
}

export default errorModal