import { ESCAPED_TIMEZONE } from 'constants/regex';
import { format } from 'date-fns-tz'
import { th } from 'date-fns/locale'
import { get, isEmpty } from 'lodash'
import moment from 'moment'
import { Moment } from 'moment-timezone'

const differenceBetweenChristianEraAndBuddhistEra = 543

const convertTimeToMoment = (
  hours: string = '00',
  minutes: string = '00'
): Moment => {
  const momentTime = moment(`${hours}:${minutes}`, 'HH:mm')
  return momentTime
}

const calibratingTime = (
  timeasString?: string,
  local: boolean = false,
  buddhistEra: boolean = true,
): any => {
  if (isEmpty(timeasString)) {
    const checkedTimeAsString = new Date()
    if (local) {
      const checkedLocal = checkedTimeAsString

      if (buddhistEra) {
        checkedLocal.setFullYear(checkedLocal.getFullYear() + differenceBetweenChristianEraAndBuddhistEra)
        const checkedBuddhistEra = checkedLocal
        return checkedBuddhistEra
      }

      if (!buddhistEra) {
        const checkedBuddhistEra = checkedLocal
        return checkedBuddhistEra
      }
    }

    if (!local) {
      const localTimezoneArray = format(
        checkedTimeAsString,
        "yyyy-MM-dd'T'HH:mm:ssXXX"
      ).match(ESCAPED_TIMEZONE)
      const escapedTimezoneArray = checkedTimeAsString.toISOString().match(ESCAPED_TIMEZONE)
      const servertime = get(escapedTimezoneArray, '0', '')
      const localTimezone = get(localTimezoneArray, '1', '')
      const calibratedTimeString = `${servertime}${localTimezone}`
      const checkedLocal = new Date(calibratedTimeString)

      if (buddhistEra) {
        checkedLocal.setFullYear(checkedLocal.getFullYear() + differenceBetweenChristianEraAndBuddhistEra)
        const checkedBuddhistEra = checkedLocal
        return checkedBuddhistEra
      }

      if (!buddhistEra) {
        const checkedBuddhistEra = checkedLocal
        return checkedBuddhistEra
      }
    }


  }

  if (!isEmpty(timeasString)) {
    const checkedTimeAsString = new Date(timeasString!)
    if (local) {
      const checkedLocal = checkedTimeAsString

      if (buddhistEra) {
        checkedLocal.setFullYear(checkedLocal.getFullYear() + differenceBetweenChristianEraAndBuddhistEra)
        const checkedBuddhistEra = checkedLocal
        return checkedBuddhistEra
      }

      if (!buddhistEra) {
        const checkedBuddhistEra = checkedLocal
        return checkedBuddhistEra
      }
    }

    if (!local) {
      const localTimezoneArray = format(
        checkedTimeAsString,
        "yyyy-MM-dd'T'HH:mm:ssXXX"
      ).match(ESCAPED_TIMEZONE)
      const escapedTimezoneArray = checkedTimeAsString.toISOString().match(ESCAPED_TIMEZONE)
      const servertime = get(escapedTimezoneArray, '0', '')
      const localTimezone = get(localTimezoneArray, '1', '')
      const calibratedTimeString = `${servertime}${localTimezone}`
      const checkedLocal = new Date(calibratedTimeString)

      if (buddhistEra) {
        checkedLocal.setFullYear(checkedLocal.getFullYear() + differenceBetweenChristianEraAndBuddhistEra)
        const checkedBuddhistEra = checkedLocal
        return checkedBuddhistEra
      }

      if (!buddhistEra) {
        const checkedBuddhistEra = checkedLocal
        return checkedBuddhistEra
      }

    }
  }
}

const formatDateTime = (date: string, withTime?: boolean, toThaiDate: boolean = false) => {
  const locale: { locale: Locale } = {
    locale: th
  }
  const options: Intl.DateTimeFormatOptions | undefined = {
    day: 'numeric',
    month: 'short',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }
  if (toThaiDate) {
    options.timeZone = 'GMT'
  }
  if (withTime) {
    return moment(date, 'YYYY-MM-DD HH:mm:ss').add(543, 'year').format('DD MMM YY HH:mm')
  } else {
    return format(new Date(date), 'dd MMM yy', locale)
  }
}

type TFloorTimeModeParam = 'start' | 'end' | 'none'
const floorTime = (date: string, mode: TFloorTimeModeParam = 'none') => {
  let dateClearHour = new Date(date)
  
  if (!date)
    return ''
  if (mode === 'start')
    dateClearHour.setHours(0, 0, 0, 0)
  else if (mode === 'end') {
    dateClearHour.setDate(dateClearHour.getDate() + 1)
    dateClearHour.setHours(0, 0, 0, 0)
  }
  return format(dateClearHour, 'yyyy-MM-dd HH:mm:ss zzz')
}

const formatDateTimeForApi = (date: string | Date) => {
  if (!date)
    return ''
  const ISOTime = moment(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD[T]HH:mm:ss[Z]')
  
  return ISOTime
}

const timeCalibrated = {
  convertTimeToMoment,
  calibratingTime,
  formatDateTime,
  formatDateTimeForApi,
  floorTime,
}

export default timeCalibrated
