import axios from 'axios'
import { transformer } from 'utils'
import environment from '../../constants/environment'

const getAllVendorsEndpoint =
`${environment.environments[environment.environmentName].endpoint.url}/api${environment.endpoint.getAllPartner}`
const putPartnerEndpoint =
`${environment.environments[environment.environmentName].endpoint.url}/api/tc${environment.endpoint.getAllPartner}`

const getPartnerScheduleEndpoint =
`${environment.environments[environment.environmentName].endpoint.url}/api/tc${environment.endpoint.getAllPartner}/auto`

const putPartnerScheduleEndpoint =
`${environment.environments[environment.environmentName].endpoint.url}/api/tc${environment.endpoint.getAllPartner}/auto`

const fetchGetAllPartners = () => axios.get(`${getAllVendorsEndpoint}`)
const fetchPutPartner = (data: IGameConfig) => axios.put(`${putPartnerEndpoint}`, data)
const fetchPutPartnerSchedule = (data: IPartnerScheduleConfig[]) => axios.put(`${putPartnerScheduleEndpoint}`, data)
const fetchGetPartnerSchedule = (query?: IPartnerScheduleQuery) => axios.get(`${getPartnerScheduleEndpoint}${transformer.urlSearchParams(query)}`,)


export {
    fetchPutPartnerSchedule,
    fetchGetAllPartners,
    fetchGetPartnerSchedule,
    fetchPutPartner
}
