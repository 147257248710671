import React, { Component, ComponentClass, ChangeEvent } from 'react'
import { noop } from 'lodash'
import { SPACE } from 'constants/regex'
import './inputText.style.scss'

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IInputTextProps = {
  placeholder: '',
  type: 'text',
  name: '',
  textAlign: 'left',
  value: '',
  label: '',
  errorMessage: '',
  toLowercase: false,
  noWhitespace: false,
  error: false,
  disabled: false,
  useNumberpad: false,
  hiddenErrorBlock: false,
  onBlur() { noop() },
  onChange() { noop() },
  onClick() { noop() },
  setFieldValue() { noop() },
  fontSize: '',
  underlineSpace: '',
  verticalAlign: '',
}

const InputText = class extends Component<IInputTextProps & DefaultProps> {

  static defaultProps = defaultProps

  render() {
    const {
      name,
      value,
      onBlur,
      onChange,
      setFieldValue,
      type,
      textAlign,
      error,
      hiddenErrorBlock,
      useNumberpad,
      errorMessage,
      placeholder,
      disabled,
      toLowercase,
      noWhitespace,
      label,
      maxLenght,
      fontSize,
      underlineSpace,
      verticalAlign,
      onClick,
    } = this.props

    const hasErrorClass = error ? 'error' : ''
    const lineHeight = 'inherit'
    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (toLowercase || noWhitespace) {
        const fieldValue = (event.target.value || '')
        const fieldName = event.target.name
        let castingValue = fieldValue
        if (toLowercase) {
          castingValue = castingValue.toLowerCase()
        }
        if (noWhitespace) {
          castingValue = castingValue.replace(SPACE, '')
        }
        setFieldValue!(fieldName, castingValue)
      } 
      if (onChange)
        return onChange!(event)
    }
    const Message = () => hiddenErrorBlock
      ? <></>
      : <div className="input-error-message">{errorMessage}</div>

    return (
      <div className={`input-text ${hasErrorClass}`}>
        <label htmlFor={name}>{label}</label>
        <input
          maxLength={maxLenght}
          disabled={disabled}
          id={name}
          name={name}
          type={type}
          value={value}
          autoCapitalize="none"
          pattern={useNumberpad ? '\\d*' : undefined}
          onBlur={onBlur}
          onChange={handleOnChange}
          className="input-core"
          placeholder={placeholder}
          style={{
            textAlign,
            fontSize,
            lineHeight,
            verticalAlign
          }}
          onClick={onClick}
        />
        <div className="input-underline" style={{ marginTop: underlineSpace }} />
        <Message />
      </div>
    )
  }
} as ComponentClass<IInputTextProps>

export default InputText