import { responseCode } from 'constants/response'
import { Formik, FormikProps } from 'formik'
import { noop, trim } from 'lodash'
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { LoginForm } from './components'
import { ErrorModal } from 'components'
import scheme from './model/scheme'
import './login.style.scss'

const constants = {
  okMsg: 'OK',
  userNotFound: 'Cannot Logging in please check your username',
  passwordNotMatch: 'Password incorrect please try again'
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: ILoginContainerProps & ILoginContainerActionProps = {
  login() {
    noop()
  },
  loginCode: 0,
  loginError: '',
  loginIsFetching: false
}
class LoginContainer extends Component<DefaultProps & RouteComponentProps> {
  static defaultProps = defaultProps

  componentDidUpdate(prevState: ILoginContainerProps) {
    if (
      prevState.loginIsFetching !== this.props.loginIsFetching &&
      !this.props.loginIsFetching
    ) {
      if (this.props.loginCode === responseCode.OK) {
        this.props.history.replace('/main')
      } else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: this.props.loginError,
          actionText: constants.okMsg
        })
      }
    }
  }

  state: ILoginContainerState = {
    initialForm: { username: '', password: '' }
  }

  onSubmitLogin = (values: ILoginRequest) => {
    const loginObject: ILogin1 = {
      username: trim(values.username),
      password: trim(values.password)
    }
    this.props.login(loginObject)
  }

  renderLoginForm = (): JSX.Element => {
    const LoginFormComponent = (formProps: FormikProps<ILogin1>) => {
      return <LoginForm {...formProps} />
    }
    return (
      <Formik
        onSubmit={this.onSubmitLogin}
        validationSchema={scheme}
        initialValues={this.state.initialForm}
        enableReinitialize
      >
        {LoginFormComponent}
      </Formik>
    )
  }

  render() {
    const RenderLoginFromComponent = this.renderLoginForm
    return (
      <div>
        <div className="container login-container">
          <div className="row justify-content-center content-wrapper">
            <div className="centered col-12 text-center d-flex justify-content-center">
              <div className="card-wrapper">
                <div className="card-box shadow-lg bg-dark rounded">
                  <div className="px-0 mb-2">
                    <h1 className="headding-wrapper">Welcome to OMEGA</h1>
                    <h3 className="tertiary-text text-bold mb-5">
                      Technical Support App
                    </h3>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="m3-t">
                        <RenderLoginFromComponent />
                      </div>
                    </div>
                  </div>
                  <h6 className="gray-text mt-2">
                    If you can't login Please contact admin
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LoginContainer
