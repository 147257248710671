import axios, {
  AxiosRequestConfig,
  AxiosTransformer,
  AxiosResponse,
  AxiosError,
} from 'axios'
import { ErrorModal } from 'components'
import { get } from 'lodash'
import { Store } from 'redux'
import { transformer, apiResponse } from '../utils'
import { logout } from 'reduxs/authentication/logout/action'
const transformResponse: AxiosTransformer = transformer.camelcaseTransform
const transformRequest: AxiosTransformer = (data) => JSON.stringify(transformer.snakecaseTransform(data))

const requestInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig | Promise<AxiosRequestConfig> => {
  const configure: AxiosRequestConfig = {
    ...config,
    headers: {
      ...config.headers,
    },
    transformResponse,
    transformRequest,
    url: config.url?.replace(/([^:])(\/\/)/g, '$1/'),
  }
  return configure
}

const errorRequestHandler = (error: any) => Promise.reject(error)

const responseInterceptor = (response: AxiosResponse<APIResponse>): AxiosResponse<APIResponse> => {
  const data = apiResponse.converter(response)
  return { ...response, data }
}


const errorResponseHandler = (_: Store) => (error: AxiosError<APIResponse>) => {
  const errorResponse = apiResponse.converter(error)
  const axiosResponse: AxiosResponse<APIResponse> = get(error, 'response', {
    data: errorResponse,
    status: errorResponse.code,
    statusText: errorResponse.devMessage,
    headers: get(error, 'response.headers', {}),
    config: get(error, 'response.config', {}),
    request: get(error, 'response.request', {}),
  })
  const expCode = get(
    error,
    'response.data.code',
    get(
      error,
      'response.status',
      Number(get(error, 'code', 0))
    ))
  const errorConverted: AxiosError<APIResponse> = {
    ...error,
    response: axiosResponse,
  }
  if (expCode === 403001) {
    ErrorModal.show({
      action: () => {
        ErrorModal.hide()
      },
      title: 'เซสชันหมดอายุ',
      description: errorResponse.devMessage,
      actionText: 'ตกลง',
    })
    _.dispatch<any>(logout())
  }
  return Promise.reject(errorConverted)
}


const intercepterConfiguration = (config: any, store: any) => {
  axios.defaults.responseType = 'json'
  axios.defaults.headers['Content-Type'] = 'application/json'
  axios.interceptors.request.use(requestInterceptor, errorRequestHandler)
  axios.interceptors.response.use(responseInterceptor, errorResponseHandler(store))
  axios.defaults.timeout = 60000

}

export default intercepterConfiguration