export const GET_WEBBANK_OBSERVER_REQUEST = 'GET_WEBBANK_OBSERVER_REQUEST'
export const GET_WEBBANK_OBSERVER_SUCCESS = 'GET_WEBBANK_OBSERVER_SUCCESS'
export const GET_WEBBANK_OBSERVER_FAILURE = 'GET_WEBBANK_OBSERVER_FAILURE'
export const CLEAR_WEBBANK_OBSERVER = 'CLEAR_WEBBANK_OBSERVER'

export const POST_WEBBANK_SWITCHING_REQUEST = 'POST_WEBBANK_SWITCHING_REQUEST'
export const POST_WEBBANK_SWITCHING_SUCCESS = 'POST_WEBBANK_SWITCHING_SUCCESS'
export const POST_WEBBANK_SWITCHING_FAILURE = 'POST_WEBBANK_SWITCHING_FAILURE'

export const POST_WEBBANK_RESET_REQUEST = 'POST_WEBBANK_RESET_REQUEST'
export const POST_WEBBANK_RESET_SUCCESS = 'POST_WEBBANK_RESET_SUCCESS'
export const POST_WEBBANK_RESET_FAILURE = 'POST_WEBBANK_RESET_FAILURE'

export const webbankObserverState: ReducerState<IPagination<IResponseWebbankObserver>> = {
  isFetching: false,
  code: 0,
  data: {
    dataList: [],
    limit: 0,
    page: 0,
    total: 0,
  },
  error: '',
}

export const webbankPostState: ReducerState<{}> = {
  isFetching: false,
  code: 0,
  data: {},
  error: '',
}
