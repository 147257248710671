import {
  ALink,
  Breadcrumb,
  ErrorModal,
  InputTextIcon,
  RollerLoading,
  TransactionItemCollapsible
} from 'components'
import { responseCode } from 'constants/response'
import React, { Component, RefObject, createRef } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  faChevronLeft,
  faCog,
  faSearch,
  faSpinner,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './creditInfo.style.scss'
import colors from 'constants/colors'
import {
  Dictionary,
  get,
  groupBy,
  isEmpty,
  keys,
  map,
  noop,
  reverse,
  sortBy
} from 'lodash'
import InfiniteScroll from 'react-infinite-scroll-component'
import { date, interactive } from 'utils'
import moment from 'moment'
import errorModal from 'components/Modal/components/Error/Error.component'
import { format } from 'date-fns'

const constants = {
  routeTextSecodary: 'Home',
  routeTextActive: 'Credit-Info',
  routeTextTertiary: 'OMEGA',
  back: 'Back',
  ok: 'OK',
  search: 'Search',
  sucess: '',
  sectionName: 'Credit Details',
  validate: 'โปรดกรอกข้อมูลที่ไม่ซ้ำกับข้อมูลเดิม !!',
  today: 'วันนี้',
  yesterday: 'เมื่อวาน',
  editMoneyField: 'Edit Money',
  placeHolderEditMoney: 'Enter Money',
  incrementButtonTitle: 'Increase Money',
  decrementButtonTitle: 'Decrease Money',
  totalMoney: (money: string) => `Total Money : ${money}`
}
const defaultProps: ICreditInfoContainerProps &
  ICreditInfoContainerActionProps = {
  getAllUser() {
    noop()
  },
  getCreditMe() {
    noop()
  },
  getCreditMeDetail() {
    noop()
  },
  clearAllUser() {
    noop()
  },
  clearCreditMe() {
    noop()
  },
  getAllCode: 0,
  getAllError: '',
  getAllIsFetching: false,
  getCreditMeCode: 0,
  getCreditMeError: '',
  getCreditMeIsFetching: false,
  userMe: {
    id: 0,
    affilateMeUuid: '',
    username: '',
    phoneNumber: '',
    permission: '',
    bank: {
      createdAt: '',
      id: 0,
      name: '',
      number: '',
      type: '',
      updatedAt: ''
    },
    user_bank_id: 0,
    wallet: {
      createdAt: '',
      id: 0,
      money: 0,
      updatedAt: ''
    },
    wallet_id: 0,
    createdAt: '',
    updatedAt: ''
  },
  storeCreditMeDetail: {},
  creditMe: {
    dataList: [],
    money: 0,
    limit: 0,
    page: 0,
    total: 0
  },
  userList: {
    dataList: [],
    limit: 0,
    page: 0,
    total: 0
  }
}
type DefaultProps = Readonly<typeof defaultProps>
export default class CreditInfoContainer extends Component<
  DefaultProps & ICreditInfoContainerProps & RouteComponentProps,
  ICreditInfoContainerState
> {
  static defaultProps = defaultProps
  pageContainerRef: RefObject<HTMLDivElement> = createRef()
  creditMeDetail: RefObject<HTMLDivElement> = createRef()

  state: ICreditInfoContainerState = {
    queryUserData: {
      permission: 'USER',
      search: '',
      page: 1,
      limit: 100
    },
    queryCreditData: {
      userid: 7,
      page: 1,
      limit: 100
    },
    hasMore: true,
    hasMoreCredit: true,
    queryTimer: setTimeout(() => {
      noop()
    }, 0),
    spinIcon: false,
    isSearched: false,
    isSelected: false,
    userList: [],
    creditMe: [],
    selectedUser: {
      id: 0,
      isFake: false,
      createdAt: '',
      affilateMeUuid: '',
      permission: '',
      phoneNumber: '',
      updatedAt: '',
      username: '',
      userBank: {
        id: 0,
        name: '',
        number: '',
        type: '',
        createdAt: '',
        updatedAt: ''
      },
      wallet: {
        id: 0,
        money: 0,
        createdAt: '',
        updatedAt: ''
      }
    }
  }

  componentDidMount() {
    this.onGetUserLists()
    interactive.scrollTo(this.pageContainerRef)
  }

  componentDidUpdate(prevProps: ICreditInfoContainerProps) {
    if (
      prevProps.getAllIsFetching !== this.props.getAllIsFetching &&
      !this.props.getAllIsFetching
    ) {
      if (this.props.getAllCode === responseCode.OK) {
        this.setState({
          isSearched: false,
          userList: this.props.userList.dataList
        })
        if (this.props.userList.page >= this.props.userList.total) {
          this.setState({ hasMore: false })
        } else {
          this.setState({ hasMore: true })
        }
      } else if (this.props.getAllCode === responseCode.NOT_FOUND) {
        ErrorModal.show({
          description: this.props.getAllError,
          actionText: constants.ok,
          action: errorModal.hide
        })
        this.setState({ isSearched: false, userList: [] })
      }
    }
    if (
      prevProps.getCreditMeIsFetching !== this.props.getCreditMeIsFetching &&
      !this.props.getCreditMeIsFetching
    ) {
      if (this.props.getCreditMeCode === responseCode.OK) {
        this.setState((_, props) => ({ creditMe: props.creditMe.dataList }))
        if (this.props.creditMe.page >= this.props.creditMe.total) {
          this.setState({ hasMoreCredit: false })
        } else {
          this.setState({ hasMoreCredit: true })
        }
      } else if (this.props.getCreditMeCode === responseCode.NOT_FOUND) {
        ErrorModal.show({
          description: `${this.props.getCreditMeError}\n`,
          actionText: constants.ok,
          action: errorModal.hide
        })
        this.setState({ isSearched: false, creditMe: [] })
      }
    }
  }

  onGetUserLists = (isFirst: boolean = true) => {
    this.props.getAllUser(this.state.queryUserData, isFirst)
    this.setState((_, props) => ({ userList: props.userList.dataList }))
  }

  handleOnBack = () => {
    this.props.history.push('/main')
  }

  handleOnClickBreadcrumb = (path: string) => {
    this.props.history.push(path)
  }

  handleScrollCredit = () => {
    this.setState(
      (prevState, props) => ({
        queryCreditData: {
          ...prevState.queryCreditData,
          page: props.creditMe.page + 1
        }
      }),
      () => this.props.getCreditMe(this.state.queryCreditData, false)
    )
  }

  handleScrollUser = () => {
    this.setState(
      (prevState, props) => ({
        queryUserData: {
          ...prevState.queryUserData,
          page: prevState.isSearched ? 1 : props.userList.page + 1
        }
      }),
      () =>
        this.props.getAllUser(this.state.queryUserData, this.state.isSearched)
    )
  }

  handleOnSearch = (isFirst: boolean) => {
    clearTimeout(this.state.queryTimer)
    this.setState((prevState, props) => ({
      queryUserData: {
        ...prevState.queryUserData,
        page: isFirst ? 1 : props.userList.page + 1
      },
      queryTimer: setTimeout(() => {
        this.handleClearCredit()
        this.props.getAllUser(this.state.queryUserData, isFirst)
        this.setState((prevState, props) => ({
          userList: props.userList.dataList,
          spinIcon: false,
          isSearched: false,
          queryCreditData: { ...prevState.queryCreditData, page: 1 }
        }))
      }, 1000)
    }))
  }
  handleInputQuerySearch = (values: any) => {
    const querySearch = values.target.value
    this.setState(
      (prevState) => ({
        isSearched: true,
        spinIcon: true,
        queryUserData: {
          ...prevState.queryUserData,
          search: querySearch
        }
      }),
      () => {
        this.handleClearUser()
        this.handleOnSearch(this.state.isSearched)
      }
    )
  }

  handleSelectUser = (user: IUser) => {
    this.handleClearCredit()
    this.setState(
      (prevState) => ({
        selectedUser: user,
        isSelected: true,
        queryCreditData: {
          ...prevState.queryCreditData,
          page: 1,
          userid: user.id
        }
      }),
      () => this.props.getCreditMe(this.state.queryCreditData, true)
    )
    interactive.scrollTo(this.creditMeDetail)
  }

  onGetCreditMedetail = (createdAt: string, slug: string, status: string) => {
    this.props.getCreditMeDetail({
      createdAt: createdAt,
      slug: slug,
      status: status,
      userid: this.state.queryCreditData.userid
    })
  }

  getDataDetail = (keyDetail: string): ICreditDetail[] => {
    return get(this.props.storeCreditMeDetail, `${keyDetail}`, [])
  }

  renderCreditInfo = () => {
    const creditGroupList: Dictionary<ICredit[]> = groupBy<ICredit>(
      map(this.state.creditMe, (credit) => ({
        ...credit,
        groupTime: format(
          date.calibratingTime(credit.createdAt, true, true),
          'yyyyMMdd'
        )
      })),
      'groupTime'
    )
    if (isEmpty(creditGroupList)) {
      return <div style={{ height: '55vh' }}></div>
    }

    return (
      <InfiniteScroll
        style={{
          overflowX: 'hidden'
        }}
        height={'55vh'}
        dataLength={this.state.creditMe.length}
        next={this.handleScrollCredit}
        hasMore={this.state.hasMoreCredit}
        loader={<RollerLoading />}
      >
        {reverse(keys(creditGroupList).sort()).map((key, index) => {
          const CreditPerDay = map(
            reverse(sortBy(creditGroupList[key], ['createdAt'])),
            (transaction, transactionIndex) => {
              let status = transaction.groupType.startsWith('BET_GAME_CASINO')
                ? ''
                : transaction.status
              let generateStatus = transaction.status
              return (
                <TransactionItemCollapsible
                  credit={transaction}
                  key={`credit-info-items-${transactionIndex}`}
                  creditDetail={this.getDataDetail(
                    transaction.slug +
                      '_' +
                      transaction.createdAt +
                      '_' +
                      status +
                      '_' +
                      generateStatus
                  )}
                  action={this.onGetCreditMedetail}
                />
              )
            }
          )
          const dateMoment = moment(key, 'YYYYMMDD').local()
          const isToDay = dateMoment.clone().diff(moment().local(), 'day')
          const displayDayString =
            isToDay === 0
              ? constants.today
              : isToDay === -1
              ? constants.yesterday
              : dateMoment.format('DD MMM YYYY')

          return (
            <div className="row mt-4 w-100" key={`${key}-${index}`}>
              <div className="col">
                <div className="display-date-text mb-2">{displayDayString}</div>
                {CreditPerDay}
              </div>
            </div>
          )
        })}
      </InfiniteScroll>
    )
  }

  renderUserList = () => {
    const userGroupList: Dictionary<IUser[]> = groupBy<IUser>(
      map(this.state.userList, (userLists) => ({
        ...userLists,
        groupTime: format(
          date.calibratingTime(userLists.createdAt, true, true),
          'yyyyMMdd'
        )
      })),
      'groupTime'
    )
    if (isEmpty(userGroupList)) {
      return <></>
    }

    return (
      <InfiniteScroll
        style={{
          overflowX: 'hidden'
        }}
        height={'65vh'}
        dataLength={this.state.userList.length}
        next={this.handleScrollUser}
        hasMore={this.state.hasMore}
        loader={<RollerLoading />}
      >
        {reverse(keys(userGroupList).sort()).map((key, index) => {
          const UserList = map(
            reverse(sortBy(userGroupList[key], ['createdAt'])),
            (users, userIndex) => (
              <div
                key={`user-${userIndex}`}
                tabIndex={0}
                onClick={() => this.handleSelectUser(users)}
                className={`user-wrapper py-1 ${
                  users.id === this.state.selectedUser.id ? 'active' : ''
                }`}
              >
                <FontAwesomeIcon icon={faUser} color={colors.PRIMARY_RED} />
                &nbsp;
                {users.username}
              </div>
            )
          )
          return (
            <div className="row" key={`${key}-${index}`}>
              <div className="col-12 w-100 body-1 rounded">{UserList}</div>
            </div>
          )
        })}
      </InfiniteScroll>
    )
  }
  handleClearUser = () => {
    this.props.clearAllUser()
    this.setState((_, props) => ({
      isSearched: false,
      userList: props.userList.dataList
    }))
  }

  handleClearCredit = () => {
    this.props.clearCreditMe()
    this.setState((_, props) => ({ creditMe: props.creditMe.dataList }))
  }

  componentWillUnmount() {
    this.handleClearUser()
    this.handleClearCredit()
  }

  render() {
    const {
      queryUserData: { search }
    } = this.state
    const navigates: IBreadcrumbItem[] = [
      { label: constants.routeTextTertiary, active: false, path: '/main' },
      { label: constants.routeTextSecodary, active: false, path: '/main' },
      { label: constants.routeTextActive, active: true, path: '/creditinfo' }
    ]
    const UserLists = this.renderUserList()
    const RenderCreditInfo = this.renderCreditInfo()
    return (
      <div
        className="container-fluid pt-5 px-4 page-container"
        ref={this.pageContainerRef}
      >
        <div className="row">
          <div className="col-12 pt-5 mt-2">
            <Breadcrumb
              items={navigates}
              handleOnClickItem={this.handleOnClickBreadcrumb}
            />
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-12">
            <ALink
              id="backto-previous-page"
              color={colors.SECONDARY_RED}
              bold
              onClick={this.handleOnBack}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              &nbsp;{constants.back}
            </ALink>
          </div>
        </div>
        <div className="row mt-3 justify-content-end content-wrapper shadow-lg bg-dark rounded">
          <div className="col-12 col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-1">
            <div className="row px-4">
              <div className="col-12">
                <div className="row">
                  <div className="col-10">
                    <div className="pt-3 search-wrapper">
                      <InputTextIcon
                        className={`centered`}
                        icon={faSearch}
                        id={`querySearch`}
                        name={`querySearch`}
                        placeholder={constants.search}
                        value={search}
                        onChange={this.handleInputQuerySearch}
                      />
                    </div>
                  </div>
                  <div className="col-2 d-flex align-items-center body-1">
                    <FontAwesomeIcon
                      icon={faSpinner}
                      className={`fa-spin`}
                      style={
                        this.state.spinIcon
                          ? { visibility: 'unset' }
                          : { visibility: 'hidden' }
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <span className="col-12 px-0">{UserLists}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-9 col-md-6 col-sm-6 col-xs-12 mb-1 detail-wrapper">
            <div className="row">
              <div className="col-12 mt-4 pt-1">
                <span className="body-1" style={{ fontSize: '1.5rem' }}>
                  <FontAwesomeIcon icon={faCog} color={colors.PRIMARY_RED} />
                  &nbsp;
                  {constants.sectionName}
                </span>
              </div>
            </div>
            <div className="col-12 py-2 mt-3 detail-content">
              <div className="row mb-3 topic-content shadow-lg">
                <div className="col-12 pt-2" ref={this.creditMeDetail}>
                  {RenderCreditInfo}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
