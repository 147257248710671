import React, { FC } from 'react'
import { InputText } from 'components'
import './InputTextBorder.style.scss'

const InputTextBorder: FC<IInputTextProps> = ({ height, ...inputProps}) => {
    return (
        <div className="input-text-border-container" style={{ height }}>
            <InputText {...inputProps} />
        </div>
    )
}

export default InputTextBorder