import { AxiosError, AxiosResponse } from 'axios'
import { combineReducers } from 'redux'
import { ActionType, createReducer } from 'typesafe-actions'
import { responseConverter } from 'utils'
import * as lottoAction from './actionCreator'
import { initialLottoListState } from './constants'

export type lottoActionType = ActionType<typeof lottoAction>

const list = createReducer<ReducerState<ILotto[]>, lottoActionType>(initialLottoListState)
  .handleAction(
    lottoAction.getLottoListAction.request,
    (state: ReducerState<ILotto[]>) => {
      return {
        ...state,
        isFetching: true
      }
    }
  )
  .handleAction(
    lottoAction.getLottoListAction.success,
    (state: ReducerState<ILotto[]>, action: lottoActionType) => {
      const payload: AxiosResponse<IAPIResponse<ILotto[]>> = action.payload
      const convertedResponse = responseConverter.getMessage(payload)
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data
      }
    }
  )
  .handleAction(
    lottoAction.getLottoListAction.failure,
    (state: ReducerState<ILotto[]>, action: lottoActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload
      const convertedResponse = responseConverter.getMessage(payload)
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message
      }
    }
  )

export default combineReducers({
  list
})
