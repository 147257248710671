import { connect } from 'react-redux'
import WebBankManagementContainer from './WebBankManagement.container'
import webbankAction from 'reduxs/Webbank/actions'
import webbankSelector from 'reduxs/Webbank/selector'

const mapStateToProps = (
  state: RootReducers
): IWebbankManagementContainerProps => {
  const webbankObserver = webbankSelector.webBankObserverSelector(state)
  const webbankSwitching = webbankSelector.webBankSwitchingSelector(state)
  const webbankReset = webbankSelector.webBankResetSelector(state)
  return {
    webbankObserverList: webbankObserver.data,
    getwebbankObserverCode: webbankObserver.code,
    getwebbankObserverError: webbankObserver.error,
    getwebbankObserverIsFetching: webbankObserver.isFetching,
    postwebbankResetCode: webbankReset.code,
    postwebbankResetError: webbankReset.error,
    postwebbankResetIsFetching: webbankReset.isFetching,
    postwebbankSwitchingCode: webbankSwitching.code,
    postwebbankSwitchingError: webbankSwitching.error,
    postwebbankSwitchingIsFetching: webbankSwitching.isFetching
  }
}

const mapDispatchToProps: IWebbankManagementContainerActionProps = {
  getWebbankObserver: webbankAction.getWebBankObserver,
  clearWebbankObserver: webbankAction.clearWebBankObserver,
  postWebbankReset: webbankAction.postWebbankReset,
  postWebbankSwitching: webbankAction.postWebbankSwitching
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WebBankManagementContainer)
