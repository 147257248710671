import { AxiosError, AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import { loaderAction } from 'reduxs/loader/actionCreator'
import { postLottoCurrentResultAction } from '../actionCreator'
import { fetchPostLottoCurrentResult } from '../services'

const postLottoCurrentResult =
  (data: IQueryLotterCurrentResult) => async (dispatch: Dispatch) => {
    dispatch(loaderAction.request({}))
    dispatch(postLottoCurrentResultAction.request({}))
    await fetchPostLottoCurrentResult(data)
      .then(
        (res: AxiosResponse<IAPIResponse<IResponseLotterCurrentResult>>) => {
          dispatch(loaderAction.success({}))
          dispatch(postLottoCurrentResultAction.success(res))
        }
      )
      .catch((error: AxiosError<IAPIResponse>) => {
        dispatch(loaderAction.success({}))
        dispatch(postLottoCurrentResultAction.failure(error))
      })
  }
  
export { postLottoCurrentResult }
