import { get } from 'lodash'
import { CreditMeState, userMoneyState, putUserMoneyState, CreditMeDetailState } from './constants'

const creditMe = (state: RootReducers): ReducerState<APIPagination<ICredit>> =>
    get(state, 'credit.list', CreditMeState)
const creditMeDetail = (state: RootReducers): ReducerState<ICreditDetail[]> =>
    get(state, 'credit.getCreditMeDetail', CreditMeDetailState)
const userMoney = (state: RootReducers): ReducerState<IUserMoney> =>
    get(state, 'credit.userMoney', userMoneyState)
const putUserMoney = (state: RootReducers): ReducerState<IPutUserMoneyBody> =>
    get(state, 'credit.putUserMoney', putUserMoneyState)


const userSelector = {
    creditMe,
    userMoney,
    putUserMoney,
    creditMeDetail
}

export default userSelector