import { AxiosError, AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import { loaderAction } from 'reduxs/loader/actionCreator'
import { postLottoResultAction } from '../actionCreator'
import { fetchPostLottoResult } from '../services'

const postLottoResult =
  (data: ICreateLotterResult[]) => async (dispatch: Dispatch) => {
    dispatch(loaderAction.request({}))
    dispatch(postLottoResultAction.request({}))
    await fetchPostLottoResult(data)
      .then((res: AxiosResponse<IAPIResponse<ILotterySchedule[]>>) => {
        dispatch(loaderAction.success({}))
        dispatch(postLottoResultAction.success(res))
      })
      .catch((error: AxiosError<IAPIResponse>) => {
        dispatch(loaderAction.success({}))
        dispatch(postLottoResultAction.failure(error))
      })
  }

export { postLottoResult }
