import axios from 'axios'
import environment from 'constants/environment'

const fetchPostLottoResult = (data: Array<ICreateLotterResult>) => {
  const endpoint = `${
    environment.environments[environment.environmentName].endpoint.url
  }${environment.endpoint.postLottoResult}`
  return axios.post(endpoint, data)
}

const fetchPostLottoReverse = (data: IReverseLotterPayment) => {
  const endpoint = `${
    environment.environments[environment.environmentName].endpoint.url
  }${environment.endpoint.postLottoReverse}`
  return axios.post(endpoint, data)
}

const fetchPostLottoCurrentResult = (data: IQueryLotterCurrentResult) => {
  const endpoint = `${
    environment.environments[environment.environmentName].endpoint.url
  }${environment.endpoint.postLottoCurrentResult}`
  return axios.post(endpoint, data)
}

export { fetchPostLottoResult, fetchPostLottoReverse, fetchPostLottoCurrentResult }
