import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ALink,
  Breadcrumb,
  Button,
  ConfirmModal,
  Dropdown,
  ErrorModal,
  Input,
  LottoResultCard,
  SuccessModal
} from 'components'
import colors from 'constants/colors'
import {
  LOTTO_FLAG_ALPHA,
  LOTTO_SLUG_NAME,
  LOTTO_TYPE
} from 'constants/variables'
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router'
import './lotteryManagement.style.scss'
import LottoFlags from 'assets/images/global/flags'
import {
  cloneDeep,
  filter,
  findIndex,
  get,
  includes,
  invert,
  isEmpty,
  map,
  split
} from 'lodash'
import { ResponsiveIcon } from 'components'
import { NON_NUMBER } from 'constants/regex'
import { responseCode } from 'constants/response'
import { date } from 'utils'
import { format, isValid } from 'date-fns'

const constants = {
  routeTextSecodary: 'Home',
  routeTextActive: 'Lottery Management',
  routeTextTertiary: 'OMEGA',
  back: 'Back',
  title: 'Lottery Management',
  reverse: 'ยกเลิกการจ่าย',
  lottoSlug: 'ประเภทหวย',
  resultDate: 'วันที่ออก:',
  status: 'สถานะ:',
  currentResult: 'ผลปัจจุบัน',
  lotto: 'หวย',
  editedResult: 'ผลหลังแก้ไข',
  save: 'บันทึกการแก้ไข',
  reset: 'รีเซ็ต',
  threeUp: '3 ตัวบน',
  twoDown: '2 ตัวล่าง',
  fourSuite: 'เลขชุดสี่ตัว',
  reverseConfirmation: 'ยืนยันการเก็บเงินคืน',
  successModalTitle: 'สำเร็จ',
  successModalLottoResultDescription: 'ทำรายการแก้ไขสำเร็จ',
  successModalLottoReverseDescription: 'ทำการเก็บเงินคืนสำเร็จ',
  errorModalTitle: 'เกิดข้อผิดพลาด',
  unavailable: 'ไม่เปิดใช้งาน'
}

const defaultProps: ILotteryManagementContainerProps &
  ILotteryManagementContainerActionProps = {
  getLottoList: () => {
    return new Promise(() => undefined)
  },
  postLottoResult() {
    return new Promise(() => undefined)
  },
  lottos: [],
  lottoIsFetching: false,
  lottoCode: 0,
  lottoError: '',

  lottoResultIsFetching: false,
  lottoResult: [],
  lottoResultCode: 0,
  lottoResultError: '',

  lottoReverseCode: 0,
  lottoReverseError: '',
  lottoReverseIsFetching: false,
  postLottoReverse() {
    return new Promise(() => undefined)
  },

  currentLottoResultCode: 0,
  currentLottoResultError: '',
  currentLottoResultIsFetching: false,
  postLottoCurrentResult() {
    return new Promise(() => undefined)
  },
  currentLottoResult: {
    createdAt: '',
    lotterCurrentResult: [],
    status: ''
  }
}

type DefaultProps = Readonly<typeof defaultProps>

class LotteryManagementContainer extends Component<
  DefaultProps & ILotteryManagementContainerProps & RouteComponentProps,
  ILotteryManagementContainerState
> {
  static defaultProps = defaultProps

  state: ILotteryManagementContainerState = {
    selectedOption: {
      createdAt: '',
      lotterCurrentResult: [],
      status: ''
    },
    threeUpCurrentResult: '',
    twoDownCurrentResult: '',
    threeUpEditedResult: '',
    twoDownEditedResult: '',
    fourSuiteCurrentResult: '',
    fourSuiteEditedResult: '',
    editedSelectedOption: {
      createdAt: '',
      lotterCurrentResult: [],
      status: ''
    }
  }

  componentDidMount() {
    this.props.getLottoList()
  }

  componentDidUpdate(prevProps: ILotteryManagementContainerProps) {
    if (
      prevProps.lottoResultIsFetching !== this.props.lottoResultIsFetching &&
      !this.props.lottoResultIsFetching
    ) {
      if (this.props.lottoResultCode === responseCode.OK) {
        SuccessModal.show({
          title: constants.successModalTitle,
          description: constants.successModalLottoResultDescription,
          action: SuccessModal.hide
        })
        this.props.postLottoCurrentResult({
          lotterType: this.state.editedSelectedOption.code as TLottoType
        })
      } else {
        ErrorModal.show({
          title: constants.errorModalTitle,
          description: this.props.lottoResultError,
          action: ErrorModal.hide
        })
      }
    }

    if (
      prevProps.lottoReverseIsFetching !== this.props.lottoReverseIsFetching &&
      !this.props.lottoReverseIsFetching
    ) {
      if (this.props.lottoReverseCode === responseCode.OK) {
        SuccessModal.show({
          title: constants.successModalTitle,
          description: constants.successModalLottoReverseDescription,
          action: SuccessModal.hide
        })
      } else {
        ErrorModal.show({
          title: constants.errorModalTitle,
          description: this.props.lottoReverseError,
          action: ErrorModal.hide
        })
      }
    }

    if (
      prevProps.currentLottoResultIsFetching !==
        this.props.currentLottoResultIsFetching &&
      !this.props.currentLottoResultIsFetching
    ) {
      if (this.props.currentLottoResultCode === responseCode.OK) {
        this.setState(
          { selectedOption: this.state.editedSelectedOption },
          () => {
            this.handleInputState()
          }
        )
      } else {
        this.setState({ editedSelectedOption: this.state.selectedOption })
      }
    }
  }

  handleInputState = () => {
    if (this.state.selectedOption.code !== 'LAO_SUITE') {
      let threeUpCurrentResultIndex: number = findIndex(
        this.props.currentLottoResult.lotterCurrentResult,
        (item: IGetLotterCurrentResult) => item.valueType === 'THREE_UP'
      )
      let twoDownCurrentResultIndex: number = findIndex(
        this.props.currentLottoResult.lotterCurrentResult,
        (item: IGetLotterCurrentResult) => item.valueType === 'TWO_DOWN'
      )

      if (
        threeUpCurrentResultIndex === -1 ||
        twoDownCurrentResultIndex === -1
      ) {
        threeUpCurrentResultIndex = 0
        twoDownCurrentResultIndex = 1
      }

      const threeUpCurrentResult =
        this.props.currentLottoResult.lotterCurrentResult[
          threeUpCurrentResultIndex
        ].value || 'xxx'

      const twoDownCurrentResult: string =
        this.props.currentLottoResult.lotterCurrentResult[
          twoDownCurrentResultIndex
        ].value || 'xx'
      this.setState({ threeUpCurrentResult: threeUpCurrentResult }, () => {
        this.setState({ threeUpEditedResult: threeUpCurrentResult })
      })
      this.setState({ twoDownCurrentResult: twoDownCurrentResult }, () => {
        this.setState({ twoDownEditedResult: twoDownCurrentResult })
      })
    } else {
      const fourSuiteCurrentResult =
        this.props.currentLottoResult.lotterCurrentResult[0].value || 'xxxx'
      this.setState({ fourSuiteCurrentResult: fourSuiteCurrentResult }, () => {
        this.setState({ fourSuiteEditedResult: fourSuiteCurrentResult })
      })
    }
  }

  handleOnClickBreadCrumb = (path: string) => {
    this.props.history.push(path)
  }

  handleOnBack = () => {
    this.props.history.push('/main')
  }

  renderLottoSlugDropDownOptions = () => {
    const lottosWithOutYegee = filter(
      this.props.lottos,
      (lotto) => !lotto.code.includes('YEGEE')
    )
    const options = map(lottosWithOutYegee, (lotto, _) => {
      const normalizationCode: TLottoType = includes(lotto.code, 'YEGEE')
        ? 'YEGEE'
        : lotto.code
      const FlagIcon = get(
        LottoFlags,
        `${get(LOTTO_FLAG_ALPHA, normalizationCode, '')}.Icon`,
        ''
      )
      const gameName =
        normalizationCode === 'YEGEE'
          ? `${constants.lotto}${LOTTO_TYPE[normalizationCode]}`
          : LOTTO_SLUG_NAME[`LOTTER_${normalizationCode}` as TLottoSlug]
      const gameRound =
        normalizationCode === 'YEGEE' ? Number(split(lotto.code, '_')[1]) : ''
      const DropDownOptions: IOptions = {
        value: LOTTO_SLUG_NAME[`LOTTER_${lotto.code}` as TLottoSlug],
        label: (
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="mr-3 align-self-stretch">
              {gameName} {gameRound}
            </div>
            <ResponsiveIcon
              icon={FlagIcon}
              alt="flag"
              className="lotto-slug-dropdown-flag"
            />
          </div>
        ),
        disabled: lotto.code === 'LAO_SUITE' ? true : false
      }
      return DropDownOptions
    })
    return options
  }

  renderLottoResultCard = (
    currentResult: IResponseLotterCurrentResult,
    isEdited: boolean = false
  ) => {
    if (isEmpty(this.state.selectedOption.lotterCurrentResult)) {
      return <></>
    }
    return (
      <div key={`lotto-card-${currentResult.code}`} className="col-12">
        <LottoResultCard currentResult={currentResult} isEdited={isEdited} />
      </div>
    )
  }

  handleDropDownOnChange = async (target: IOptions) => {
    const INVERTED_LOTTO_SLUG_NAME = invert(LOTTO_SLUG_NAME)
    const lottoSlugName = INVERTED_LOTTO_SLUG_NAME[target.value]
    const underScoreIndex = lottoSlugName.indexOf('_')
    const lottoTypeName = lottoSlugName.substring(underScoreIndex + 1)

    await this.props.postLottoCurrentResult({
      lotterType: lottoTypeName as TLottoType
    })
    this.setState(
      {
        selectedOption: {
          ...this.props.currentLottoResult,
          code: lottoTypeName as TLottoType
        }
      },
      () => {
        this.setState({ editedSelectedOption: this.state.selectedOption })
      }
    )

    if (this.props.currentLottoResultCode === responseCode.OK) {
      this.handleInputState()
    } else if (this.props.currentLottoResultCode === responseCode.NOT_FOUND) {
      this.handleInputState()
    }
  }

  setStateEditedSelectedOption = (
    gameType: TLottoGameType,
    targetValue: string
  ) => {
    const clonedEditedSelectedOption = cloneDeep(
      this.state.editedSelectedOption
    )
    const lottoIndex = findIndex(
      clonedEditedSelectedOption.lotterCurrentResult,
      (item: IGetLotterCurrentResult) => item.valueType === gameType
    )
    clonedEditedSelectedOption.lotterCurrentResult[lottoIndex].value =
      targetValue
    this.setState(
      { editedSelectedOption: clonedEditedSelectedOption },
      () => {}
    )
  }

  handleReset = (lotto: TLottoType) => {
    if (lotto === 'LAO_SUITE') {
      this.setState(
        { fourSuiteEditedResult: this.state.fourSuiteCurrentResult },
        () => {
          this.setStateEditedSelectedOption(
            'FOUR_SUITE',
            this.state.fourSuiteEditedResult
          )
        }
      )
    } else {
      this.setState(
        { threeUpEditedResult: this.state.threeUpCurrentResult },
        () => {
          this.setState(
            {
              twoDownEditedResult: this.state.twoDownCurrentResult
            },
            () => {
              this.setStateEditedSelectedOption(
                'TWO_DOWN',
                this.state.twoDownEditedResult
              )
            }
          )
          this.setStateEditedSelectedOption(
            'THREE_UP',
            this.state.threeUpEditedResult
          )
        }
      )
    }
  }

  isSaveButtonDisabled = () => {
    if (isEmpty(this.state.selectedOption.lotterCurrentResult)) {
      return true
    }

    if (this.props.currentLottoResult.status === constants.unavailable) {
      return true
    }

    if (this.state.selectedOption.code === 'LAO_SUITE') {
      if (this.state.fourSuiteEditedResult.length !== 4) {
        return true
      }
      return false
    }
    if (
      this.state.threeUpEditedResult.length !== 3 ||
      this.state.twoDownEditedResult.length !== 2
    ) {
      return true
    }
    return false
  }

  handleSaveButton = () => {
    ConfirmModal.show({
      action: () => {
        const arr: ICreateLotterResult[] = []
        const lottoType: TLottoType = this.state.selectedOption.code!
        if (lottoType === 'LAO_SUITE') {
          arr.push({
            value: this.state.fourSuiteEditedResult,
            valueType: 'FOUR_SUITE',
            slug: `LOTTER_${lottoType}`
          })
        } else {
          arr.push({
            value: this.state.twoDownEditedResult,
            valueType: 'TWO_DOWN',
            slug: `LOTTER_${lottoType}`
          })
          arr.push({
            value: this.state.threeUpEditedResult,
            valueType: 'THREE_UP',
            slug: `LOTTER_${lottoType}`
          })
        }
        ConfirmModal.hide()
        this.props.postLottoResult(arr)
      },
      cancel: ConfirmModal.hide
    })
  }

  handleReverse = () => {
    ConfirmModal.show({
      action: () => {
        this.props.postLottoReverse({
          lotterType: this.state.selectedOption.code!
        })
        ConfirmModal.hide()
      },
      cancel: ConfirmModal.hide,
      description: constants.reverseConfirmation
    })
  }

  formatResultDate = () => {
    const currentLottoResultCreatedAt = this.props.currentLottoResult.createdAt
    const calibratedTime = date.calibratingTime(
      currentLottoResultCreatedAt,
      true,
      true
    )
    if (!isValid(calibratedTime)) {
      return currentLottoResultCreatedAt
    }
    const formattedTime = format(calibratedTime, "d'/'M'/'Y HH':'mm 'น.'")
    return formattedTime || constants.unavailable
  }

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.routeTextTertiary, active: false, path: '/main' },
      { label: constants.routeTextSecodary, active: false, path: '/main' },
      {
        label: constants.routeTextActive,
        active: true,
        path: '/lottery-management'
      }
    ]

    return (
      <div className="container-fluid pt-5 px-sm-5 page-container vh-100 d-flex flex-column">
        <div className="row">
          <div className="col-12 pt-5 mt-2">
            <Breadcrumb
              items={navigates}
              handleOnClickItem={this.handleOnClickBreadCrumb}
            />
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-12">
            <ALink
              id="backto-previous-page"
              color={colors.SECONDARY_RED}
              bold
              onClick={this.handleOnBack}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              &nbsp;{constants.back}
            </ALink>
          </div>
        </div>
        <div className="row mt-3 box-shadow-container">
          <div className="col-12 tertiary-bg shadow-lg px-4 py-4 overflow-auto rounded">
            <div className="row m3-b">
              <div className="col-12 justify-content-between align-items-center d-flex flex-row flex-wrap">
                <h2 className="m2-r">
                  <span className="responsive-title-text">
                    {constants.title}
                  </span>
                </h2>
                <div className="align-self-center overflow-hidden mt-sm-0 mt-2">
                  <Button
                    id={`lotto-reverse`}
                    text={constants.reverse}
                    size={'medium'}
                    borderRadius="12px"
                    paddingLeft={10}
                    paddingRight={10}
                    onClick={this.handleReverse}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-12">
                <Dropdown
                  placeholder={constants.lottoSlug}
                  options={this.renderLottoSlugDropDownOptions()}
                  handleOnChange={(target) =>
                    this.handleDropDownOnChange(target)
                  }
                />
              </div>
              <div className="col-sm-6 col-12 mt-sm-0 mt-3">
                {!isEmpty(this.state.selectedOption.lotterCurrentResult) && (
                  <>
                    <div className="w-100 d-flex flex-row">
                      <div className="body-1 m1-r">
                        <span className="responsive-body-text">
                          {constants.resultDate}
                        </span>
                      </div>
                      <h4 className="body-text-md">
                        <span className="responsive-body-text">
                          {this.formatResultDate()}
                        </span>
                      </h4>
                    </div>
                    <div className="w-100 d-flex flex-row m1-t">
                      <div className="body-1 m1-r">
                        <span className="responsive-body-text">
                          {constants.status}
                        </span>
                      </div>
                      <h4 className="primary-red-text">
                        <span className="responsive-body-text">
                          {this.props.currentLottoResult.status ||
                            constants.unavailable}
                        </span>
                      </h4>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="row m2-t">
              <div className="col-sm-6 col-12">
                <div className="row">
                  <div className="col-12">
                    <h2>
                      <span className="responsive-title-text">
                        {constants.currentResult}
                      </span>
                    </h2>
                  </div>
                </div>

                <div className="row m1-t">
                  {this.renderLottoResultCard(this.state.selectedOption)}
                </div>
              </div>
              <div className="col-sm-6 col-12 d-flex flex-column">
                {!isEmpty(this.state.selectedOption.lotterCurrentResult) &&
                  this.state.selectedOption.code !== 'LAO_SUITE' && (
                    <>
                      <h2>
                        <span className="responsive-body-text">&nbsp;</span>
                      </h2>
                      <div className="d-flex flex-column flex-grow-1 justify-content-around">
                        <div className="d-flex align-items-center">
                          <h3 className="w-25 m1-r">
                            <span className="responsive-body-text">
                              {constants.threeUp}
                            </span>
                          </h3>
                          <Input
                            value={this.state.threeUpCurrentResult}
                            id={'three-up-current-result'}
                            backgroundColor={colors.TERTIARY_BG}
                            disabled
                          />
                        </div>
                        <div className="d-flex align-items-center mt-0-sm mt-4">
                          <h3 className="w-25 m1-r">
                            <span className="responsive-body-text">
                              {constants.twoDown}
                            </span>
                          </h3>
                          <Input
                            value={this.state.twoDownCurrentResult}
                            id={'two-down-current-result'}
                            backgroundColor={colors.TERTIARY_BG}
                            disabled
                          />
                        </div>
                      </div>
                    </>
                  )}
                {!isEmpty(this.state.selectedOption.lotterCurrentResult) &&
                  this.state.selectedOption.code === 'LAO_SUITE' && (
                    <>
                      <h3>
                        <span className="responsive-body-text">&nbsp;</span>
                      </h3>
                      <div className="d-flex flex-column flex-grow-1 justify-content-around">
                        <div className="d-flex align-items-center">
                          <h3 className="w-25 m1-r">
                            <span className="responsive-body-text">
                              {constants.fourSuite}
                            </span>
                          </h3>
                          <Input
                            value={this.state.fourSuiteCurrentResult}
                            id={'four-suite-current-result'}
                            backgroundColor={colors.TERTIARY_BG}
                            disabled
                          />
                        </div>
                      </div>
                    </>
                  )}
              </div>
            </div>
            <div className="row m2-t">
              <div className="col-sm-6 col-12">
                <div className="row">
                  <div className="col-12">
                    <h2>
                      <span className="responsive-title-text">
                        {constants.editedResult}
                      </span>
                    </h2>
                  </div>
                </div>
                <div className="row m1-t">
                  {this.renderLottoResultCard(
                    this.state.editedSelectedOption,
                    true
                  )}
                </div>
              </div>
              <div className="col-sm-6 col-12 d-flex flex-column">
                {!isEmpty(this.state.selectedOption.lotterCurrentResult) &&
                  this.state.selectedOption.code !== 'LAO_SUITE' && (
                    <>
                      <h3>
                        <span className="responsive-body-text">&nbsp;</span>
                      </h3>
                      <div className="d-flex flex-column flex-grow-1 justify-content-around">
                        <div className="d-flex align-items-center">
                          <h3 className="w-25 m1-r">
                            <span className="responsive-body-text">
                              {constants.threeUp}
                            </span>
                          </h3>
                          <Input
                            id={'three-up-edited-result'}
                            backgroundColor={colors.TERTIARY_BG}
                            value={this.state.threeUpEditedResult}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              this.setStateEditedSelectedOption(
                                'THREE_UP',
                                e.currentTarget.value.replace(NON_NUMBER, '')
                              )
                              this.setState({
                                threeUpEditedResult:
                                  e.currentTarget.value.replace(NON_NUMBER, '')
                              })
                            }}
                            maxLength={3}
                            disabled={
                              this.props.currentLottoResult.status ===
                              constants.unavailable
                                ? true
                                : false
                            }
                          />
                        </div>
                        <div className="d-flex align-items-center mt-0-sm mt-4">
                          <h3 className="w-25 m1-r">
                            <span className="responsive-body-text">
                              {constants.twoDown}
                            </span>
                          </h3>
                          <Input
                            id={'two-down-edited-result'}
                            backgroundColor={colors.TERTIARY_BG}
                            value={this.state.twoDownEditedResult}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              this.setStateEditedSelectedOption(
                                'TWO_DOWN',
                                e.currentTarget.value.replace(NON_NUMBER, '')
                              )
                              this.setState({
                                twoDownEditedResult:
                                  e.currentTarget.value.replace(NON_NUMBER, '')
                              })
                            }}
                            maxLength={2}
                            disabled={
                              this.props.currentLottoResult.status ===
                              constants.unavailable
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}
                {!isEmpty(this.state.selectedOption.lotterCurrentResult) &&
                  this.state.selectedOption.code === 'LAO_SUITE' && (
                    <>
                      <h3>
                        <span className="responsive-body-text">&nbsp;</span>
                      </h3>
                      <div className="d-flex flex-column flex-grow-1 justify-content-around">
                        <div className="d-flex align-items-center">
                          <h3 className="w-25 m1-r">
                            <span className="responsive-body-text">
                              {constants.fourSuite}
                            </span>
                          </h3>
                          <Input
                            id={'four-suite-edited-result'}
                            backgroundColor={colors.TERTIARY_BG}
                            value={this.state.fourSuiteEditedResult}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              this.setStateEditedSelectedOption(
                                'FOUR_SUITE',
                                e.currentTarget.value.replace(NON_NUMBER, '')
                              )
                              this.setState({
                                fourSuiteEditedResult:
                                  e.currentTarget.value.replace(NON_NUMBER, '')
                              })
                            }}
                            maxLength={4}
                            disabled={
                              this.props.currentLottoResult.status ===
                              constants.unavailable
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}
              </div>
            </div>
            <div className="row m2-t">
              <div className="col-md-4 col-lg-6 col-0">
                <></>
              </div>
              <div className="col-md-4 col-lg-3 col-sm-6 col-12 mt-md-0 mt-sm-0 mt-4">
                <Button
                  id={`get-money-back`}
                  text={constants.reset}
                  size={'medium'}
                  borderRadius="12px"
                  backgroundColor={colors.PRIMARY_BG}
                  backgroundHoverColor={colors.SECONDARY_BG}
                  disabled={
                    isEmpty(this.state.selectedOption.lotterCurrentResult)
                      ? true
                      : false
                  }
                  onClick={() => {
                    this.handleReset(this.state.selectedOption.code!)
                  }}
                />
              </div>
              <div className="col-md-4 col-lg-3 col-sm-6 col-12 mt-md-0 mt-sm-0 mt-4">
                <Button
                  id={`get-money-back`}
                  text={constants.save}
                  size={'medium'}
                  borderRadius="12px"
                  disabled={this.isSaveButtonDisabled()}
                  onClick={() => {
                    this.handleSaveButton()
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LotteryManagementContainer
