export const NUMBER = /^[0-9]*$/g
export const DECIMAL_DITGITS = /(\.\d+)+/g
export const COMMA = /[,]/g
export const ENG = /^[a-zA-Z0-9]+$/g
export const LOWERCASE = /^[a-z0-9]+$/g
export const ESCAPED_TIMEZONE = /\S{1,19}/g
export const SPACE = /\s+/g
export const ZERO = /^[1-9][0-9]*$/
export const SPECIAL_ENG = /^[a-zA-Z0-9!@#$%^&*)(+=._-]+$/g
    
export const CHECK_NUM_POINT = /[^0-9.-]/g
export const CHECK_POINT = /\./g
export const CHECK_HYPHEN = /(?!^)-/g

export const NON_NUMBER = /[^0-9]/g

