import { connect } from 'react-redux'
import LotteryManagementContainer from './LotteryManagement.container'
import lottoSelector from 'reduxs/lotto/selector'
import { getLottoList } from 'reduxs/lotto/list/action'
import { postLottoResult } from 'reduxs/config/lotto/result/action'
import configLottoSelector from 'reduxs/config/lotto/selector'
import { postLottoReverse } from 'reduxs/config/lotto/reverse/action'
import { postLottoCurrentResult } from 'reduxs/config/lotto/currentResult/action'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

const mapStateToProps = (
  state: RootReducers
): ILotteryManagementContainerProps => {
  const listLotto = lottoSelector.listLotto(state)
  const resultLotto = configLottoSelector.lottoResult(state)
  const reverseLotto = configLottoSelector.lottoReverse(state)
  const currentResultLotto = configLottoSelector.lottoCurrentResult(state)
  return {
    lottoIsFetching: listLotto.isFetching,
    lottoCode: listLotto.code,
    lottoError: listLotto.error,
    lottos: listLotto.data,
    lottoResultIsFetching: resultLotto.isFetching,
    lottoResultCode: resultLotto.code,
    lottoResultError: resultLotto.error,
    lottoResult: resultLotto.data,
    lottoReverseIsFetching: reverseLotto.isFetching,
    lottoReverseCode: reverseLotto.code,
    lottoReverseError: reverseLotto.error,
    currentLottoResultIsFetching: currentResultLotto.isFetching,
    currentLottoResultCode: currentResultLotto.code,
    currentLottoResultError: currentResultLotto.error,
    currentLottoResult: currentResultLotto.data
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<RootReducers, void, AnyAction>): ILotteryManagementContainerActionProps => {
  return {
    getLottoList: (query?: ILottoQuery) => dispatch(getLottoList(query)),
    postLottoResult: (data: ICreateLotterResult[]) => dispatch(postLottoResult(data)),
    postLottoReverse: (data: IReverseLotterPayment) => dispatch(postLottoReverse(data)),
    postLottoCurrentResult: (data: IQueryLotterCurrentResult) => dispatch(postLottoCurrentResult(data)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LotteryManagementContainer)
