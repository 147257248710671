const initialNumber: IUpdateBetUser = {
 betTransactionId: 0,
 result: '',
 status: 'CANCEL',
 value: '',
}

const initialBetQuery: ITransactionFilterForm = {
  usernameSearch: '',
  type: [],
  subType: '',
  status: [],
  playTimeStart: '',
  playTimeEnd: '',
  resultTimeStart: '',
  resultTimeEnd: '',
}

const initials = {
  number: initialNumber,
  betQuery: initialBetQuery
}
export default initials