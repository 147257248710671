import { Breadcrumb } from 'components'
import { map, noop } from 'lodash'
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import './main.style.scss'

const constants = {
  routeTextSecodary: 'OMEGA',
  routeTextActive: 'Home',
  logoutText: 'Logout',
  userMenu: 'User',
  userMenuDesc: 'Edit user',
  catchMenu: 'Catch',
  catchMenuDesc: 'Catching user who break the rule',
  ok: 'OK',
  error: 'ERROR'
}

interface menuLists {
  name: string
  detail: string
  path: string
}

const menu: menuLists[] = [
  {
    name: 'User',
    detail: 'Edit user',
    path: '/userinfo'
  },
  {
    name: `Partner Controllers`,
    detail: 'Setting Games',
    path: '/partner-controllers'
  },
  {
    name: 'Credit',
    detail: 'Credit-me',
    path: '/creditinfo'
  },
  {
    name: 'Lottery Management',
    detail: 'Reverse transaction and fix',
    path: '/lottery-management'
  },
  {
    name: 'Transaction Management',
    detail: 'Edit Transaction',
    path: '/transactionManagement'
  },
  {
    name: 'Webbank Management',
    detail: 'Edit Webbank',
    path: '/webbankManagement'
  }
]

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IMainContainerActionProps & IMainContainerProps = {
  logout() {
    noop()
  }
}

export default class MainContainer extends Component<
  IMainContainerProps &
    IMainContainerActionProps &
    RouteComponentProps &
    DefaultProps
> {
  onNavigateRoutes = (path: string) => {
    this.props.history.push(path)
  }

  handleOnClickBreadcrumb = (path: string) => {
    this.props.history.push(path)
  }

  renderMenu = (menu: menuLists[]) =>
    map(menu, (menuList, index) => (
      <div
        key={`MenuList-${index}`}
        className={`pt-4 ${
          index % 3 !== 0 ? 'col-lg-6 col-md-6' : 'col-lg-12 col-md-12'
        } col-12`}
      >
        <div
          onClick={() => this.onNavigateRoutes(menuList.path)}
          className="box-shadow bg-dark p-4"
        >
          <h3 className="header-text">{menuList.name}</h3>
          <h5 className="desc-text">{menuList.detail}</h5>
        </div>
      </div>
    ))

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.routeTextSecodary, active: false, path: '/main' },
      { label: constants.routeTextActive, active: true, path: '/main' }
    ]
    const RenderMenu = this.renderMenu(menu)
    return (
      <div className="container-fluid pt-5 px-5">
        <div className="pt-5 mt-2">
          <Breadcrumb
            items={navigates}
            handleOnClickItem={this.handleOnClickBreadcrumb}
          />
        </div>
        <div className="row mt-2">{RenderMenu}</div>
      </div>
    )
  }
}
