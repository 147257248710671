import React, { Component, createRef, RefObject } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  ALink,
  Breadcrumb,
  Button,
  ConfirmModal,
  ErrorModal,
  ResponsiveIcon,
  RollerLoading
} from 'components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft
} from '@fortawesome/free-solid-svg-icons'

import { isEmpty, map, noop } from 'lodash'
import colors from 'constants/colors'
import './webBankManagement.style.scss'

import SimpleBar from 'simplebar-react'
import { date } from 'utils'
import collectBank from 'assets/images/global/bank'
import { BANK_NAME, TRANSACTION_TYPE } from 'constants/variables'
import ANYBANK_ICON from 'assets/images/global/bank/any.png'
import InfiniteScroll from 'react-infinite-scroll-component'
import { responseCode } from 'constants/response'

const constants = {
  routeTextSecodary: 'Home',
  routeTextActive: 'Webbank Management',
  routeTextTertiary: 'OMEGA',
  back: 'Back',
  ok: 'OK',
  labelSection: 'Webbank Management',
  webbankActiveLabel: 'หยุดใช้งาน',
  webbankReadyToHarvestLabel: 'เคลียร์ยอด',
  webbankConfirmModalTitle: `ยืนยันการหยุดใช้งาน \n บัญชีดังกล่าวจะเข้าสู่สถานะรอใช้งาน \n เพื่อรอคิวเปิดใช้ต่อไป`
}

type TTableTitleLabel =
  | 'รหัส'
  | 'ชื่อบัญชี'
  | 'ธนาคาร'
  | 'เลขบัญชี'
  | 'ประเภท'
  | 'สถานะ'
  | 'แก้ไขล่าสุด'
  | 'เริ่มใช้งาน'
  | 'จัดการ'

const tableTitleConstants: { title: TTableTitleLabel }[] = [
  { title: 'รหัส' },
  { title: 'ชื่อบัญชี' },
  { title: 'ธนาคาร' },
  { title: 'เลขบัญชี' },
  { title: 'ประเภท' },
  { title: 'สถานะ' },
  { title: 'แก้ไขล่าสุด' },
  { title: 'เริ่มใช้งาน' },
  { title: 'จัดการ' }
]

const webbankStatus: { [key in TWebbankStatus]: string } = {
  ACTIVE: 'ใช้งาน',
  DELETED: 'ปิดใช้งาน',
  INACTIVE: 'รอใช้งาน',
  READY_TO_HARVEST: 'เต็มวงเงิน'
}

const defaultProps: IWebbankManagementContainerActionProps &
  IWebbankManagementContainerProps = {
  clearWebbankObserver() {
    noop()
  },
  getWebbankObserver() {
    noop()
  },
  postWebbankReset() {
    noop()
  },
  postWebbankSwitching() {
    noop()
  },
  postwebbankResetCode: 0,
  postwebbankResetError: '',
  postwebbankResetIsFetching: false,
  postwebbankSwitchingCode: 0,
  postwebbankSwitchingError: '',
  postwebbankSwitchingIsFetching: false,
  getwebbankObserverCode: 0,
  getwebbankObserverError: '',
  getwebbankObserverIsFetching: false,
  webbankObserverList: {
    dataList: [],
    limit: 0,
    page: 0,
    total: 0
  }
}

type DefaultProps = Readonly<typeof defaultProps>

class WebBankManagementContainer extends Component<
  IWebbankManagementContainerActionProps &
    IWebbankManagementContainerProps &
    RouteComponentProps &
    DefaultProps,
  IWebbankManagementContainerState
> {
  static defaultProps = defaultProps
  pageContainerRef: RefObject<HTMLDivElement> = createRef()
  state: IWebbankManagementContainerState = {
    hasMoreWebbankObserver: true,
    webbankObserverQuery: {
      page: 1,
      limit: 10
    },
    webbankObserverList: []
  }

  componentDidMount() {
    this.onGetWebbankObserver()
  }

  componentDidUpdate(prevProps: IWebbankManagementContainerProps) {
    if (
      prevProps.getwebbankObserverIsFetching !==
        this.props.getwebbankObserverIsFetching &&
      !this.props.getwebbankObserverIsFetching
    ) {
      if (this.props.getwebbankObserverCode === responseCode.OK) {
        this.setState({
          webbankObserverList: [...this.props.webbankObserverList.dataList]
        })
        if (
          this.props.webbankObserverList.page >=
          this.props.webbankObserverList.total
        ) {
          this.setState({ hasMoreWebbankObserver: false })
        } else {
          this.setState({ hasMoreWebbankObserver: true })
        }
      } else if (this.props.getwebbankObserverCode === responseCode.NOT_FOUND) {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: `${this.props.getwebbankObserverError}\n`,
          actionText: constants.ok
        })
        this.setState({ webbankObserverList: [] })
      }
    }
    if (
      prevProps.postwebbankResetIsFetching !==
        this.props.postwebbankResetIsFetching &&
      !this.props.postwebbankResetIsFetching
    ) {
      if (this.props.postwebbankResetCode === responseCode.OK) {
        this.setState(
          {
            webbankObserverQuery: {
              page: 1,
              limit: 10
            }
          },
          () => {
            this.props.clearWebbankObserver()
            this.onGetWebbankObserver()
          }
        )
      } else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: `${this.props.postwebbankResetError}\n`,
          actionText: constants.ok
        })
      }
    }
    if (
      prevProps.postwebbankSwitchingIsFetching !==
        this.props.postwebbankSwitchingIsFetching &&
      !this.props.postwebbankSwitchingIsFetching
    ) {
      if (this.props.postwebbankSwitchingCode === responseCode.OK) {
        this.setState(
          {
            webbankObserverQuery: {
              page: 1,
              limit: 10
            }
          },
          () => {
            this.props.clearWebbankObserver()
            this.onGetWebbankObserver()
          }
        )
      } else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: `${this.props.postwebbankSwitchingError}\n`,
          actionText: constants.ok
        })
      }
    }
  }
  componentWillUnmount() {
    this.props.clearWebbankObserver()
  }

  onGetWebbankObserver = () => {
    const { webbankObserverQuery } = this.state
    this.props.getWebbankObserver(webbankObserverQuery, true)
  }

  onHarvest = (body: { id: number }) => {
    ConfirmModal.show({
      description: constants.webbankConfirmModalTitle,
      cancel: ConfirmModal.hide,
      action: () => {
        this.props.postWebbankReset(body)
        ConfirmModal.hide()
      }
    })
  }

  onDelete = (body: { id: number; type: string }) => {
    ConfirmModal.show({
      description: constants.webbankConfirmModalTitle,
      cancel: ConfirmModal.hide,
      action: () => {
        this.props.postWebbankSwitching(body)
        ConfirmModal.hide()
      }
    })
  }

  handleOnBack = () => this.props.history.replace('/main')

  handleOnClickBreadcrumb = (path: string) => this.props.history.replace(path)

  handleScroll = () =>
    this.setState(
      (prevState, props) => ({
        webbankObserverQuery: {
          ...prevState.webbankObserverQuery,
          page: props.webbankObserverList.page + 1
        }
      }),
      () => {
        const { webbankObserverQuery } = this.state
        this.props.getWebbankObserver(webbankObserverQuery, false)
      }
    )

  handleTableTitleElement = () => (
    <>
      {map(tableTitleConstants, (data, index) => (
        <th
          key={`label-item-${index}`}
          className={`
          justify-content-center
          `}
        >
          <div className="row">
            <div className="col d-flex justify-content-center">
              <span className="text-center">{data.title}</span>
            </div>
          </div>
        </th>
      ))}
    </>
  )

  handleTableDataElement = () => {
    const {
      webbankObserverList: { dataList }
    } = this.props
    const createData = map(dataList, (dataList, index) => {
      const createBankIcon: JSX.Element = (
        <>
          {map(dataList.mapBank, (_, index) => (
            <div
              key={`icon-item-${index}`}
              className={` px-0 ${
                index !== 0 && 'responsive-icon-bank-wrapper-rows'
              }
              ${index === 9 && 'responsive-icon-bank-wrapper-rows'}
              
              ${index === 18 && 'responsive-icon-bank-wrapper-rows'}
              `}
            >
              <ResponsiveIcon
                className="responsive-icon-bank"
                icon={
                  dataList.mapBank[index] === '$any' || collectBank[dataList.mapBank[index] as TBankType] === undefined
                    ? ANYBANK_ICON
                    : collectBank[dataList.mapBank[index] as TBankType].Icon
                }
              />
            </div>
          ))}
        </>
      )
      return (
        <tr
          key={`row-item-${index}`}
          className={`active`}
          style={{
            color: dataList.status === 'DELETED' ? colors.SECONDARY_PURPLE : ''
          }}
        >
          <td>
            <div className="row">
              <div className="col d-flex justify-content-center pl-0">
                <span className="text-center">{dataList.id}</span>
              </div>
            </div>
          </td>
          <td>
            <div className="row">
              <div className="col d-flex justify-content-center pl-1">
                <span className="text-center">{dataList.name}</span>
              </div>
            </div>
          </td>
          <td>
            <div className="row">
              <div className="col d-flex justify-content-center px-0">
                <span className="text-center">{BANK_NAME[dataList.type]}</span>
              </div>
            </div>
          </td>
          <td>
            <div className="row">
              <div className="col d-flex justify-content-center">
                <span className="text-center">{dataList.number}</span>
              </div>
            </div>
          </td>
          <td style={{ flexDirection: 'column' }}>
            <div className="row">
              <div className="col-12 d-flex justify-content-center pr-2">
                <span className="text-center">
                  {TRANSACTION_TYPE[dataList.operateType as TTransactionType]}
                </span>
              </div>
            </div>
            <div className={`row ${dataList.mapBank.length > 6 && 'mt-3'}`}>
              <div className="col responsive-icon-bank-wrapper pr-2">
                <div
                  className="row"
                  style={{ width: 150, justifyContent: 'center' }}
                >
                  {createBankIcon}
                </div>
              </div>
            </div>
          </td>
          <td
            style={{
              color:
                dataList.status === 'ACTIVE'
                  ? colors.SECONDARY_GREEN
                  : dataList.status === 'READY_TO_HARVEST'
                  ? colors.SECONDARY_PURPLE
                  : '',
              fontWeight: 'bold'
            }}
          >
            <div className="row">
              <div className="col d-flex justify-content-center pr-0">
                <div className="col text-center">
                  <span className="text-center">
                    {webbankStatus[dataList.status]}
                  </span>
                </div>
              </div>
            </div>
          </td>
          <td>
            <div className="row">
              <div className="col d-flex justify-content-center pr-0">
                <span className="text-center">
                  {date.formatDateTime(dataList.updatedAt, true)}
                </span>
              </div>
            </div>
          </td>
          <td>
            <div className="row">
              <div className="col d-flex justify-content-center pr-0">
                <span className="text-center">
                  {date.formatDateTime(dataList.createdAt, true)}
                </span>
              </div>
            </div>
          </td>
          <td>
            <div className="row">
              <div className="col d-flex justify-content-center pr-0 width-150">
                {dataList.status === 'ACTIVE' ? (
                  <Button
                    size="medium"
                    id={String(dataList.id)}
                    text={constants.webbankActiveLabel}
                    buttonType="button"
                    backgroundColor={colors.PRIMARY_PURPLE}
                    backgroundHoverColor={colors.SECONDARY_PURPLE}
                    type="rectangle"
                    onClick={() =>
                      this.onDelete({ id: dataList.id, type: dataList.type })
                    }
                  />
                ) : (
                  dataList.status === 'READY_TO_HARVEST' && (
                    <Button
                      size="medium"
                      id={String(dataList.id)}
                      text={constants.webbankReadyToHarvestLabel}
                      buttonType="button"
                      backgroundColor={colors.PRIMARY_RED}
                      backgroundHoverColor={colors.SECONDARY_RED}
                      type="rectangle"
                      onClick={() => this.onHarvest({ id: dataList.id })}
                    />
                  )
                )}
              </div>
            </div>
          </td>
        </tr>
      )
    })
    return (
      <InfiniteScroll
        height={'65vh'}
        dataLength={this.state.webbankObserverList.length}
        next={this.handleScroll}
        hasMore={this.state.hasMoreWebbankObserver}
        loader={<RollerLoading />}
      >
        {createData}
      </InfiniteScroll>
    )
  }

  handleGenerateTableData = () => {
    const RenderTableData = this.handleTableDataElement
    const tableData = isEmpty(this.props.webbankObserverList.dataList) ? (
      <></>
    ) : (
      <RenderTableData />
    )

    return <>{tableData}</>
  }

  renderTableData = () => {
    const RenderTableTitle = this.handleTableTitleElement
    const RenderTableData = this.handleGenerateTableData
    return (
      <table className="ea_table table-hover table-dark">
        <thead>
          <tr>
            <RenderTableTitle />
          </tr>
        </thead>
        <tbody>
          <RenderTableData />
        </tbody>
      </table>
    )
  }
  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.routeTextTertiary, active: false, path: '/main' },
      { label: constants.routeTextSecodary, active: false, path: '/main' },
      {
        label: constants.routeTextActive,
        active: true,
        path: '/webbankManagement'
      }
    ]

    const RenderTableData = this.renderTableData
    return (
      <div
        className="container-fluid pt-5 px-5 page-container"
        ref={this.pageContainerRef}
      >
        <div className="row">
          <div className="col-12 pt-5 mt-2">
            <Breadcrumb
              items={navigates}
              handleOnClickItem={this.handleOnClickBreadcrumb}
            />
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-12">
            <ALink
              id="backto-previus-page"
              color={colors.SECONDARY_RED}
              bold
              onClick={this.handleOnBack}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              &nbsp;{constants.back}
            </ALink>
          </div>
        </div>
        <div className="row mt-3 justify-content-end content-wrapper shadow-lg bg-dark rounded">
          <div className="col-12 m3-t p3-l">
            <span className="body-1" style={{ fontSize: '1.5rem' }}>
              {constants.labelSection}
            </span>
          </div>
          <div className="col-12 mb-1 detail-wrapper">
            <SimpleBar
              className="scroll"
              style={{ overflowY: 'hidden', width: '100%' }}
            >
              <div className="col-12 py-2 mt-3 detail-content">
                <RenderTableData />
              </div>
            </SimpleBar>
          </div>
        </div>
      </div>
    )
  }
}

export default WebBankManagementContainer
