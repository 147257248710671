import { Login } from './Login'
import { Main } from './Main'
import { UserInfo } from './UserInfo'
import { CreditInfo } from './CreditInfo'
import { ComingSoon } from './ComingSoon'
import { PartnerControllers } from './PartnerControllers'
import { LotteryManagement } from './LotteryManagement'
import { TransactionManagement } from './TransactionManagement'
import { WebBankManagement } from './WebBankManagement'

const containers = {
    Login,
    Main,
    UserInfo,
    CreditInfo,
    PartnerControllers,
    ComingSoon,
    LotteryManagement,
    TransactionManagement,
    WebBankManagement,
}

export default containers