import { combineReducers } from 'redux'
import authentication from './authentication/reducers'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { loader } from './loader/reducers'
import user from './users/reducers'
import partner from './partners/reducers'
import credit from './credits/reducers'
import lotto from './lotto/reducers'
import betTransaction from './betTransaction/reducers'
import config from './config/reducers'
import webbank from './Webbank/reducers'
import environment from 'constants/environment'

const persistConfig = {
    key: environment.name,
    storage,
    whitelist: ['authentication'],
}

const authenPersistConfig = {
    key: 'authentication',
    storage,
}

const rootReducers = {
    authentication: persistReducer(authenPersistConfig, authentication),
    loader,
    user,
    partner,
    credit,
    lotto,
    betTransaction,
    config,
    webbank
}

export default persistReducer(
    persistConfig,
    combineReducers(rootReducers)
)