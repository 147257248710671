import React, { FC } from 'react'
import { noop } from 'lodash'
import colors from 'constants/colors'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import './BackButton.style.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const constants = {
  back: 'ย้อนกลับ'
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IBackButtonProps = {
  text: constants.back,
  titleText: '',
  color: colors.TERTIARY_RED,
  onClick() { noop() },
}

const BackButton: FC<IBackButtonProps & DefaultProps> = (props) => {
  const { text, titleText, color } = props
  return (
    <div className="back-button-container">
      <span className='back-text scale' style={{ color: color }} onClick={props.onClick}>
        <FontAwesomeIcon icon={faChevronLeft} className='mr-1' />
        {text}
      </span>
      <span className="title-text primary-white-text scale">
        {titleText}
      </span>
    </div>
  )
}

BackButton.defaultProps = defaultProps
export default BackButton