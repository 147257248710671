import { AxiosError, AxiosResponse } from 'axios'
import { combineReducers } from 'redux'
import { ActionType, createReducer } from 'typesafe-actions'
import { responseConverter } from 'utils'
import * as partnerAction from './actionCreator'
import { partnerState, putPartnerState, partnerScheduleState, putPartnerScheduleState } from './constants'

export type PartnerActionType = ActionType<typeof partnerAction>

const getAllPartner = createReducer<ReducerState<IPartnerConfig>, PartnerActionType>(partnerState)
    .handleAction(partnerAction.getAllPartnersAction.request, (state: ReducerState<IPartnerConfig>) => {
        return {
            ...state,
            isFetching: true,
        }
    })
    .handleAction(partnerAction.getAllPartnersAction.success, (state: ReducerState<IPartnerConfig>, action: PartnerActionType) => {
        const payload: AxiosResponse<IAPIResponse<IPartnerConfig>> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data: payload.data.data,
        }
    })
    .handleAction(partnerAction.getAllPartnersAction.failure, (state: ReducerState<IPartnerConfig>, action: PartnerActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message
        }
    })
    .handleAction(partnerAction.getAllPartnersAction.cancel, () => partnerState)

const getPartnerSchedule = createReducer<ReducerState<IPartnerScheduleConfig[]>, PartnerActionType>(partnerScheduleState)
    .handleAction(partnerAction.getPartnerSchedulesAction.request, (state: ReducerState<IPartnerScheduleConfig[]>) => {
        return {
            ...state,
            isFetching: true,
        }
    })
    .handleAction(partnerAction.getPartnerSchedulesAction.success, (state: ReducerState<IPartnerScheduleConfig[]>, action: PartnerActionType) => {
        const payload: AxiosResponse<IAPIResponse<IPartnerScheduleConfig[]>> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data: payload.data.data,
        }
    })
    .handleAction(partnerAction.getPartnerSchedulesAction.failure, (state: ReducerState<IPartnerScheduleConfig[]>, action: PartnerActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message
        }
    })
    .handleAction(partnerAction.getPartnerSchedulesAction.cancel, () => partnerScheduleState)

const putPartner = createReducer<ReducerState<IGameConfig>, PartnerActionType>(putPartnerState)
    .handleAction(partnerAction.updatePartnerAction.request, (state: ReducerState<IGameConfig>) => {
        return {
            ...state,
            isFetching: true,
        }
    })
    .handleAction(partnerAction.updatePartnerAction.success, (state: ReducerState<IGameConfig>, action: PartnerActionType) => {
        const payload: AxiosResponse<IAPIResponse<IGameConfig>> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)

        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data: payload.data.data,
        }
    })
    .handleAction(partnerAction.updatePartnerAction.failure, (state: ReducerState<IGameConfig>, action: PartnerActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message,
        }
    })
    
    const putPartnerSchedule = createReducer<ReducerState<IPartnerScheduleConfig[]>, PartnerActionType>(putPartnerScheduleState)
    .handleAction(partnerAction.updatePartnerScheduleAction.request, (state: ReducerState<IPartnerScheduleConfig[]>) => {
        return {
            ...state,
            isFetching: true,
        }
    })
    .handleAction(partnerAction.updatePartnerScheduleAction.success, (state: ReducerState<IPartnerScheduleConfig[]>, action: PartnerActionType) => {
        const payload: AxiosResponse<IAPIResponse<IPartnerScheduleConfig[]>> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)

        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data: payload.data.data,
        }
    })
    .handleAction(partnerAction.updatePartnerScheduleAction.failure, (state: ReducerState<IPartnerScheduleConfig[]>, action: PartnerActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message,
        }
    })
    // 

export default combineReducers({
    list: getAllPartner,
    schedule: getPartnerSchedule,
    put: putPartner,
    putSchedule: putPartnerSchedule,
})