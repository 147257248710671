import { ActionType, createReducer } from 'typesafe-actions'
import * as LoaderAction from './actionCreator'
import { loaderState } from './constants'

export type LoaderActionType = ActionType<typeof LoaderAction>

export const loader = createReducer<any, LoaderActionType>(loaderState)
    .handleAction(LoaderAction.loaderAction.request, (state: boolean = false, action: LoaderActionType) => {
    return ( {loader: true} )
    })
    .handleAction(LoaderAction.loaderAction.success, (state: boolean = false, action: LoaderActionType) => {
      return ( {loader: false} )  
      })


