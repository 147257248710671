import DateFnsUtils from '@date-io/date-fns'
import { ThemeProvider, createTheme, alpha } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ButtonIcon } from 'components'
import errorModal from 'components/Modal/components/Error/Error.component'
import colors from 'constants/colors'
import { enUS } from 'date-fns/locale'
import { Form, FormikProps } from 'formik'
import { isEqual } from 'lodash'
import moment from 'moment'
import React from 'react'
import { useState } from 'react'

const constant = {
    placeHolderStartTime: 'StartTime',
    placeHolderEndTime: 'EndTime',
    errorValidate: 'End Time should be greater than Start Time',
    back: 'Back'
}
const dafaultTheme = createTheme({
    palette: {
        primary: red,
        background: {
            paper: alpha(colors.SECONDARY_BG, 1),
            default: alpha(colors.PRIMARY_RED,1),
        },
        text: {
            primary: alpha(colors.PRIMARY_TEXT,1),
            secondary: alpha(colors.PRIMARY_RED,1),
            hint: alpha(colors.SECONDARY_TEXT,1),
        },
        divider: alpha(colors.PRIMARY_RED,1),
        type: 'dark',
    },
})

const DateRangePickerForm = (props: FormikProps<IPartnerDateRange>) => {
    const [addSchedule, setSchedule] = useState<boolean>(true)
    const [errorDesc, setErrorDesc] = useState<any>('')
    const {
        values,
        errors,
        handleSubmit,
        setFieldValue,
    } = props

    const onSubmit = () => {
        const mapTime = (date: Date) => moment(date).format()
        if (!isEqual(mapTime(values.startTime), mapTime(values.endTime)) && mapTime(values.startTime) < mapTime(values.endTime) && values.saved === false) {
            if(mapTime(new Date()) < mapTime(values.startTime)){
                values.saved = true
                return handleSubmit()
            }
            errorModal.show({
                description: errorDesc ? errorDesc : constant.errorValidate,
                actionText: constant.back,
                action: errorModal.hide
            })
        }
        else if (mapTime(values.startTime) > mapTime(values.endTime) && values.saved === false) {
            errorModal.show({
                description: errorDesc ? errorDesc : constant.errorValidate,
                actionText: constant.back,
                action: errorModal.hide
            })
        }
        else {
            if (values.saved) {
                values.saved = false
                return handleSubmit()
            } else {
                return setSchedule(!addSchedule);
            }
        }
    }
    return (
        <Form>
            <div className="row m1-t">
                <div className="col-12 d-flex">
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enUS}>
                        <ThemeProvider theme={dafaultTheme}>
                            <DateTimePicker
                                disabled={addSchedule || values.saved}
                                name="startTime"
                                disablePast
                                format={`dd MMM yyyy HH:mm`}
                                ampm={false}
                                minDate={values.startTime}
                                label={constant.placeHolderStartTime}
                                inputVariant="outlined"
                                minutesStep={1}
                                value={values.startTime}
                                onChange={data => setFieldValue('startTime', data)}
                                error={!!errors.startTime}
                                onError={(err) => setErrorDesc(err)}
                                helperText={false}
                            />
                            <h4 className="align-self-center mx-1">{`~`}</h4>
                            <DateTimePicker
                                disabled={addSchedule || values.saved}
                                name="endTime"
                                minutesStep={1}
                                minDate={values.startTime}
                                format={`dd MMM yyyy HH:mm`}
                                ampm={false}
                                label={constant.placeHolderEndTime}
                                inputVariant="outlined"
                                value={values.endTime}
                                onChange={data => setFieldValue('endTime', data)}
                                error={!!errors.endTime}
                                onError={(err) => setErrorDesc(err)}
                                helperText={false}
                                
                            />
                            <div className="align-self-center m1-l" style={{ display: values.saved ? 'none' : 'unset' }}>
                                <ButtonIcon
                                    id={`submit`}
                                    type={addSchedule ? 'add' : 'save'}
                                    onClick={onSubmit}
                                    color={colors.PRIMARY_GREEN}
                                />
                            </div>
                            <div className="align-self-baseline m1-l" style={{ display: !values.saved ? 'none' : 'unset' }}>
                                <ButtonIcon
                                    id={`add`}
                                    type={'close'}
                                    onClick={onSubmit}
                                    color={colors.PRIMARY_RED}
                                />
                            </div>
                        </ThemeProvider>
                    </MuiPickersUtilsProvider>
                </div>
            </div>
        </Form>
    )
}

export default DateRangePickerForm
