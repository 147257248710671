import { DECIMAL_DITGITS, COMMA } from 'constants/regex'
import { padStart } from 'lodash'

const castToInteger = (numberString: string = '') => {
  const result = numberString.replace(COMMA, '').replace(DECIMAL_DITGITS, '')
  return result
}

const padNumber = (value: string, ditgit: number) => {
  const padString = '0'
  return padStart(value, ditgit, padString)
}

const castToMoney = (value: number, spacing?: boolean) => {
  const locals = 'th-TH'
  const currency = 'THB'
  const money = new Intl.NumberFormat(locals, {
    style: 'currency',
    currency
  }).format(value || 0)
  if (spacing) {
    return money.replace(/^(\D+)/, '$1 ')
  }
  return money
}
const decimalsFormatComma = (data: string | number, Digits: number = 2) => {
  return Intl.NumberFormat('en-US', {
    minimumFractionDigits: Digits,
    maximumFractionDigits: Digits
  }).format(Number(data))
}
const addComma = (value: number) =>
  ` ${new Intl.NumberFormat('th-TH').format(value || 0)} `

const number = {
  castToInteger,
  castToMoney,
  padNumber,
  decimalsFormatComma,
  addComma
}

export default number
