import { createAsyncAction } from 'typesafe-actions'
import {
  POST_LOTTO_RESULT_REQUEST,
  POST_LOTTO_RESULT_SUCCESS,
  POST_LOTTO_RESULT_FAILURE,
  POST_LOTTO_REVERSE_REQUEST,
  POST_LOTTO_REVERSE_SUCCESS,
  POST_LOTTO_REVERSE_FAILURE,
  POST_LOTTO_CURRENT_RESULT_REQUEST,
  POST_LOTTO_CURRENT_RESULT_SUCCESS,
  POST_LOTTO_CURRENT_RESULT_FAILURE
} from './constants'
import { AxiosResponse, AxiosError } from 'axios'

const postLottoResultAction = createAsyncAction(
  POST_LOTTO_RESULT_REQUEST,
  POST_LOTTO_RESULT_SUCCESS,
  POST_LOTTO_RESULT_FAILURE
)<
  any,
  AxiosResponse<IAPIResponse<ILotterySchedule[]>>,
  AxiosError<IAPIResponse>
>()

const postLottoReverseAction = createAsyncAction(
  POST_LOTTO_REVERSE_REQUEST,
  POST_LOTTO_REVERSE_SUCCESS,
  POST_LOTTO_REVERSE_FAILURE
)<any, AxiosResponse<IAPIResponse<null>>, AxiosError<IAPIResponse>>()

const postLottoCurrentResultAction = createAsyncAction(
  POST_LOTTO_CURRENT_RESULT_REQUEST,
  POST_LOTTO_CURRENT_RESULT_SUCCESS,
  POST_LOTTO_CURRENT_RESULT_FAILURE
)<
  any,
  AxiosResponse<IAPIResponse<IResponseLotterCurrentResult>>,
  AxiosError<IAPIResponse>
>()

export {
  postLottoResultAction,
  postLottoReverseAction,
  postLottoCurrentResultAction
}
