import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faMoneyBill, faPhone, faUniversity, faWallet } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ButtonIcon, InputSelect, InputText, SelectorItem } from 'components'
import { FormikProps } from 'formik'
import { get, keys, map } from 'lodash'
import React, { Component } from 'react'
import './updateUserForm.style.scss'
import colors from '../../../../constants/colors'
import { BANKS } from 'constants/variables'
const mapIcon: { [key in handleFormMenu]: IconProp } = {
    affilateMeUuid: faWallet,
    phoneNumber: faPhone,
    userBankName: faUniversity,
    userBankNumber: faMoneyBill,
    userBankType: faUniversity,
}
const mapMenu: { [key in handleFormMenu]: string } = {
    affilateMeUuid: 'Affilate Host',
    phoneNumber: 'Phone',
    userBankName: 'BankName',
    userBankNumber: 'BankNumber',
    userBankType: 'BankType',
}
const mapMaxLenght: { [key in handleFormMenu]: number } = {
    affilateMeUuid: 200,
    phoneNumber: 10,
    userBankName: 100,
    userBankNumber: 12,
    userBankType: 100,
}

const constants = {
    placeholderInput: (type: string) => `ระบุ${type}`.slice(0, -1),
    selectBankText: 'เลือกธนาคาร *',
    placeholderSelectBankText: 'ธนาคาร *',
}
export default class UpdateUserFormComponent extends Component<FormikProps<IUpdateUser>, IUserUpdateFormState> {

    state: IUserUpdateFormState = {
        initialUpdateUserForm: {
            affilateMeUuid: '',
            phoneNumber: '',
            userBankName: '',
            userBankNumber: '',
            userBankType: '',
        },
        isSetting: {
            affilateMeUuid: true,
            phoneNumber: true,
            userBankName: true,
            userBankNumber: true,
            userBankType: true,
        },
        isButton: '',

    }
    componentDidUpdate(prevProps: FormikProps<IUpdateUser>) {
        if (this.props.initialValues.id !== prevProps.initialValues.id && this.props.initialValues.id) {
            this.setState((_,props) =>({
                initialUpdateUserForm: {
                    affilateMeUuid: props.initialValues.affilateMeUuid!,
                    phoneNumber: props.initialValues.phoneNumber,
                    userBankName: props.initialValues.userBankName,
                    userBankNumber: props.initialValues.userBankName,
                    userBankType: props.initialValues.userBankType!,
                },
                isSetting: {
                    affilateMeUuid: true,
                    phoneNumber: true,
                    userBankName: true,
                    userBankNumber: true,
                    userBankType: true,
                },
                isButton: ''
            }))
        }
    }
    handleSwitch = (switching: handleFormMenu) =>
        this.setState(prevState => ({
            isButton: prevState.isButton !== switching ? switching : '',
            isSetting: {
                ...prevState.isSetting,
                [switching]: !prevState.isSetting[switching],
            },
        }), () => {this.props.dirty && this.state.isButton === '' && this.props.handleSubmit()})

    renderBankOption = ({ item, ...selectProps }: IInputDefaultSelectProps<ICBank>) =>
        (<SelectorItem icon={item.Icon} title={item.name} {...selectProps} />)

    renderUpdateForm = (values: IUserUpdateFormTemp) =>
        map(keys(values), (key: handleFormMenu, index) =>
            <div className={`map-form ${key !== 'userBankType' ? 'col-12 col-lg-6 col-md-12' : 'col-12'}`} key={`form-${index}`}>
                <div className="mr-3 map-icon text-left">
                    <FontAwesomeIcon color={colors.PRIMARY_RED} icon={mapIcon[key]} />
                </div>
                <div className="w-100">
                    {key !== 'userBankType' ? (
                        <InputText
                            disabled={this.state.isSetting[key]}
                            key={index}
                            name={key}
                            onChange={this.props.handleChange}
                            value={get(this.props.values, [key], '')}
                            setFieldValue={this.props.setFieldValue}
                            type={'text'}
                            errorMessage={this.props.errors[key]}
                            maxLenght={mapMaxLenght[key]}
                            label={mapMenu[key]}
                            placeholder={mapMenu[key]}
                            error={!!this.props.errors[key] && this.props.touched[key]}
                        />
                    ) : (
                        <InputSelect<ICBank>
                            disabled={this.state.isSetting[key]}
                            key={index}
                            name={key}
                            items={BANKS}
                            valueKey="value"
                            value={get(this.props.values, [key], '')}
                            onChange={(selected, name) => {
                                this.props.setFieldValue(name, selected.value)
                            }}
                            placeholder={constants.placeholderInput(constants.placeholderSelectBankText)}
                            RenderSelected={this.renderBankOption}
                        />
                    )}

                </div>
                <div className={
                    `align-self-center 
                            ${!this.props.values[key] || this.props.errors[key] ? `hide` : ``}
                            ${this.state.isButton !== '' && this.state.isButton !== key ? `hide` : ``}
                            ${this.state.initialUpdateUserForm.affilateMeUuid === `` ? `hide` : ``}
                            `
                }>
                    {key !== 'userBankType' ? (
                        <ButtonIcon
                            id={`submit-${key}`}
                            type={this.state.isSetting[key] ? 'setting' : 'save' && this.props.dirty ? 'save' : 'close'}
                            onClick={() => this.handleSwitch(key)}
                        />
                    ) : (
                        <ButtonIcon
                            id={`submit-${key}`}
                            type={this.state.isSetting[key] ? 'setting' : 'save' && this.props.dirty ? 'save' : 'close'}
                            onClick={() => this.handleSwitch(key)}
                        />
                    )}
                </div>
            </div>
        )

    render() {
        const RenderUpdateForm = this.renderUpdateForm(this.state.initialUpdateUserForm)
        return (
            <div className="row">
                {RenderUpdateForm}
            </div>
        )
    }
}
