import { Dispatch } from 'redux'
import { setAuthenticationAction } from '../actionCreator'
import { responseCode } from '../../../constants/response'


const setToken = (tokenData: ILogin) => (dispatch: Dispatch) => {
    dispatch(setAuthenticationAction.request({}))
    try {
        dispatch(setAuthenticationAction.success(tokenData))
    } catch {
        dispatch(setAuthenticationAction.failure(responseCode.BAD_REQUEST))
    }
}

export {
    setToken,
}