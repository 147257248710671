import axios from 'axios'
import environment from 'constants/environment'
import { transformer } from 'utils'

const lotterBetEndpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.lotterBet}`
const betReadEndpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.getBetRead}`

const fetGetLotterBet = (query: IQueryBetUser & IQueryPagination) => axios.get(`${lotterBetEndpoint}${transformer.urlSearchParams(query)}`)
const fetPutLotterBet = (body: IUpdateBetUser) => axios.put(`${lotterBetEndpoint}`, body)

const fetGetBetRead = (query: IV1ReadBetTx & IQueryPagination) => axios.get(`${betReadEndpoint}${transformer.urlSearchParams(query)}`)

export {
    fetGetLotterBet,
    fetPutLotterBet,
    fetGetBetRead,
}