export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

export const VERYFY_TOKEN = 'VERYFY_TOKEN'

export const SET_AUTHENTINATION_KEY_REQUEST = 'SET_AUTHENTINATION_KEY_REQUEST'
export const SET_AUTHENTINATION_KEY_SUCCESS = 'SET_AUTHENTINATION_KEY_SUCCESS'
export const SET_AUTHENTINATION_KEY_FAILURE = 'SET_AUTHENTINATION_KEY_FAILURE'

export const loginState: ReducerState<ILogin> = {
    isFetching: false,
    code: 0,
    data: {
        token: ''
    },
    error: ''
}

export const tokenState: IToken = {
    accessToken: '',
    refreshToken: '',
}