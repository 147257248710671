import { AxiosError, AxiosResponse } from 'axios'
import { combineReducers } from 'redux'
import { ActionType, createReducer } from 'typesafe-actions'
import { responseConverter } from '../../utils'
import { webbankObserverState, webbankPostState } from './constants'
import * as webbankAction from './actionCreator'

export type webbankActionType = ActionType<typeof webbankAction>

const getWebBankObserver = createReducer<ReducerState<IPagination<IResponseWebbankObserver>>, webbankActionType>(webbankObserverState)
    .handleAction(webbankAction.getWebbankObserverAction.request, (state: ReducerState<IPagination<IResponseWebbankObserver>>) => {
        return {
            ...state,
            isFetching: true,
        }
    })
    .handleAction(webbankAction.getWebbankObserverAction.success, (state: ReducerState<IPagination<IResponseWebbankObserver>>, action: ActionType<typeof webbankAction.getWebbankObserverAction.success>) => {
        const payload: AxiosResponse<IAPIResponse<IPagination<IResponseWebbankObserver>>> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        const data: IPagination<IResponseWebbankObserver> = (action.meta)
            ? payload.data.data
            : ({
                ...payload.data.data,
                dataList: [
                    ...(state.data.dataList || []),
                    ...(payload.data.data.dataList || [])
                ]
            })
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data,
        }
    })
    .handleAction(webbankAction.getWebbankObserverAction.failure, (state: ReducerState<IPagination<IResponseWebbankObserver>>, action: webbankActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message,
        }
    })
    .handleAction(webbankAction.getWebbankObserverAction.cancel, () => webbankObserverState)

const postWebbankSwitching = createReducer<ReducerState<{}>, webbankActionType>(webbankPostState)
    .handleAction(webbankAction.postWebbankSwitchingAction.request, (state: ReducerState<{}>) => {
        return {
            ...state,
            isFetching: true,
        }
    })
    .handleAction(webbankAction.postWebbankSwitchingAction.success, (state: ReducerState<{}>, action: webbankActionType) => {
        const payload: AxiosResponse<IAPIResponse<IUpdateBetUser>> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)

        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data: payload.data.data,
        }
    })
    .handleAction(webbankAction.postWebbankSwitchingAction.failure, (state: ReducerState<{}>, action: webbankActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message,
        }
    })

    const postWebbankReset = createReducer<ReducerState<{}>, webbankActionType>(webbankPostState)
    .handleAction(webbankAction.postWebbankResetAction.request, (state: ReducerState<{}>) => {
        return {
            ...state,
            isFetching: true,
        }
    })
    .handleAction(webbankAction.postWebbankResetAction.success, (state: ReducerState<{}>, action: webbankActionType) => {
        const payload: AxiosResponse<IAPIResponse<IUpdateBetUser>> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)

        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data: payload.data.data,
        }
    })
    .handleAction(webbankAction.postWebbankResetAction.failure, (state: ReducerState<{}>, action: webbankActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message,
        }
    })

export default combineReducers({
    getWebBankObserver,
    postWebbankSwitching,
    postWebbankReset
})