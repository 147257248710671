import React, { FC, useState } from 'react'
import { noop } from 'lodash'
import './inputCheckbox.style.scss'
import { INPUT } from 'constants/styles'

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IInputCheckbox = {
  name: '',
  label: '',
  checked: false,
  value: '',
  onBlur() { noop() },
  onChange() { noop() },
  isTextInBox: false,
  boxHoverColor: '',
}

const InputCheckbox: FC<IInputCheckbox & DefaultProps> = (props) => {
  const {
    name,
    label,
    checked,
    value,
    onBlur,
    onChange,
    isTextInBox,
    boxHoverColor,
  } = props

  const [isHover, setIsHover] = useState(false)
  const wrapperClass = `input-checkbox ${isTextInBox && 'text-in-box'}`
  const backgroundColor = 
    isTextInBox
    ? isHover || checked
      ? boxHoverColor
      : ''
    : ''
  const borderColor = 
    isTextInBox
    ? isHover || checked
      ? boxHoverColor
      : ''
    : ''
  const height = INPUT.HEIGHT
  
  return (
    <label
      className={wrapperClass}
      onMouseOver={() => {
        setIsHover(true)
      }}
      onMouseUp={() => {
        setIsHover(false)
      }}
      onMouseLeave={() => {
        setIsHover(false)
      }}
      style={{ backgroundColor, borderColor, height }}
    >
      <span className="checkbox-label">{label}</span>
      <input
        type="checkbox"
        className="core-checkbox"
        name={name}
        checked={checked}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
      />
      <span className="checkbox-checkmark" />
    </label>
  )
}

InputCheckbox.defaultProps = defaultProps

export default InputCheckbox