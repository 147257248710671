import axios from 'axios'
import thunk from 'redux-thunk'
import rootReducers from '../reduxs/reducers'
import { persistStore } from 'redux-persist'
import axiosMiddleware from '../middleware/axios'
import environment from '../constants/environment'
import { createStore, applyMiddleware, Store } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'



const enhancer = [axiosMiddleware, thunk.withExtraArgument(axios)]

const composedEnhancer = (environment.name !== 'production')
    ? composeWithDevTools(applyMiddleware(...enhancer))
    : applyMiddleware(...enhancer)

export default function configureStore(initialState?: RootReducers) {
    //@ts-ignore
    const store:Store = createStore(
      rootReducers,
      {},
      composedEnhancer
    )
    const persistor = persistStore(store)
    return { store, persistor }
  }