import { connect } from 'react-redux'
import RootContainer from './Root.container'
import { logout } from '../../reduxs/authentication/logout/action'
import { login } from '../../reduxs/authentication/login/action'
import users from 'reduxs/users/actions'
import { verifyToken } from '../../reduxs/authentication/verify/action'
import authSelector from '../../reduxs/authentication/selector'
import userSelector from 'reduxs/users/selector'

const mapStateToProps = (state: RootReducers): IRootProps => {
  const meUser = userSelector.getMeUser(state)
  const authenState = authSelector.token(state)
  return {
    userMe: meUser.data,
    accessToken: authenState.accessToken,
    textRunning: 'Welcome to OMEGA Technical Support'
  }
}

const mapDispatchToProps: IRootActionProps = {
  login: login,
  logout: logout,
  verifyAccess: verifyToken,
  getMeUser: users.getMeUser,
  clearMeUser: users.clearMeUser
}
export default connect(mapStateToProps, mapDispatchToProps)(RootContainer)
