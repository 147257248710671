import { AxiosError, AxiosResponse } from 'axios'
import { combineReducers } from 'redux'
import { ActionType, createReducer } from 'typesafe-actions'
import { responseConverter } from '../../utils'
import * as userAction from './actionCreator'
import {
    allUserState,
    IGetMeUserState,
    updateUserState,
    allUserAffilateState,
    statUserReadState,
} from './constants'

export type UserActionType = ActionType<typeof userAction>

const getAllUser = createReducer<ReducerState<IPagination<IUser>>, UserActionType>(allUserState)
    .handleAction(userAction.getAllUserAction.request, (state: ReducerState<IPagination<IUser>>) => {
        return {
            ...state,
            isFetching: true,
        }
    })
    .handleAction(userAction.getAllUserAction.success, (state: ReducerState<IPagination<IUser>>, action: ActionType<typeof userAction.getAllUserAction.success>) => {
        const payload: AxiosResponse<IAPIResponse<IPagination<IUser>>> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        const data: IPagination<IUser> = (action.meta)
            ? payload.data.data
            : ({
                ...payload.data.data,
                dataList: [
                    ...(state.data.dataList || []),
                    ...(payload.data.data.dataList || [])
                ]
            })
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data,
        }
    })
    .handleAction(userAction.getAllUserAction.failure, (state: ReducerState<IPagination<IUser>>, action: UserActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message,
        }
    })
    .handleAction(userAction.getAllUserAction.cancel, () => allUserState)

// 
const getAllUserAffilate = createReducer<ReducerState<IPagination<IUserAffilate, IAffilateTansaction, IUserAffilate>>, UserActionType>(allUserAffilateState)
    .handleAction(userAction.getAllUserAffilateAction.request, (state: ReducerState<IPagination<IUserAffilate, IAffilateTansaction, IUserAffilate>>) => {
        return {
            ...state,
            isFetching: true,
        }
    })
    .handleAction(userAction.getAllUserAffilateAction.success, (state: ReducerState<IPagination<IUserAffilate, IAffilateTansaction, IUserAffilate>>, action: ActionType<typeof userAction.getAllUserAffilateAction.success>) => {
        const payload: AxiosResponse<IAPIResponse<IPagination<IUserAffilate, IAffilateTansaction, IUserAffilate>>> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        const data: IPagination<IUserAffilate, IAffilateTansaction, IUserAffilate> = (action.meta)
            ? payload.data.data
            : ({
                ...payload.data.data,
                dataList: [
                    ...(state.data.dataList || []),
                    ...(payload.data.data.dataList || [])
                ]
            })
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data,
        }
    })
    .handleAction(userAction.getAllUserAffilateAction.failure, (state: ReducerState<IPagination<IUserAffilate, IAffilateTansaction, IUserAffilate>>, action: UserActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message,
        }
    })
    .handleAction(userAction.getAllUserAffilateAction.cancel, () => allUserAffilateState)
// 

const getMeUser = createReducer<ReducerState<IUserMe>, UserActionType>(IGetMeUserState)
    .handleAction(userAction.getMeUserAction.request, (state: ReducerState<IUserMe>) => {
        return {
            ...state,
            isFetching: true,
        }
    })
    .handleAction(userAction.getMeUserAction.success, (state: ReducerState<IUserMe>, action: UserActionType) => {
        const payload: AxiosResponse<IAPIResponse<IUserMe>> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data: payload.data.data,
        }
    })
    .handleAction(userAction.getAllUserAction.failure, (state: ReducerState<IUserMe>, action: UserActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message,
        }
    })
    .handleAction(userAction.getMeUserAction.cancel, () => IGetMeUserState)


const updateUser = createReducer<ReducerState<IUpdateUser>, UserActionType>(updateUserState)
    .handleAction(userAction.updateUserAction.request, (state: ReducerState<IUpdateUser>) => {
        return {
            ...state,
            isFetching: true,
        }
    })
    .handleAction(userAction.updateUserAction.success, (state: ReducerState<IUpdateUser>, action: UserActionType) => {
        const payload: AxiosResponse<IAPIResponse<IUpdateUser>> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)

        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data: payload.data.data,
        }
    })
    .handleAction(userAction.updateUserAction.failure, (state: ReducerState<IUpdateUser>, action: UserActionType) => {

        const payload: AxiosError<IAPIResponse> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message,
        }
    })

const getStatUserRead = createReducer<ReducerState<IPagination<IV1RespReadStatsUser>>, UserActionType>(statUserReadState)
    .handleAction(userAction.getStatUserReadAction.request, (state: ReducerState<IPagination<IV1RespReadStatsUser>>) => {
        return {
            ...state,
            isFetching: true,
        }
    })
    .handleAction(userAction.getStatUserReadAction.success, (state: ReducerState<IPagination<IV1RespReadStatsUser>>, action: ActionType<typeof userAction.getStatUserReadAction.success>) => {
        const payload: AxiosResponse<IAPIResponse<IPagination<IV1RespReadStatsUser>>> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data: payload.data.data
        }
    })
    .handleAction(userAction.getStatUserReadAction.failure, (state: ReducerState<IPagination<IV1RespReadStatsUser>>, action: UserActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message,
        }
    })
    .handleAction(userAction.getStatUserReadAction.cancel, () => statUserReadState)

export default combineReducers({
    list: getAllUser,
    updated: updateUser,
    getMe: getMeUser,
    getUserAffilate: getAllUserAffilate,
    getStatUserRead: getStatUserRead,
})