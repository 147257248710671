import { get } from 'lodash'
import {
  initialLottoCurrentResultState,
  initialLottoResultState,
  initialLottoReverseState
} from './constants'

const lottoResult = (state: RootReducers): ReducerState<ILotterySchedule[]> =>
  get(state, 'config.lotto.result', initialLottoResultState)

const lottoReverse = (state: RootReducers): ReducerState<null> =>
  get(state, 'config.lotto.reverse', initialLottoReverseState)

const lottoCurrentResult = (
  state: RootReducers
): ReducerState<IResponseLotterCurrentResult> =>
  get(state, 'config.lotto.currentResult', initialLottoCurrentResultState)

const configLottoSelector = {
  lottoResult,
  lottoReverse,
  lottoCurrentResult
}

export default configLottoSelector
