const routes = {
  redirected: {
    name: "redirected",
    path: "/redirected",
    exactPath: "/redirected",
  },
  notFound: { name: "404", path: "*", exactPath: "*" },
  login: { name: "login", path: "/", exactPath: "/" },
  main: { name: "main", path: "/main", exactPath: "/main" },
  userInfo: { name: "userInfo", path: "/userinfo", exactPath: "/userinfo" },
  creditInfo: { name: "creditInfo", path: "/creditInfo", exactPath: "/creditInfo" },
  partnerControllers: {
    name: "partnerControllers",
    path: "/partner-controllers",
    exactPath: "/partner-controllers",
  },
  lotteryManagement: {
    name: "lotteryManagement",
    path: "/lottery-management",
    exactPath: "/lottery-management",
  },
  transactionManagement: {
    name: "transactionManagement",
    path: "/transactionManagement",
    exactPath: "/transactionManagement",
  },
  webbankManagement: {
    name: "webbankManagement",
    path: "/webbankManagement",
    exactPath: "/webbankManagement",
  }
};

export default routes;
