import { string, object, ObjectSchema, number } from 'yup'
import { ENG, LOWERCASE, NUMBER } from '../../../constants/regex'

const constants = {
    phoneNumber: 'กรุณากรอกหมายเลขโทรศัพท์ 10 หลัก',
    onlyNumber: 'กรุณากรอกเฉพาะตัวเลขเท่านั้น',
    username: 'กรุณากรอกชื่อผู้ใช้',
    lowercase: 'กรุณากรอกเฉพาะตัวหนังสือตัวเล็ก',
    englishRequire: 'กรุณากรอกเฉพาะตัวหนังสือภาษาอังกฤษและตัวเลข',
    usernameMinLength: 'กรุณากรอกชื่อผู้ใช้ 6 ตัวขึ้นไป',
    bankNumber: 'กรุณากรอกหมายเลขบัญชีธนาคาร',
    bankNumberLimit: 'กรุณากรอกหมายเลขบัญชีธนาคารไม่เกิน 12 หลัก',
    ownerName: 'กรุณากรอกชื่อ - นามสกุลเจ้าของบัญชี',
    TypeName: 'กรุณาระบุธนาคาร',
}

const scheme: ObjectSchema<any> = object().shape({
    id: number()
        .required(constants.onlyNumber),
    affilateMeUuid: string(),
    userBankName: string().required(constants.ownerName),
    userBankType: string().required(constants.ownerName),
    userBankNumber: string()
        .max(12, constants.bankNumberLimit)
        .required(constants.bankNumber)
        .matches(NUMBER, constants.onlyNumber),
    phoneNumber: string()
        .length(10, constants.phoneNumber)
        .required(constants.phoneNumber)
        .matches(NUMBER, constants.onlyNumber),
    username: string()
        .min(6, constants.usernameMinLength)
        .matches(ENG, constants.englishRequire)
        .matches(LOWERCASE, constants.lowercase)
        .required(constants.username),
})

export default scheme