import axios from 'axios'
import environment from '../../constants/environment'
import { transformer } from '../../utils'

const getAllUserEndpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.getAllUser}`
const getMeUserEndpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.getMeUser}`
const updateUserEndpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.updateUser}`
const getAllUserAffilateEndpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.getUserAffilate}`
const getStatsUserReadEndpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.getStatsUserRead}`
const cancel = axios.CancelToken.source()

const fetGetAllUserAffilate = (query: IGetAffilateUserQuery) => axios.get(`${getAllUserAffilateEndpoint}${transformer.urlSearchParams(query)}`)
const fetchGetAllUser = (query: IUserQuery) => axios.get(`${getAllUserEndpoint}${transformer.urlSearchParams(query)}`, {cancelToken: cancel.token})
const fetchGetMeUser = () => axios.get(`${getMeUserEndpoint}`)
const fetchUpdateUser = (query: IUserQuery, data: IUpdateUser) => axios.put(`${updateUserEndpoint}${transformer.urlSearchParams(query)}`, data)
const fetchStatUserRead = (query: IV1ReadStatsUser) => axios.get(`${getStatsUserReadEndpoint}${transformer.urlSearchParams(query)}`)

export {
    fetchGetAllUser,
    fetchUpdateUser,
    fetchGetMeUser,
    fetGetAllUserAffilate,
    fetchStatUserRead
}       