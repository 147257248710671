import { AxiosError, AxiosResponse } from "axios"
import { Dispatch } from "redux"
import { loaderAction } from "reduxs/loader/actionCreator"
import { putLotterBetAction, getLotterBetAction, getBetReadAction } from './actionCreator'
import { fetGetBetRead, fetGetLotterBet, fetPutLotterBet } from './services'


const getLotterBet = (userQuery: IQueryBetUser & IQueryPagination) => async (dispatch: Dispatch) => {
    dispatch(loaderAction.request({}))
    dispatch(getLotterBetAction.request({}))
    await fetGetLotterBet(userQuery)
        .then((response: AxiosResponse<IAPIResponse<IPagination<IResponseBetUser>>>) => {
            dispatch(loaderAction.success({}))
            return dispatch(getLotterBetAction.success(response))
        })
        .catch((error: AxiosError<IAPIResponse>) => {
            dispatch(loaderAction.success({}))
            return dispatch(getLotterBetAction.failure(error))
        })
}
const clearLotterBet = () => (dispatch: Dispatch) =>
    dispatch(getLotterBetAction.cancel({}))

const getBetRead = (userQuery: IQueryBetUser & IQueryPagination, isFirst?: boolean) => async (dispatch: Dispatch) => {
    dispatch(loaderAction.request({}))
    dispatch(getBetReadAction.request({}))
    await fetGetBetRead(userQuery)
        .then((response: AxiosResponse<IAPIResponse<IPagination<IResponseBetUser>>>) => {
            dispatch(loaderAction.success({}))
            return dispatch(getBetReadAction.success(response, isFirst!))
        })
        .catch((error: AxiosError<IAPIResponse>) => {
            dispatch(loaderAction.success({}))
            return dispatch(getBetReadAction.failure(error))
        })
}
const clearBetRead = () => (dispatch: Dispatch) =>
    dispatch(getBetReadAction.cancel({}))

const putUserMoney = (body: IUpdateBetUser) => async (dispatch: Dispatch) => {
    dispatch(loaderAction.request({}))
    dispatch(putLotterBetAction.request({}))
    await fetPutLotterBet(body)
        .then((response: AxiosResponse<IAPIResponse<any>>) => {
            dispatch(loaderAction.success({}))
            return dispatch(putLotterBetAction.success(response))
        })
        .catch((error: AxiosError<IAPIResponse>) => {
            dispatch(loaderAction.success({}))
            return dispatch(putLotterBetAction.failure(error))
        })
}

const credits = {
    getLotterBet,
    clearLotterBet,
    putUserMoney,
    getBetRead,
    clearBetRead,
}

export default credits