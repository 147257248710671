import React, { FC } from 'react'
import { noop } from 'lodash'
import './InputCheckboxGroup.style.scss'
import { InputCheckbox } from 'components'

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IInputCheckboxGroup = {
  name: '',
  options: [{value: '', label: ''}],
  values: [],
  onChange() { noop() },
  isTextInBox: false,
  boxHoverColor: '',
}

const InputCheckboxGroup: FC<IInputCheckboxGroup & DefaultProps> = (props) => {

  const {
    name,
    options,
    values,
    onChange,
    isTextInBox,
    boxHoverColor,
  } = props

  return (
    <div className='checkbox-group-container'>
      {options.map((option, index) => (
        <InputCheckbox
          key={`input-checkbox-${name}-${index}`}
          name={`${name}`}
          label={option.label}
          value={option.value}
          checked={values.includes(option.value)}
          onChange={onChange}
          isTextInBox={isTextInBox}
          boxHoverColor={boxHoverColor}
        />
    ))}
    </div>
  )
}

InputCheckboxGroup.defaultProps = defaultProps

export default InputCheckboxGroup