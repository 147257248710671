import { AxiosError, AxiosResponse } from "axios"
import { Dispatch } from "redux"
import { loaderAction } from "reduxs/loader/actionCreator"
import { getCreditMeAction, getUserMoneyAction, putUserMoneyAction, postCreditMeDeTail } from './actionCreator'
import { fetGetCreditMe, fetGetUserMoney, fetPutUserMoney, fetGetCreditMeDetail } from './services'


const getCreditMe = (userQuery: ICreditQuery, isFirst?: boolean) => async (dispatch: Dispatch) => {
    dispatch(loaderAction.request({}))
    dispatch(getCreditMeAction.request({}))
    await fetGetCreditMe(userQuery)
        .then((response: AxiosResponse<IAPIResponse<IPagination<ICredit>>>) => {
            dispatch(loaderAction.success({}))
            return dispatch(getCreditMeAction.success(response, isFirst!))
        })
        .catch((error: AxiosError<IAPIResponse>) => {
            dispatch(loaderAction.success({}))
            return dispatch(getCreditMeAction.failure(error))
        })
}
const clearCreditMe = () => (dispatch: Dispatch) =>
    dispatch(getCreditMeAction.cancel({}))

const getUserMoney = (Query: IGetUserMoneyQuery) => async (dispatch: Dispatch) => {
    dispatch(loaderAction.request({}))
    dispatch(getUserMoneyAction.request({}))
    await fetGetUserMoney(Query)
        .then((response: AxiosResponse<IAPIResponse<IUserMoney>>) => {
            dispatch(loaderAction.success({}))
            return dispatch(getUserMoneyAction.success(response))
        })
        .catch((error: AxiosError<IAPIResponse>) => {
            dispatch(loaderAction.success({}))
            return dispatch(getUserMoneyAction.failure(error))
        })
}
const clearUserMoney = () => (dispatch: Dispatch) =>
    dispatch(getUserMoneyAction.cancel({}))

const putUserMoney = (body: IPutUserMoneyBody) => async (dispatch: Dispatch) => {
    dispatch(loaderAction.request({}))
    dispatch(putUserMoneyAction.request({}))
    await fetPutUserMoney(body)
        .then((response: AxiosResponse<IAPIResponse<IUserMoney>>) => {
            dispatch(loaderAction.success({}))
            return dispatch(putUserMoneyAction.success(response))
        })
        .catch((error: AxiosError<IAPIResponse>) => {
            dispatch(loaderAction.success({}))
            return dispatch(putUserMoneyAction.failure(error))
        })
}

const getCreditMeDetail = (userQuery: ICreditMeDetailReq) => async (dispatch: Dispatch) => {
    dispatch(loaderAction.request({}))
    dispatch(postCreditMeDeTail.request({}))
    await fetGetCreditMeDetail(userQuery)
        .then((response: AxiosResponse<IAPIResponse<ICreditDetail>>) => {
            dispatch(loaderAction.success({}))
            return dispatch(postCreditMeDeTail.success(response))
        })
        .catch((error: AxiosError<IAPIResponse>) => {
            dispatch(loaderAction.success({}))
            return dispatch(postCreditMeDeTail.failure(error))
        })
}
const clearCreditMeDetail = () => (dispatch: Dispatch) =>
    dispatch(postCreditMeDeTail.cancel({}))

const credits = {
    getCreditMe,
    clearCreditMe,
    getUserMoney,
    clearUserMoney,
    putUserMoney,

    getCreditMeDetail,
    clearCreditMeDetail
}

export default credits