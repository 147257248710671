import { faChessKing, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ALink, Breadcrumb, ErrorModal } from 'components'
import colors from 'constants/colors'
import { responseCode } from 'constants/response'
import { filter, forEach, get, isEmpty, keys, map, noop } from 'lodash'
import React, { Component, createRef, RefObject } from 'react'
import { RouteComponentProps } from 'react-router'
import Switch from 'react-switch'
import thb from 'assets/images/partner-logo/logomain.png'
import aeSexy from 'assets/images/partner-logo/game-logo-ae-sexy-200x200-1.png'
import saGame from 'assets/images/partner-logo/sa-gaming.png'
import jokerGame from 'assets/images/partner-logo/logo-joker.png'
import dreamTechLogo from 'assets/images/partner-logo/dreamTech.png'
import PGLogo from 'assets/images/partner-logo/logo-pgslot-80x80.png'
import WMLogo from 'assets/images/partner-logo/wm-banner.png'
import DGLogo from 'assets/images/partner-logo/dg-gaming.png'
import W88Logo from 'assets/images/partner-logo/logo-large.png'
import AllBetLogo from 'assets/images/partner-logo/allBet-banner.png'
import PPLogo from 'assets/images/partner-logo/pp-game.svg'
import JILILogo from 'assets/images/partner-logo/jili_logo.png'
import EVOLogo from 'assets/images/partner-logo/evolution-icon.png'
import './partnerControllers.style.scss'
import { Formik, FormikProps } from 'formik'
import schema from './model/scheme'
import { DateRangeForm } from './components'
import environment from 'constants/environment'
import moment from 'moment'
import { format } from 'date-fns'
import { date } from 'utils'

const constants = {
  routeTextActive: 'Partner Controllers',
  routeTextSecodary: 'Home',
  routeTextTertiary: 'OMEGA',
  back: 'Back',
  ok: 'OK',
  search: 'Search',
  sucess: '',
  sectionName: 'Partner Details',
  emptyPartner: 'Please Select Partner..',
  scheduleTitle: 'Add Schedule Time',
  scheduleReset: 'Reset Schedule Time',
  partnerPlaceholder: (name: string) => `Partner : ${name}`
}

const defaultProps: IPartnerControllersContainerActionProps &
  IPartnerControllersContainerProps = {
  clearAllPartner() {
    noop()
  },
  getAllPartner() {
    noop()
  },
  getAllPartnerCode: 0,
  getAllPartnerError: '',
  getAllPartnerIsFetching: false,
  partnerList: {},
  putPartner() {
    noop()
  },
  putPartnerSchedule() {
    noop()
  },
  putPartnerCode: 0,
  putPartnerError: '',
  putPartnerIsFetching: false,
  putPartnerScheduleCode: 0,
  putPartnerScheduleError: '',
  putPartnerScheduleIsFetching: false,
  clearPartnerSchdule() {
    noop()
  },
  getPartnerSchedule() {
    noop()
  },
  getPartnerScheduleCode: 0,
  getPartnerScheduleError: '',
  getPartnerScheduleIsFetching: false,
  partnerSchedule: []
}

interface ISwitchButtonTemp {
  company: TGameCompany | ''
  value: boolean
}

const mapPartnerImg: { [name in string | '']: string } = {
  AESEXY: aeSexy,
  JOKER: jokerGame,
  SAGAME: saGame,
  DREAMTECH: dreamTechLogo,
  PG: PGLogo,
  ALLBET: AllBetLogo,
  WMGAME: WMLogo,
  THBGAME: thb,
  DREAMGAMING: DGLogo,
  W88CASINO: W88Logo,
  W88LOTTERY: W88Logo,
  W88P2P: W88Logo,
  W88SLOT: W88Logo,
  PPGAME: PPLogo,
  EVOLUTION: EVOLogo,
  JILIGAME:JILILogo,
  '': thb
}

type DefaultProps = Readonly<typeof defaultProps>

export class PartnerControllersContainer extends Component<
  RouteComponentProps &
  DefaultProps &
  IPartnerControllersContainerProps &
  IPartnerControllersContainerActionProps,
  IPartnerControllersContainerState
> {
  static defaultProps = defaultProps
  state: IPartnerControllersContainerState = {
    spinIcon: false,
    statusCheck: false,
    tempDate: [],
    scheduledData: [],
    onSelected: {
      company: '',
      value: false
    },
    scheduleQuery: [],
    scheduleList: [],
    onSelectSchedule: []
  }

  divPickDate: RefObject<HTMLDivElement> = createRef()
  PickDate: RefObject<HTMLInputElement> = createRef()

  componentDidMount() {
    this.onGetPartner()
  }

  componentDidUpdate(prevProps: IPartnerControllersContainerProps) {
    if (
      prevProps.getAllPartnerIsFetching !==
      this.props.getAllPartnerIsFetching &&
      !this.props.getAllPartnerIsFetching
    ) {
      if (this.props.getAllPartnerCode === responseCode.OK) {
        this.handleUpperCase(
          this.props.partnerList[
          environment.environments[environment.environmentName]
            .name as TEnvironmentName
          ]!
        )
      } else {
        ErrorModal.show({
          description: this.props.getAllPartnerError,
          action: () => {
            ErrorModal.hide()
            return this.handleOnBack()
          }
        })
      }
    }
    if (
      prevProps.getPartnerScheduleIsFetching !==
      this.props.getPartnerScheduleIsFetching &&
      !this.props.getPartnerScheduleIsFetching
    ) {
      if (this.props.getPartnerScheduleCode === responseCode.OK) {

        this.setState({ scheduleList: this.props.partnerSchedule })
      } else {
        ErrorModal.show({
          description: this.props.getPartnerScheduleError,
          action: () => {
            ErrorModal.hide()
          }
        })
      }
    }
    if (
      prevProps.putPartnerIsFetching !== this.props.putPartnerIsFetching &&
      !this.props.putPartnerIsFetching
    ) {
      if (this.props.putPartnerCode === responseCode.OK) {
        this.onGetPartner()
      } else {
        ErrorModal.show({
          description:
            this.props.putPartnerCode === responseCode.BAD_REQUEST
              ? 'ตรวจรอบการตั้งค่าเวลาเกมส์ ไม่สามารถแก้ไขระหว่างการตั้งค่าเวลา เปิด-ปิดเกมส์ ได้'
              : this.props.putPartnerError,
          action: () => {
            ErrorModal.hide()
            this.setState((ps) => ({
              onSelected: {
                company: ps.onSelected.company,
                value: !ps.onSelected.value
              }
            }))
            return this.props.getAllPartner()
          }
        })
      }
    }

    if (
      prevProps.putPartnerScheduleIsFetching !==
      this.props.putPartnerScheduleIsFetching &&
      !this.props.putPartnerScheduleIsFetching
    ) {
      if (this.props.putPartnerScheduleCode === responseCode.OK) {
        this.onGetPartner()
      } else {
        const { onSelected, partnerList } = this.state
        this.onGetPartner()
        ErrorModal.show({
          description: this.props.putPartnerScheduleError,
          action: () => {
            this.setState({ scheduledData: [], tempDate: [] })
            this.onSelectUser(onSelected.company as TGameCompany, partnerList!)
            return ErrorModal.hide()
          }
        })
      }
    }
  }

  componentWillUnmount() {
    this.props.clearAllPartner()
    this.props.clearPartnerSchdule()
  }

  handleOnClickBreadcrumb = (path: string) => {
    this.props.history.push(path)
  }

  handleOnBack = () => {
    this.props.history.push('/main')
  }

  onGetPartner = async () => {
    this.props.getAllPartner()
    this.props.getPartnerSchedule()
  }

  onSubmitDate = (data: IPartnerDateRange) => {
    if (data.saved) {
      this.setState(
        (ps) => ({
          scheduledData: [
            ...ps.scheduledData.filter((val) => val.saved === data.saved),
            ...[data]
          ]
        }),
        () =>
          this.setState(
            (ps) => ({ tempDate: [...ps.scheduledData] }),
            () => this.queryHandler(this.state.scheduledData)
          )
      )
      this.addRowHandler(data)
    } else {
      this.setState(
        (ps) => ({
          tempDate: ps.tempDate.filter((val) => val.saved !== data.saved)
        }),
        () =>
          this.setState(
            (ps) => ({ scheduledData: [...ps.tempDate] }),
            () => this.queryHandler(this.state.scheduledData)
          )
      )
      this.addRowHandler(data)
    }
    return
  }

  queryHandler = (data: IPartnerDateRange[]) => {
    const formatDate = (dateToBeFormatted: string) =>
      format(
        date.calibratingTime(dateToBeFormatted, true, false),
        `yyyy-MM-dd'T'HH:mm:ss`
      )
    const newObj: any = {}
    const createQuery: IPartnerScheduleDateQuery[] = []
    forEach(data, (data) => {
      newObj.startTime = formatDate(
        moment(data.startTime).seconds(0).milliseconds(0).format()
      )
      newObj.endTime = formatDate(
        moment(data.endTime).seconds(0).milliseconds(0).format()
      )
      createQuery.push({ ...newObj })
    })
    this.onPutPartnerSchdule(createQuery)
  }

  onPutPartnerSchdule = (query: IPartnerScheduleDateQuery[]) => {
    const editedArray: IPartnerScheduleConfig[] = this.state.onSelectSchedule
    const masterArray: IPartnerScheduleConfig[] = this.state.scheduleList

    editedArray[0].closeTime = query
    editedArray[0].status = !isEmpty(query) ? false : true
    editedArray.forEach((updateObj) => {
      const indexMasterQuery = masterArray.map((masterObj) => masterObj.partner).indexOf(updateObj.partner)
      if (indexMasterQuery !== undefined) {
        masterArray.splice(indexMasterQuery, 1, updateObj)
      }
    })
    const confList  = map(masterArray, (partner) => {
      const filterData = filter(partner.closeTime,(t)=> !(moment(t.endTime) < moment(new Date()) ))
      const expireDateConf =  map(filterData, (time, index) => {
        if (!isEmpty(time)) {
           if (moment(time.startTime) < moment(new Date())) {
            return {...time, startTime: format(
              date.calibratingTime(moment(new Date()).add(1,'minute').second(0).millisecond(0).format(), true, false),
              `yyyy-MM-dd'T'HH:mm:ss`
            )}
          }
        }
          return time
      })

      return {...partner, closeTime: expireDateConf}
    })
  this.props.putPartnerSchedule(confList as IPartnerScheduleConfig[])
    // editedArray[0].closeTime = query
    // editedArray[0].status = !isEmpty(query) ? false : true
    // editedArray.forEach((updateObj) => {
    //   const indexMasterQuery = masterArray
    //     .map((masterObj) => masterObj.partner).indexOf(updateObj.partner)
    //   if (indexMasterQuery !== undefined) {
    //     masterArray.splice(indexMasterQuery, 1, updateObj)
    //     // this.props.putPartnerSchedule(masterArray)
    //   }
  }

  onInitialDate = () => {
    if (isEmpty(this.state.scheduledData)) {
      const getDate = new Date(
        moment(new Date())
          .date(new Date().getDate())
          .minutes(0)
          .hours(new Date().getHours())
          .format()
      )
      const dateRange: IPartnerDateRange = {
        startTime: getDate,
        endTime: getDate,
        saved: false
      }
      this.setState({ tempDate: [...[dateRange]], scheduledData: [] })
    } else
      this.setState({ tempDate: [...[]], scheduledData: [...[]] }, () =>
        this.queryHandler(this.state.scheduledData)
      )
  }

  // Remove When API Updated
  handleUpperCase = (data: IGameConfig) => {
    const convertedOBJ: { [key in string]: boolean } = {}
    for (const [key, value] of Object.entries(data)) {

      convertedOBJ[key.toUpperCase()] = value
    }
    this.setState({
      partnerList: convertedOBJ as IGameConfig,
      queryPutData: convertedOBJ as IGameConfig
    })
    return
  }

  handleSwitchSubmit = (data: ISwitchButtonTemp) => {
    const removeWhiteSpace = data.company.replace(/\s/g, '')
    this.setState({
      onSelected: {
        company: removeWhiteSpace as TGameCompany,
        value: !data.value
      }
    })
    return this.setState(
      (ps) => ({
        queryPutData: { ...ps.partnerList!, [`${data.company}`]: !data.value }
      }),
      () => {
        this.props.putPartner(this.state.queryPutData!)
      }
    )
  }

  addRowHandler = (currentDate: IPartnerDateRange, saved?: boolean) => {
    const rows = this.state.tempDate
    if (saved) {
      rows.push({
        startTime: currentDate.startTime,
        endTime: currentDate.endTime,
        saved: saved || false
      })
      return this.setState({ tempDate: rows, scheduledData: rows })
    } else {
      rows.push({
        startTime: currentDate.endTime,
        endTime: currentDate.endTime,
        saved: saved || false
      })
      return this.setState({ tempDate: rows })
    }
  }

  onSelectUser = (key: TGameCompany, data: IGameConfig) => {
    const removeWhiteSpace = key.replace(/\s/g, '')
    const filterd = ['W88_CASINO', 'W88_SLOT', 'W88_P2P', 'W88_LOTTERY']
    const filteredforminterpreter = (key: string) =>
      key === 'W88CASINO'
        ? filterd[0]
        : key === 'W88SLOT'
          ? filterd[1]
          : key === 'W88P2P'
            ? filterd[2]
            : key === 'W88LOTTERY'
              ? filterd[3]
              : key
    this.setState({
      onSelected: {
        company: removeWhiteSpace as TGameCompany,
        value: data[key]
      },
      tempDate: [],
      scheduledData: []
    })
    this.setState(
      (ps) => ({
        onSelectSchedule: ps.scheduleList.filter(
          (val) => val.partner ===
            filteredforminterpreter(key)
        )
      }),
      () => {
        const createData: IPartnerDateRange[] = []
        const createObject: IPartnerTimeConfig = {}
        if (
          !isEmpty(get(this.state.onSelectSchedule, '[0].closeTime', undefined))
        ) {
          forEach(this.state.onSelectSchedule[0].closeTime, (data, index) => {
            createObject.startTime = new Date(
              new Date(data.startTime!).toISOString()
            )!
            createObject.endTime = new Date(
              new Date(data.endTime!).toISOString()
            )!
            createObject.saved = true!
            createData.push({ ...createObject } as IPartnerDateRange)
            this.addRowHandler(createData[index], true)
          })
          this.addRowHandler(createData[createData.length - 1])
        }
      }
    )
  }

  renderPartnerList = (data: IGameConfig) => {
    const { onSelected } = this.state
    const createDataElement = map(keys(data), (key: TGameCompany, index) => {
      return (
        <div
          className={`partner-wrapper py-1 row ${onSelected.company === key ? 'active' : ''
            }`}
          key={`${key}-${index}`}
          tabIndex={0}
          onClick={() => this.onSelectUser(key, data)}
        >
          <div className="m2-r col">{key}</div>
          <div className="text-right col">
            <div
              className={`${data[key] ? 'text-success' : 'text-danger'
                } badge badge-outlined`}
            >
              {data[key] ? 'Active' : 'OFF'}
            </div>
          </div>
        </div>
      )
    })
    return (
      <div className="row">
        <div className="col-12 w-100 body-1 rounded">{createDataElement}</div>
      </div>
    )
  }

  renderDateScheduleForm = (dataArr: IPartnerDateRange[]) => {
    const formComponent = (formProps: FormikProps<IPartnerDateRange>) => {
      return <DateRangeForm {...formProps} />
    }
    return map(dataArr, (data, index) => (
      <Formik
        key={index}
        onSubmit={this.onSubmitDate}
        validationSchema={schema}
        initialValues={data}
        enableReinitialize
      >
        {formComponent}
      </Formik>
    ))
  }

  renderPartnerConfig = (data: ISwitchButtonTemp) => {
    const RenderDateScheduleForm = this.renderDateScheduleForm(
      this.state.tempDate
    )
    return (
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12">
              <div className="row vendor-img">
                <div className={`col-12 m2-b vendor-img`}>
                  <div className="img-box">
                    <img
                      className={`${data.value ? '' : 'img-select'}`}
                      src={mapPartnerImg[data.company as TGameCompany]}
                      alt={data.company}
                    />
                  </div>
                </div>
                <div className="vendor-name col-12 m2-b">
                  <div className="row">
                    <div className="col-12">
                      <h4>
                        {constants.partnerPlaceholder(get(data, 'company', ''))}
                      </h4>
                    </div>
                    <div className="col-12 m1-t">
                      <Switch
                        disabled={isEmpty(data.company)}
                        onChange={() => this.handleSwitchSubmit(data)}
                        checked={data.value}
                      />
                    </div>
                    {isEmpty(data.company) ? (
                      []
                    ) : (
                      <div className="col-12">
                        <div
                          onClick={this.onInitialDate}
                          className="badge badge-outlined m1-t"
                        >
                          <h5 className="text-danger">
                            {isEmpty(this.state.scheduledData)
                              ? constants.scheduleTitle
                              : constants.scheduleReset}
                          </h5>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {RenderDateScheduleForm}
        </div>
      </div>
    )
  }

  render() {
    const { partnerList, onSelected } = this.state
    const navigates: IBreadcrumbItem[] = [
      { label: constants.routeTextTertiary, active: false, path: '/main' },
      { label: constants.routeTextSecodary, active: false, path: '/main' },
      {
        label: constants.routeTextActive,
        active: true,
        path: '/partner-controllers'
      }
    ]
    const RenderPartnerList = this.renderPartnerList
    const RenderPartnerConfig = this.renderPartnerConfig
    return (
      <div className="container-fluid pt-5 px-5 page-container">
        <div className="row">
          <div className="col-12 pt-5 mt-2">
            <Breadcrumb
              items={navigates}
              handleOnClickItem={this.handleOnClickBreadcrumb}
            />
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-12">
            <ALink
              id="backto-previus-page"
              color={colors.SECONDARY_RED}
              bold
              onClick={this.handleOnBack}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              &nbsp;{constants.back}
            </ALink>
          </div>
        </div>
        <div className="row mt-3 justify-content-end content-wrapper shadow-lg bg-dark rounded">
          <div className="col-12 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-1">
            <div className="row px-4">
              <div className="col-12">
                <div className="row m4-t">
                  <span className="col-12 px-0">
                    <RenderPartnerList {...partnerList!} />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-xl-9 col-lg-8 col-md-6 col-sm-6 col-xs-12 mb-1 detail-wrapper">
            <div className="row">
              <div className="col-12 mt-4 pt-1">
                <span className="body-1" style={{ fontSize: '1.5rem' }}>
                  <FontAwesomeIcon
                    icon={faChessKing}
                    color={colors.PRIMARY_RED}
                  />
                  &nbsp;
                  {constants.sectionName}
                </span>
              </div>
            </div>
            <div className="col-12 py-2 mt-3 detail-content">
              <RenderPartnerConfig {...onSelected} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PartnerControllersContainer
