import React, { Component } from 'react'

export default class ComingSoonContainer extends Component {
    render() {
        return (
            <div>
                <h1>404</h1>
            </div>
        )
    }
}
