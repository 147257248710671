import { AxiosError, AxiosResponse } from 'axios'
import { createAsyncAction } from 'typesafe-actions'
import {
    GET_ALL_PARTNER_REQUEST,
    GET_ALL_PARTNER_SUCCESS,
    GET_ALL_PARTNER_FAILURE,
    CLEAR_ALL_PARTNER,
    UPDATE_PARTNER_REQUEST,
    UPDATE_PARTNER_SUCCESS,
    UPDATE_PARTNER_FAILURE,
    GET_PARTNER_SCHEDULE_REQUEST,
    GET_PARTNER_SCHEDULE_SUCCESS,
    GET_PARTNER_SCHEDULE_FAILURE,
    CLEAR_SCHEDULE_PARTNER,
    UPDATE_PARTNER_SCHEDULE_REQUEST,
    UPDATE_PARTNER_SCHEDULE_SUCCESS,
    UPDATE_PARTNER_SCHEDULE_FAILURE,
} from './constants'

const getAllPartnersAction = createAsyncAction(
    GET_ALL_PARTNER_REQUEST,
    GET_ALL_PARTNER_SUCCESS,
    GET_ALL_PARTNER_FAILURE,
    CLEAR_ALL_PARTNER,
)<any, AxiosResponse<IAPIResponse<IPartnerConfig>>, AxiosError<IAPIResponse>, any>()

const getPartnerSchedulesAction = createAsyncAction(
    GET_PARTNER_SCHEDULE_REQUEST,
    GET_PARTNER_SCHEDULE_SUCCESS,
    GET_PARTNER_SCHEDULE_FAILURE,
    CLEAR_SCHEDULE_PARTNER,
)<any, AxiosResponse<IAPIResponse<IPartnerScheduleConfig[]>>, AxiosError<IAPIResponse>, any>()


const updatePartnerAction = createAsyncAction(
    UPDATE_PARTNER_REQUEST,
    UPDATE_PARTNER_SUCCESS,
    UPDATE_PARTNER_FAILURE,
)<any, AxiosResponse<IAPIResponse<IGameConfig>>, AxiosError<IAPIResponse>>()

const updatePartnerScheduleAction = createAsyncAction(
    UPDATE_PARTNER_SCHEDULE_REQUEST,
    UPDATE_PARTNER_SCHEDULE_SUCCESS,
    UPDATE_PARTNER_SCHEDULE_FAILURE,
)<any, AxiosResponse<IAPIResponse<IPartnerScheduleConfig[]>>, AxiosError<IAPIResponse>>()

export {
    getPartnerSchedulesAction,
    getAllPartnersAction,
    updatePartnerScheduleAction,
    updatePartnerAction,
}