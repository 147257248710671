
import route from 'constants/routes'
import containers from 'containers'

const routes: IRoutes[] = [
  {
    name: route.redirected.name,
    path: route.redirected.path,
    component: containers.Main,
    private: true,
  },
  {
    exact: true,
    name: route.login.name,
    path: route.login.path,
    component: containers.Login,
    private: false,
  },
  {
    name: route.main.name,
    path: route.main.path,
    component: containers.Main,
    private: true,
  },
  {
    exact: true,
    name: route.userInfo.name,
    path: route.userInfo.path,
    component: containers.UserInfo,
    private: true,
  },
  {
    exact: true,
    name: route.creditInfo.name,
    path: route.creditInfo.path,
    component: containers.CreditInfo,
    private: true,
  },
  {
    exact: true,
    name: route.partnerControllers.name,
    path: route.partnerControllers.path,
    component: containers.PartnerControllers,
    private: true,
  },
  {
    exact: true,
    name: route.lotteryManagement.name,
    path: route.lotteryManagement.path,
    component: containers.LotteryManagement,
    private: true,
  },
  {
    exact: true,
    name: route.transactionManagement.name,
    path: route.transactionManagement.path,
    component: containers.TransactionManagement,
    private: true,
  },
  {
    exact: true,
    name: route.webbankManagement.name,
    path: route.webbankManagement.path,
    component: containers.WebBankManagement,
    private: true,
  },
  {
    name: route.notFound.name,
    path: route.notFound.path,
    component: containers.ComingSoon,
    private: false
  },
]

export default routes