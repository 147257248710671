import { Dispatch } from 'redux'
import { logoutAction } from '../actionCreator'
import { responseCode } from '../../../constants/response'
import { setToken } from '../token/action'
import CreditAction from '../../credits/actions'
import PartnerAction from '../../partners/actions'
import UserAction from '../../users/actions'


const logout = () => (dispatch: Dispatch) => {
    dispatch(logoutAction.request({}))
    try {
        dispatch(logoutAction.success({}))
        setToken({ token: '' })(dispatch)
        CreditAction.clearCreditMe()
        CreditAction.clearUserMoney()
        PartnerAction.clearAllPartner()
        PartnerAction.clearPartnerSchedule()
        UserAction.clearMeUser()
        UserAction.clearAllUser()
    } catch {
        dispatch(logoutAction.failure(responseCode.BAD_REQUEST))
    }
}

export {
    logout,
}