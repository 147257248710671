import { createAsyncAction } from "typesafe-actions";
import {
    GET_LOTTO_LIST_REQUEST,
    GET_LOTTO_LIST_SUCCESS,
    GET_LOTTO_LIST_FAILURE,
  } from './constants'
import { AxiosResponse, AxiosError } from "axios";

const getLottoListAction =  createAsyncAction(
    GET_LOTTO_LIST_REQUEST,
    GET_LOTTO_LIST_SUCCESS,
    GET_LOTTO_LIST_FAILURE,
)<any, AxiosResponse<APIResponse<ILotto[]>>, AxiosError<IAPIResponse>>()

export { 
    getLottoListAction
}