import { string, object, ObjectSchema, number } from 'yup'

const constants = {
  betTransactionId: 'ID is Required',
  result: 'ระบุผลลัพธ์',
  status: 'Please Pick Status.',
  value: 'ระบุเงินเดิมพัน.',
  moneyFormat: 'กรุณาใส่ตัวเลขให้ถูกต้อง'
}

const numberRegex = /^-?(?:0|[1-9]\d{0,2}(?:,?\d{3})*)(?:\.\d+)?$/

const schemeBetUser: ObjectSchema<any> = object().shape({
  betTransactionId: number().required(constants.betTransactionId),
  status: string().required(constants.status),
  result: string().required(constants.result).matches(numberRegex, constants.moneyFormat),
  value: string().required(constants.value).matches(numberRegex, constants.moneyFormat),
})
const schemeBetQuery: ObjectSchema<any> = object().shape({
})
const schemes = {
  betUser: schemeBetUser,
  betQuery: schemeBetQuery
}
export default schemes