import React, { FC } from 'react'
import LottoFlags from 'assets/images/global/flags'
import { get, groupBy, includes, isEmpty, map, split } from 'lodash'
import {
  LOTTO_FLAG_ALPHA,
  LOTTO_GAME_TYPE_NAME,
  LOTTO_SLUG_NAME,
  LOTTO_TYPE
} from 'constants/variables'
import { Badge } from 'components'
import colors from 'constants/colors'
import './lottoResultCard.style.scss'

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: ILottoResultCard = {
  currentResult: {
    code: 'GOVN',
    createdAt: '',
    lotterCurrentResult: [],
    status: ''
  },
  isEdited: false
}

const constants = {
  lotto: 'หวย',
  noResult: 'ไม่ออกผล'
}

const LottoResultCard: FC<ILottoResultCard & DefaultProps> = (props) => {
  const { currentResult, isEdited } = props

  const normalizationCode: TLottoType = includes(currentResult.code, 'YEGEE')
    ? 'YEGEE'
    : currentResult.code!

  const groupLottoTypes: {
    [type in TLottoGameType]: IGetLotterCurrentResult[]
  } = groupBy<IGetLotterCurrentResult>(
    currentResult.lotterCurrentResult,
    'valueType'
  ) as {
    [type in TLottoGameType]: IGetLotterCurrentResult[]
  }

  const NumberComponent = ({
    lottoNumber,
    type,
    gameType
  }: {
    lottoNumber: IGetLotterCurrentResult[]
    type: TLottoType
    gameType: TLottoGameType
  }) => {
    if (isEmpty(lottoNumber) || typeof lottoNumber === 'undefined') {
      return <></>
    }
    return (
      <>
        <div className="row pt-1">
          <div className="col text-center">
            <h5
              className="secondary-text"
              id={`lotto-name-${type}-${gameType}`}
            >
              {LOTTO_GAME_TYPE_NAME[gameType]}
            </h5>
          </div>
        </div>
        <div className="row m1-t">
          <div className="col text-center">
            {map(
              lottoNumber,
              (numberResult: IGetLotterCurrentResult, resultIndex: number) => {
                if (!isEdited) {
                  return (
                    <h1
                      className="lotto secondary-purple-text"
                      key={`lotto-number-${type}-${gameType}-${resultIndex}`}
                      id={`lotto-number-${type}-${gameType}-${resultIndex}`}
                    >
                      {numberResult.value}
                    </h1>
                  )
                }
                return (
                  <h1
                    className="lotto primary-red-text"
                    key={`lotto-number-${type}-${gameType}-${resultIndex}`}
                    id={`lotto-number-${type}-${gameType}-${resultIndex}`}
                  >
                    {numberResult.value}
                  </h1>
                )
              }
            )}
          </div>
        </div>
      </>
    )
  }

  const LottoNumbersFormat = () => {
    switch (normalizationCode) {
      case 'GOVN':
      case 'GSB':
      case 'BAAC':
      case 'TH_SHARE_MORNING':
      case 'TH_SHARE_MIDDAY':
      case 'TH_SHARE_AFTERNOON':
      case 'TH_SHARE_EVENING':
      case 'NAT_SHARE_DOWNJON':
      case 'NAT_SHARE_EGYPT':
      case 'NAT_SHARE_GERMANY':
      case 'NAT_SHARE_NIKAII_MORNING':
      case 'NAT_SHARE_NIKAII_AFTERNOON':
      case 'NAT_SHARE_CHINA_MORNING':
      case 'NAT_SHARE_CHINA_AFTERNOON':
      case 'NAT_SHARE_TAIWAN':
      case 'NAT_SHARE_KOREA':
      case 'NAT_SHARE_SINGAPORE':
      case 'NAT_SHARE_INDIA':
      case 'NAT_SHARE_HANOI_SPECIAL':
      case 'NAT_SHARE_MALAY':
      case 'NAT_SHARE_VIETNAM_HANOI':
      case 'NAT_SHARE_VIETNAM_HANOI_VIP':
      case 'NAT_SHARE_HANOI_4D':
      case 'NAT_SHARE_RUSSIA':
      case 'NAT_SHARE_ENGLISH':
      case 'NAT_SHARE_HUNGSENG_MORNING':
      case 'NAT_SHARE_HUNGSENG_AFTERNOON':
      case 'NAT_SHARE_LAO':
      case 'YEGEE':
      case 'LAO_HD':
      case 'HANOI_TV':
      case 'LAO_STAR':
      case 'LAO_VIP':
      case 'HANOI_RED_CROSS':
      case 'HANOI_SAMAKKEE':
      case 'HANOI_PHATTANA':
      case 'LAO_SAMAKKEE':
      case 'LAO_ASEAN':
      case 'LAO_STAR_VIP':
      case 'ENGLISH_VIP':
      case 'GERMANY_VIP':
      case 'LAO_RED_CROSS':
      case 'RUSSIA_VIP':
      case 'DOWNJON_VIP':
      case 'HANOI_ASEAN':
      case 'LAO_TV':
      case 'HANOI_HD':
      case 'HANOI_STAR':
      case 'MALAY':
      case 'HANOI_EXTRA':
      case 'NAT_SHARE_HUNGSENG_MORNING_VIP':
      case 'NAT_SHARE_HUNGSENG_AFTERNOON_VIP':
      case 'NAT_SHARE_CHINA_MORNING_VIP':
      case 'NAT_SHARE_CHINA_AFTERNOON_VIP':
      case 'NAT_SHARE_SINGAPORE_VIP':
      case 'NAT_SHARE_KOREA_VIP':
      case 'NAT_SHARE_TAIWAN_VIP':
      case 'NAT_SHARE_NIKAII_MORNING_VIP':
      case 'NAT_SHARE_NIKAII_AFTERNOON_VIP':
      case 'HANOI_CHINESE_NEW_YEAR':
      case 'NAT_SHARE_DOWNJON_STAR':
        return (
          <div className="row">
            <div className="col">
              <NumberComponent
                type={normalizationCode}
                lottoNumber={groupLottoTypes.THREE_UP}
                gameType="THREE_UP"
              />
            </div>
            <div className="col">
              <NumberComponent
                type={normalizationCode}
                lottoNumber={groupLottoTypes.TWO_DOWN}
                gameType="TWO_DOWN"
              />
            </div>
          </div>
        )
      case 'LAO_SUITE':
        return (
          <div className="row">
            <div className="col">
              <NumberComponent
                type={normalizationCode}
                lottoNumber={[groupLottoTypes.FOUR_SUITE[0]]}
                gameType="FOUR_SUITE"
              />
            </div>
          </div>
        )
      default:
        return <></>
    }
  }

  const FlagIcon = get(
    LottoFlags,
    `${get(LOTTO_FLAG_ALPHA, normalizationCode, '')}.Icon`,
    ''
  )
  const gameName =
    normalizationCode === 'YEGEE'
      ? `${constants.lotto}${LOTTO_TYPE[normalizationCode]}`
      : LOTTO_SLUG_NAME[`LOTTER_${normalizationCode}` as TLottoSlug]
  const gameRound =
    normalizationCode === 'YEGEE'
      ? Number(split(currentResult.code, '_')[1])
      : ''

  const RenderBadge = () => (
    <Badge
      text={currentResult.status}
      backgroundColor={colors.SECONDARY_TEXT}
    />
  )

  return (
    <div className="lotto-card-container secondary-bg p3">
      <div className="row m2-b">
        <div className="col text-left d-flex flex-row align-items-center">
          <div className="d-flex flex-row flex">
            <img alt="lotto-flag" src={FlagIcon} className="flag-icon" />
            <h4 className="flex">
              {gameName} {gameRound}
            </h4>
          </div>
          <RenderBadge />
        </div>
      </div>
      <LottoNumbersFormat />
    </div>
  )
}

export default LottoResultCard
