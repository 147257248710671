import React, { FC } from 'react'
import Logo from 'assets/images/logo/logothailandbet.png'
import { noop } from 'lodash'
import routers from 'constants/routes'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faUser } from '@fortawesome/free-solid-svg-icons'
import './navbar.style.scss'
import { ButtonIcon } from 'components'

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: INavbarProps = {
  username: 'N/A',
  mode: 'light-mode',
  isDisplayWallet: true,
  isAuthorized: false,
  onPressesMenu() {
    noop()
  },
  onPressLogout() {
    noop()
  }
}

const Navbar: FC<INavbarProps & DefaultProps> = ({
  username,
  mode,
  isAuthorized,
  onPressesMenu,
  onPressLogout
}) => {
  const history = useHistory()

  const handleOnClick = () => {
    history.push(routers.main.path)
  }

  const BurgerComponent = () => {
    if (isAuthorized) {
      return (
        <FontAwesomeIcon
          icon={faBars}
          className="burger-container"
          onClick={onPressesMenu}
        />
      )
    }
    return <></>
  }

  const authorizedClass = () => {
    return isAuthorized ? '' : 'non-authorized'
  }

  return (
    <div
      className={`col-12 navbar-container position-fixed ${mode} ${authorizedClass()} shadow-lg`}
    >
      <div className="container-fluid h-100">
        <div className="leading-navbar-container" onClick={handleOnClick}>
          <img src={Logo} alt="thailand" className="logo-container" />
          <h4 className="name-container p1-l m0-t scale">
            OMEGA <span>Technical Support</span>
          </h4>
        </div>
        <div className="trailing-navbar-container">
          <h5>
            <FontAwesomeIcon icon={faUser} /> {username}{' '}
          </h5>
          {` `}
          <ButtonIcon id={`signout`} type={`signout`} onClick={onPressLogout} />
          <BurgerComponent />
        </div>
      </div>
    </div>
  )
}

Navbar.defaultProps = defaultProps

export default Navbar
