const environment: IProjectEnviroment = {
    name: 'omega',
    environmentName: process.env.REACT_APP_OMEGA_ENV || 'develop',
    endpoint: {
        login: '/api/auth/login',
        getAllUser: '/api/user/all',
        getMeUser: '/api/user/me',
        updateUser: '/api/tc/user',
        getAllPartner: '/config/partner',
        getCreditMe: '/api/tc/user/credit',
        getCreditMeDetail: '/api/tc/user/credit/list',
        getUserMoney: '/api/tc/user/money',
        getUserAffilate: '/api/tc/user/affilate',
        getAllLotter: '/api/lotter/all',
        getBetRead: '/api/v1/bet/read',
        lotterBet: '/api/lotter/bet',
        postLottoResult: '/api/lotter',
        postLottoReverse: '/api/lotter/reverse',
        postLottoCurrentResult: '/api/lotter/current-result',
        webbank: '/api/webbank',
        getStatsUserRead: '/api/v1/stats/user/read',
    },
    environments: {
        development: {
            name: 'develop',
            endpoint: {
                url: 'https://devapi.tlbetmantra.com',
                socket: 'https://devapi.tlbetmantra.com',
                aesSecretKey: process.env.REACT_APP_AES_SECRET_KEY || '',
            }
        },
        release: {
            name: 'staging',
            endpoint: {
                url: 'https://stapi.tlbetmantra.com',
                socket: 'https://stapi.tlbetmantra.com',
                aesSecretKey: process.env.REACT_APP_AES_SECRET_KEY || '',
            }
        },
        production: {
            name: 'production',
            endpoint: {
                url: 'https://prodapi.tlbetmantra.com',
                socket: 'https://prodapi.tlbetmantra.com',
                aesSecretKey: process.env.REACT_APP_AES_SECRET_KEY || '',
            }
        },
    }
}

export default environment