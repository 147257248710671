import { AxiosError, AxiosResponse } from 'axios'
import { createAsyncAction } from 'typesafe-actions'
import {
    GET_LOTTER_BET_REQUEST,
    GET_LOTTER_BET_SUCCESS,
    GET_LOTTER_BET_FAILURE,
    CLEAR_LOTTER_BET,
    PUT_LOTTER_BET_REQUEST,
    PUT_LOTTER_BET_SUCCESS,
    PUT_LOTTER_BET_FAILURE,
    GET_BET_READ_REQUEST,
    GET_BET_READ_SUCCESS,
    GET_BET_READ_FAILURE,
    CLEAR_BET_READ,
} from './constants'

const getLotterBetAction = createAsyncAction(
    GET_LOTTER_BET_REQUEST,
    GET_LOTTER_BET_SUCCESS,
    GET_LOTTER_BET_FAILURE,
    CLEAR_LOTTER_BET,
)<any, AxiosResponse<IAPIResponse<IPagination<IResponseBetUser>>>, AxiosError<IAPIResponse>, any>()

const getBetReadAction = createAsyncAction(
    GET_BET_READ_REQUEST,
    GET_BET_READ_SUCCESS,
    GET_BET_READ_FAILURE,
    CLEAR_BET_READ,
)<any, [AxiosResponse<IAPIResponse<IPagination<IResponseBetUser>>>, Boolean], AxiosError<IAPIResponse>, any>()

const putLotterBetAction = createAsyncAction(
    PUT_LOTTER_BET_REQUEST,
    PUT_LOTTER_BET_SUCCESS,
    PUT_LOTTER_BET_FAILURE,
)<any, AxiosResponse<IAPIResponse<any>>, AxiosError<IAPIResponse>>()

export {
    getLotterBetAction,
    putLotterBetAction,
    getBetReadAction,
}