import { get } from 'lodash'
import { allUserState, updateUserState, IGetMeUserState, allUserAffilateState, statUserReadState } from './constants'

const allUser = (state: RootReducers): ReducerState<APIPagination<IUser>> =>
    get(state, 'user.list', allUserState)

const updatedUser = (state: RootReducers): ReducerState<IUpdateUser> =>
    get(state, 'user.updated', updateUserState)

const getMeUser = (state: RootReducers): ReducerState<IUserMe> =>
    get(state, 'user.getMe', IGetMeUserState)

const getUserAffilate = (state: RootReducers): ReducerState<APIPagination<IUserAffilate, IAffilateTansaction, IUserAffilate>> =>
    get(state, 'user.getUserAffilate', allUserAffilateState)

const getStatUserRead = (state: RootReducers): ReducerState<APIPagination<IV1RespReadStatsUser>> =>
    get(state, 'user.getStatUserRead', statUserReadState)

const userSelector = {
    allUser,
    updatedUser,
    getMeUser,
    getUserAffilate,
    getStatUserRead
}

export default userSelector