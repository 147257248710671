import { createAsyncAction } from 'typesafe-actions'
import { 
    LOADING_HIDE,
    LOADING_SHOW
 } from './constants'

 const loaderAction = createAsyncAction(
     LOADING_SHOW,
     LOADING_HIDE,
     ""
 )<any, any>()

 export {
     loaderAction
 }