import { connect } from "react-redux"
import Main  from './Main.container'
import { logout } from '../../reduxs/authentication/logout/action'
import userSelector from "reduxs/users/selector"

const mapStateToProps = (state: RootReducers):IMainContainerProps  => {
    const meUser = userSelector.getMeUser(state)
    return {
        userMe: meUser.data!,
    }

}

const mapDispatchToProos:IMainContainerActionProps = {
 logout: logout,
}


export default connect(mapStateToProps, mapDispatchToProos)(Main)