import { connect } from "react-redux"
import users from "reduxs/users/actions"
import userSelector from "reduxs/users/selector"
import CreditInfoContainer from './CreditInfo.container'
import credits from 'reduxs/credits/actions'
import creditSelector from "reduxs/credits/selector"


const mapStateToProps = (state: RootReducers): ICreditInfoContainerProps => {
    const allUser = userSelector.allUser(state)
    const meUser = userSelector.getMeUser(state)
    const creditMe = creditSelector.creditMe(state)
    const creditMeDetail = creditSelector.creditMeDetail(state)
    return {
        getAllCode: allUser.code,
        getAllError: allUser.error,
        getAllIsFetching: allUser.isFetching,
        getCreditMeCode: creditMe.code,   
        getCreditMeError: creditMe.error,
        getCreditMeIsFetching: creditMe.isFetching,
        creditMe: creditMe.data,
        userList: allUser.data,
        userMe: meUser.data,
        storeCreditMeDetail: creditMeDetail.storeData!,
    }
}

const mapDispatchToProps: ICreditInfoContainerActionProps = {
    getAllUser: (query, isFirst) => users.getAllUser(query, isFirst),
    getCreditMe: (query, isFirst) => credits.getCreditMe(query, isFirst),
    getCreditMeDetail: (query) => credits.getCreditMeDetail(query),
    clearAllUser: users.clearAllUser,
    clearCreditMe: credits.clearCreditMe,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditInfoContainer)