import { AxiosError, AxiosResponse } from 'axios'
import { createAsyncAction } from 'typesafe-actions'
import {
    GET_ME_CREDIT_REQUEST,
    GET_ME_CREDIT_SUCCESS,
    GET_ME_CREDIT_FAILURE,
    CLEAR_ME_CREDIT,
    GET_USER_MONEY_REQUEST,
    GET_USER_MONEY_SUCCESS,
    GET_USER_MONEY_FAILURE,
    CLEAR_USER_MONEY,
    PUT_USER_MONEY_REQUEST,
    PUT_USER_MONEY_SUCCESS,
    PUT_USER_MONEY_FAILURE,

    GET_ME_CREDIT_DETAIL_REQUEST,
    GET_ME_CREDIT_DETAIL_SUCCESS,
    GET_ME_CREDIT_DETAIL_FAILURE,
    CLEAR_ME_CREDIT_DETAIL,
} from './constants'

const getCreditMeAction = createAsyncAction(
    GET_ME_CREDIT_REQUEST,
    GET_ME_CREDIT_SUCCESS,
    GET_ME_CREDIT_FAILURE,
    CLEAR_ME_CREDIT,
)<any, [AxiosResponse<IAPIResponse<IPagination<ICredit>>>, Boolean], AxiosError<IAPIResponse>, any>()

const getUserMoneyAction = createAsyncAction(
    GET_USER_MONEY_REQUEST,
    GET_USER_MONEY_SUCCESS,
    GET_USER_MONEY_FAILURE,
    CLEAR_USER_MONEY,
)<any, AxiosResponse<IAPIResponse<IUserMoney>>, AxiosError<IAPIResponse>, any>()

const putUserMoneyAction = createAsyncAction(
    PUT_USER_MONEY_REQUEST,
    PUT_USER_MONEY_SUCCESS,
    PUT_USER_MONEY_FAILURE,
)<any, AxiosResponse<IAPIResponse<IUserMoney>>, AxiosError<IAPIResponse>>()

const postCreditMeDeTail = createAsyncAction(
    GET_ME_CREDIT_DETAIL_REQUEST,
    GET_ME_CREDIT_DETAIL_SUCCESS,
    GET_ME_CREDIT_DETAIL_FAILURE,
    CLEAR_ME_CREDIT_DETAIL,
)<any, AxiosResponse<IAPIResponse<ICreditDetail>>, AxiosError<IAPIResponse>, any>()

export {
    getCreditMeAction,
    getUserMoneyAction,
    putUserMoneyAction,
    postCreditMeDeTail
}