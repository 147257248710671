export const POST_LOTTO_RESULT_REQUEST = 'POST_LOTTO_RESULT_REQUEST'
export const POST_LOTTO_RESULT_SUCCESS = 'POST_LOTTO_RESULT_SUCCESS'
export const POST_LOTTO_RESULT_FAILURE = 'POST_LOTTO_RESULT_FAILURE'
export const POST_LOTTO_RESULT_CANCEL = 'POST_LOTTO_RESULT_CANCEL'

export const POST_LOTTO_REVERSE_REQUEST = 'POST_LOTTO_REVERSE_REQUEST'
export const POST_LOTTO_REVERSE_SUCCESS = 'POST_LOTTO_REVERSE_SUCCESS'
export const POST_LOTTO_REVERSE_FAILURE = 'POST_LOTTO_REVERSE_FAILURE'

export const POST_LOTTO_CURRENT_RESULT_REQUEST =
    'POST_LOTTO_CURRENT_RESULT_REQUEST'
export const POST_LOTTO_CURRENT_RESULT_SUCCESS =
    'POST_LOTTO_CURRENT_RESULT_SUCCESS'
export const POST_LOTTO_CURRENT_RESULT_FAILURE =
    'POST_LOTTO_CURRENT_RESULT_FAILURE'

export const initialLottoResultState: ReducerState<ILotterySchedule[]> = {
    isFetching: false,
    code: 0,
    data: [],
    error: ''
}

export const initialLottoReverseState: ReducerState<null> = {
    isFetching: false,
    code: 0,
    data: null,
    error: ''
}

export const initialLottoCurrentResultState: ReducerState<IResponseLotterCurrentResult> =
{
    isFetching: false,
    code: 0,
    data: {
        createdAt: '',
        lotterCurrentResult: [],
        status: ''
    },
    error: ''
}
